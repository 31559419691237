import React, {useState} from "react";
import { Select } from 'antd';
const { Option } = Select;

export default function SearchSelect({
                                      style,
                                      placeholder,
                                      value,
                                      options,
                                      onSelect,
                                    }) {

  let [filteredOptions, setFilteredOptions] = useState([]);

  const handleSearch = (query) => {
    setFilteredOptions(options.filter(el => el.title.toLowerCase().indexOf(query.toLowerCase()) >= 0))
  };

  const handleSelect = (selection) => {
    const option = options.find((el) => el.id == selection);
    setFilteredOptions([]);
    onSelect(option);
  };

  return (
    <Select
      allowClear
      showSearch
      value={value}
      placeholder={placeholder}
      style={style}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleSelect}
      notFoundContent={null}
    >
      {filteredOptions?.map((d) => <Option key={d.id}>{d.title}</Option>)}
    </Select>
  );
}