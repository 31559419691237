import React, {useEffect, useState} from "react";
import moment from "moment";
import {apiClient} from "../../../../services/ApiClient";
import {Button, DatePicker, Input, message, PageHeader, Select, Space, Table} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import UpdateButton from "../../../buttons/UpdateButton";

const {Search} = Input;
const {Option} = Select;
const { RangePicker } = DatePicker;

const STATES = [
  {"id": "issuance", title: "ВОЗВРАТ/ВЫДАЧА (ПРОСТОЙ)"},
  {"id": "paid_issuance", title: "ВОЗВРАТ/ВЫДАЧА С ОПЛАТОЙ"},
  {"id": "paid_issuance_only", title: "ВЫДАЧА С ОПЛАТОЙ"},
  {"id": "return", title: "ВОЗВРАТ (ПРОСТОЙ)"},
  {"id": "paid_return", title: "ВОЗВРАТ С ОПЛАТОЙ"},
];

export default function InvestorReportPage() {
  let [records, setRecords] = useState([]);
  let [loading, setLoading] = useState(false);
  let [recordsStamp, setRecordsStamp] = useState(0);
  let [cols, setCols] = useState([]);
  let [paymentGroups, setPaymentGroups] = useState([]);
  let [ownerOptions, setOwnerOptions] = useState([]);
  let [modelOptions, setModelOptions] = useState([]);
  let [regions, setRegions] = useState([]);
  let [filter, setFilter] = useState({});

  useEffect(() => {
    setLoading(true);
    let args = {
      year: moment().year(),
      month: moment().month() + 1,
      group: filter.group,
      search: filter.search,
    };
    if(filter.dateFilter) {
      args.year = moment(filter.dateFilter, "YYYY-MM-DD").year();
      args.month = moment(filter.dateFilter, "YYYY-MM-DD").month() + 1;
    }
    apiClient.getReportRecords("invest/summary", args)
      .then(res => {
        if(res.records) {
          setRecords(res.records);
          let columns = [
            {
              title: "",
              align: "center",
              width: 30,
            },
            {
              title: 'Номер ТС',
              width: 120,
              render: (text, record, index) => {
                return record.vehicle?.reg_number;
              },
            },
            {
              title: "Собственник ТС",
              width: 200,
              render: (text, record, index) => {
                return record.owner?.title;
              },
            }
          ];

          res.dates.forEach(el => {
            const dateLabel = moment(el).format('DD.MM.YYYY');
            columns.push({
              title: dateLabel,
              width: 100,
              align: "center",
              render: (text, record, index) => {
                const stateId = record.days.find(d => d.date === dateLabel)?.state;
                return STATES.find(s => s.id === stateId)?.title;
              },
            });
          });

          columns.push({
            title: "",
            align: "center",
            width: 20,
          });

          setCols(columns);
        }
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [recordsStamp, filter]);

  useEffect(() => {
    apiClient.getVehiclesFilters()
      .then(res => {
        setOwnerOptions(res.owners);
        setModelOptions(res.brands);
        setPaymentGroups(res.groups);
        setRegions(res.regions);
      })
      .catch(err => message.error(err))
  }, []);

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Отчет инвесторам"
      />

      <Space direction={"horizontal"} wrap style={{width: '100%', marginBottom: 10}}>
        <Search
          enterButton
          placeholder={"Поиск по номеру ТС"}
          onSearch={(value) => {
            setFilter(prevState => {
              return {...prevState, "search": value}
            });
          }}
          allowClear={true}
          style={{width: 400}}
        />
        <DatePicker
          allowClear
          format="MMMM, YYYY"
          picker={"month"}
          placeholder="Год/Месяц"
          value={filter.dateFilter ? moment(filter.dateFilter, "YYYY-MM-DD") : null}
          onChange={(val) => {
            setFilter(prevState => {
              return {...prevState, "dateFilter": val ? val.format("YYYY-MM-DD") : null}
            });
          }}
          style={{width: 220}}
        />
        <Select
          showSearch
          allowClear
          defaultValue={[]}
          style={{width: 400}}
          placeholder="Группа"
          onChange={(value) => {
            setFilter(prevState => {
              return {...prevState, "group": value}
            })
          }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {paymentGroups.map((el) => (<Option key={el.id} value={el.id}>{el.title}</Option>))}
        </Select>
      </Space>

      <Table
        dataSource={records}
        scroll={{x: 1000}}
        sticky
        columns={cols}
        size="small"
        style={{margin: '10px 0', marginTop: 40}}
        rowKey="id"
        loading={loading}
        pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [50, 100, 200]}}
      />
    </>
  );
}