import React, {useEffect, useState} from "react";
import {Col, Input, InputNumber, message, Modal, Row} from "antd";
import '../../../ModalStyle.css';

const rowStyle = {padding: '8px'};

export default function TaxiCarGroupEditDialog({visible, taxiCarGroup, onOk, onCancel, title}) {
  let [record, setRecord] = useState({id: 0});

  useEffect(() => {
    if(taxiCarGroup) {
      setRecord({
        id: taxiCarGroup.id,
        title: taxiCarGroup.title || "",
        transaction_type: taxiCarGroup.payment_percentage || 0
      });
    } else {
      setRecord({id: 0});
    }
  }, [taxiCarGroup]);

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-small-window"
      title={title || "Новая запись"}
      visible={visible}
      onOk={() => {
        if(!record.title) {
          message.warn("Укажите наименование");
          return;
        }
        if(!record.payment_percentage) {
          message.warn("Укажите процент управляющей компании");
          return;
        }
        onOk(record);
        setRecord({id: 0});
      }}
      onCancel={onCancel}>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Наименование</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            placeholder="Наименование"
            value={record.title}
            onChange={(e) => { setRecord({...record, title: e.target.value}) }}/>
        </Col>
      </Row>


      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Процент</Col>
        <Col span={18}>
          <InputNumber
            style={{width: '100%'}}
            value={record.payment_percentage}
            min="0"
            max="100"
            step="0.01"
            onChange={(value) => {
              setRecord({...record, payment_percentage: value});
            }}/>
        </Col>
      </Row>

    </Modal>
  );
}