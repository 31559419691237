import React, {useEffect, useState} from "react";
import {Button, Table, message} from "antd";
import moment from "moment";
import {apiClient} from "../../../../../services/ApiClient";
import AddFineManualPaymentsDialog from "./AddFineManualPaymentsDialog";

export default function FineManualPaymentsTab({record, driver, onPaymentCreated}) {

  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [paymentsUpdate, setPaymentsUpdate] = useState(0);
  const [addFineManualPaymentsDialogVisible, setAddFineManualPaymentsDialogVisible] = useState(false);

  useEffect(() => {
    setLoading(true);
    apiClient.getPaymentTemplateExecutionPayments(driver.id, record.id)
      .then(res => {
        setPayments(res.records);
      })
      .catch(err => message.error(err))
      .finally(() => {
        setLoading(false);
      })

  }, [driver, record, paymentsUpdate]);

  const addManualPayment = (amount, comment) => {
    setLoading(true);
    apiClient.addPaymentTemplateExecutionPayments(driver.id, record.id, amount, comment)
      .then(() => {
        onPaymentCreated();
        setPaymentsUpdate(prevState => prevState + 1);
      })
      .catch(err => message.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{margin: '0 10px 0 10px'}}>
      <div style={{margin: '0 0 5px 0'}}>
        <Button onClick={() => { setAddFineManualPaymentsDialogVisible(true) }} disabled={record?.execution_status === "completed"}>Добавить</Button>
      </div>
      <Table
        loading={loading}
        dataSource={payments}
        rowClassName={(record, index) => {
          return `status-${record.execution_status}`
        }}
        columns={[
          {
            title: "",
            align: "center",
            width: 30,
          },
          {
            width: 150,
            title: "Дата",
            dataIndex: "create_dt",
            render: (text, record, index) => {
              return <div style={{textAlign: "left"}}>{`${moment(record.create_dt).format('DD.MM.YYYY HH:mm:ss')}`}</div>;
            },
          },
          {
            width: 120,
            title: "Сумма",
            dataIndex: "paid",
          },
          {
            title: "Комментарий",
            dataIndex: "comment",
          },
        ]}
        size="small"
        scroll={{x: 1000}}
        sticky
        rowKey="id"
        pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
      />

      <AddFineManualPaymentsDialog
        visible={addFineManualPaymentsDialogVisible}
        onOk={(amount, comment) => {
          if(amount > record.balance) {
            message.warn("Сумма внесений не может быть больше остатка");
            return;
          }
          addManualPayment(amount, comment);
          setAddFineManualPaymentsDialogVisible(false);
        }}
        onCancel={() => {
          setAddFineManualPaymentsDialogVisible(false);
        }}
      />

    </div>
  )
}