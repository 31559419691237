import React, {useState} from "react";
import {Tabs} from "antd";
import RegionsSettingsTab from "./dictionaries/RegionsSettingsTab";
import TransactionGroupsSettingsTab from "./dictionaries/TransactionGroupsSettingsTab";
import ExpenseCategoriesSettingsTab from "./dictionaries/ExpenseCategoriesSettingsTab";
import TaxiCarGroupsSettingsTab from "./dictionaries/TaxiCarGroupsSettingsTab";
import TaxiLicenceAuthoritySettingsTab from "./dictionaries/TaxiLicenceAuthoritySettingsTab";

const { TabPane } = Tabs;

export default function DictionariesTab({active}) {

  let [activeTabKey, setActiveTabKey] = useState("regionsSettings");

  const onTabChange = (key) => {
    setActiveTabKey(key);
  };

  return (
    <Tabs onChange={onTabChange} tabPosition={"left"}>
      <TabPane tab="Регионы" key="regionsSettings">
        <RegionsSettingsTab active={activeTabKey === "regionsSettings"}/>
      </TabPane>
      <TabPane tab="Группы платежей" key="transactionGroups">
        <TransactionGroupsSettingsTab active={activeTabKey === "transactionGroups"}/>
      </TabPane>
      <TabPane tab="Категории расходов" key="expenseCategories">
        <ExpenseCategoriesSettingsTab active={activeTabKey === "expenseCategories"}/>
      </TabPane>
      <TabPane tab="Группы ТС" key="taxiCarGroups">
        <TaxiCarGroupsSettingsTab active={activeTabKey === "taxiCarGroups"}/>
      </TabPane>
      <TabPane tab="Лицензирующие организации" key="taxiLicenceAuthorities">
        <TaxiLicenceAuthoritySettingsTab active={activeTabKey === "taxiLicenceAuthorities"}/>
      </TabPane>
    </Tabs>
  )
}