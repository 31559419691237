import React, {useEffect, useState} from "react";
import {Button, Col, Input, message, Row} from "antd";
import {apiClient} from "../../../../services/ApiClient";

export default function TgNotificationsSettingsTab({active}) {
  let [loading, setLoading] = useState(false);
  let [botToken, setBotToken] = useState("");
  let [driverBotToken, setDriverBotToken] = useState("");
  let [autoNotificationsChannel, setAutoNotificationsChannel] = useState("");
  let [manualNotificationsChannel, setManualNotificationsChannel] = useState("");

  const saveData = () => {
    const properties = {
      'core.tg_notification_chat': autoNotificationsChannel,
      'core.tg_manual_notification_chat': manualNotificationsChannel,
      'core.tg_bot_token': botToken,
      'core.tg_driver_bot_token': driverBotToken,
    };

    setLoading(true);
    apiClient.setSystemProperties(properties)
      .then(res => {
        message.info("Изменения сохранены");
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    apiClient.getSystemProperty("core.tg_bot_token")
      .then(res => {
        setBotToken(res.value);
      })
      .catch(err => message.error(err));
    apiClient.getSystemProperty("core.tg_notification_chat")
      .then(res => {
        setAutoNotificationsChannel(res.value);
      })
      .catch(err => message.error(err));
    apiClient.getSystemProperty("core.tg_manual_notification_chat")
      .then(res => {
        setManualNotificationsChannel(res.value);
      })
      .catch(err => message.error(err));
    apiClient.getSystemProperty("core.tg_driver_bot_token")
      .then(res => {
        setDriverBotToken(res.value);
      })
      .catch(err => message.error(err));
  }, [active]);

  return (
    <>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={5}>
          <h4>Токен Телеграм-бота Водитель</h4>
        </Col>
        <Col span={16}>
          <Input
            allowClear={true}
            style={{marginBottom: 8}}
            value={driverBotToken}
            onChange={(e) => { setDriverBotToken(e.target.value) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={5}>
          <h4>Токен Телеграм-бота уведомлений</h4>
        </Col>
        <Col span={16}>
          <Input
            allowClear={true}
            style={{marginBottom: 8}}
            value={botToken}
            onChange={(e) => { setBotToken(e.target.value) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={5}>
          <h4>Ручная рассылка отчета по самым новым комментариям водителей со страницы "Список водителей"</h4>
        </Col>
        <Col span={8}>
          <Input
            allowClear={true}
            placeholder="ID чата/группы"
            style={{width: 300, marginBottom: 8}}
            value={manualNotificationsChannel}
            onChange={(e) => { setManualNotificationsChannel(e.target.value) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={5}>
          <h4>Автоматическая рассылка новых комментариев у водителей</h4>
        </Col>
        <Col span={4}>
          <Input
            allowClear={true}
            placeholder="ID чата/группы"
            style={{width: 300, marginBottom: 8}}
            value={autoNotificationsChannel}
            onChange={(e) => { setAutoNotificationsChannel(e.target.value) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 8]} style={{margin: '9px 0'}}>
        <Col span={13}>
          <Button type="primary" loading={loading} onClick={() => { saveData() }}>
            Сохранить
          </Button>
        </Col>
      </Row>
    </>
  )
}