import React, {useEffect, useState} from "react";
import {message, PageHeader, DatePicker, Col, Text, Select, Card, List, Space} from "antd";
import { Label, Line, XAxis, CartesianGrid, Tooltip, ComposedChart, YAxis, Legend, Area, Bar, RadialBar, AreaChart } from "recharts";
import moment from "moment";
import { format } from 'date-fns';
import './ControlPanelArea.css'
const rowStyle = {padding: '4px', margin: "8px"};
const { RangePicker } = DatePicker;
let NOW = new Date();
let CURRENT = new Date(NOW.getFullYear(), NOW.getMonth() - 1, NOW.getDate());
NOW = format(NOW, "yyyy-MM-dd");
CURRENT = format(CURRENT, "yyyy-MM-dd");

export default function DashboardPage() {
  let [filters, setFilters] = useState({date_from: CURRENT, date_to: NOW});

  const data = [
    { name: 'C1', value: 100, value2: 50 },
    { name: 'C2', value: 20, value2: 200 },
    { name: 'C3', value: 300, value2: 30 },
    { name: 'C4', value: 90, value2: 70 },
  ];

  const data2 = [
    { name: 'C1', value: 100 }
  ];

  const listData = [
    'Автомобиль №1.',
    'Автомобиль №5.',
    'Автомобиль №2.',
    'Автомобиль №4.',
    'Автомобиль №3.',
  ];

  const listData2 = [
    'Документ №1.',
    'Документ №5.',
    'Документ №2.',
    'Документ №4.',
    'Документ №3.',
  ];

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Дашборды"
      />
      <Space direction="horizontal" align={{width: '100%'}} style={{margin: 15}} wrap>
        <RangePicker
          style={{width: 350}}
          defaultValue={[moment(CURRENT), moment(NOW)]}
          format={"YYYY-MM-DD"}
          onChange={(dates, dateStrings) => { setFilters({...filters, date_from: dateStrings[0], date_to: dateStrings[1]}); }}
        />
        <Select
          mode="multiple"
          style={{width: "240px"}}
          placeholder="Статус"
          value={[]}
          defaultValue={[]}
          // onChange={}
          allowClear
        >
        </Select>
      </Space>
      <div class="flexbox-container">
        <div style={{width: '55%', float: 'left', margin: 15}}>
          <Card title="Выдано машин" style={{borderRadius: 4}}>
            <AreaChart
              width={600}
              height={200}
              data={data}
              margin={{
                top: 20,
                right: 10,
                bottom: 20,
                left: 10
              }}
            >
              <defs>
                <linearGradient id="graph" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#D35858" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#D35858" stopOpacity={0.4} />
                </linearGradient>
                <linearGradient id="graph2" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#1b3fc2" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#1b3fc2" stopOpacity={0.4} />
                </linearGradient>
              </defs>
              <XAxis dataKey="date">
              </XAxis>
              <YAxis>
              </YAxis>
              <Tooltip />
                <>
                  {/* <Bar dataKey="value" name="Данные" barSize={20} fill="#D35858" /> */}
                </>
                <>
                  <Area type="monotone" dataKey="value2" name="Данные" stroke="#1b3fc2" fillOpacity={1} fill="url(#graph2)"  />
                  {/* <Area type="monotone" dataKey="value" name="Данные" stroke="#D35858" fillOpacity={1} fill="url(#graph)"  /> */}
                </>
              
            </AreaChart>
          </Card>
        </div>
        <div style={{width: '45%', float: 'right', margin: 15}}>
          <Card title="Список автомобилей"  style={{borderRadius: 4 }}>
            <List
              itemLayout="horizontal"
              size="small"
              dataSource={listData}
              renderItem={(item) => <List.Item>{item}</List.Item>}
              locale={{emptyText: 'Нет записей'}}
            />
          </Card>
        </div>
      </div>
      <div class="flexbox-container">
        <div style={{width: '70%', float: 'left', margin: 15}}>
          <Card title="Количество возвратов автомобилей" style={{borderRadius: 4}}>
            <ComposedChart
              width={700}
              height={250}
              data={data}
              margin={{
                top: 20,
                right: 10,
                bottom: 20,
                left: 10
              }}
            >
              <XAxis dataKey="date">
              </XAxis>
              <YAxis>
              </YAxis>
              <Tooltip />
                <>
                  <Bar dataKey="value" name="Данные" barSize={20} fill="#FACC2B" />
                  <Bar dataKey="value2" name="Данные" barSize={20} fill="#D35858" />
                </>
                <>
                  {/* <Line type="monotone" dataKey="value" name="Данные" stroke="#FACC2B" /> */}
                </>
              
            </ComposedChart>
          </Card>
        </div>
        <div style={{width: '30%', float: 'right', margin: 15}}>
          <Card style={{borderRadius: 4, background: "#58D3A5"}}>
            <h2 style={{color: "white"}}>7</h2>
            <h3 style={{color: "white"}}>Количество должников</h3>
          </Card>
          <Card style={{borderRadius: 4, background: "#1b3fc2", marginTop: 5}}>
            <h2 style={{color: "white"}}>15</h2>
            <h3 style={{color: "white"}}>ТО на сегодня</h3>
          </Card>
          <Card style={{borderRadius: 4, background: "#ff5767", marginTop: 5}}>
            <h2 style={{color: "white"}}>15</h2>
            <h3 style={{color: "white"}}>Документы со скорым окончанием срока действия</h3>
          </Card>
        </div>
      </div>
      {/* <div class="flexbox-container">
        <div style={{width: '70%', float: 'left', margin: 15}}>
          <Card title="Документы со скорым окончанием срока действия" style={{borderRadius: 4}}>
            <List
              itemLayout="horizontal"
              size="small"
              dataSource={listData2}
              renderItem={(item) => <List.Item>{item}</List.Item>}
              locale={{emptyText: 'Нет записей'}}
            />
          </Card>
        </div>
      </div> */}
    </>
  )
}