import React, {useContext, useEffect, useState} from "react";
import {Col, Input, Modal, Row, Select, message, TimePicker, Button, Space, Switch} from "antd";
import {
  TemplatePaymentSource,
  TemplateProcessingType,
  TemplateCategory,
  TemplateScheduleType,
  SchedulerPeriods
} from "../../../../common/AppEnums";
import moment from "moment";
import UserProfileContext from "../../../../../services/UserProfileContext";

const { Option } = Select;
const { TextArea } = Input;

const emptyTemplate = {
  id: 0,
  title: '',
  comment: '',
  payment_source: TemplatePaymentSource[0].value,
  processing_type: TemplateProcessingType[0].value,
  enable_custom_schedule: false,
  schedule_type: TemplateScheduleType[0].value,
  regular_period: SchedulerPeriods[0].value,
  pattern_run_days: 1,
  pattern_skip_days: 1,
  category: null,
  execution_time: null,
  rate: 0,
  limit: 0,
};
const rowStyle = {padding: '8px'};

export default function TransactionTemplateDialog({visible, record, readOnly, onOk, onCancel, title}) {
  const userProfile = useContext(UserProfileContext);

  let [template, setTemplate] = useState(emptyTemplate);

  useEffect(() => {
    if(record) {
      setTemplate(r => {
        return {
          ...r,
          id: record.id,
          execution_time: record.execution_time || emptyTemplate.execution_time,
          title: record.title || emptyTemplate.title,
          comment: record.comment || emptyTemplate.comment,
          payment_source: record.payment_source || emptyTemplate.payment_source,
          processing_type: record.processing_type || emptyTemplate.processing_type,
          enable_custom_schedule: record.enable_custom_schedule || emptyTemplate.enable_custom_schedule,
          schedule_type: record.schedule_type || emptyTemplate.schedule_type,
          regular_period: record.regular_period ? String(record.regular_period) : emptyTemplate.regular_period,
          pattern_run_days: record.pattern_run_days || emptyTemplate.pattern_run_days,
          pattern_skip_days: record.pattern_skip_days || emptyTemplate.pattern_skip_days,
          rate: record.rate || emptyTemplate.rate,
          limit: record.limit || emptyTemplate.limit,
          category: record.category || emptyTemplate.category,
        }
      });
    } else {
      setTemplate(emptyTemplate);
    }
  }, [record]);

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-middle-window"
      style={{top: 10}}
      title={title || "Новая запись"}
      visible={visible}
      onCancel={() => { onCancel(); }}
      footer={[
        !readOnly && (
          <Button key="submit"
                  type="primary"
                  onClick={() => {
                    if(!template.title) {
                      message.error("Укажите наименование шаблона");
                      return;
                    }
                    if(!template.rate) {
                      message.error("Укажите ставку");
                      return;
                    }
                    if(template.processing_type === "limited" && !template.limit) {
                      message.error("Укажите лимит");
                      return;
                    }
                    if(template.enable_custom_schedule) {
                      if(!template.schedule_type) {
                        message.error("Укажите порядок списания");
                        return;
                      }
                      if(template.schedule_type === "regular" && !template.regular_period) {
                        message.error("Укажите период списания");
                        return;
                      }
                      if(template.schedule_type === "pattern" && (!template.pattern_run_days || !template.pattern_skip_days)) {
                        message.error("Укажите параметры периодичности списаний");
                        return;
                      }
                    }

                    onOk(template);

                    setTemplate(emptyTemplate);
                  }}>
            OK
          </Button>
        ),
        <Button key="cancel"
                onClick={() => {
                  onCancel();
                }}>
          Отмена
        </Button>,
      ]}
    >

      {/* Наименование */}
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Наименование</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            disabled={readOnly}
            placeholder="Наименование"
            required
            value={template.title}
            onChange={(e) => { setTemplate({...template, title: e.target.value}) }}/>
        </Col>
      </Row>

      {/* Счет */}
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Баланс</Col>
        <Col span={18}>
          <Select
            disabled={readOnly}
            value={template.payment_source ? template.payment_source : null}
            style={{width: '100%'}}
            onChange={(val, option) => {
              let change = {
                payment_source: val
              };
              if(val === "summary") {
                change.processing_type = "limited";
              }
              setTemplate({...template, ...change});
            }}>
            {
              TemplatePaymentSource.map(e => <Option key={e.value} value={e.value}>{e.text}</Option>)
            }
          </Select>
        </Col>
      </Row>

      {/* категория */}
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Категория</Col>
        <Col span={18}>
          <Select
            disabled={readOnly}
            placeholder={"Категория"}
            value={template.category ? template.category : null}
            style={{width: '100%'}}
            onChange={(val, option) => {
              setTemplate({...template, category: val})
            }}>
            {
              TemplateCategory
                .filter(el => !(!readOnly && el.value === "fine"))
                .map(e => <Option key={e.value} value={e.value}>{e.text}</Option>)
            }
          </Select>
        </Col>
      </Row>

      {/* вид операции (регулярный платеж / платеж с лимитом) */}
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Вид списаний</Col>
        <Col span={18}>
          <Select
            disabled={readOnly}
            value={template.processing_type ? template.processing_type : null}
            style={{width: '100%'}}
            onChange={(val, option) => {
              setTemplate({...template, processing_type: val})
            }}>
            {
              TemplateProcessingType
                .filter(e => (template.payment_source === "summary" && e.value === "limited") || template.payment_source !== "summary")
                .map(e => <Option key={e.value} value={e.value}>{e.text}</Option>)
            }
          </Select>
        </Col>
      </Row>

      {/* вид операции (регулярный платеж / платеж с лимитом) */}
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Ставка, руб</Col>
        <Col span={18}>
          <Input allowClear={true}
                 disabled={readOnly}
                 value={template.rate ? template.rate : null}
                 onChange={(e) => { setTemplate({...template, rate: e.target.value}) }}
                 placeholder="Сумма, руб" type={"number"}/>
        </Col>
      </Row>

      {
        template.processing_type === "limited"
          ? (
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Лимит, руб</Col>
              <Col span={18}>
                <Input allowClear={true}
                       disabled={readOnly}
                       value={template.limit ? template.limit : null}
                       onChange={(e) => { setTemplate({...template, limit: e.target.value}) }}
                       placeholder="Сумма, руб" type={"number"}/>
              </Col>
            </Row>
          ) : null
      }

      {
        userProfile.tenant.is_root && (
          <>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Изменить порядок списания</Col>
              <Col span={18}>
                <Switch
                  onChange={(checked) => {
                    setTemplate({...template, enable_custom_schedule: checked})
                  }}
                  checked={template?.enable_custom_schedule}
                />
              </Col>
            </Row>

            {
              template?.enable_custom_schedule && (
                <>
                  {/* Порядок списания */}
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Порядок списания</Col>
                    <Col span={18}>
                      <Select
                        value={template.schedule_type}
                        style={{width: '100%'}}
                        onChange={(val, option) => {
                          setTemplate({...template, schedule_type: val})
                        }}>
                        {
                          TemplateScheduleType.map(e => <Option key={e.value} value={e.value}>{e.text}</Option>)
                        }
                      </Select>
                    </Col>
                  </Row>

                  {
                    template.schedule_type === "regular"
                      ? (
                        // периодические списания
                        <Row gutter={[0, 16]} style={rowStyle}>
                          <Col span={6}>Периодичность списаний</Col>
                          <Col span={18}>
                            <Select
                              value={template.regular_period}
                              style={{width: '100%'}}
                              onChange={(val, option) => {
                                setTemplate({...template, regular_period: val})
                              }}>
                              {
                                SchedulerPeriods
                                  .filter(e => e.visible)
                                  .map(e => <Option key={e.value} value={e.value}>{e.text}</Option>)
                              }
                            </Select>
                          </Col>
                        </Row>
                      )
                      : (
                        // списания по шаблону
                        <>
                          <Row gutter={[0, 16]} style={rowStyle}>
                            <Col span={6}>Периодичность списаний</Col>
                            <Col span={18}>
                              <Space direction={"horizontal"}>
                                <Input allowClear={true}
                                       value={template.pattern_run_days}
                                       onChange={(e) => { setTemplate({...template, pattern_run_days: e.target.value}) }}
                                       placeholder="Дней списаний"
                                       type={"number"}/>
                                <span>через</span>
                                <Input allowClear={true}
                                       value={template.pattern_skip_days}
                                       onChange={(e) => { setTemplate({...template, pattern_skip_days: e.target.value}) }}
                                       placeholder="Дней пропуска"
                                       type={"number"}/>
                              </Space>
                            </Col>
                          </Row>
                        </>
                      )
                  }
                </>
              )
            }
          </>
        )
      }

      {/* Время списания */}
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Время списания</Col>
        <Col span={18}>
          <TimePicker allowClear={true}
                      disabled={readOnly}
                      style={{width: 200}}
                      value={template.execution_time ? moment(template.execution_time, 'HH:mm:ss') : null}
                      onChange={(time, timeString) => { setTemplate({...template, execution_time: timeString }) }}
                      placeholder="Время списания"/>
        </Col>
      </Row>

      {/* комментарий */}
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Комментарий</Col>
        <Col span={18}>
          <TextArea rows={3}
                    disabled={readOnly}
                    onChange={(e) => { setTemplate({...template, comment: e.target.value}); }}
                    value={template.comment}
                    placeholder="Комментарий"/>
        </Col>
      </Row>

    </Modal>
  )
}