import React, {useEffect, useState} from "react";
import {
  AutoComplete,
  Col,
  Collapse,
  DatePicker,
  Input,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import {apiClient} from "../../../services/ApiClient";

import moment from "moment";
import {daDataService} from "../../../services/DaDataService";

const { Panel } = Collapse;
const { Option } = Select;

const rowStyle = {padding: '4px'};
const inputErrorStyle = {borderColor: 'darkred', borderWidth: '1px'};

export default function DriverCreateDialog({onOK, onCancel}) {

  let [loading, setLoading] = useState(false);
  let [record, setRecord] = useState({driver_license_country: "rus"});
  let [formErrors, setFormErrors] = useState([]);
  let [vehiclesLoading, setVehiclesLoading] = useState(false);
  let [vehicles, setVehicles] = useState([]);
  let [taxiParks, setTaxiParks] = useState([]);
  let [workRules, setWorkRules] = useState([]);
  let [workRulesLoading, setWorkRulesLoading] = useState(false);
  let [licenseCountries, setLicenseCountries] = useState([]);
  let [suggestions, setSuggestions] = useState({});

  useEffect(() => {
    apiClient.getParks()
      .then(res => { setTaxiParks(res.records); })
      .catch(err => { message.error(err); });
    apiClient.getValueLists(["driver_license_countries"])
      .then(res => {
        setLicenseCountries(res.records["driver_license_countries"])
      })
      .catch(err => { message.error(err); });
  }, []);

  const validateRecord = () => {
    return new Promise((resolve, reject) => {
      let errors = [
        "surname",
        "driver_license_number",
        "name",
        "patronymic",
        "driver_license_issue_dt",
        "phone",
        "driver_license_expiry_dt",
        "driver_license_experience_dt",
        "taxi_park_id",
        "work_rule_uid",
        "contact_info",
        "birthday_dt",
        "email",
        "address",
        "vehicle_id",
      ].filter(attr => { return !record[attr] });

      if(!errors || errors.length < 1) {
        resolve(record);
      } else {
        reject(errors);
      }
    })
  };

  const buildStyle = (attribute, style = {}) => {
    return formErrors.indexOf(attribute) >= 0
      ? {
        ...style,
        ...inputErrorStyle
      } :
      style;
  };

  return (
    <Modal
      destroyOnClose={true}
      style={{top: 10}}
      width={"80%"}
      title={"Водитель"}
      visible={true}
      confirmLoading={loading}
      onOk={() => {
        validateRecord()
          .then(validated => {
            setLoading(true);
            apiClient.createDriver(record)
              .then(res => {
                message.info("Водитель создан");
                onOK(res.record_id);
              })
              .catch(err => message.error(err))
              .finally(() => setLoading(false));
          })
          .catch(err => {
            setFormErrors(err);
            message.error("Заполните все необходимые поля")
          });
      }}
      onCancel={onCancel}>

      <Collapse style={{marginTop: 16}} defaultActiveKey={['1']}>
        <Panel header="Детали" key="1">
          <Row gutter={[0, 16]} style={rowStyle}>
            <Col md={3}>Фамилия</Col>
            <Col md={6}>
              <AutoComplete
                allowClear={true}
                placeholder="Фамилия"
                options={suggestions.surname || []}
                value={record?.surname}
                style={buildStyle("surname", {width: '100%'})}
                onChange={(data) => {
                  setRecord({...record, surname: data});
                  setSuggestions({...suggestions, surname: []});
                }}
                onSearch={(text) => {
                  if(text && text.length > 1 && !text.endsWith(" ")) {
                    daDataService.suggestFio(text)
                      .then(res => {
                        setSuggestions({...suggestions, surname: res.suggestions.map(el => {return {value: el.value}})});
                      })
                  }
                }}
              />
            </Col>
            <Col offset={1} md={3}>Серия и номер ВУ</Col>
            <Col md={6}>
              <Input
                style={buildStyle("driver_license_number")}
                allowClear={true}
                value={record.driver_license_number}
                onChange={(e) => { setRecord({...record, driver_license_number: e.target.value}) }}/>
            </Col>
          </Row>
          <Row gutter={[0, 16]} style={rowStyle}>
            <Col md={3}>Имя</Col>
            <Col md={6}>
              <AutoComplete
                allowClear={true}
                placeholder="Имя"
                options={suggestions.name || []}
                value={record?.name}
                style={buildStyle("name", {width: '100%'})}
                onChange={(data) => {
                  setRecord({...record, name: data});
                  setSuggestions({...suggestions, name: []});
                }}
                onSearch={(text) => {
                  if(text && text.length > 1 && !text.endsWith(" ")) {
                    daDataService.suggestFio(text)
                      .then(res => {
                        setSuggestions({...suggestions, name: res.suggestions.map(el => {return {value: el.value}})});
                      })
                  }
                }}
              />
            </Col>
            <Col offset={1} md={3}>Страна выдачи ВУ</Col>
            <Col md={6}>
              <Select
                showSearch
                allowClear
                style={buildStyle("name", {width: "100%"})}
                value={record.driver_license_country || "rus"}
                onChange={(value) => {
                  setRecord({...record, driver_license_country: value})
                }}
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {
                  licenseCountries.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
                }
              </Select>
            </Col>
          </Row>
          <Row gutter={[0, 16]} style={rowStyle}>
            <Col md={3}>Отчество</Col>
            <Col md={6}>
              <AutoComplete
                allowClear={true}
                placeholder="Отчество"
                options={suggestions.patronymic || []}
                value={record?.patronymic}
                style={buildStyle("patronymic", {width: '100%'})}
                onChange={(data) => {
                  setRecord({...record, patronymic: data});
                  setSuggestions({...suggestions, patronymic: []});
                }}
                onSearch={(text) => {
                  if(text && text.length > 1 && !text.endsWith(" ")) {
                    daDataService.suggestFio(text)
                      .then(res => {
                        setSuggestions({...suggestions, patronymic: res.suggestions.map(el => {return {value: el.value}})});
                      })
                  }
                }}
              />
            </Col>
            <Col offset={1} md={3}>Дата выдачи ВУ</Col>
            <Col md={6}>
              <DatePicker
                allowClear={false}
                format="DD.MM.YYYY"
                style={buildStyle("driver_license_issue_dt", {width: "100%"})}
                value={record.driver_license_issue_dt ? moment(record.driver_license_issue_dt, "YYYY-MM-DD") : null}
                onChange={(val) => { setRecord({...record, driver_license_issue_dt: val ? val.format("YYYY-MM-DD") : null }) }}
              />
            </Col>
          </Row>
          <Row gutter={[0, 16]} style={rowStyle}>
            <Col md={3}>Телефон</Col>
            <Col md={6}>
              <Input
                prefix={"+"}
                allowClear={true}
                style={buildStyle("phone")}
                value={record.phone}
                onChange={(e) => { setRecord({...record, phone: e.target.value}) }}/>
            </Col>
            <Col offset={1} md={3}>Действует до</Col>
            <Col md={6}>
              <DatePicker
                allowClear={false}
                format="DD.MM.YYYY"
                style={buildStyle("driver_license_expiry_dt", {width: "100%"})}
                value={record.driver_license_expiry_dt ? moment(record.driver_license_expiry_dt, "YYYY-MM-DD") : null}
                onChange={(val) => { setRecord({...record, driver_license_expiry_dt: val ? val.format("YYYY-MM-DD") : null }) }}
              />
            </Col>
          </Row>
          <Row gutter={[0, 16]} style={rowStyle}>
            <Col md={3}>Водительский стаж с</Col>
            <Col md={6}>
              <DatePicker
                allowClear={false}
                format="DD.MM.YYYY"
                style={buildStyle("driver_license_experience_dt", {width: "100%"})}
                value={record.driver_license_experience_dt ? moment(record.driver_license_experience_dt, "YYYY-MM-DD") : null}
                onChange={(val) => { setRecord({...record, driver_license_experience_dt: val ? val.format("YYYY-MM-DD") : null }) }}
              />
            </Col>
          </Row>
        </Panel>
        <Panel header="Условия работы" key="2">
          <Row gutter={[0, 16]} style={rowStyle}>
            <Col md={3}>Парк</Col>
            <Col md={6}>
              <Select
                showSearch
                allowClear
                value={record.taxi_park_id}
                style={buildStyle("taxi_park_id", {width: "100%"})}
                onChange={(value) => {
                  let park = taxiParks.find(el => el.id === value);
                  if(park) {
                    setWorkRulesLoading(true);
                    apiClient.getParkWorkRules(park.client_id, park.api_key, park.park_id)
                      .then(res => {
                        setWorkRules(res.records.filter(el => park.work_rules.find(pwr => pwr === el.id)));
                      })
                      .catch(err => { message.error(err); })
                      .finally(() => { setWorkRulesLoading(false) });
                    setVehiclesLoading(true);
                    apiClient.getVehicles({owned: true, park: park.id}, "minimal")
                      .then(res => { setVehicles(res.records); })
                      .catch(err => message.error(err))
                      .finally(() => { setVehiclesLoading(false) });
                  }
                  setRecord({...record, taxi_park_id: value})
                }}
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {
                  taxiParks.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
                }
              </Select>
            </Col>
            <Col offset={1} md={3}>Условия работы</Col>
            <Col md={6}>
              <Select
                showSearch
                allowClear
                loading={workRulesLoading}
                value={record.work_rule_uid}
                style={buildStyle("work_rule_uid", {width: "100%"})}
                onChange={(value) => {
                  setRecord({...record, work_rule_uid: value})
                }}
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {
                  workRules.map(el => <Option key={el.id} value={el.id}>{el.name}</Option>)
                }
              </Select>
            </Col>
          </Row>
          <Row gutter={[0, 16]} style={rowStyle}>
            <Col md={3}>Выберите ТС</Col>
            <Col md={6}>
              <Select
                allowClear
                showSearch
                loading={vehiclesLoading}
                value={record.vehicle_id}
                style={buildStyle("vehicle_id", {width: "100%"})}
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                onChange={(value) => {
                  setRecord({...record, vehicle_id: value})
                }}
              >
                {
                  vehicles?.map((el) => <Option key={el.id}>{`${el.brand} ${el.model} ${el.reg_number}`}</Option>)
                }
              </Select>
            </Col>
          </Row>
        </Panel>
        <Panel header="Личные данные" key="3">
          <Row gutter={[0, 16]} style={rowStyle}>
            <Col md={3}>Доверенный контакт</Col>
            <Col md={6}>
              <AutoComplete
                allowClear={true}
                placeholder="Доверенный контакт"
                options={suggestions.contact_info || []}
                value={record?.contact_info}
                style={buildStyle("contact_info", {width: '100%'})}
                onChange={(data) => {
                  setRecord({...record, contact_info: data});
                  setSuggestions({...suggestions, contact_info: []});
                }}
                onSearch={(text) => {
                  if(text && text.length > 1 && !text.endsWith(" ")) {
                    daDataService.suggestFio(text)
                      .then(res => {
                        setSuggestions({...suggestions, contact_info: res.suggestions.map(el => {return {value: el.value}})});
                      })
                  }
                }}
              />
            </Col>
            <Col offset={1} md={3}>Дата рождения</Col>
            <Col md={6}>
              <DatePicker
                allowClear={false}
                format="DD.MM.YYYY"
                style={buildStyle("birthday_dt", {width: "100%"})}
                value={record.birthday_dt ? moment(record.birthday_dt, "YYYY-MM-DD") : null}
                onChange={(val) => { setRecord({...record, birthday_dt: val ? val.format("YYYY-MM-DD") : null }) }}
              />
            </Col>
          </Row>
          <Row gutter={[0, 16]} style={rowStyle}>
            <Col md={3}>Email</Col>
            <Col md={6}>
              <AutoComplete
                allowClear={true}
                placeholder="Email"
                options={suggestions.email || []}
                value={record?.email}
                style={buildStyle("email", {width: '100%'})}
                onChange={(data) => {
                  setRecord({...record, email: data});
                  setSuggestions({...suggestions, email: []});
                }}
                onSearch={(text) => {
                  if(text && text.length > 1 && !text.endsWith(" ")) {
                    daDataService.suggestEmail(text)
                      .then(res => {
                        setSuggestions({...suggestions, email: res.suggestions.map(el => {return {value: el.value}})});
                      })
                  }
                }}
              />
            </Col>
          </Row>
          <Row gutter={[0, 16]} style={rowStyle}>
            <Col md={3}>Адрес</Col>
            <Col md={16}>
              <AutoComplete
                allowClear={true}
                placeholder="Адрес"
                options={suggestions.address || []}
                value={record?.address}
                style={buildStyle("address", {width: '100%'})}
                onChange={(data) => {
                  setRecord({...record, address: data});
                  setSuggestions({...suggestions, address: []});
                }}
                onSearch={(text) => {
                  if(text && text.length > 1 && !text.endsWith(" ")) {
                    daDataService.suggestAddress(text)
                      .then(res => {
                        setSuggestions({...suggestions, address: res.suggestions.map(el => {return {value: el.value}})});
                      })
                  }
                }}
              />
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </Modal>
  );
}