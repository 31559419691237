import React, {useEffect, useState} from "react";
import {Input, Space, Table, PageHeader, message} from "antd";
import UpdateButton from "../../buttons/UpdateButton";
import {apiClient} from "../../../services/ApiClient";
import "./VehiclesPage.css";
const {Search} = Input;

export default function VehiclesOSAGO() {
  let [loading, setLoading] = useState(false);
  let [update, setUpdate] = useState(0);
  let [ownedCount, setOwnedCount] = useState(0);
  let [search, setSearch] = useState("");
  let ownStatus = "owned";
  let [records, setRecords] = useState([]);

  useEffect(() => {
    setLoading(true);
    let params = {
      search: search,
    };
    if(ownStatus !== "all") {
      params.owned = true;
    }
    apiClient.getVehiclesOSAGO(params)
      .then(res => {
        if(res.owned_count) {
          setRecords(res.records);
          setOwnedCount(res.owned_count);
        }
      })
      .catch(err => message.error(err))
      .finally(() => { setLoading(false); });
  }, [update, search, ownStatus]);

  

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Тех. осмотры"
        extra={
          <div style={{textAlign: "right"}}>
            Во владении: {ownedCount}
          </div>
        }
      />
      <Space direction={"horizontal"} wrap>
        <Search
          enterButton
          placeholder={"Поиск по гос.номеру"}
          onChange={(e) => { setSearch(e.target.value) }}
          allowClear={true}
          style={{width: 400}}/>
      </Space>
      <div>
        <Space style={{marginBottom: 16, float: "right"}} direction="horizontal">
          <UpdateButton loading={loading} onClick={() => { setUpdate(u => u + 1) }}/>
        </Space>
      </div>
      <Table
        loading={loading}
        size={'small'}
        scroll={{x: 1000}}
        sticky
        columns={[
          {
            title: "",
            align: "center",
            width: 30,
          },
          {
            title: 'Номер ТС',
            dataIndex: 'reg_number',
            width: 100,
          },
          {
            title: 'Марка/Модель',
            render: (text, record, index) => {
              return `${record.brand} ${record.model}`;
            },
            width: 200,
          },
          {
            title: 'Арендодатель',
            render: (text, record, index) => {
                return record.owner
                    ? record.owner.title
                    : "";
            }
          },
          {
            title: 'Дата окончания',
          },
          {
            title: 'Статус',
          },
          {
            title: 'Тип страховки',
          },
          {
            title: 'Комментарий',
            width: 150,
          },
          {
            title: "",
            align: "center",
            width: 20,
          }
        ]}
        dataSource={records}
        rowKey="id"
        rowClassName={(index) => {
            let className =  index % 2 !== 0 ? "vehicle-activity-blocked" : "vehicle-activity-active";
            return className;
        }}
      />
    </>
  )
}