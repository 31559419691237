import React, {useState} from "react";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  notification,
  Row,
} from "antd";
import {Link, useParams} from "react-router-dom";
import {apiClient} from "../../../services/ApiClient";
import './DriverCandidatePage.css'

const PHONE_REGEX = /^(\+7|7)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/gm;

export default function DriverCandidatePage() {

  const { tenantCode } = useParams();
  const [form] = Form.useForm();

  let [loading, setLoading] = useState(false);
  let [submitEnabled, setSubmitEnabled] = useState(false);
  let [formSubmitted, setFormSubmitted] = useState(false);
  let [requestId, setRequestId] = useState(apiClient.uuidv4());

  const onFinish = (values) => {
    values.rid = requestId;
    values.tenant_code = tenantCode;

    setLoading(true);

    apiClient
      .createDriverCandidatePublic(values)
      .then(() => {
        // clear form
        form.setFieldsValue({fio: '', phone: '', other_phone: '', email: '', address: '', friend_name: '', friend_phone: '', agreement: false});
        // update request id
        setRequestId(apiClient.uuidv4());
        //
        setFormSubmitted(true);
      })
      .catch(() => {
        // notify user
        notification.error({message: "Ошибка. Повторите операцию"});
      })
      .finally(() => {setLoading(false)});
  };

  if(formSubmitted) {
    return (
      <React.Fragment>
        <Row justify="space-around" align="middle">
          <Col xs={1} md={2}> </Col>
          <Col xs={22} md={20}>
            <br/>
            <br/>
            <br/>
            <div style={{textAlign: "center", margin: '50px 0'}}>
              <Alert message="Ваша заявка успешно зарегистрирована" type="success" />
              <p>
                <Link to={`/candidates/${tenantCode}`} onClick={() => { document.location.reload(true) }}>Назад</Link>
              </p>
            </div>
          </Col>
          <Col xs={1} md={2}> </Col>
        </Row>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Row justify="space-around" align="middle">
        <Col xs={1} md={2}> </Col>
        <Col xs={22} md={20}>
          <div>
            <div style={{textAlign: 'center', margin: '20px 0'}}>
              <h1>Анкета водителя</h1>
            </div>

            <Form
              form={form}
              className="user-form"
              layout="vertical"
              initialValues={{

              }}
              onFinish={onFinish}
              onValuesChange={(changedValues, allValues) => {
                setSubmitEnabled(allValues['agreement']);
              }}
            >

              <div className="form-fields">

                <Form.Item
                  label="ФИО"
                  name="fio"
                  rules={[
                    {
                      required: true,
                      message: 'Укажите ФИО',
                    },
                  ]}
                >
                  <Input placeholder="Введите ваше ФИО"/>
                </Form.Item>

                <Form.Item
                  label="Контактный телефон"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      pattern: PHONE_REGEX,
                      message: 'Укажите контактный телефон',
                    },
                  ]}
                >
                  <Input placeholder="+7 942 424 12 42"/>
                </Form.Item>

                <Form.Item
                  label="Ваш дополнительный номер телефона"
                  name="other_phone"
                  rules={[
                    {
                      required: true,
                      pattern: PHONE_REGEX,
                      message: 'Укажите телефон',
                    },
                  ]}
                >
                  <Input placeholder="+7 942 424 12 42"/>
                </Form.Item>

                <Form.Item
                  label="Ваша электронная почта"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: 'Укажите вашу электронную почту',
                    },
                  ]}
                >
                  <Input placeholder="fio@mail.ru"/>
                </Form.Item>

                <Form.Item
                  label="Адрес проживания"
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: 'Укажите адрес проживания',
                    },
                  ]}
                >
                  <Input placeholder=""/>
                </Form.Item>

                <Form.Item
                  label="ФИО родственника / друга"
                  name="friend_name"
                  rules={[
                    {
                      required: true,
                      message: 'Укажите ФИО родственника / друга',
                    },
                  ]}
                >
                  <Input placeholder=""/>
                </Form.Item>

                <Form.Item
                  label="Номер телефона родственника, друга"
                  name="friend_phone"
                  rules={[
                    {
                      required: true,
                      pattern: PHONE_REGEX,
                      message: 'Укажите номер телефона родственника, друга',
                    },
                  ]}
                >
                  <Input placeholder="+7 942 424 12 42"/>
                </Form.Item>


                <Form.Item
                  label="Согласие с политикой конфиденциальности"
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                      message: 'Необходимо принять политику конфиденцильности',
                    },
                  ]}
                >
                  <Checkbox>Нажимая кнопку «Отправить», я даю свое согласие на обработку персональных данных, в соответствии с Федеральным законом №152-ФЗ «О персональных данных»</Checkbox>
                </Form.Item>

              </div>

              <div style={{textAlign: "center"}}>
                <Form.Item>
                  <Button type="primary"
                          htmlType="submit"
                          className="form-button"
                          disabled={!submitEnabled}
                          loading={loading}>
                    Отправить
                  </Button>
                </Form.Item>
              </div>

            </Form>
          </div>
        </Col>
        <Col xs={1} md={2}> </Col>
      </Row>
    </React.Fragment>
  );
}