import React, {useEffect, useState} from "react";
import {Col, Input, message, Modal, Row} from "antd";
import '../../ModalStyle.css';

const rowStyle = {padding: '8px'};

export default function GroupDialog({visible, record, onOk, onCancel, title}) {
  let [group, setGroup] = useState({name: "", id: 0});

  useEffect(() => {
    if(record) {
      setGroup(r => {
        return {
          ...r,
          id: record.id,
          name: record.name,
        }
      });
    } else {
      setGroup({name: "", id: 0});
    }
  }, [record]);

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-small-window"
      style={{top: 10}}
      title={title || "Новая роль"}
      visible={visible}
      onOk={() => {
        if(!group.name) {
          message.warn("Укажите логин");
          return;
        }
        onOk(group);
        setGroup({name: "", id: 0});
      }}
      onCancel={onCancel}>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Имя</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            placeholder="Имя роли"
            value={group.name}
            onChange={(e) => { setGroup({...group, name: e.target.value}) }}/>
        </Col>
      </Row>

    </Modal>
  );}