import React, {useEffect, useState} from "react";
import { Droppable } from "react-beautiful-dnd";
import ListItem from "./ListItem";
import "./DragList.css";
// import styled from "styled-components";

// const ColumnHeader = styled.div`
//   text-transform: uppercase;
//   margin-bottom: 20px`;

const DraggableElement = ({ elements, key, prefix, onClick, status }) => {
  let color = {"NEW": "#58D3A5", "INSURANCE_INSPECTION": "#FACC2B", "INDEPENDENT_EXAMINATION": "#2196f3", "COURT": "#c03df7", "WAIT_FOR_INSURANCE_PAY": "orange", "WAIT_FOR_CULPRIT_PAY": "#f44336", "PAID": "#4caf50"};
  console.log({prefix});
  return (
    <Droppable key={prefix} droppableId={prefix}>
      {(provided, snapshot) => (
        <div>
        <div className="sticky" style={{marginBottom: 20, background: color[prefix], padding: 8, color: "white", borderRadius: 3}}>{status}</div>
        {/* <div style = {{...provided.droppableProps}} snapshot={snapshot} ref={provided.innerRef}> */}
        <div className="droppable-style" {...provided.droppableProps} ref={provided.innerRef}>
          {elements !== undefined && (elements.map((item, idx) => (
              <ListItem key={prefix} index={idx} item={item} onClick={onClick} status={status} prefix={prefix} />
          )))}
          {provided.placeholder}
        </div>
        </div>
      )}
    </Droppable>
  )
};

export default DraggableElement;