import React, {useContext, useEffect, useState} from "react";
import {Checkbox, Col, DatePicker, Input, message, Modal, Row, Select} from "antd";
import {apiClient} from "../../../../services/ApiClient";
import UserProfileContext from "../../../../services/UserProfileContext";
import moment from "moment";
import '../../ModalStyle.css';

const { Option } = Select;
const {TextArea} = Input;
const rowStyle = {padding: '4px'};

const emptyUser = {
  id: 0,
  username: "",
  first_name: "",
  email: "",
  password: "",
  passwordCheck: "",
  group: {},
  tenant: {},
  permissions: []
};

export default function UserDialog({visible, record, onOk, onCancel, title}) {
  const userProfile = useContext(UserProfileContext);

  let [user, setUser] = useState(emptyUser);
  let [groups, setGroups] = useState([]);
  let [tenants, setTenants] = useState([]);

  useEffect(() => {
    if(record) {
      setUser(r => {
        return {
          ...r,
          ...record
        }
      });
      if(record.group) {
        setGroups([record.group]);
      }
    } else {
      setUser(emptyUser);
    }
  }, [record]);

  useEffect(() => {
    apiClient.getGroups()
      .then(res => {
        setGroups(res.records);
      })
      .catch(err => {
        message.error(err);
      });
    if(userProfile.tenant.is_root) {
      apiClient.getTenants()
        .then(res => {
          setTenants(res.records);
        })
        .catch(err => {
          message.error(err);
        });
    }
  }, [record, userProfile]);

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-middle-window"
      style={{top: 10}}
      title={title || "Новый пользователь"}
      visible={visible}
      onOk={() => {
        if(!user.username) {
          message.warn("Укажите логин");
          return;
        }
        if(!user.first_name) {
          message.warn("Укажите ФИО");
          return;
        }
        if(!user.email) {
          message.warn("Укажите Email");
          return;
        }
        if(userProfile.tenant.is_root && (!user.tenant || !user.tenant.id)) {
          message.warn("Укажите организацию");
          return;
        }
        if(!user.group || !user.group.id) {
          message.warn("Укажите роль");
          return;
        }
        if(user.id === 0) {
          if(!user.password) {
            message.warn("Укажите пароль");
            return;
          }
        }
        if(user.password !== user.passwordCheck) {
          message.warn("Пароли не совпадают");
          return;
        }
        onOk(user);
        setUser(emptyUser);
      }}
      onCancel={() => {
        setUser(emptyUser);
        onCancel();
      }}>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Логин</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            value={user.username}
            onChange={(e) => { setUser({...user, username: e.target.value}) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>ФИО</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            value={user.first_name}
            onChange={(e) => { setUser({...user, first_name: e.target.value}) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>В документах</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            value={user.short_name}
            onChange={(e) => { setUser({...user, short_name: e.target.value}) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Email</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            value={user.email}
            onChange={(e) => { setUser({...user, email: e.target.value}) }}/>
        </Col>
      </Row>
      {
        userProfile.tenant.is_root ?
          (
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Организация</Col>
              <Col span={18}>
                <Select
                  placeholder={"Выберите организацию"}
                  value={user.tenant ? user.tenant.id : null}
                  style={{width: '100%'}}
                  onChange={(val, option) => {
                    let tenant = tenants.find(g => g.id === val);
                    if(tenant) {
                      setUser({...user, tenant: tenant})
                    }
                  }}>
                  {
                    tenants.map(e => <Option key={e.id} value={e.id}>{e.name}</Option>)
                  }
                </Select>
              </Col>
            </Row>
          ) : null
      }
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Роль</Col>
        <Col span={18}>
          <Select
            placeholder={"Роль"}
            value={user.group ? user.group.id : null}
            style={{width: '100%'}}
            onChange={(val, option) => {
              let group = groups.find(g => g.id === val);
              if(group) {
                setUser({...user, group: group})
              }
            }}>
            {
              groups.map(e => <Option key={e.id} value={e.id}>{e.name}</Option>)
            }
          </Select>
        </Col>
      </Row>
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Разрешения</Col>
        <Col span={18}>
          <div>
            <Checkbox
              checked={user.is_staff}
              onChange={(e) => {
                setUser({...user, is_staff: e.target.checked});
              }}
            >
              Администратор организации
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={user ? user.permissions.some(el => el === "balance_debit") : false}
              onChange={(e) => {
                if(e.target.checked) {
                  setUser({...user, permissions: [...user.permissions || [], 'balance_debit']});
                } else {
                  setUser({...user, permissions: user.permissions.filter(el => el !== 'balance_debit')});
                }
              }}
            >
              Списания с баланса
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={user ? user.permissions.some(el => el === "balance_credit") : false}
              onChange={(e) => {
                if(e.target.checked) {
                  setUser({...user, permissions: [...user.permissions || [], 'balance_credit']});
                } else {
                  setUser({...user, permissions: user.permissions.filter(el => el !== 'balance_credit')});
                }
              }}
            >
              Зачисления на баланс
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={user ? user.permissions.some(el => el === "change_work_condition") : false}
              onChange={(e) => {
                if(e.target.checked) {
                  setUser({...user, permissions: [...user.permissions || [], 'change_work_condition']});
                } else {
                  setUser({...user, permissions: user.permissions.filter(el => el !== 'change_work_condition')});
                }
              }}
            >
              Изменение условий работы
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={user ? user.permissions.some(el => el === "car_tracker_event:mark_as_seen") : false}
              onChange={(e) => {
                if(e.target.checked) {
                  setUser({...user, permissions: [...user.permissions || [], 'car_tracker_event:mark_as_seen']});
                } else {
                  setUser({...user, permissions: user.permissions.filter(el => el !== 'car_tracker_event:mark_as_seen')});
                }
              }}
            >
              Удаление событий мониторинга
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={user ? user.permissions.some(el => el === "change_fine_balance") : false}
              onChange={(e) => {
                if(e.target.checked) {
                  setUser({...user, permissions: [...user.permissions || [], 'change_fine_balance']});
                } else {
                  setUser({...user, permissions: user.permissions.filter(el => el !== 'change_fine_balance')});
                }
              }}
            >
              Изменение баланса списаний по штрафам
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={user ? user.permissions.some(el => el === "refund_payment_execution") : false}
              onChange={(e) => {
                if(e.target.checked) {
                  setUser({...user, permissions: [...user.permissions || [], 'refund_payment_execution']});
                } else {
                  setUser({...user, permissions: user.permissions.filter(el => el !== 'refund_payment_execution')});
                }
              }}
            >
              Возврат списаний по шаблонам
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={user ? user.permissions.some(el => el === "view_return_request_feedback") : false}
              onChange={(e) => {
                if(e.target.checked) {
                  setUser({...user, permissions: [...user.permissions || [], 'view_return_request_feedback']});
                } else {
                  setUser({...user, permissions: user.permissions.filter(el => el !== 'view_return_request_feedback')});
                }
              }}
            >
              Просмотр причин возврата ТС
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={user ? user.permissions.some(el => el === "dtp_view_summary") : false}
              onChange={(e) => {
                if(e.target.checked) {
                  setUser({...user, permissions: [...user.permissions || [], 'dtp_view_summary']});
                } else {
                  setUser({...user, permissions: user.permissions.filter(el => el !== 'dtp_view_summary')});
                }
              }}
            >
              ДТП: Сводная информация
            </Checkbox>
          </div>
        </Col>
      </Row>
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Макс. пополнения</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            type={"number"}
            placeholder="Сумма"
            value={user.max_balance_credit}
            onChange={(e) => { setUser({...user, max_balance_credit: e.target.value}) }}/>
            <div>
              Сегодня: {user.today_balance_credit} руб.
            </div>
        </Col>
      </Row>
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Макс. снятия</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            type={"number"}
            placeholder="Сумма"
            value={user.max_balance_debit}
            onChange={(e) => { setUser({...user, max_balance_debit: e.target.value}) }}/>
          <div>
            Сегодня: {user.today_balance_debit} руб.
          </div>
        </Col>
      </Row>
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Доверенность</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            placeholder="Номер и дата выдачи доверенности"
            value={user.letter_of_attorney}
            onChange={(e) => { setUser({...user, letter_of_attorney: e.target.value}) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Дата рождения</Col>
        <Col span={18}>
          <DatePicker
            allowClear={true}
            format="DD.MM.YYYY"
            placeholder="Дата рождения"
            value={user.birthday_dt ? moment(user.birthday_dt, "YYYY-MM-DD") : null}
            onChange={(val) => { setUser({...user, birthday_dt: val ? val.format("YYYY-MM-DD") : null }) }}
            style={{width: '100%'}}
          />
        </Col>
      </Row>
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Поздравление</Col>
        <Col span={18}>
          <TextArea
            rows={4}
            value={user.birthday_message}
            style={{width: '100%'}}
            onChange={(e) => setUser({...user, birthday_message: e.target.value })}
          />
        </Col>
      </Row>
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Пароль</Col>
        <Col span={18}>
          <Input.Password
            allowClear={true}
            placeholder="Пароль"
            onChange={(e) => { setUser({...user, password: e.target.value}) }}/>
        </Col>
      </Row>
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Подтверждение</Col>
        <Col span={18}>
          <Input.Password
            allowClear={true}
            placeholder="Пароль"
            onChange={(e) => { setUser({...user, passwordCheck: e.target.value}) }}/>
        </Col>
      </Row>

    </Modal>
  );}