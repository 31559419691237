import React, {useEffect, useState} from "react";
import {DatePicker, message, Modal, Select, Space} from "antd";
import {apiClient} from "../../../services/ApiClient";
import {TrackerEventCodes} from "../../../components/common/AppEnums";
import SearchSelect from "../../common/SearchSelect";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default function EventsSettingsDialog({settings, onOK, onCancel}) {

  let [data, setData] = useState(settings);
  let [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    apiClient.getVehicles({owned: true}, "minimal")
      .then(res => {
        setVehicles(res.records);
      })
      .catch(err => message.error(err));
  }, []);

  return (
    <Modal
      title={"Настройки"}
      visible={true}
      onOk={() => {
        onOK(data);
      }}
      onCancel={() => {
        onCancel();
      }}>
      <>
        <Space direction={"vertical"} style={{width: '100%'}}>
          <SearchSelect
            placeholder="Гос.номер"
            value={data?.regNumber}
            style={{width: '100%'}}
            options={vehicles.map(el => ({id: el.id, title: `${el.reg_number}`}))}
            onSelect={(el) => {
              setData({...data, ...{regNumber: el?.title}});
            }}
          />
          <RangePicker
            format="DD.MM.YYYY"
            style={{width: '100%'}}
            value={data?.period ? [moment(data.period[0]), moment(data.period[1])] : []}
            onChange={(val) => setData({...data, ...{period: val}})}
          />
          <Select
            allowClear
            mode="multiple"
            placeholder="События"
            value={data?.codes}
            style={{width: '100%'}}
            onChange={(value) => { setData({...data, ...{codes: value}}); }}
          >
            {
              TrackerEventCodes.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
            }
          </Select>
        </Space>
      </>
    </Modal>
  );
}