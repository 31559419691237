import React, {useEffect, useState} from "react";
import {Button, Space, Table, message} from "antd";
import {EyeOutlined, ReloadOutlined} from "@ant-design/icons";
import moment from "moment";
import {apiClient} from "../../../../services/ApiClient";
import InspectionDetails from "./InspectionDetails";
import UpdateButton from "../../../buttons/UpdateButton";
import DownloadButton from "../../../buttons/DownloadButton";

export default function InspectionTab({inspection, search, filterByDriver, status, active}) {
  let [loading, setLoading] = useState(true);
  let [records, setRecords] = useState([]);
  let [selectedRecord, setSelectedRecord] = useState(null);
  let [page, setPage] = useState(1);
  let [pageSize, setPageSize] = useState(50);
  let [total, setTotal] = useState(0);
  let [update, setUpdate] = useState(0);

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    {
      title: "",
      align: "center",
      dataIndex: "id",
      width: 50,
      render: function(text, record, index) {
        return (
          <Space direction={"horizontal"}>
            <Button type="link" icon={<EyeOutlined/>} onClick={() => {setSelectedRecord(record); return true; }}/>
          </Space>
        );
      }
    },
    {
      title: 'Дата прохождения',
      width: 180,
      align: "center",
      dataIndex: 'create_dt',
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
      },
      sorter: (a, b) => {
        return moment(a.create_dt).unix() - moment(b.create_dt).unix();
      }
    },
    {
      title: 'Номер ТС',
      dataIndex: 'vehicle_number',
      align: "center",
    },
    {
      title: 'Водитель',
      dataIndex: 'id',
      render: (text, record, index) => {
        if(!record.driver_info || !record.driver_info.last_name) {
          return "";
        }
        return `${record.driver_info.last_name} ${record.driver_info.first_name} ${record.driver_info.middle_name} (${record.driver_info.phone})`;
      },
    },
    {
      title: 'Пробег',
      dataIndex: 'mileage',
      align: "center",
    },
    {
      title: 'Уровень топлива',
      dataIndex: 'fuel',
      align: "center",
    },
    {
      title: 'Уведомление о ТО',
      dataIndex: 'notification',
    },
    {
      title: 'Описание ТО, ремонта',
      dataIndex: 'technical_description',
    },
    {
      title: 'Сотрудник',
      dataIndex: 'inspector',
      render: (text, record, index) => {
        if(!record.inspector) {
          return "";
        }
        return record.inspector;
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(() => {
    if(active) {
      const filters = {search, filterByDriver, status, page, pageSize, inspection: inspection};
      const subscription = apiClient.subscribeInspectionRecords(filters, ({records, total}) => {
        setRecords(records);
        setTotal(total);
        setLoading(false);
      });
      return () => apiClient.unsubscribe(subscription);
    }
  }, [search, filterByDriver, status, active, update, page, pageSize, inspection]);

  useEffect(() => {
    setLoading(active);
  }, [status, filterByDriver, search, active, update, page, pageSize, inspection]);

  return (
    <>
      <div style={{width: '10%', float: 'right'}}>
        <Space style={{float: "right"}}>
          <UpdateButton icon={<ReloadOutlined/>} onClick={ () => setUpdate(update + 1) }/>
          <DownloadButton onClick={ () => {
            const filters = {
              search,
              filterByDriver,
              status,
              page: 1,
              pageSize: 999999,
              startDate: moment().subtract(1, 'months').format('DD.MM.YYYY'),
              endDate: moment().format('DD.MM.YYYY'),
              inspection: inspection
            };
            setLoading(true);
            apiClient.exportInspectionRecords(filters, 'xlsx')
              .then(res => {
                window.open(res.link);
              })
              .catch(err => message.error(err))
              .finally(() => {
                setLoading(false);
              })
          } }/>
        </Space>
      </div>

      <Table
        loading={loading}
        columns={cols}
        size="small"
        rowKey="id"
        scroll={{x: 1000}}
        sticky
        dataSource={records}
        pagination={{
          position: "topRight",
          pageSizeOptions: [50, 100, 200],
          defaultPageSize: pageSize,
          current: page,
          total: total,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }
        }}
      />

      <InspectionDetails
        title={"Инспекция"}
        visible={selectedRecord !== null}
        record={selectedRecord}
        onClose={() => {
          setSelectedRecord(null)
        }}
      />
    </>
  );
}