import React, {useEffect, useState} from "react";
import {Button, Input, message, Modal, PageHeader, Select, Space, Table, Upload} from "antd";
import {
  DeleteOutlined,
  EditFilled, UploadOutlined, ReloadOutlined
} from "@ant-design/icons";
import {apiClient} from "../../../services/ApiClient";
import VehicleOwnerEditDialog from "./owner/VehicleOwnerEditDialog";
import {UnitTypes} from "../../common/AppEnums";
import UpdateButton from "../../buttons/UpdateButton";
import CancelButton from "../../buttons/CancelButton";

const {Search} = Input;
const { Option } = Select;

export default function VehicleOwnersPage() {
  let [loading, setLoading] = useState(false);
  let [editedRecord, setEditedRecord] = useState(null);
  let [selected, setSelected] = useState([]);
  let [records, setRecords] = useState([]);
  let [update, setUpdate] = useState(0);
  let [filterOptions, setFilterOptions] = useState({});

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: "",
      width: 50,
      render: (text, record, index) => {
        return <Button type="link" onClick={() => { setEditedRecord(record) }}><EditFilled/></Button>
      }
    },
    {
      title: 'Наименование',
      render: (text, record, index) => {
        if(record.type === "ip" || record.type === "sw") {
          return record.fio;
        }
        return record.full_title || record.short_title || "";
      }
    },
    {
      title: 'ИНН',
      dataIndex: 'inn',
    },
    {
      title: 'Тип собственника',
      render: (text, record, index) => {
        return UnitTypes.filter(el => el.id === record.type)[0].title;
      }
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(() => {
    setLoading(true);
    apiClient.getVehicleOwners(filterOptions)
      .then(res => {
        setRecords(res.records);
      })
      .catch(err => message.error(err))
      .finally(() => { setLoading(false); });
  }, [update, filterOptions]);

  const onDelete = () => {
    Modal.confirm({
      title:  "Удаление",
      content: "Вы уверены, что хотите удалить записи?",
      icon: <DeleteOutlined/>,
      visible: true,
      onOk() {
        apiClient.removeVehicleOwners(selected)
          .then(res => {
            message.success("Записи удалены");
            setSelected([]);
            setRecords(prevState => {
              return prevState.filter(e => selected.indexOf(e.id) < 0);
            })
          })
          .catch(err => message.error(err));
      },
    });
  };

  const onAdd = props => {
    apiClient.addVehicleOwner(props)
      .then(res => {
        message.success("Запись добавлена");
        apiClient.getVehicleOwners()
          .then(res => {
            setRecords(res.records);
          })
          .catch(err => message.error(err));
      })
      .catch(err => message.error(err));
  };

  const onUpdate = props => {
    apiClient.updateVehicleOwner(props.id, { ...props })
      .then(res => {
        message.success("Запись обновлена");
        apiClient.getVehicleOwners()
          .then(res => {
            setRecords(res.records);
          })
          .catch(err => message.error(err));
      })
      .catch(err => message.error(err));
  };

  const onExportClick = () => {
    setLoading(true);
    apiClient.exportVehicleOwners(filterOptions)
      .then(res => {
        window.open(apiClient.actionUrl(`park/owners/export/download?uid=${res.uid}`), "_blank");
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => { setLoading(false); });
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Собственники ТС"
        extra={
          <div style={{textAlign: "right"}}>
            Всего: {records.length}
          </div>
        }
      />

      <div style={{marginBottom: 5}}>
        <span style={{marginLeft: 8}}>
          <span style={{float: "right"}}>
            {selected.length > 0 ? `Выбрано ${selected.length} записей` : ''}
          </span>
        </span>
      </div>

      <div style={{width: '100%'}}>
        <div style={{width: '55%', float: 'left', marginBottom: 40}}>
        <Space direction="horizontal" wrap>
          <Search
            enterButton
            allowClear
            placeholder="Поиск по наименованию или ИНН"
            onSearch={(value) => setFilterOptions(prevState => { return {...prevState, search: value} })}
            style={{width: 400}}
          />
          <Select
            allowClear
            style={{ width: 200 }}
            placeholder="Тип собственника"
            onChange={(value) => { setFilterOptions(prevState => { return {...prevState, type: value} }) }}
          >
            {
              UnitTypes
              .filter(el => el.id !== "pl")
              .map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
            }
          </Select>
        </Space>
        </div>
        <div style={{width: '45%', float: 'right'}}>
        <Space style={{float: "right"}} direction="horizontal" wrap>
          <UpdateButton loading={loading} onClick={() => { setUpdate(u => u + 1) }}/>
          <Button type="primary" loading={loading} onClick={() => { setEditedRecord({id: 0}) }}>Добавить</Button>
          <Button type="danger" loading={loading} onClick={onDelete} disabled={selected.length === 0}>
            Удалить
          </Button>
          <CancelButton disabled={loading} onClick={() => { onExportClick() }} value="Экспорт"/>
          <Upload
            name={"file"}
            action={apiClient.actionUrl(`park/owners/import`)}
            headers={apiClient.requestConfig().headers}
            multiple={false}
            showUploadList={false}
            onChange={(info) => {
              if (info.file.status !== 'uploading') {
                setLoading(true);
              }
              if (info.file.status === 'done') {
                message.success({info});
                setUpdate(prevState => prevState + 1);
                setLoading(false);
              } else if (info.file.status === 'error') {
                if(info.file.response.message) {
                  Modal.error({
                    title: 'Ошибка импорта',
                    width: 800,
                    content: (
                      <>
                        <table style={{width: '100%'}}>
                          <thead>
                          <tr>
                            <th>Строка</th>
                            <th>Ошибка</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            info.file.response.details
                              ? info.file.response.details.map(row => {
                                return (
                                  <tr>
                                    <td>{row.row}</td>
                                    <td>{row.message}</td>
                                  </tr>
                                )
                              })
                              : (
                                <tr>
                                  <td colSpan={2}>Нет данных</td>
                                </tr>
                              )
                          }
                          </tbody>
                        </table>
                      </>
                    ),
                    onOk() {},
                  });
                } else if(info.file?.response?.detail) {
                  message.error(info.file?.response?.detail);
                } else {
                  message.error("Ошибка");
                }
                setUpdate(prevState => prevState + 1);
                setLoading(false);
              }
            }}
          >
            <Button type="primary" icon={<UploadOutlined />}>Загрузить</Button>
          </Upload>
        </Space>
        </div>
      </div>

      <Table
        columns={cols}
        dataSource={records}
        rowKey="id"
        scroll={{x: 1000}}
        sticky
        size="small"
        pagination={{current: 1, position: 'topRight', defaultPageSize: 20, pageSizeOptions: [50, 100, 200]}}
        rowSelection={{
          selectedRowKeys: selected,
          onChange: (selectedRowKeys) => {
            setSelected(selectedRowKeys);
          },
          getCheckboxProps: (record) => ({
            disabled: record.users_count > 0,
          }),
        }}
      />

      <VehicleOwnerEditDialog
        visible={editedRecord !== null}
        park={editedRecord}
        onOk={(p) => {
          if (p.id > 0) {
            onUpdate(p);
          } else {
            onAdd(p);
          }
          setEditedRecord(null);
        }}
        onCancel={() => {
          setEditedRecord(null)
        }}
        title={"Изменить запись"}
      />
    </>
  )
}