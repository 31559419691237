import React, {useEffect, useState} from "react";
import {
  AutoComplete,
  Checkbox,
  Col,
  Collapse, DatePicker, Input,
  message,
  Modal, Row, Select, Space,
  Button, Table, Tooltip, InputNumber
} from "antd";
import {
  LoadingOutlined,
  CloseOutlined,
  EditFilled,
  DeleteOutlined,
  CheckOutlined, QuestionCircleOutlined
} from "@ant-design/icons";
import {apiClient} from "../../../../services/ApiClient";
import moment from "moment";
import {
  ParkStatuses,
  VehicleCategories,
  VehicleGearboxTypes,
  VehicleSalonTypes,
  VehicleType,
  PassportType,
} from "../../../common/AppEnums";
import VehicleRentHistory from "./VehicleRentHistory";
import VehicleInspectionsHistory from "./VehicleInspectionsHistory";
import VehiclePropertiesChangeHistory from "./VehiclePropertiesChangeHistory";
import VehicleContracts from "./VehicleContracts";
import VehicleFiles from "./VehicleFiles";
import MenuButton from "../../../buttons/MenuButton";
import OsagoPolicyEditDialog from "./OsagoPolicyEditDialog";
import KaskoPolicyEditDialog from "./KaskoPolicyEditDialog";
import DiagnosticCardEditDialog from "./DiagnosticCardEditDialog";
import "../../ModalStyle.css";
import LinkButton from "../../../buttons/LinkButton";

const titleColStyle = {textAlign: "left", padding: 4, paddingLeft: 30};
const rowStyle = {padding: '4px'};
const inputErrorStyle = {borderColor: 'darkred', borderWidth: '1px'};
const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;

export default function VehicleEditDialog({visible, vehicleId, onClose}) {
  let [loading, setLoading] = useState(false);
  let [record, setRecord] = useState({id: 0});
  let [vehicleOwners, setVehicleOwners] = useState([]);
  let [vehicleGroups, setVehicleGroups] = useState([]);
  let [drivers, setDrivers] = useState([]);
  let [taxiparks, setTaxiparks] = useState([]);
  let [brandingOptions, setBrandingOptions] = useState([]);
  let [lightBoxOptions, setLightBoxOptions] = useState([]);
  let [promoOptions, setPromoOptions] = useState([]);
  let [regions, setRegions] = useState([]);
  let [taxiLicenceAuthorities, setTaxiLicenceAuthorities] = useState([]);
  let [selectedTabKey, setSelectedTabKey] = useState("1");
  let [formErrors, setFormErrors] = useState([]);
  let [notification, setNotification] = useState({TO: false, OSAGO: false, DK: false});
  let [osagoRecordsVersion, setOsagoRecordsVersion] = useState(0);
  let [osagoRecords, setOsagoRecords] = useState([]);
  let [editedOsagoRecord, setEditedOsagoRecord] = useState(null);
  let [editedKaskoRecord, setEditedKaskoRecord] = useState(null);
  let [kaskoRecordsVersion, setKaskoRecordsVersion] = useState(0);
  let [kaskoRecords, setKaskoRecords] = useState([]);
  let [editedDiagnosticCardRecord, setEditedDiagnosticCardRecord] = useState(null);
  let [diagnosticCardsVersion, setDiagnosticCardsVersion] = useState(0);
  let [diagnosticCards, setDiagnosticCards] = useState([]);

  useEffect(() => {
    setFormErrors([]);

    if(vehicleId && vehicleId > 0) {
      apiClient.getVehicleById(vehicleId)
        .then(res => {
          setRecord(res.record);

          if(!res.record.branding) {
            apiClient.getVehicleBrandingOptions()
              .then(res => setBrandingOptions(res.records))
              .catch(err => console.error(err));
          }
          if(!res.record.lightbox) {
            apiClient.getVehicleLightBoxOptions()
              .then(res => setLightBoxOptions(res.records))
              .catch(err => console.error(err));
          }
          if(!res.record.promo) {
            apiClient.getVehiclePromoOptions()
              .then(res => setPromoOptions(res.records))
              .catch(err => console.error(err));
          }
          if(!regions && res.record.work_region) {
            setRegions([res.record.work_region])
          }
          if(!taxiLicenceAuthorities && res.record.license_authority) {
            setTaxiLicenceAuthorities([res.record.license_authority])
          }
        })
        .catch(err => { message.error(err); })
        .finally(() => { setLoading(false) });
    } else {
      setRecord({id: 0})
    }
  }, [vehicleId]);

  useEffect(() => {
    // load OSAGO
    if(vehicleId && vehicleId > 0) {
      apiClient.getVehicleOsago(vehicleId)
        .then(res => {
          setOsagoRecords(res.records);
        })
        .catch(err => message.error(err));
    }
  }, [vehicleId, osagoRecordsVersion]);

  useEffect(() => {
    // load KASKO
    if(vehicleId && vehicleId > 0) {
      apiClient.getVehicleKasko(vehicleId)
        .then(res => {
          setKaskoRecords(res.records);
        })
        .catch(err => message.error(err));
    }
  }, [vehicleId, kaskoRecordsVersion]);

  useEffect(() => {
    if(vehicleId && vehicleId > 0) {
      apiClient.getVehicleDiagnosticCards(vehicleId)
        .then(res => {
          setDiagnosticCards(res.records);
        })
        .catch(err => message.error(err));
    }
  }, [vehicleId, diagnosticCardsVersion]);

  useEffect(() => {
    apiClient.getVehicleOwners()
      .then(res => { setVehicleOwners(res.records) })
      .catch(err => { console.error(err) });
    apiClient.getVehicleGroups()
      .then(res => { setVehicleGroups(res.records) })
      .catch(err => { console.error(err) });
    apiClient.getDriversList({ accountStatus: 'working' }, 'simple')
      .then(res => { setDrivers(res.drivers) })
      .catch(err => { console.error(err) });
    apiClient.getParks()
      .then(res => { setTaxiparks(res.records) })
      .catch(err => { console.error(err) });
    apiClient.getRegions({"order": "title"})
      .then(res => { setRegions(res.records) })
      .catch(err => { console.error(err) });
    apiClient.getTaxiLicenceAuthorities({"order": "title"})
      .then(res => { setTaxiLicenceAuthorities(res.records) })
      .catch(err => { console.error(err) });
  }, []);

  const deleteOsagoPolicy = policy => {
    Modal.confirm({
      title:  "Удаление",
      content: "Вы уверены, что хотите удалить записи?",
      icon: <DeleteOutlined/>,
      visible: true,
      onOk() {
        apiClient.deleteVehicleOsago(vehicleId, policy.id)
          .then(res => {
            setOsagoRecordsVersion(prevState => prevState + 1);
            message.info("Запись удалена");
          })
          .catch(err => message.error(err));
      },
    });
  };

  const deleteKaskoPolicy = policy => {
    Modal.confirm({
      title:  "Удаление",
      content: "Вы уверены, что хотите удалить записи?",
      icon: <DeleteOutlined/>,
      visible: true,
      onOk() {
        apiClient.deleteVehicleKasko(vehicleId, policy.id)
          .then(res => {
            setKaskoRecordsVersion(prevState => prevState + 1);
            message.info("Запись удалена");
          })
          .catch(err => message.error(err));
      },
    });
  };

  const deleteDiagnosticCard = policy => {
    Modal.confirm({
      title:  "Удаление",
      content: "Вы уверены, что хотите удалить записи?",
      icon: <DeleteOutlined/>,
      visible: true,
      onOk() {
        apiClient.deleteVehicleDiagnosticCard(vehicleId, policy.id)
          .then(res => {
            setDiagnosticCardsVersion(prevState => prevState + 1);
            message.info("Запись удалена");
          })
          .catch(err => message.error(err));
      },
    });
  };

  const parseDatetime = (datePart, timePart) => {
    if(!datePart) {
      return null;
    }
    if(timePart) {
      return moment(`${datePart} ${timePart}`)
    } else {
      return moment(datePart)
    }
  };

  const vehicleForm = () => {
    return (
      <>
        <Collapse style={{marginTop: 16}} defaultActiveKey={['1']}>
          <Panel header="Общие" key="1">
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Во владении</Col>
              <Col span={18}>
                <Checkbox
                  checked={record.is_owned}
                  onChange={(e) => {
                    setRecord({...record, is_owned: e.target.checked });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Статус ТС</Col>
              <Col span={18}>
                <Select
                  showSearch
                  allowClear
                  style={{width: 500}}
                  value={record.park_status}
                  onChange={(value) => {
                    if(value) {
                      setRecord({...record, park_status: value})
                    }
                  }}
                >
                  {
                    ParkStatuses.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
                  }
                </Select>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Тип ТС</Col>
              <Col span={6}>
                <Select
                  allowClear
                  value={record.vehicle_type}
                  style={{width: '100%'}}
                  onChange={(value) => {
                    if(value) {
                      setRecord({...record, vehicle_type: value});
                    }
                  }}
                >
                  {
                    VehicleType.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
                  }
                </Select>
              </Col>
              <Col span={2} style={titleColStyle}>Группа</Col>
              <Col span={6}>
                <Select
                  showSearch
                  allowClear
                  value={record.payment_group?.id}
                  style={{width: '100%'}}
                  onChange={(value) => {
                    if(value) {
                      setRecord({...record, payment_group: vehicleGroups.find(el => el.id === value)})
                    } else {
                      setRecord({...record, payment_group: null})
                    }
                  }}
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                >
                  {
                    vehicleGroups.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
                  }
                </Select>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Гос номер</Col>
              <Col span={6}>
                <Input
                  allowClear={true}
                  placeholder="Номер"
                  style={errorStyle("reg_number")}
                  value={record.reg_number}
                  onChange={(e) => { setRecord({...record, reg_number: e.target.value}) }}/>
              </Col>
              <Col span={2} style={titleColStyle}>Процент</Col>
              <Col span={6}>
                <InputNumber
                  style={{width: '100%'}}
                  value={record.payment_percentage || null}
                  precision={2}
                  min="0"
                  max="100"
                  step="0.01"
                  addonAfter={"%"}
                  onChange={(value) => {
                    setRecord({...record, payment_percentage: value});
                  }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Гаражный номер</Col>
              <Col span={6}>
                <Input
                  allowClear={true}
                  placeholder="Гаражный номер (позывной)"
                  value={record.callsign}
                  onChange={(e) => { setRecord({...record, callsign: e.target.value}) }}/>
              </Col>
              <Col span={2} style={titleColStyle}>Код</Col>
              <Col span={6}>
                <Input
                  allowClear={true}
                  placeholder="Код"
                  value={record.code}
                  onChange={(e) => { setRecord({...record, code: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>
                Св-во о регистрации
                <Tooltip placement="top" title="ВНИМАНИЕ! Невозможно изменить СТС, 
                  во избежание потери штрафов. 
                  Если СТС с ошибкой, то удалите этот элемент и создайте новый."
                >
                  <QuestionCircleOutlined style={{marginLeft: "5px"}}/>
                </Tooltip>
              </Col>
              <Col span={6}>
                  <Input
                    disabled={true}
                    placeholder="Серия и номер"
                    style={errorStyle("registration_cert")}
                    value={record.registration_cert}
                    onChange={(e) => { setRecord({...record, registration_cert: e.target.value}) }}/>
              </Col>
              <Col span={2}></Col>
              <Col span={7}>
                  <DatePicker
                    allowClear={false}
                    format="DD.MM.YYYY"
                    placeholder="Дата выдачи"
                    value={record.registration_cert_dt ? moment(record.registration_cert_dt, "YYYY-MM-DD") : null}
                    onChange={(val) => { setRecord({...record, registration_cert_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                    style={{width: "85%"}}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Марка</Col>
              <Col span={6}>
                <Input
                  allowClear={true}
                  placeholder="Марка"
                  value={record.brand}
                  style={errorStyle("brand")}
                  onChange={(e) => {
                    setRecord({...record, brand: e.target.value})
                  }}/>
              </Col>
              <Col span={2} style={titleColStyle}>
                Модель
              </Col>
              <Col span={6}>
                <Input
                  allowClear={true}
                  placeholder="Код"
                  value={record.model}
                  style={errorStyle("model")}
                  onChange={(e) => {
                    setRecord({...record, model: e.target.value})
                  }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Год выпуска</Col>
              <Col span={6}>
                <Input
                  allowClear={true}
                  type={"number"}
                  value={record.year}
                  onChange={(e) => { setRecord({...record, year: e.target.value}) }}/>
              </Col>
              <Col span={2} style={titleColStyle}>
                Цвет
              </Col>
              <Col span={6}>
                <Input
                  allowClear={true}
                  placeholder="Код"
                  value={record.color}
                  style={errorStyle("color")}
                  onChange={(e) => {
                    setRecord({...record, color: e.target.value})
                  }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Регион</Col>
              <Col span={6}>
                <Select
                  allowClear
                  placeholder="Выберите вариант из списка"
                  value={record?.work_region?.id}
                  style={{width: 300}}
                  onChange={(data) => {
                    let region = regions.find(el => el.id === data);
                    if(region) {
                      setRecord({...record, work_region: region});
                    }
                  }}
                >
                  {
                    regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
                  }
                </Select>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>
                Организация
                <Tooltip placement="top" title="C кем заключен агентский договор"
                >
                  <QuestionCircleOutlined style={{marginLeft: "5px"}}/>
                </Tooltip>
              </Col>
              <Col span={18}>
                <Input
                  allowClear={true}
                  placeholder={"Наименование организации"}
                  value={record.agency_contract_company}
                  onChange={(e) => { setRecord({...record, agency_contract_company: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>
                Арендодатель
                <Tooltip placement="top" title="Арендодатель (собственник ТС)">
                  <QuestionCircleOutlined style={{marginLeft: "5px"}}/>
                </Tooltip>
              </Col>
              <Col span={18}>
                <Select
                  showSearch
                  allowClear
                  value={record.owner ? record.owner.id : null}
                  style={{width: '100%'}}
                  onChange={(value) => {
                    if(value) {
                      setRecord({...record, owner: vehicleOwners.filter(el => el.id === value)[0]})
                    } else {
                      setRecord({...record, owner: null})
                    }
                  }}
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                >
                  {
                    vehicleOwners.map(el => <Option key={el.id} value={el.id}>{`${el.fio || el.full_title || el.short_title}`}</Option>)
                  }
                </Select>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Лизингодатель</Col>
              <Col span={18}>
                <Input
                  style={{width: '100%'}}
                  allowClear={true}
                  placeholder={"Лизингодатель"}
                  value={record.lessor_title}
                  onChange={(e) => { setRecord({...record, lessor_title: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Договор лизингодателя</Col>
              <Col span={18}>
                <Input
                  style={{width: '100%'}}
                  allowClear={true}
                  placeholder={"Договор лизингодателя"}
                  value={record.lessors_agreement}
                  onChange={(e) => { setRecord({...record, lessors_agreement: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Сублизингодатель</Col>
              <Col span={18}>
                <Input
                  style={{width: '100%'}}
                  allowClear={true}
                  placeholder={"Сублизингодатель"}
                  value={record.sub_lessor_title}
                  onChange={(e) => { setRecord({...record, sub_lessor_title: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Диспетчерская</Col>
              <Col span={6}>
                <Select
                  showSearch
                  allowClear
                  style={{width: '100%'}}
                  value={record.taxipark_id}
                  optionFilterProp='children'
                  onChange={(value) => {
                    setRecord({...record, taxipark_id: value})
                  }}
                >
                  {
                    taxiparks.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
                  }
                </Select>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>
                Водитель
                <Tooltip placement="top" title="Водителя можно изменить из карточки водителя при настройке текущего арендуемого ТС">
                  <QuestionCircleOutlined style={{marginLeft: "5px"}}/>
                </Tooltip>
              </Col>
              <Col span={18}>
                <Select
                  disabled={true}
                  style={{width: 500}}
                  value={record.current_driver?.id}
                  optionFilterProp='children'
                  onChange={(value) => {
                    if(value) {
                      setRecord({...record, current_driver: drivers.filter(el => el.id === value)[0]})
                    } else {
                      setRecord({...record, current_driver: null})
                    }
                  }}
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                >
                  {
                    drivers
                      .filter(el => el.id === record?.current_driver?.id)
                      .map(el => <Option key={el.id} value={el.id}>{`${el.last_name} ${el.first_name} ${el.middle_name} (${el.phone})`}</Option>)
                  }
                </Select>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Комментарий механика</Col>
              <Col span={18}>
                <Table
                  size={'small'}
                  pagination={{position: 'topRight', defaultPageSize: 10, pageSizeOptions: [15, 25, 50]}}
                  columns={[
                    {
                      title: "",
                      align: "center",
                      width: 10,
                    },
                    {
                      title: 'Дата',
                      width: 140,
                      render: (text, record, index) => {
                        return moment(record.create_dt).format("DD.MM.YYYY HH:mm");
                      },
                    },
                    {
                      title: 'Комментарий',
                      render: (text, record, index) => {
                        return record.comment;
                      }
                    },
                    {
                      title: "",
                      align: "center",
                      width: 10,
                    }
                  ]}
                  dataSource={record.maintenance_comments}
                  rowKey="id"
                />
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Статус загрузки штрафов</Col>
              <Col span={18}>
                <TextArea
                  rows={3}
                  value={record.fines_load_error}
                  onChange={(e) => { setRecord({...record, comment: e.target.value}) }}
                />
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Исключить из договоров</Col>
              <Col span={18}>
                <Space>
                  <Checkbox
                    checked={record.documents_disabled}
                    onChange={(e) => {
                      setRecord({...record, documents_disabled: e.target.checked });
                    }}
                  />
                  <Tooltip placement="top" title="Если автомобиль исключен из договоров, 
                    то он не будет отображаться в таблицах:  
                    Статус ТО, Осаго, Каско, ДК, Статус по типу ТС, Статусы по моделям"
                  >
                    <QuestionCircleOutlined/>
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Panel>
          <Panel header="Характеристики ТС" key="2">
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Пробег</Col>
              <Col span={6}>
                <Input
                  allowClear={true}
                  style={{width: 285}}
                  placeholder={"Пробег"}
                  value={record.mileage}
                  onChange={(e) => { setRecord({...record, mileage: e.target.value}) }}/>
              </Col>
            </Row>

            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Дней с осмотра</Col>
              <Col span={6}>
                <Input
                  allowClear={true}
                  style={{width: 285}}
                  placeholder={"Дней с осмотра"}
                  value={record.inspection_days}
                  onChange={(e) => { setRecord({...record, inspection_days: e.target.value}) }}/>
              </Col>
            </Row>

            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Категория ТС</Col>
              <Col span={6}>
                <Select
                  showSearch
                  allowClear
                  style={{width: 285}}
                  value={record.category}
                  onChange={(value) => {
                    if(value) {
                      setRecord({...record, category: value})
                    }
                  }}
                >
                  {
                    VehicleCategories.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
                  }
                </Select>
              </Col>
            </Row>

            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Тип КПП</Col>
              <Col span={6}>
                <Select
                  showSearch
                  allowClear
                  style={{width: 285}}
                  value={record.gearbox_type}
                  onChange={(value) => {
                    if(value) {
                      setRecord({...record, gearbox_type: value})
                    }
                  }}
                >
                  {
                    VehicleGearboxTypes.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
                  }
                </Select>
              </Col>
            </Row>

            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Тип салона</Col>
              <Col span={6}>
                <Select
                  showSearch
                  allowClear
                  style={{width: 285}}
                  value={record.salon_type}
                  onChange={(value) => {
                    if(value) {
                      setRecord({...record, salon_type: value})
                    }
                  }}
                >
                  {
                    VehicleSalonTypes.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
                  }
                </Select>
              </Col>
            </Row>

            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>VIN</Col>
              <Col span={6}>
                <Input
                  allowClear={true}
                  placeholder={"VIN"}
                  style={{width: 285}}
                  value={record.vin}
                  onChange={(e) => { setRecord({...record, vin: e.target.value}) }}/>
              </Col>
            </Row>

            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Номер двигателя</Col>
              <Col span={6}>
                <Input
                  allowClear={true}
                  placeholder={"Номер двигателя"}
                  style={{width: 285}}
                  value={record.engine_number}
                  onChange={(e) => { setRecord({...record, engine_number: e.target.value}) }}/>
              </Col>
            </Row>

            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Объем двигателя</Col>
              <Col span={6}>
                <Input
                  allowClear={true}
                  type={"number"}
                  placeholder={"Объем двигателя"}
                  style={{width: 285}}
                  value={record.engine_volume}
                  onChange={(e) => { setRecord({...record, engine_volume: e.target.value}) }}/>
              </Col>
            </Row>

            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Мощность двигателя (л/с)</Col>
              <Col span={6}>
                <Input
                  allowClear={true}
                  type={"number"}
                  placeholder={"Мощность двигателя"}
                  style={{width: 285}}
                  value={record.engine_power}
                  onChange={(e) => { setRecord({...record, engine_power: e.target.value}) }}/>
              </Col>
            </Row>

            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Номер газового балона</Col>
              <Col span={6}>
                <Input
                  allowClear={true}
                  placeholder={"Номер газового балона"}
                  style={{width: 285}}
                  value={record.gas_bottle_number}
                  onChange={(e) => { setRecord({...record, gas_bottle_number: e.target.value}) }}/>
              </Col>
            </Row>

            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Год газового балона</Col>
              <Col span={6}>
                <Input
                  allowClear={true}
                  type={"number"}
                  placeholder={"Год газового балона"}
                  style={{width: 285}}
                  value={record.gas_bottle_year}
                  onChange={(e) => { setRecord({...record, gas_bottle_year: e.target.value}) }}/>
              </Col>
            </Row>

            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Брендирование</Col>
              <Col span={6}>
                <AutoComplete
                  style={{width: 285}}
                  value={record.branding}
                  onChange={(data) => { setRecord({...record, branding: data}); }}
                  onSearch={(value) => {
                    apiClient.getVehicleBrandingOptions(value)
                      .then(res => setBrandingOptions(res.records))
                      .catch(err => console.error(err));
                  }}
                  placeholder="Брендирование"
                >
                  {brandingOptions.map((el) => (<Option key={el} value={el}>{el}</Option>))}
                </AutoComplete>
              </Col>
            </Row>

            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Световой короб</Col>
              <Col span={6}>
                <AutoComplete
                  style={{width: 285}}
                  placeholder="Световой короб"
                  value={record.lightbox}
                  onChange={(data) => { setRecord({...record, lightbox: data}); }}
                  onSearch={(value) => {
                    apiClient.getVehicleLightBoxOptions(value)
                      .then(res => setLightBoxOptions(res.records))
                      .catch(err => console.error(err));
                  }}
                >
                  {lightBoxOptions.map((el) => (<Option key={el} value={el}>{el}</Option>))}
                </AutoComplete>
              </Col>
            </Row>

            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Реклама</Col>
              <Col span={6}>
                <AutoComplete
                  style={{width: 285}}
                  placeholder="Реклама"
                  value={record.promo}
                  onChange={(data) => { setRecord({...record, promo: data}); }}
                  onSearch={(value) => {
                    apiClient.getVehiclePromoOptions(value)
                      .then(res => setPromoOptions(res.records))
                      .catch(err => console.error(err));
                  }}

                >
                  {promoOptions.map((el) => (<Option key={el} value={el}>{el}</Option>))}
                </AutoComplete>
              </Col>
            </Row>

            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>ID в GPS системе</Col>
              <Col span={6}>
                <Input
                  allowClear={true}
                  placeholder={"ID в GPS системе"}
                  style={{width: 285}}
                  value={record.gps_system_id}
                  onChange={(e) => { setRecord({...record, gps_system_id: e.target.value}) }}/>
              </Col>
            </Row>
          </Panel>

          <Panel header="Паспорт ТС" key="3">

            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Тип</Col>
              <Col span={6}>
                <Select
                  style={{ width: 220 }}
                  value={record.passport_type}
                  onChange={(value) => {
                    setRecord({...record, passport_type: value});
                  }}
                  allowClear={false}
                >
                  {PassportType.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)}
                </Select>
              </Col>
            </Row>

            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Серия и номер</Col>
              <Col span={6}>
                <Input
                  style={{width: 220}}
                  allowClear={true}
                  placeholder={"Серия и номер ПТС"}
                  value={record.passport_number}
                  onChange={(e) => { setRecord({...record, passport_number: e.target.value}) }}/>
              </Col>
            </Row>

            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Дата выдачи</Col>
              <Col span={6}>
                  <DatePicker
                    allowClear={false}
                    format="DD.MM.YYYY"
                    placeholder="Дата выдачи"
                    value={record.passport_issue_dt ? moment(record.passport_issue_dt, "YYYY-MM-DD") : null}
                    onChange={(val) => { setRecord({...record, passport_issue_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                    style={{width: 220}}/>
              </Col>
            </Row>

          </Panel>
          <Panel header="ТО" key="4">
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Пробег при последнем ТО</Col>
              <Col span={6}>
                <Input
                  style={{width: 250}}
                  value={record.maintenance_status?.last_maintenance_mileage}
                  placeholder={"Пробег при последнем ТО"}
                  onChange={(e) => {
                    let maintenance_status = record.maintenance_status || {};
                    maintenance_status.last_maintenance_mileage = e.target.value;
                    console.log(maintenance_status);
                    setRecord({...record, maintenance_status: maintenance_status })
                  }}
                />
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Дата предыдущего осмотра</Col>
              <Col span={6}>
                <Space direction={"horizontal"}>
                  <DatePicker
                    allowClear={true}
                    format="DD.MM.YYYY"
                    placeholder="Дата предыдущего осмотра"
                    value={
                      record.maintenance_status?.prev_maintenance_dt
                        ? moment(record.maintenance_status?.prev_maintenance_dt)
                        : null
                    }
                    onChange={(val) => {  setRecord({...record, maintenance_status: { ...record.maintenance_status, prev_maintenance_dt: val ? val.format("YYYY-MM-DD") : null } }) }}
                    style={{width: 250}}/>
                </Space>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Запланированная дата обслуживания</Col>
              <Col span={6}>
                <Space direction={"horizontal"}>
                  <DatePicker
                    allowClear={true}
                    format="DD.MM.YYYY"
                    placeholder="Дата предыдущего осмотра"
                    value={parseDatetime(record.maintenance_status?.planned_maintenance_dt, record.maintenance_status?.planned_maintenance_time)}
                    onChange={(val) => {  setRecord({...record, maintenance_status: { ...record.maintenance_status, planned_maintenance_dt: val ? val.format("YYYY-MM-DD") : null } }) }}
                    style={{width: 250}}/>
                </Space>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Интервал ТО</Col>
              <Col span={6}>
                <InputNumber
                  style={{width: 250}}
                  min={0}
                  value={record.maintenance_interval}
                  onChange={(value) => { setRecord({...record, maintenance_interval: value}) }}
                />
              </Col>
            </Row>
          </Panel>
          <Panel header="ОСАГО" key="5">
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={24}>
                <Space direction={"vertical"} style={{width: '100%'}}>
                  <Button onClick={() => { setEditedOsagoRecord({id: 0}) }}>Добавить</Button>
                  <Table
                    columns={[
                      {
                        title: "",
                        width: 30,
                      },
                      {
                        title: "",
                        width: 50,
                        render: (text, record, index) => {
                          return (
                            <Space direction={"horizontal"}>
                              <LinkButton onClick={() => { setEditedOsagoRecord(record) }} label={<EditFilled/>}/>
                              <LinkButton onClick={() => { deleteOsagoPolicy(record) }} label={<DeleteOutlined/>}/>
                            </Space>
                          )
                        }
                      },
                      {
                        title: 'Действующий',
                        dataIndex: 'is_current',
                        render: (text, record, index) => {
                          return record.is_current
                            ? <CheckOutlined/>
                            : null;
                        }
                      },
                      {
                        title: 'Страховщик',
                        dataIndex: 'company_title',
                      },
                      {
                        title: 'Серия/номер полиса',
                        dataIndex: 'policy_number',
                      },
                      {
                        title: 'Дата выдачи',
                        render: (text, record, index) => {
                          return record.policy_issue_dt
                            ? moment(record.policy_issue_dt, "YYYY-MM-DD").format("DD.MM.YYYY")
                            : null;
                        }
                      },
                      {
                        title: 'Срок действия',
                        render: (text, record, index) => {
                          return record.policy_due_dt ?
                            moment(record.policy_due_dt, "YYYY-MM-DD").format("DD.MM.YYYY")
                            : null;
                        }
                      },
                      {
                        title: "",
                        align: "center",
                        width: 20,
                      }
                    ]}
                    size={"small"}
                    scroll={{x: 1000}}
                    sticky
                    dataSource={osagoRecords}
                    rowKey="id"
                  />
                </Space>
              </Col>
            </Row>
          </Panel>
          <Panel header="КАСКО" key="6">
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={24}>
                <Space direction={"vertical"} style={{width: '100%'}}>
                  <Button onClick={() => { setEditedKaskoRecord({id: 0}) }}>Добавить</Button>
                  <Table
                    columns={[
                      {
                        title: "",
                        width: 30,
                      },
                      {
                        title: "",
                        width: 50,
                        render: (text, record, index) => {
                          return (
                            <Space direction={"horizontal"}>
                              <Button type="link" onClick={() => { setEditedKaskoRecord(record) }}><EditFilled/></Button>
                              <Button type="link" onClick={() => { deleteKaskoPolicy(record); }}><DeleteOutlined/></Button>
                            </Space>
                          )
                        }
                      },
                      {
                        title: 'Действующий',
                        dataIndex: 'is_current',
                        render: (text, record, index) => {
                          return record.is_current
                            ? <CheckOutlined/>
                            : null;
                        }
                      },
                      {
                        title: 'Страховщик',
                        dataIndex: 'company_title',
                      },
                      {
                        title: 'Серия/номер полиса',
                        dataIndex: 'policy_number',
                      },
                      {
                        title: 'Премия',
                        dataIndex: 'policy_premium',
                      },
                      {
                        title: 'Франшиза',
                        dataIndex: 'policy_deductible',
                      },
                      {
                        title: 'Дата выдачи',
                        render: (text, record, index) => {
                          return record.policy_issue_dt
                            ? moment(record.policy_issue_dt, "YYYY-MM-DD").format("DD.MM.YYYY")
                            : null;
                        }
                      },
                      {
                        title: 'Срок действия',
                        render: (text, record, index) => {
                          return record.policy_due_dt ?
                            moment(record.policy_due_dt, "YYYY-MM-DD").format("DD.MM.YYYY")
                            : null;
                        }
                      },
                      {
                        title: "",
                        align: "center",
                        width: 20,
                      }
                    ]}
                    size={"small"}
                    scroll={{x: 1000}}
                    sticky
                    dataSource={kaskoRecords}
                    rowKey="id"
                  />
                </Space>
              </Col>
            </Row>
          </Panel>
          <Panel header="Диагностическая карта" key="7">
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={24}>
                <Space direction={"vertical"} style={{width: '100%'}}>
                  <Button onClick={() => { setEditedDiagnosticCardRecord({id: 0}) }}>Добавить</Button>
                  <Table
                    columns={[
                      {
                        title: "",
                        width: 30,
                      },
                      {
                        title: "",
                        width: 100,
                        render: (text, record, index) => {
                          return (
                            <Space direction={"horizontal"}>
                              <Button type="link" onClick={() => { setEditedDiagnosticCardRecord(record) }}><EditFilled/></Button>
                              <Button type="link" onClick={() => { deleteDiagnosticCard(record); }}><DeleteOutlined style={{color: "#58D3A5"}}/></Button>
                            </Space>
                          )
                        }
                      },
                      {
                        title: 'Действующий',
                        dataIndex: 'is_current',
                        render: (text, record, index) => {
                          return record.is_current
                            ? <CheckOutlined/>
                            : null;
                        }
                      },
                      {
                        title: 'Номер',
                        dataIndex: 'reg_number',
                      },
                      {
                        title: 'Дата выдачи',
                        render: (text, record, index) => {
                          return record.issue_dt
                            ? moment(record.issue_dt, "YYYY-MM-DD").format("DD.MM.YYYY")
                            : null;
                        }
                      },
                      {
                        title: 'Срок действия',
                        render: (text, record, index) => {
                          return record.due_dt ?
                            moment(record.due_dt, "YYYY-MM-DD").format("DD.MM.YYYY")
                            : null;
                        }
                      },
                      {
                        title: "",
                        align: "center",
                        width: 20,
                      }
                    ]}
                    size={"small"}
                    scroll={{x: 1000}}
                    sticky
                    dataSource={diagnosticCards}
                    rowKey="id"
                  />
                </Space>
              </Col>
            </Row>
          </Panel>
          <Panel header="Разрешение на такси" key="8">
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Наименование органа выдавшего разрешение</Col>
              <Col span={18}>
                <Select
                  allowClear
                  placeholder="Выберите вариант из списка"
                  value={record?.taxi_license_authority?.id}
                  style={{width: '100%'}}
                  onChange={(data) => {
                    let licenseAuthority = taxiLicenceAuthorities.find(el => el.id === data);
                    if(licenseAuthority) {
                      console.log(licenseAuthority);
                      setRecord({...record, taxi_license_authority: licenseAuthority});
                    }
                  }}
                >
                  {
                    taxiLicenceAuthorities.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
                  }
                </Select>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Лицензиат</Col>
              <Col span={18}>
                <Input
                  allowClear={true}
                  style={{width: 300}}
                  placeholder={"Лицензиат"}
                  value={record.taxi_license_target}
                  onChange={(e) => { setRecord({...record, taxi_license_target: e.target.value}) }}
                />
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Адрес лицензиата</Col>
              <Col span={18}>
                <Input
                  allowClear={true}
                  style={{width: '100%'}}
                  placeholder={"Адрес лицензиата"}
                  value={record.taxi_license_target_address}
                  onChange={(e) => { setRecord({...record, taxi_license_target_address: e.target.value}) }}
                />
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Серия и номер бланка разрешения</Col>
              <Col span={6}>
                <Input
                  allowClear={true}
                  style={{width: 300}}
                  placeholder={"Серия"}
                  value={record.taxi_license_series}
                  onChange={(e) => { setRecord({...record, taxi_license_series: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Регистрационный номер разрешения такси</Col>
              <Col span={6}>
                <Input
                  allowClear={true}
                  style={{width: 300}}
                  placeholder={"Номер"}
                  value={record.taxi_license_number}
                  onChange={(e) => { setRecord({...record, taxi_license_number: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Дата выдачи</Col>
              <Col span={18}>
                <Space direction={"horizontal"}>
                  <DatePicker
                    allowClear={false}
                    format="DD.MM.YYYY"
                    placeholder="Дата выдачи"
                    value={record.taxi_license_issue_dt ? moment(record.taxi_license_issue_dt, "YYYY-MM-DD") : null}
                    onChange={(val) => { setRecord({...record, taxi_license_issue_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                    style={{width: 300}}/>
                </Space>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Срок действия</Col>
              <Col span={18}>
                <Space direction={"horizontal"}>
                  <DatePicker
                    allowClear={false}
                    format="DD.MM.YYYY"
                    placeholder="Срок действия"
                    value={record.taxi_license_due_dt ? moment(record.taxi_license_due_dt, "YYYY-MM-DD") : null}
                    onChange={(val) => { setRecord({...record, taxi_license_due_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                    style={{width: 300}}/>
                </Space>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Дата ввода в эксплуатацию</Col>
              <Col span={18}>
                <Space direction={"horizontal"}>
                  <DatePicker
                    allowClear={false}
                    format="DD.MM.YYYY"
                    placeholder="Дата ввода в эксплуатацию"
                    value={record.taxi_start_dt ? moment(record.taxi_start_dt, "YYYY-MM-DD") : null}
                    onChange={(val) => { setRecord({...record, taxi_start_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                    style={{width: 300}}/>
                </Space>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Дата выбытия</Col>
              <Col span={18}>
                <Space direction={"horizontal"}>
                  <DatePicker
                    allowClear={false}
                    format="DD.MM.YYYY"
                    placeholder="Дата выбытия"
                    value={record.taxi_end_dt ? moment(record.taxi_end_dt, "YYYY-MM-DD") : null}
                    onChange={(val) => { setRecord({...record, taxi_end_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                    style={{width: 300}}/>
                </Space>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Периодичность осмотра</Col>
              <Col span={12}>
                <Input
                  allowClear={true}
                  type={"number"}
                  placeholder={"Периодичность осмотра"}
                  style={{width: 300}}
                  value={record.inspection_period}
                  onChange={(e) => { setRecord({...record, inspection_period: e.target.value}) }}/>
              </Col>
            </Row>
          </Panel>
          <Panel header="Интеграции" key="9">
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Яндекс.Таксометр ID</Col>
              <Col span={12}>
                <Input
                  allowClear={true}
                  style={{width: 400}}
                  placeholder={"Яндекс.Таксометр ID"}
                  value={record.yandex_service_id}
                  onChange={(e) => { setRecord({...record, yandex_service_id: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>CityMobil ID</Col>
              <Col span={12}>
                <Input
                  allowClear={true}
                  style={{width: 400}}
                  placeholder={"CityMobil ID"}
                  value={record.citymobil_service_id}
                  onChange={(e) => { setRecord({...record, citymobil_service_id: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Gett ID</Col>
              <Col span={12}>
                <Input
                  allowClear={true}
                  style={{width: 400}}
                  placeholder={"Gett ID"}
                  value={record.gett_service_id}
                  onChange={(e) => { setRecord({...record, gett_service_id: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>CarTaxi ID</Col>
              <Col span={12}>
                <Input
                  allowClear={true}
                  style={{width: 400}}
                  placeholder={"CarTaxi ID"}
                  value={record.cartaxi_service_id}
                  onChange={(e) => { setRecord({...record, cartaxi_service_id: e.target.value}) }}/>
              </Col>
            </Row>
          </Panel>
        </Collapse>

      </>
    )
  };

  const form = () => {
    return (
      <>
        <MenuButton value = "Информация" statusBtn={selectedTabKey === "1"} onClick={() => setSelectedTabKey("1")}/>
        <MenuButton value = "История водителей" statusBtn={selectedTabKey === "2"} onClick={() => setSelectedTabKey("2")}/>
        <MenuButton value = "Осмотры" statusBtn={selectedTabKey === "3"} onClick={() => setSelectedTabKey("3")}/>
        <MenuButton value = "ТО" statusBtn={selectedTabKey === "4"} onClick={() => setSelectedTabKey("4")}/>
        <MenuButton value = "Договоры аренды" statusBtn={selectedTabKey === "5"} onClick={() => setSelectedTabKey("5")}/>
        <MenuButton value = "Файлы" statusBtn={selectedTabKey === "6"} onClick={() => setSelectedTabKey("6")}/>
        <MenuButton value = "История изменений" statusBtn={selectedTabKey === "7"} onClick={() => setSelectedTabKey("7")}/>
        <Row>
        </Row>
        {notification.TO === true && (
            <div className="div-color-TO">
              Приближается дата тех. осмотра
              <Button onClick={() => {setNotification({...notification, TO: false})}} type="link" style={{float: "right"}}><CloseOutlined/></Button>
            </div>

        )}
        {notification.OSAGO === true && (
          <div className="div-color-OSAGO">
            Приближается дата окончания действия ОСАГО
            <Button onClick={() => {setNotification({...notification, OSAGO: false})}} type="link" style={{float: "right"}}><CloseOutlined/></Button>
          </div>
        )}
        {notification.DK === true && (
          <div className="div-color-DK">
            Приближается дата окончания действия диагностических карт
            <Button onClick={() => {setNotification({...notification, DK: false})}} type="link" style={{float: "right"}}><CloseOutlined/></Button>
          </div>
        )}
        {selectedTabKey === "1" && (
            vehicleForm()
        )}
        {selectedTabKey === "2" && (
            <VehicleRentHistory vehicle={record} active={selectedTabKey === "2"}/>
        )}
        {selectedTabKey === "3" && (
            <VehicleInspectionsHistory vehicle={record} inspectionType={"current_inspection"} active={selectedTabKey === "3"} />
        )}
        {selectedTabKey === "4" && (
            <VehicleInspectionsHistory vehicle={record} inspectionType={"technical_inspection"} active={selectedTabKey === "4"} />
        )}
        {selectedTabKey === "5" && (
            <VehicleContracts vehicle={record} active={selectedTabKey === "5"} />
        )}
        {selectedTabKey === "6" && (
            <VehicleFiles vehicle={record} active={selectedTabKey === "6"}/>
        )}
        {selectedTabKey === "7" && (
            <VehiclePropertiesChangeHistory vehicle={record} active={selectedTabKey === "7"}/>
        )}
      </>
    )
  };

  const validateRecord = record => {
    return new Promise((resolve, reject) => {
      let errors = [
        "reg_number",
        "registration_cert",
        "brand",
        "model",
      ].filter(attr => { return !record[attr] });

      if(!errors || errors.length < 1) {
        resolve(record);
      } else {
        reject(errors);
      }
    })
  };

  const errorStyle = (attribute) => {
    return formErrors.indexOf(attribute) >= 0 ? inputErrorStyle : {};
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        style={{top: 10}}
        className="size-for-max-window"
        title={record && record.id > 0 ? `${record.brand} ${record.model} ${record.reg_number}` : 'Транспортное средство'}
        visible={visible}
        confirmLoading={loading}
        onOk={() => {
          validateRecord(record)
            .then(validated => {
              setLoading(true);
              if (vehicleId) {
                apiClient.updateVehicle(vehicleId, validated)
                  .then(res => {
                    message.info("Запись обновлена");
                    onClose(true);
                  })
                  .catch(err => message.error(err))
                  .finally(() => setLoading(false))
              } else {
                apiClient.createVehicle(validated)
                  .then(res => {
                    message.info("Запись добавлена");
                    onClose(true);
                  })
                  .catch(err => message.error(err))
                  .finally(() => setLoading(false))
              }
            })
            .catch(err => {
              setFormErrors(err);
              message.error("Заполните все необходимые поля")
            });
        }}
        onCancel={() => { onClose(false); }}>

        {
          vehicleId !== null
            ? form()
            : (
              <div style={{margin: '20px auto'}}><LoadingOutlined/></div>
            )
        }

      </Modal>
      {
        editedOsagoRecord !== null
          ? (
            <OsagoPolicyEditDialog
              policy={editedOsagoRecord}
              onOk={record => {
                if(record.id > 0) {
                  apiClient.updateVehicleOsago(vehicleId, record.id, record)
                    .then(res => {
                      setOsagoRecordsVersion(prevState => prevState + 1);
                      setEditedOsagoRecord(null);
                      message.info("Полис обновлен");
                    })
                    .catch(err => {
                      message.error(err);
                    });
                } else {
                  apiClient.createVehicleOsago(vehicleId, record)
                    .then(res => {
                      setOsagoRecordsVersion(prevState => prevState + 1);
                      setEditedOsagoRecord(null);
                      message.info("Полис добавлен");
                    })
                    .catch(err => {
                      message.error(err);
                    });
                }
              }}
              onCancel={() => {
                setEditedOsagoRecord(null);
              }}
            />
          ) : null
      }
      {
        editedKaskoRecord !== null
          ? (
            <KaskoPolicyEditDialog
              policy={editedKaskoRecord}
              onOk={record => {
                if(record.id > 0) {
                  apiClient.updateVehicleKasko(vehicleId, record.id, record)
                    .then(res => {
                      setKaskoRecordsVersion(prevState => prevState + 1);
                      setEditedKaskoRecord(null);
                      message.info("Полис обновлен");
                    })
                    .catch(err => {
                      message.error(err);
                    });
                } else {
                  apiClient.createVehicleKasko(vehicleId, record)
                    .then(res => {
                      setKaskoRecordsVersion(prevState => prevState + 1);
                      setEditedKaskoRecord(null);
                      message.info("Полис добавлен");
                    })
                    .catch(err => {
                      message.error(err);
                    });
                }
              }}
              onCancel={() => {
                setEditedKaskoRecord(null);
              }}
            />
          ) : null
      }
      {
        editedDiagnosticCardRecord !== null
          ? (
            <DiagnosticCardEditDialog
              diagnosticCard={editedDiagnosticCardRecord}
              onOk={record => {
                if(record.id > 0) {
                  apiClient.updateVehicleDiagnosticCard(vehicleId, record.id, record)
                    .then(res => {
                      setDiagnosticCardsVersion(prevState => prevState + 1);
                      setEditedDiagnosticCardRecord(null);
                      message.info("Запись обновлена");
                    })
                    .catch(err => {
                      message.error(err);
                    });
                } else {
                  apiClient.createVehicleDiagnosticCard(vehicleId, record)
                    .then(res => {
                      setDiagnosticCardsVersion(prevState => prevState + 1);
                      setEditedDiagnosticCardRecord(null);
                      message.info("Запись добавлена");
                    })
                    .catch(err => {
                      message.error(err);
                    });
                }
              }}
              onCancel={() => {
                setEditedDiagnosticCardRecord(null);
              }}
            />
          ) : null
      }
    </>
  );
}