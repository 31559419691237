import React from "react";
import {Button, Modal, Table} from "antd";
import {VehicleGearboxTypes} from "../../../common/AppEnums";
import {windowService} from "../../../../services/WindowService";
import '../../ModalStyle.css';

export default function ServiceSummaryTypeStatusDetailsDialog({data, onClose}) {
  return (
    <Modal
      title={"Информация"}
      visible={true}
      width={1024}
      onOk={() => {
        onClose();
      }}
      onCancel={() => {
        onClose();
      }}
    >
      <Table
        className="size-for-middle-window"
        dataSource={data}
        columns={[
          {
            title: 'Гос.номер',
            dataIndex: 'reg_number',
            width: 120,
            render: (text, record, index) => {
              return (
                <Button
                  type={"link"}
                  onClick={() => {
                    windowService.openRouteWindow(`cp/park/vehicles/${record.id}`);
                  }}
                >
                  {
                    <>
                      <div>
                        {record.reg_number}
                      </div>
                    </>
                  }
                </Button>
              );
            },
          },
          {
            title: "Марка",
            dataIndex: 'brand',
          },
          {
            title: "Модель",
            dataIndex: 'model',
          },
          {
            title: "Год выпуска",
            dataIndex: 'year',
            width: 100,
          },
          {
            title: "Тип КПП",
            dataIndex: 'gearbox_type',
            width: 130,
            render: (text, record, index) => {
              return VehicleGearboxTypes.find(el => el.id === record.gearbox_type)?.title || record.gearbox_type
            }
          },
          {
            title: "Пробег",
            dataIndex: 'last_maintenance_mileage',
            width: 120,
          },
        ]}
        size="small"
        rowKey="id"
      />
    </Modal>
  )
}