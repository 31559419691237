import React, {useEffect, useState} from "react";
import {AutoComplete, Button, Col, DatePicker, Form, Input, message, Modal, Row, Space, Switch} from "antd";
import {CloseCircleOutlined, DeleteOutlined, EditFilled, MinusCircleFilled, PlusCircleFilled} from "@ant-design/icons";
import {apiClient} from "../../../../services/ApiClient";
import moment from "moment";

const { RangePicker } = DatePicker;
const { confirm } = Modal;

export default function MechanicSection({onSelect, value}) {
  const mapRecord = (record) => {
    record.displayName = `${record.name} (${record.sign_hash})`;
    return {
      id: record.id,
      key: record.id,
      value: (
        <div>
          {record.name}&nbsp;({record.sign_hash})
        </div>
      ),
      record: record
    };
  };

  let [record, setRecord] = useState(value ? mapRecord(value) : null);
  let [options, setOptions] = useState([]);
  let [formVisible, setFormVisible] = useState(false);
  let [loading, setLoading] = useState(false);
  let [enableSave, setEnableSave] = useState(false);
  let [form] = Form.useForm();

  const updateEnableSave = (checked) => {
    localStorage.setItem('MechanicSection.enableSave', String(checked));
    if(!checked) {
      localStorage.removeItem('MechanicSection.record');
    } else if(record) {
      localStorage.setItem('MechanicSection.record', JSON.stringify(record));
    }
    setEnableSave(checked);
  };

  useEffect(() => {
    setRecord(value);

    let save = false;
    if(localStorage.getItem(`MechanicSection.enableSave`) === null) {
      save = false;
    } else {
      save = localStorage.getItem('MechanicSection.enableSave') === "true";
      if(save) {
        const rec = localStorage.getItem('MechanicSection.record');
        if(rec) {
          setRecord(JSON.parse(rec));
        }
      }
    }
    setEnableSave(save);
  }, [value]);

  const updateRecord = rec => {
    if(rec) {
      localStorage.setItem('MechanicSection.record', JSON.stringify(rec));
    } else {
      localStorage.removeItem('MechanicSection.record');
    }
    setRecord(rec);
    onSelect(rec);
    setOptions([]);
  };

  const search = (val) => {
    apiClient.searchDirectoryRecord('mechanic', val)
      .then(res => {
        if(res.success) {
          setOptions(res.records.map(e => mapRecord(e)));
        } else if(res.errorMessage) {
          message.error(res.errorMessage);
        }
      })
      .catch(err => {
        message.error("Не удалось выполнить поиск")
      });
  };

  const create = (args) => {
    setLoading(true);
    apiClient.createDirectoryRecord('mechanic', args)
      .then(res => {
        if(res.success) {
          form.resetFields();
          setFormVisible(false);
          setRecord(mapRecord(res.record).record);
          onSelect(res.record);
        } else if(res.errorMessage) {
          message.error(res.errorMessage);
        }
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const update = (args) => {
    setLoading(true);
    apiClient.updateDirectoryRecord('mechanic', record.id, args)
      .then(res => {
        if(res.success) {
          form.resetFields();
          setFormVisible(false);
          setRecord(mapRecord(res.record).record);
          onSelect(res.record);
        } else if(res.errorMessage) {
          message.error(res.errorMessage);
        }
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const remove = () => {
    if(!record) {
      return;
    }
    apiClient.removeDirectoryRecord('mechanic', record.id)
      .then(res => {
        if(res.success) {
          setRecord(null);
          setOptions([]);
          onSelect(null);
          if(formVisible) {
            form.resetFields();
          }
        } else {
          message.error("Ошибка");
        }
      })
      .catch(err => {
        message.error(err);
      });
  };

  const renderForm = () => {
    if(!formVisible) {
      return null;
    }
    if(record) {
      form.setFieldsValue({
        "name": record.name,
        "sign": record.sign_hash,
        "inn": record.inn,
        "sign_period": [moment(record.sign_valid_from || new Date()), moment(record.sign_valid_to || new Date())],
        "qrlink": record.qrlink,
      });
    }
    return (
      <Form form={form} layout="horizontal" style={{margin: '10px 0'}}
            labelCol={{span: 3}}
            wrapperCol={{span: 10}}
            onFinish={(args) => {
              args["sign_period"][0].hour(12);
              args["sign_period"][1].hour(12);
              if(record !== null && record.id > 0) {
                update(args);
              } else {
                create(args);
              }
            }}
      >
        <Form.Item
          label="ФИО"
          name="name"
          rules={[
            {
              required: true,
              message: 'Укажите ФИО',
            },
          ]}
        >
          <Input allowClear={true}/>
        </Form.Item>

        <Form.Item
          label="ИНН"
          name="inn"
          rules={[
            {
              required: true,
              message: 'Укажите ИНН',
            },
          ]}>
          <Input allowClear={true}/>
        </Form.Item>

        <Form.Item
          label="ЭЦП"
          name="sign"
          rules={[
            {
              required: true,
              message: 'Укажите ЭЦП',
            },
          ]}>
          <Input allowClear={true}/>
        </Form.Item>

        <Form.Item
          label="Срок действия ЭЦП"
          name="sign_period"
          rules={[
            {
              required: true,
              message: 'Укажите Срок действия ЭЦП',
            },
          ]}>
          <RangePicker format="DD.MM.YYYY" style={{width: '60%'}} allowClear={true}/>
        </Form.Item>

        <Form.Item
          label="QR-ссылка"
          name="qrlink"
          rules={[
            {
              required: true,
              message: 'Укажите ЭЦП',
            },
          ]}>
          <Input allowClear={true}/>
        </Form.Item>

        <Form.Item wrapperCol={{span: 10, offset: 3}} style={{textAlign: "right"}}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const renderSelectedActions = () => {
    return (
      <>
        {formVisible
          ? <Button type="link" icon={<CloseCircleOutlined/>} onClick={() => setFormVisible(false)}/>
          : <Button type="link" icon={<EditFilled/>} onClick={() => setFormVisible(true)}/>}
        <Button type="link"
                style={{color: 'darkred'}}
                icon={<DeleteOutlined/>}
                onClick={() => {
                  confirm({
                    title:  "Удаление",
                    content: "Вы уверены, что хотите удалить запись?",
                    icon: <DeleteOutlined/>,
                    visible: true,
                    onOk() {
                      remove();
                    },
                  })
                }}/>
      </>
    );
  };

  const renderNotSelectedActions = () => (
    formVisible
      ? <Button type="link" icon={<MinusCircleFilled/>} onClick={() => setFormVisible(false)}/>
      : <Button type="link" icon={<PlusCircleFilled/>} onClick={() => setFormVisible(true)}/>
  );

  return (
    <>
      <Row>
        <Col span={3}>
          <h3>Механик</h3>
        </Col>
        <Col span={10}>
          <AutoComplete
            style={{width: '100%'}}
            options={options}
            onSelect={(val, opt) => { updateRecord(opt.record); }}
            onSearch={search}
            onChange={(val) => {
              if(val === undefined) {
                updateRecord(null);
                if(formVisible) {
                  form.resetFields();
                }
              } else {
                setRecord({displayName: val});
              }
            }}
            value={record ? record.displayName : ""}
            allowClear={true}
            placeholder="Искать механика..."
          />
        </Col>
        <Col span={1}>
          <Space direction="horizontal">
            {
              record != null && record.id > 0
                ? renderSelectedActions()
                : renderNotSelectedActions()
            }
          </Space>
        </Col>
        <Col span={10} style={{textAlign: 'right'}}>
          <Switch checked={enableSave}
                  checkedChildren="Сохранять"
                  unCheckedChildren="Не сохранять"
                  style={{width: 120}}
                  onChange={(checked, event) => { updateEnableSave(checked) }}/>
        </Col>
      </Row>
      {renderForm()}
    </>
  );
}