import React, {useEffect, useState} from "react";
import {Modal, Tabs, Col, Input, Row, Image, Empty, message} from "antd";
import moment from "moment";
import EmptyPage from "../../EmptyPage";
import {DriverPhotoControlTypes} from "../../../common/AppEnums";

import './InspectionDetails.css'
import {apiClient} from "../../../../services/ApiClient";

const { TabPane } = Tabs;

const rowStyle = {padding: '8px'};

export default function InspectionDetails({title, visible, record, onClose}) {

  let [item, setItem] = useState(null);

  const onTabChange = (key) => {

  };

  const updateMileage = value => {
    // item.mileage = value;
    //
    // apiClient.updateInspectionControl(item.id, {mileage: value})
    //   .then(res => {})
    //   .catch(err => {});
  };

  useEffect(() => {
    if(record && record.id) {
      apiClient.getInspectionControlDetails(record.id)
        .then(res => {
          setItem(res.record);
        })
        .catch(err => {
          message.error(err);
        });
    } else {
      setItem(null);
    }
  }, [record]);

  function drawContent() {
    return (
      <Tabs type="card" onChange={onTabChange}>
        <TabPane tab={item.photos && item.photos.length > 0 ? `Фото (${item.photos.length})` : 'Фото'} key="1">
          <Row gutter={[8, 0]} align="top" style={rowStyle}>
            <Col md={6}>
              <div>
                <Row gutter={[8, 0]} align="middle" style={rowStyle}>
                  <Col md={11}>Шашка такси по ГОСТу?</Col>
                  <Col md={12}>
                    <Input
                      className={item.taxi_checker_status === "yes" ? "check-success": "check-failed"}
                      value={item.taxi_checker_status === "yes" ? "установлена" : "отсутствует"}
                      bordered={false}
                      readOnly={true}/>
                  </Col>
                </Row>
                <Row gutter={[8, 0]} align="middle" style={rowStyle}>
                  <Col md={11}>Карточка фрактовщика</Col>
                  <Col md={12}>
                    <Input
                      className={item.phraser_card_status === "yes" ? "check-success": "check-failed"}
                      value={item.phraser_card_status === "yes" ? "установлена" : "отсутствует"}
                      bordered={false}
                      readOnly={true}/>
                  </Col>
                </Row>
                <Row gutter={[8, 0]} align="middle" style={rowStyle}>
                  <Col md={11}>Набор автомобилиста</Col>
                  <Col md={12}>
                    <Input
                      className={item.motorist_set_status === "yes" ? "check-success": "check-failed"}
                      value={item.motorist_set_status === "yes" ? "в наличии" : "отсутствует"}
                      bordered={false}
                      readOnly={true}/>
                  </Col>
                </Row>
                <Row gutter={[8, 0]} align="middle" style={rowStyle}>
                  <Col md={11}>Документы СТС, ОСАГО, разрешение такси, правила перевозки</Col>
                  <Col md={12}>
                    <Input
                      className={item.documents_status === "yes" ? "check-success": "check-failed"}
                      value={item.documents_status === "yes" ? "в наличии" : "отсутствует"}
                      bordered={false}
                      readOnly={true}/>
                  </Col>
                </Row>
                <Row gutter={[8, 0]} align="middle" style={rowStyle}>
                  <Col md={11}>Оплётка на руле</Col>
                  <Col md={12}>
                    <Input
                      className={item.wheel_braid_status === "yes" ? "check-success": "check-failed"}
                      value={item.wheel_braid_status === "yes" ? "в наличии" : "отсутствует"}
                      bordered={false}
                      readOnly={true}/>
                  </Col>
                </Row>
                <Row gutter={[8, 0]} align="middle" style={rowStyle}>
                  <Col md={11}>Чехлы на сидении</Col>
                  <Col md={12}>
                    <Input
                      className={item.seat_covers_status === "yes" ? "check-success": "check-failed"}
                      value={item.seat_covers_status === "yes" ? "в наличии" : "отсутствует"}
                      bordered={false}
                      readOnly={true}/>
                  </Col>
                </Row>
                <Row gutter={[8, 0]} align="middle" style={rowStyle}>
                  <Col md={11}>Наличие ошибок на панели приборов</Col>
                  <Col md={12}>
                    <Input
                      value={item.dashboard_errors}
                      bordered={false}
                      readOnly={true}/>
                  </Col>
                </Row>
                <Row gutter={[8, 0]} align="middle" style={rowStyle}>
                  <Col md={11}>Световые приборы</Col>
                  <Col md={12}>
                    <Input
                      value={item.lights_status}
                      bordered={false}
                      readOnly={true}/>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={17}>
              {
                item.photos && item.photos.length > 0
                  ? (<div style={{textAlign: 'center'}}>
                    <Image.PreviewGroup>
                      {
                        item.photos.map(photo => (
                          <div style={{padding: '10px 10px', display: 'inline-block'}} key={photo.id}>
                            <Image about={photo.type} title={DriverPhotoControlTypes[photo.type]} width={200} src={photo.url} className={`photo-${photo.status}`}/>
                            <div>
                              <span style={{margin: '0 5px'}}>{DriverPhotoControlTypes[photo.type]}</span>
                            </div>
                          </div>
                        ))
                      }
                    </Image.PreviewGroup>
                  </div>) : <Empty description={"Нет фотографий"} />
              }
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Данные" key="2">
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col md={3}>Дата</Col>
            <Col md={4}>
              <Input
                value={moment(item.create_dt).format('DD.MM.YYYY HH:mm')}
                readOnly={true}/>
            </Col>
          </Row>
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col md={3}>Водитель</Col>
            <Col md={9}>
              <Input
                value={`${item.driver_info?.last_name} ${item.driver_info?.first_name} ${item.driver_info?.middle_name} (${item.driver_info?.phone})`}
                readOnly={true}/>
            </Col>
          </Row>
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col md={3}>Транспортное средство</Col>
            <Col md={9}>
              <Input
                value={item.vehicle_number ? `${item.vehicle_brand} ${item.vehicle_model} (${item.vehicle_number})` : ""}
                readOnly={true}/>
            </Col>
          </Row>
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col md={3}>Пробег</Col>
            <Col md={9}>
              <Input value={item.mileage}
                     readOnly={true}
                     onChange={(e) => { updateMileage(e.target.value) }}/>
            </Col>
          </Row>
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col md={3}>Уведомление ТО</Col>
            <Col md={9}>
              <Input
                value={item.notification}
                readOnly={true}/>
            </Col>
          </Row>
          <Row gutter={[8, 0]} align="middle" style={rowStyle}>
            <Col md={3}>Описание ТО, ремонта</Col>
            <Col md={9}>
              <Input
                value={item.technical_description}
                readOnly={true}/>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    );
  }

  return (
    <Modal
      title={title}
      visible={visible}
      width="90%"
      style={{ top: 20 }}
      onOk={() => {
        onClose();
      }}
      onCancel={() => {
        onClose();
      }}
    >
      {
        item ? drawContent() : <EmptyPage/>
      }
    </Modal>
  );
}