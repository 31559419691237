import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import DraggableElement from "./DraggableElement";
import { Button, Space, Typography, Input, DatePicker, Select, Radio, message } from "antd";
import {apiClient} from "../../../services/ApiClient";
import "./DragList.css";
import CancelButton from "../../buttons/CancelButton";
import DtpDialog from "./DtpDialog";

const {Search} = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const removeFromList = (list, index) => {
  const result = Array.from(list);
  const [removed] = result.splice(index, 1);
  return [removed, result];
};

const addToList = (list, index, element) => {
  const result = Array.from(list);
  result.splice(index, 0, element);
  return result;
};

const lists = ["NEW", "INSURANCE_INSPECTION", "INDEPENDENT_EXAMINATION", "COURT", "WAIT_FOR_INSURANCE_PAY", "WAIT_FOR_CULPRIT_PAY", "PAID"];

function CanbanPage() {
  let [loading, setLoading] = useState(false);
  let [createDtp, setCreateDtp] = useState(null);
  let [changeDtp, setChangeDtp] = useState(null);
  let [dtp, setDtp] = useState([]);
  let [filters, setFilters] = useState([]);
  let [filtersList, setFiltersList] = useState([]);
  let [regions, setRegions] = useState([]);

  useEffect(() => {
    apiClient.getRegions({"order": "title"})
      .then(res => { setRegions(res.records) })
      .catch(err => { console.error(err) });
  }, []);

  useEffect(() => {
    setLoading(true);
    apiClient.getListsForDtp()
      .then(res => {
        setFiltersList({...filtersList, ...{
          culprit_type: res.accident_types,
          debt_status: res.debt_status,
          inspection_place: res.inspection_place,
          registration_type: res.registration_type,
          accident_statuses: res.accident_statuses
        }});
        setLoading(false);
      })
      .catch(err => {
        message.error(err);
      });
  }, []);

  useEffect(() => {
    let params = {
      reg_number: filters.vehicle,
      fio: filters.driver,
      osago_number: filters.osago,
      date_from: filters.date_from !== "" && filters.date_from !== undefined ? filters.date_from : null,
      date_to: filters.date_to !== "" && filters.date_to !== undefined ? filters.date_to : null,
      accident_types: filters.culprit_type,
      registration_type: filters.registration_type,
      // inspection_place: filters.inspection_place,
      debt_status: filters.debt_status,
      work_region_id: filters.work_region_id,
      status: filters.status,
      show_only_archived: filters.archived === "show_only_archived" ? 1 : undefined,
      notification_type: filters.notificationType !== "dtp" ? filters.notificationType : undefined
    };
    setLoading(true);
    apiClient.getAllDtp(params)
      .then(res => {
        let data = res.records;
        data.map((s) => s.driver_fio = s.driver?.display_name);
        data.map((s) => s.taxi_driver_id = s.driver?.id);
        let list = lists.reduce(
          (acc, listKey) => ({ ...acc, [listKey]: data.filter((s) => s.status && s.status === listKey)
            ? data.filter((s) => s.status && s.status === listKey) : [] }),
          {}
        );
        console.log({data});
        setDtp(list);
        setLoading(false);
      })
      .catch(err => { console.error(err) });
  }, [filters]);


  const onDragEnd = (result) => {
    // console.log({result});
    if (!result.destination) {
      return;
    }
    const listCopy = { ...dtp };
    let id = result.draggableId.split("-")[1];
    let data = listCopy[result.source.droppableId];
    let culpritType = data && data.find((s) => s.id === Number(id)).culprit_type ? data.find((s) => s.id === Number(id)).culprit_type : "";
    console.log({culpritType});
    let element = {
      id: id,
      culprit_type: culpritType,
      status: result.destination.droppableId
    }
    onUpdateDtp(element);
    const sourceList = listCopy[result.source.droppableId];
    let [removedElement, newSourceList] = removeFromList(
      sourceList,
      result.source.index
    );
    // removedElement.content = result.destination.droppableId === "todo" ? <FormattedMessage id="to_do" />
    // : result.destination.droppableId === "inProgress" ? <FormattedMessage id="in_progress" />
    // : result.destination.droppableId === "done" ? <FormattedMessage id="done" /> : "";
    listCopy[result.source.droppableId] = newSourceList;
    const destinationList = listCopy[result.destination.droppableId];
    listCopy[result.destination.droppableId] = addToList(
      destinationList,
      result.destination.index,
      removedElement
    );

    setDtp(listCopy);
  };

  const handleRegistrationTypeChange = (value) => {
    const registrationType = Object.keys(value).length !== 0 ? value.toString() : undefined;
    setFilters({...filters, ...{registration_type: registrationType}});
  };

  const handleAccidentTypeChange = (value) => {
    const accidentType = Object.keys(value).length !== 0 ? value.toString() : undefined;
    setFilters({...filters, ...{culprit_type: accidentType}});
  };

  const handleDebtStatusChange = (value) => {
    const debtStatus = Object.keys(value).length !== 0 ? value.toString() : undefined;
    setFilters({...filters, ...{debt_status: debtStatus}});
  };

  const handleRegionChange = (value) => {
    const region = Object.keys(value).length !== 0 ? value.toString() : undefined;
    setFilters({...filters, ...{work_region_id: region}});
  };

  const handleDtpStatusChange = (value) => {
    const dtpStatus = Object.keys(value).length !== 0 ? value.toString() : undefined;
    setFilters({...filters, ...{status: dtpStatus}});
  };

  const handleNotificationTypeChange = (e) => {
    setFilters({...filters, ...{notificationType: e.target.value}});
  };

  const onUpdateDtp = (dtp) => {
    dtp["saved"] = true;
    if(dtp.osago === false) {
      delete dtp["osago_number"];
      dtp["osago_active"] = false;
    }
    apiClient.updateDtp(dtp.id, dtp)
      .then(res => {
        message.success("Изменения сохранены!");
        let params = {
          reg_number: filters.vehicle,
          fio: filters.driver,
          osago_number: filters.osago,
          date_from: filters.date_from !== "" && filters.date_from !== undefined ? filters.date_from : null,
          date_to: filters.date_to !== "" && filters.date_to !== undefined ? filters.date_to : null,
          accident_types: filters.culprit_type,
          registration_type: filters.registration_type,
          // inspection_place: filters.inspection_place,
          debt_status: filters.debt_status,
          work_region_id: filters.work_region_id,
          status: filters.status,
          show_only_archived: filters.archived === "show_only_archived" ? 1 : undefined,
          notification_type: filters.notificationType !== "dtp" ? filters.notificationType : undefined
        }
        apiClient.getAllDtp(params)
        .then(res => {
          let data = res.records;
          data.map((s) => s.driver_fio = s.driver?.display_name);
          data.map((s) => s.taxi_driver_id = s.driver?.id);
          let list = lists.reduce(
            (acc, listKey) => ({ ...acc, [listKey]: data.filter((s) => s.status && s.status === listKey)
              ? data.filter((s) => s.status && s.status === listKey) : [] }),
            {}
          );
          setDtp(list);
        })
        .catch(err => message.error(err));
      })
      .catch(err => message.error(err));
  };

  return (
    <>
      {/* <Typography.Title level={5} style={{ margin: 10, marginBottom: 20 }}>
        Канбан
      </Typography.Title> */}
      <div style={{width: '100%'}}>
        <div style={{width: '80%', float: 'right'}} >
          <Space style={{float: "right"}} wrap direction="horizontal">
            <Button type="primary" onClick={() => {
              apiClient.createDtp({
                culprit_type: "OUR_DRIVER_CULPRIT",
              })
                .then(res => {
                  setCreateDtp(res.record);
                })
                .catch(err => console.error(err));
            }}>
              Добавить ДТП
            </Button>
          </Space>
        </div>
      </div>
      {/* <div>
        <span style={{marginLeft: 8}}>
          <span style={{float: "left"}}>
            {dtp.length > 0 ? `Под выбранные фильтры подходит ${dtp.length} записей` : 'Под выбранные фильтры подходит 0 записей'}
          </span>
        </span>
      </div> */}
      <div style={{width: '100%', float: 'left'}} >
        <Space direction={"horizontal"} wrap style={{marginBottom: 8, marginTop: 5}}>
          <Search
            enterButton
            placeholder={"Поиск по водителю"}
            onChange={(e) => { setFilters({...filters, ...{driver: e.target.value}}); }}
            allowClear={true}
            style={{width: 350}}
          />
          <Search
            enterButton
            placeholder={"Поиск по номеру авто"}
            onChange={(e) => { setFilters({...filters, ...{vehicle: e.target.value}}); }}
            allowClear={true}
            style={{width: 350}}
          />
          <Search
            enterButton
            placeholder={"Поиск по ОСАГО"}
            onChange={(e) => { setFilters({...filters, ...{osago: e.target.value}}); }}
            allowClear={true}
            style={{width: 350}}
          />
          <RangePicker
            allowClear={true}
            showTime
            style={{width: 350}} 
            onChange={(dates, dateStrings) => { setFilters({...filters, date_from: dateStrings[0], date_to: dateStrings[1]}); }}
          />
          {/* <Select
            allowClear
            mode="multiple"
            placeholder="Место осмотра ТС"
            // value={selectedRegionId}
            style={{width: 300}}
            onChange={(value) => { setFilters({...filters, ...{inspection_place: value}}); }}
          >
            {
              filtersList.inspection_place ? filtersList.inspection_place.map(el => <Option key={el[0]} value={el[0]}>{`${el[1]}`}</Option>) : []
            }
          </Select> */}
          <Select
            allowClear
            mode="multiple"
            placeholder="Тип оформления"
            style={{width: 300}}
            onChange={handleRegistrationTypeChange}
          >
            {
              filtersList.registration_type ? filtersList.registration_type.map(el => <Option key={el[0]} value={el[0]}>{`${el[1]}`}</Option>) : []
            }
          </Select>
          <Select
            allowClear
            mode="multiple"
            placeholder="Виновник ДТП"
            style={{width: 400}}
            onChange={handleAccidentTypeChange}
          >
            {
              filtersList.culprit_type ? filtersList.culprit_type.map(el => <Option key={el[0]} value={el[0]}>{`${el[1]}`}</Option>) : []
            }
          </Select>
          <Select
            allowClear
            mode="multiple"
            placeholder="Статус долга"
            style={{width: 300}}
            onChange={handleDebtStatusChange}
          >
            {
              filtersList.debt_status ? filtersList.debt_status.map(el => <Option key={el[0]} value={el[0]}>{`${el[1]}`}</Option>) : []
            }
          </Select>
          <Select
            allowClear
            mode="multiple"
            placeholder="Регион"
            style={{width: 300}}
            onChange={handleRegionChange}
          >
            {
              regions ? regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>) : []
            }
          </Select>
          <Radio.Group
            value={filters && filters.archived ? filters.archived : "dtp"}
            onChange={(e) => {
              setFilters({...filters, ...{archived: e.target.value}});
            }}
          >
            <Radio.Button value="dtp">Все ДТП</Radio.Button>
            <Radio.Button value="show_only_archived">Только архивные</Radio.Button>
          </Radio.Group>
          <Radio.Group
            value={filters && filters.notificationType ? filters.notificationType : "dtp"}
            onChange={handleNotificationTypeChange}
          >
            <Radio.Button value="DEBTOR">Должники</Radio.Button>
            <Radio.Button value="dtp">Все ДТП</Radio.Button>
            <Radio.Button value="INSPECTION">Осмотры ТС</Radio.Button>
          </Radio.Group>
        </Space>
      </div>
      <DragDropContext loading={loading} onDragEnd={onDragEnd}>
        <div className="list-grid">
          {lists.map((listKey) => (
            <DraggableElement
              elements={dtp[listKey]}
              key={listKey}
              prefix={listKey}
              onClick={(item) => {setChangeDtp(item);}}
              status={filtersList.accident_statuses && filtersList.accident_statuses.find((s) => s[0] === listKey)[1] ? filtersList.accident_statuses.find((s) => s[0] === listKey)[1] : ""}
            />
          ))}
        </div>
      </DragDropContext>
      {
        createDtp !== null ? (
          <DtpDialog
            visible={createDtp}
            record={{}}
            onOk={(dtp, id) => {
              setCreateDtp(null);
              onUpdateDtp(dtp);
            }}
            onCancel={() => {
              setCreateDtp(null);
              let params = {
                reg_number: filters.vehicle,
                fio: filters.driver,
                osago_number: filters.osago,
                date_from: filters.date_from !== "" && filters.date_from !== undefined ? filters.date_from : null,
                date_to: filters.date_to !== "" && filters.date_to !== undefined ? filters.date_to : null,
                accident_types: filters.culprit_type,
                registration_type: filters.registration_type,
                // inspection_place: filters.inspection_place,
                debt_status: filters.debt_status,
                work_region_id: filters.work_region_id,
                status: filters.status,
                show_only_archived: filters.archived === "show_only_archived" ? 1 : undefined,
                notification_type: filters.notificationType !== "dtp" ? filters.notificationType : undefined
              }
              apiClient.getAllDtp(params)
                .then(res => {
                  let data = res.records;
                  data.map((s) => s.driver_fio = s.driver?.display_name);
                  data.map((s) => s.taxi_driver_id = s.driver?.id);
                  let list = lists.reduce(
                    (acc, listKey) => ({ ...acc, [listKey]: data.filter((s) => s.status && s.status === listKey)
                        ? data.filter((s) => s.status && s.status === listKey) : [] }),
                    {}
                  );
                  setDtp(list);
                })
                .catch(err => message.error(err));
            }}
            type="new"
          />
        ) : null
      }
      {
        changeDtp !== null ? (
          <DtpDialog
            visible={changeDtp !== null}
            record={changeDtp}
            onOk={(dtp, id) => { setChangeDtp(null); onUpdateDtp(dtp); }}
            onCancel={() => {
              setChangeDtp(null);
              let params = {
                reg_number: filters.vehicle,
                fio: filters.driver,
                osago_number: filters.osago,
                date_from: filters.date_from !== "" && filters.date_from !== undefined ? filters.date_from : null,
                date_to: filters.date_to !== "" && filters.date_to !== undefined ? filters.date_to : null,
                accident_types: filters.culprit_type,
                registration_type: filters.registration_type,
                // inspection_place: filters.inspection_place,
                debt_status: filters.debt_status,
                work_region_id: filters.work_region_id,
                status: filters.status,
                show_only_archived: filters.archived === "show_only_archived" ? 1 : undefined,
                notification_type: filters.notificationType !== "dtp" ? filters.notificationType : undefined
              };
              apiClient.getAllDtp(params)
                .then(res => {
                  let data = res.records;
                  data.map((s) => s.driver_fio = s.driver?.display_name);
                  data.map((s) => s.taxi_driver_id = s.driver?.id);
                  let list = lists.reduce(
                    (acc, listKey) => ({ ...acc, [listKey]: data.filter((s) => s.status && s.status === listKey)
                        ? data.filter((s) => s.status && s.status === listKey) : [] }),
                    {}
                  );
                  setDtp(list);
                })
                .catch(err => message.error(err));
            }}
            type="dtp"
          />
        ) : null
      }
    </>
  );
}

export default CanbanPage;