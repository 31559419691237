import React, {useState} from "react";
import {InputNumber, Modal, Space} from "antd";

export default function NumberInputDialog({title, onOK, onCancel}) {

  let [record, setRecord] = useState(null);

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-small-window"
      title={title}
      visible={true}
      onOk={() => {
        onOK(record);
      }}
      onCancel={() => {
        setRecord(null);
        onCancel();
      }}
    >
      <Space direction={"vertical"} style={{width: '100%'}}>
        <InputNumber
          style={{width: '100%'}}
          value={record}
          onChange={(value) => {
            setRecord(value);
          }}/>
      </Space>
    </Modal>
  )
}