import React, {useEffect, useState} from "react";
import {Col, Input, message, Modal, Row, Select} from "antd";
import '../../../ModalStyle.css';

const {Option} = Select;
const rowStyle = {padding: '8px'};

export default function TransactionGroupEditDialog({visible, transactionGroup, onOk, onCancel, title}) {
  let [record, setRecord] = useState({id: 0});

  useEffect(() => {
    if(transactionGroup) {
      setRecord({
        id: transactionGroup.id,
        title: transactionGroup.title || "",
        transaction_type: transactionGroup.transaction_type || "debit"
      });
    } else {
      setRecord({id: 0});
    }
  }, [transactionGroup]);

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-small-window"
      title={title || "Новая запись"}
      visible={visible}
      onOk={() => {
        if(!record.title) {
          message.warn("Укажите наименование");
          return;
        }
        onOk(record);
        setRecord({id: 0});
      }}
      onCancel={onCancel}>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Тип платежа</Col>
        <Col span={18}>
          <Select
            style={{ width: 350 }}
            placeholder="Тип платежа"
            value={record?.transaction_type}
            onChange={(value) => {
              setRecord({...record, transaction_type: value});
            }}
          >
            {[
              {id: "debit", title: "Списание"},
              {id: "credit", title: "Зачисление"},
            ].map(s => <Option key={s.id} value={s.id}>{s.title}</Option>)}
          </Select>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Наименование</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            placeholder="Наименование"
            value={record.title}
            onChange={(e) => { setRecord({...record, title: e.target.value}) }}/>
        </Col>
      </Row>



    </Modal>
  );
}