import React, {useContext, useEffect, useState} from "react";
import {Button, DatePicker, message, Modal, PageHeader, Select, Space, Table, Row, Switch, Input, Dropdown, Menu} from "antd";
import moment from "moment";
import {apiClient} from "../../../../../services/ApiClient";
import AssignTransactionTemplateDialog from "./AssignTransactionTemplateDialog";
import {
  CheckOutlined,
  EditOutlined,
  PlaySquareOutlined,
  QuestionOutlined,
  SaveOutlined,
  StopOutlined
} from "@ant-design/icons";
import {
  TemplatePaymentSource,
  TemplateExecutionStatuses,
  TemplateScheduleType,
  AppProps
} from "../../../../common/AppEnums";

import "./AssignedTransactionTemplatesTab.css"
import EditTransactionTemplateSuspendPeriodDialog from "./EditTransactionTemplateSuspendPeriodDialog";
import UserProfileContext from "../../../../../services/UserProfileContext";
import CancelButton from "../../../../buttons/CancelButton"
import EditFineTransactionExecutionDialog from "./EditFineTransactionExecutionDialog";
import TextArea from "antd/lib/input/TextArea";
const {Option} = Select;

export default function AssignedTransactionTemplatesTab({driver, updateStamp, onUpdate}) {

  let userProfile = useContext(UserProfileContext);
  let [loading, setLoading] = useState(false);
  let [visible, setVisible] = useState(false);
  let [dsLoading, setDsLoading] = useState(false);
  let [isCompleteVisible, setIsCompleteVisible] = useState(localStorage.getItem(AppProps.IsCompletedTransactionTemplatesVisible) === "true");
  let [records, setRecords] = useState([]);
  let [workConditions, setWorkConditions] = useState([]);
  let [update, setUpdate] = useState(0);
  let [templateToEdit, setTemplateToEdit] = useState(null);
  let [selectedRecords, setSelectedRecords] = useState([]);
  let [recordsToSuspend, setRecordsToSuspend] = useState([]);
  let [workConditionUpdate, setWorkConditionUpdate] = useState({
    id: driver.work_condition ? driver.work_condition.id : null,
    title: driver.work_condition ? driver.work_condition.title : null,
    date: driver.work_condition_start_dt
  });

  useEffect(() => {
    if(driver.id) {
      setDsLoading(true);
      apiClient.getDriverAssignedPaymentTemplates(driver.id, {isCompleteVisible})
        .then(res => {
          // setRecords(res.records.sort((a, b) => {
          //   if(a.work_condition && b.work_condition) {
          //     // а и б имеют условия работы, сравнить по другим параметрам
          //     if(a.payment_template.category === "fine" && a.payment_template.category === "fine") {
          //       return moment(a.create_dt).valueOf() - moment(b.create_dt).valueOf();
          //     } else if (a.payment_template.category === "fine") {
          //       return 1;
          //     } else {
          //       return -1;
          //     }
          //   } else if(a.work_condition) {
          //     // а - больше
          //     return -1;
          //   } else {
          //     // b - больше
          //     return 1;
          //   }
          // }));

          setRecords(res.records);

          if(templateToEdit) {
            setTemplateToEdit(res.records.find(el => el.id === templateToEdit.id));
          }
        })
        .catch(err => {
          message.error("Не удалось получить список назначенных шаблонов");
        })
        .finally(() => {
          setDsLoading(false);
        });
    } else {
      setRecords([]);
    }
  }, [driver, update, isCompleteVisible]);

  useEffect(() => {
    if(workConditionUpdate.id) {
      setWorkConditions([{value: workConditionUpdate.id, text: workConditionUpdate.title}])
    }
    apiClient.getWorkConditions()
      .then(res => {
        setWorkConditions(res.records.map(el => { return { value: el.id, text: el.title } }));
      })
  }, []);

  useEffect(() => {
    setUpdate(updateStamp);
  }, [updateStamp]);

  const columns = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: "",
      dataIndex: "id",
      width: 100,
      render: (text, record, index) => {
        let actions = [];
        if(canChangeWorkConditions()) {
          if(record.execution_status !== "completed") {
            if(record.execution_status === "paused" || record.suspend_dt) {
              actions.push(<Button key="play" type="link" icon={<PlaySquareOutlined/>} onClick={() => { setShowResumePayments(record); }}/>);
            } else {
              actions.push(<Button key="stop" type="link" icon={<StopOutlined/>} onClick={() => { setRecordsToSuspend([record]); }}/>);
            }
            if(!record.work_condition) {
              actions.push(<Button key="edit" type="link" icon={<EditOutlined/>} onClick={() => { setTemplateToEdit(record); }}/>);
            }
          } else {
            if(!record.work_condition) {
              actions.push(<Button key="edit" type="link" icon={<EditOutlined/>} onClick={() => { setTemplateToEdit(record); }}/>);
            }
          }
        }
        return (
          <Space direction={"horizontal"}>
            {
              actions.map(el => el)
            }
          </Space>
        );
      }
    },
    {
      title: "Статус",
      dataIndex: "execution_status",
      width: 120,
      render: (text, record, index) => {
        let status = TemplateExecutionStatuses.filter(el => el.value === record.execution_status)[0].text;
        return (<div style={{textAlign: "center"}}>{status}</div>)
      },
    },
    {
      title: 'Шаблон списания',
      width: 250,
      dataIndex: 'title',
    },
    {
      title: 'Условия работы',
      width: 150,
      align: "center",
      dataIndex: 'work_condition',
      render: (text, record, index) => {
        return record.work_condition ? <CheckOutlined/> : null;
      },
    },
    {
      title: 'Порядок списания',
      width: 180,
      dataIndex: 'schedule_type',
      render: (text, record, index) => {
        let title = TemplateScheduleType.filter(el => el.value === record.schedule_type)[0].text;
        if(record.schedule_type === "pattern") {
          title = (
            <div>
              <div>{title}</div>
              <div>{`${record.pattern_run_days} / ${record.pattern_skip_days} `}</div>
            </div>
          )
        }
        return title;
      },
    },
    {
      title: 'Баланс списания',
      width: 180,
      dataIndex: 'payment_source',
      render: (text, record, index) => {
        return TemplatePaymentSource.find(el => el.value === record.payment_source)?.text;
      },
    },
    {
      title: 'Сумма',
      width: 120,
      render: (text, record, index) => {
        return record.payment_template && record.payment_template.processing_type === "limited" ? record.payment_template.limit : "-";
      },
    },
    {
      title: 'Сумма списания в день',
      width: 120,
      dataIndex: 'rate',
    },
    {
      title: 'Списано всего',
      width: 120,
      dataIndex: 'paid',
    },
    {
      title: 'Остаток',
      width: 120,
      dataIndex: 'balance',
      render: (text, record, index) => {
        return record.limit > 0 ? record.balance : "-";
      },
    },
    {
      title: "Начальные дата/время",
      align: "center",
      width: 200,
      dataIndex: "start_dt",
      render: (text, record, index) => {
        return <div style={{textAlign: "center"}}>{record.start_dt ? `${moment(record.start_dt).format('DD.MM.YYYY')}` : ""}<br/>{record.execution_time}</div>;
      },
    },
    {
      title: "Остановить",
      align: "center",
      width: 200,
      dataIndex: "suspend_dt",
      render: (text, record, index) => {
        return <div style={{textAlign: "center"}}>{record.suspend_dt ? `${moment(record.suspend_dt).format('DD.MM.YYYY')} ${record.execution_time}` : ""}</div>;
      },
    },
    {
      title: "Продолжить",
      align: "center",
      width: 200,
      dataIndex: "resume_dt",
      render: (text, record, index) => {
        return <div style={{textAlign: "center"}}>{record.resume_dt ? `${moment(record.resume_dt).format('DD.MM.YYYY')} ${record.execution_time}` : ""}</div>;
      },
    },
    {
      title: "Последняя операция",
      align: "center",
      width: 200,
      dataIndex: "last_execution_dt",
      render: (text, record, index) => {
        return <div style={{textAlign: "center"}}>{record.last_execution_dt ? `${moment(record.last_execution_dt).format('DD.MM.YYYY HH:mm:ss')}` : ""}</div>;
      },
    },
    {
      title: "Следущая операция",
      align: "center",
      dataIndex: "next_execution_dt",
      width: 200,
      render: (text, record, index) => {
        if(record.execution_status === "scheduled" || record.execution_status === "paused") {
          return <div style={{textAlign: "center"}}>{record.next_execution_dt ? `${moment(record.next_execution_dt).format('DD.MM.YYYY HH:mm:ss')}` : ""}</div>;
        }
        return "-";
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  const updateTemplate = (id, props) => {
    setLoading(true);
    apiClient.updateDriverPaymentExecution(driver.id, id, props)
      .then(res => {
        message.success("Шаблон обновлен");
        setUpdate(prevState => prevState + 1);
        onUpdate();
      })
      .catch(err => {
        message.error("Ошибка: " + err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const assignTemplate = props => {
    setLoading(true);
    apiClient.addDriverPaymentExecution(driver.id, props)
      .then(res => {
        message.success("Шаблон добавлен");
        setUpdate(prevState => prevState + 1);
        onUpdate();
      })
      .catch(err => {
        message.error("Ошибка: " + err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeSelected = () => {
    Modal.confirm({
      title:  "Подтверждение",
      content: `Вы уверены, что хотите удалить ${selectedRecords.length} шаблонов?`,
      icon: <QuestionOutlined/>,
      visible: true,
      onOk() {
        let proms = selectedRecords.map(el => apiClient.removeDriverPaymentExecution(driver.id, el));
        Promise.all(proms)
          .catch(err => {
            message.error("Ошибка, операция не удалась");
          })
          .finally(() => {
            setSelectedRecords([]);
            setUpdate(prevState => prevState + 1);
            message.success("Записи удалены");
          });
      }
    });
  };

  const handleWorkConditionUpdate = () => {
    if(workConditionUpdate.date === null && workConditionUpdate.id !== null) {
      message.error("При назначенных условиях работы дата начала должна быть установлена");
      return;
    }
    Modal.confirm({
      title:  "Подтверждение",
      content: `Вы уверены, что хотите изменить условия работы?`,
      icon: <QuestionOutlined/>,
      visible: true,
      onOk() {
        apiClient.updateDriverWorkCondition(driver.id, workConditionUpdate)
          .then(res => {
            message.success("Условия работы обновлены");
            setUpdate(prevState => prevState + 1);
            onUpdate();
          })
          .catch(err => message.error(err));
      }
    })
  };

  const setShowResumePayments = (record) => {
    apiClient.updateDriverPaymentExecutionSuspension(driver.id, record.id, null, null)
      .then(res => {
        setUpdate(prevState => prevState + 1);
        onUpdate();
      })
      .catch(err => message.error(err));
  };

  const suspendRecords = (records, suspend_dt, resume_dt) => {
    Promise.all(records.map(record => apiClient.updateDriverPaymentExecutionSuspension(driver.id, record.id, suspend_dt, resume_dt)))
      .then(res => {
        setUpdate(prevState => prevState + 1);
        onUpdate();
      })
      .catch(err => message.error(err));
  };

  const resumeRecords = (records) => {
    Promise.all(records.map(el => apiClient.updateDriverPaymentExecutionSuspension(driver.id, el.id, null, null)))
      .then(res => {
        setUpdate(prevState => prevState + 1);
        onUpdate();
      })
      .catch(err => message.error(err));
  };

  const canChangeWorkConditions = () => {
    return userProfile.permissions.some(el => el === "change_work_condition");
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        subTitle={
          canChangeWorkConditions() ? (
            <>
              <Space direction={"horizontal"}>
                <span>Условия работы:</span>
                {/* <Dropdown
                  visible={visible}
                  placement="bottomLeft"
                  overlay={(
                      <Menu
                        className="dropdown-scroll"
                        onClick={() => {}}
                      >
                        {workConditions.map(s =>
                          <Menu.Item style={{height: 28, marginBottom: 0}} key={s.value}>
                            {s.text}
                          </Menu.Item>
                        )}
                      </Menu>
                    )
                }>
                  <div>
                    <TextArea style={{width: "450px"}} onClick={() => {setVisible(true)}}>
                      Привет!!!
                    </TextArea>
                  </div>
                </Dropdown> */}
                <Select
                  style={{width: 450, marginRight: 8}}
                  bordered
                  showSearch
                  allowClear={true}
                  placeholder="Условия работы"
                  value={workConditionUpdate.id}
                  onChange={(e) => {
                    setWorkConditionUpdate({...workConditionUpdate, id: e ? e : null, date: e ? workConditionUpdate.date : null});
                  }}
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                >
                  { workConditions.map(s => <Option key={s.value} value={s.value}>{s.text}</Option>) }
                </Select>
              </Space>
              
              <Row style={{marginTop: 8}}>
                <span>Дата начала:</span>
                <DatePicker format="DD.MM.YYYY"
                  style={{marginLeft: 23, width:220}}
                            value={workConditionUpdate.date ? moment(workConditionUpdate.date) : null}
                            onChange={(date) => {
                              setWorkConditionUpdate({...workConditionUpdate, date: date ? date.format("YYYY-MM-DD") : null});
                            }}/>
              </Row>
            </>
          ) : (
            <Space direction={"horizontal"}>
              <span>Условия работы:</span>
              <Select
                style={{width: 370}}
                bordered
                allowClear={true}
                disabled
                placeholder="Условия работы"
                value={workConditionUpdate.id}
                onChange={(e) => {
                  setWorkConditionUpdate({...workConditionUpdate, id: e ? e : null, date: e ? workConditionUpdate.date : null});
                }}
              >
                { workConditions.map(s => <Option key={s.value} value={s.value}>{s.text}</Option>) }
              </Select>
              <Row style={{marginTop: 8}}>
                <span>Дата начала:</span>
                <DatePicker format="DD.MM.YYYY"
                            disabled
                            value={workConditionUpdate.date ? moment(workConditionUpdate.date) : null}
                            onChange={(date) => {
                              setWorkConditionUpdate({...workConditionUpdate, date: date ? date.format("YYYY-MM-DD") : null});
                            }}/>
              </Row>
            </Space>
          )
        }
        extra={
          canChangeWorkConditions()
            ? (
            <div>
              <Row>
                <div style={{width: '100%'}}>
                <Space direction={"horizontal"} style={{float: 'right'}}>
                  <Button type={"primary"} onClick={() => setTemplateToEdit({id: 0})} loading={loading}>Добавить шаблон</Button>
                  {
                    selectedRecords.length > 0
                      ? (<Button type={"danger"} onClick={() => {
                          removeSelected()
                        }} loading={loading}>
                          Удалить
                        </Button>
                      ) : null
                  }
                </Space>
                </div>
              </Row>
              <Row>
                <Space style={{float: "right", marginTop: 8}} direction="horizontal">
                  <Switch
                    checkedChildren="Отображать завершенные"
                    unCheckedChildren="Скрыть завершенные"
                    defaultChecked={isCompleteVisible}
                    onChange={(e) => {
                      setIsCompleteVisible(e);
                      localStorage.setItem(AppProps.IsCompletedTransactionTemplatesVisible, e.toString());
                    }}
                  />
                  <CancelButton icon={<SaveOutlined/>} onClick={handleWorkConditionUpdate} value="Сохранить" />
                  <CancelButton icon={<StopOutlined/>} onClick={() => { setRecordsToSuspend(records) }} value="Приостановить" />
                  <CancelButton icon={<PlaySquareOutlined/>} onClick={() => { resumeRecords(records) }} value="Возобновить" />
                </Space>
              </Row>
            </div>
          ) : (
              <Switch
                checkedChildren="Отображать завершенные"
                unCheckedChildren="Скрыть завершенные"
                defaultChecked
                onChange={(e) => {
                  setIsCompleteVisible(e);
                  localStorage.setItem(AppProps.IsCompletedTransactionTemplatesVisible, e.toString());
                }}
              />
          )
        }
      />

      <div>
        <Table
          dataSource={records}
          rowClassName={(record, index) => {
            return record.limit > 0 && record.balance < 0.01
              ? "status-completed"
              : `status-${record.execution_status}`
          }}
          columns={columns}
          size="small"
          className="select-text-for-wrap"
          scroll={{x: 2500}}
          sticky
          rowKey="id"
          loading={dsLoading}
          rowSelection={{
            selectedRecords,
            onChange: (selectedRowKeys) => {
              setSelectedRecords(selectedRowKeys)
            },
            getCheckboxProps: (record) => ({
              disabled: record.work_condition,
              name: record.id,
            })
          }}
          pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
        />
      </div>

      {
        /* Назначение шаблона спсание */
        templateToEdit !== null && templateToEdit?.payment_template?.category !== "fine" && (
          <AssignTransactionTemplateDialog
            visible={true}
            record={templateToEdit}
            driver={driver}
            onOK={({id, ...props}) => {
              if (id > 0) {
                updateTemplate(id, props);
              } else {
                assignTemplate(props);
              }
              setTemplateToEdit(null);
            }}
            onCancel={() => {
              setTemplateToEdit(null);
              setUpdate(prevState => prevState + 1);
            }}
          />
        )
      }

      {
        templateToEdit !== null && templateToEdit?.payment_template?.category === "fine" && (
          <EditFineTransactionExecutionDialog
            visible={true}
            record={templateToEdit}
            driver={driver}
            onUpdate={(changes) => {
              apiClient.updateDriverPaymentExecutionProperties(driver.id, templateToEdit.id, changes)
                .then(res => {
                  message.success("Шаблон обновлен");
                  setUpdate(prevState => prevState + 1);
                  onUpdate();
                })
                .catch(err => {
                  message.error("Ошибка: " + err);
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
            onOK={() => {
              setTemplateToEdit(null);
            }}
            onCancel={() => {
              setTemplateToEdit(null);
              setUpdate(prevState => prevState + 1);
            }}
          />
        )
      }

      {
        recordsToSuspend.length > 0 && (
          <EditTransactionTemplateSuspendPeriodDialog
            visible={true}
            onOK={({suspend_dt, resume_dt}) => {
              suspendRecords(recordsToSuspend, suspend_dt, resume_dt);
              setRecordsToSuspend([])
            }}
            onCancel={() => {
              setRecordsToSuspend([])
            }}
          />
        )
      }
    </>
  );
}