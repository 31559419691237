import React, {useContext, useEffect, useState} from "react";
import {Button, DatePicker, Input, List, message, PageHeader, Radio, Space, Tooltip} from "antd";
import {apiClient} from "../../../services/ApiClient";
import {TrackerEventCodes} from "../../../components/common/AppEnums";
import moment from "moment";

import './MonitoringPage.css'
import TrackerEvent from "./TrackerEvent";
import TrackerItem from "./TrackerItem";
import TrackerIdleItem from "./TrackerIdleItem";
import CommentDialog from "../common/CommentDialog";
import CheckTrackerAlertDialog from "../common/CheckTrackerAlertDialog";
import LeaveZoneRequest from "./LeaveZoneRequest";
import UserProfileContext from "../../../services/UserProfileContext";
import EventsSettingsDialog from "./EventsSettingsDialog";
import {QuestionCircleOutlined, SettingOutlined} from "@ant-design/icons";

const {Search} = Input;
const { RangePicker } = DatePicker;

const audio = new Audio('/assets/sound_11085.wav');

const PERM_ZONE_REQUESTS = "monitoring:zone_requests";
const PERM_EVENTS = "monitoring:events";
const PERM_IDLE_TRACKERS = "monitoring:idle_trackers";
const PERM_TRACKERS = "monitoring:trackers";

export default function MonitoringPage(props) {
  const userProfile = useContext(UserProfileContext);

  let [trackers, setTrackers] = useState([]);
  let [dataSource, setDataSource] = useState([]);
  let [idleTrackers, setIdleTrackers] = useState([]);
  let [notifications, setNotifications] = useState([]);
  let [notificationsTotal, setNotificationsTotal] = useState(0);
  let [notificationsPage, setNotificationsPage] = useState(0);
  let [firstLoad, setFirstLoad] = useState(true);
  let [idleTrackersLoading, setIdleTrackersLoading] = useState(false);
  let [eventsLoading, setEventsLoading] = useState(false);
  let [selectedSearch, setSearch] = useState('');
  let [blockedTracker, setBlockedTracker] = useState(null);
  let [editAlertContext, setEditAlertContext] = useState(null);
  let [zoneRequestsLoading, setZoneRequestsLoading] = useState(false);
  let [zoneRequests, setZoneRequests] = useState([]);
  let [zoneRequestsUpdate, setZoneRequestsUpdate] = useState(0);
  let [zoneRequestsSearch, setZoneRequestsSearch] = useState(null);
  let [zoneRequestsPeriod, setZoneRequestsPeriod] = useState(null);
  let [zoneRequestsMode, setZoneRequestsMode] = useState('new');
  let [zoneRequestsStatus, setZoneRequestsStatus] = useState('accepted');
  let [eventsSettings, setEventsSettings] = useState({});
  let [editEventsSettings, setEditEventsSettings] = useState(false);

  const handleRemoveNotificationClick = item => {
    apiClient.markNotificationSeen(item.id)
      .then(() => {
        setNotifications(n => n.filter(e => e.id !== item.id));
      })
      .catch(err => {
        message.error(err);
      });
  };

  // сортировка уведомлений (3 колонка)
  const sortNotifications = (a, b) => {
    if (a.notification_dt && b.notification_dt) {
      return Date.parse(b.notification_dt) - Date.parse(a.notification_dt);
    } else if (a.notification_dt && b.notification_dt === null) {
      return -1;
    } else if (b.notification_dt && a.notification_dt === null) {
      return 1;
    }
    return Date.parse(b.event_dt) - Date.parse(a.event_dt);
  };

  const handleTrackerEventAlertClick = (item, dt) => {
    if(dt) {
      apiClient.updateTrackerEventNotification(item.id, dt)
        .then(() => {
          item.notification_dt = dt;

          const other = notifications.filter(e => e.id !== item.id);

          setNotifications([item, ...other].sort(sortNotifications));

          message.info("Дата уведомления обновлена");
        })
        .catch(err => {
          message.error('Ошибка при сохранении даты уведомления');
        });
    }
  };

  const addTrackerComment = (item, comment) => {
    apiClient.addTrackerComment(item.tracker, comment)
      .then(() => {
        message.success("Комментарий добавлен");
      })
      .catch(err => {
        message.error('Ошибка при добавлении комментария');
      });
  };

  const handleTrackerEventAlertRemoveClick = item => {
    apiClient.removeTrackerEventNotification(item.id)
      .then(() => {
        item.notification_dt = null;

        const other = notifications.filter(e => e.id !== item.id);

        setNotifications([item, ...other].sort(sortNotifications));
      })
      .catch(err => {
        message.error('Ошибка');
      });
  };

  const handleSearchTermChanged = (e) => {
    setSearch(e.target.value);
  };

  const handleTrackerBlockedChange = (item, blocked) => {
    setBlockedTracker({tracker: item, blocked: blocked});
  };

  const onBlockingAcceped = (comment) => {
    apiClient.updateTrackerBlocked(blockedTracker.tracker.id, blockedTracker.blocked, comment)
      .then(() => {
        blockedTracker.tracker.is_blocked = blockedTracker.blocked;
        setTrackers([blockedTracker.tracker, ...trackers.filter(e => e.id !== blockedTracker.tracker.id)]);
        if (!blockedTracker.blocked) {
          message.success('Автомобиль разблокирован');
        } else {
          message.success('Автомобиль заблокирован');
        }
      })
      .catch(err => {
        message.error('Ошибка');
      })
      .finally(() => {
        setBlockedTracker(null);
      });
  };

  // add trackers state subscription
  useEffect(() => {
    if(!userProfile?.tenant?.scopes.find(scope => scope === PERM_IDLE_TRACKERS)) {
      return
    }
    setIdleTrackersLoading(true);
    const subscription = apiClient
      .subscribeTrackersState((trackers) => {
        setIdleTrackersLoading(false);
        setIdleTrackers(trackers
          .filter(e => e.has_driver && e.movement_state === 'parked' && moment(e.actual_track_update).add(15, 'hours').isBefore(moment()))
          .sort((a, b) => {
            if (!a.actual_track_update || !b.actual_track_update) {
              return 0;
            }
            return Date.parse(b.actual_track_update) - Date.parse(a.actual_track_update);
          })
        );
        setTrackers(trackers.sort((a, b) => {
          if (a.connection_state === b.connection_state) {
            return 0;
          }
          if (a.connection_state === 'offline' || a.connection_state === 'idle' || a.connection_state === 'signal_lost') {
            return -1;
          }
          if (b.connection_state === 'offline' || b.connection_state === 'idle' || b.connection_state === 'signal_lost') {
            return 1;
          }
          return 0;
        }));
      });
    return () => apiClient.unsubscribeTrackersState(subscription);
  }, [userProfile]);

  // add events subscription
  useEffect(() => {
    if(!userProfile?.tenant?.scopes.find(scope => scope === PERM_EVENTS)) {
      return
    }

    const filter = {
      'event_type': eventsSettings.codes?.join(),
      'regNumber': eventsSettings.regNumber,
    };

    if(eventsSettings.period && eventsSettings.period.length > 0) {
      filter.from_dt = moment(eventsSettings.period[0]).format('YYYY-MM-DD');
      filter.to_dt = moment(eventsSettings.period[1]).format('YYYY-MM-DD');
    }

    if(!filter.event_type || filter.event_type.length < 1) {
      filter.event_type = TrackerEventCodes.map(el => el.id).join();
    }

    setEventsLoading(true);
    setNotifications([]);
    const subscription = apiClient.subscribeTrackerEvents(filter, (events) => {
      setEventsLoading(false);
      if (events.length > 0) {
        let skipAudio = true;
        setFirstLoad(v => {
          skipAudio = v;
          return false;
        });
        setNotifications(prev => {
          events = events.map(n => {
            n.isNew = !skipAudio;
            return n;
          });
          return [...prev, ...events]
            .sort(sortNotifications);
        });
        if (!skipAudio) {
          audio.play();
        }
      }
    });
    return () => apiClient.unsubscribeTrackerEvents(subscription);
  }, [userProfile, eventsSettings]);

  // update trackers list, using filters
  useEffect(() => {
    if(!userProfile?.tenant?.scopes.find(scope => scope === PERM_TRACKERS)) {
      return
    }
    let filtered = [...trackers];
    if (selectedSearch) {
      let selectedSearchLc = selectedSearch.toLowerCase();
      filtered = filtered.filter(e => e.label.toLowerCase().indexOf(selectedSearchLc) >= 0);
    }
    setDataSource(filtered);
  }, [trackers, selectedSearch, userProfile]);

  useEffect(() => {
    if(!userProfile.tenant?.scopes.find(scope => scope === PERM_ZONE_REQUESTS)) {
      return
    }
    const fn = () => {
      apiClient.getZoneRequests({
        "car_number": zoneRequestsSearch,
        "period": zoneRequestsPeriod,
        "mode": zoneRequestsMode,
        "status": zoneRequestsStatus,
      })
        .then(res => {
          setZoneRequestsLoading(false);
          setZoneRequests(res.records);
        })
        .catch(err => message.error(err));
    };
    setZoneRequestsLoading(true);
    fn();
    const i = setInterval(fn, 1000 * 60);
    return () => clearTimeout(i);
  }, [zoneRequestsUpdate, zoneRequestsSearch, zoneRequestsPeriod, zoneRequestsMode, zoneRequestsStatus, userProfile]);

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Мониторинг"
      />

      <CommentDialog
        title={blockedTracker ? `${blockedTracker.tracker.is_blocked ? "Разблокировать" : "Заблокировать"} авто ${blockedTracker.tracker.label}` : ""}
        label="Причина"
        visible={blockedTracker !== null}
        onOk={(comment) => {
          setBlockedTracker(null);
          onBlockingAcceped(comment);
        }}
        onCancel={() => {
          setBlockedTracker(null);
        }}
      />

      {
        editAlertContext !== null
          ? (
            <CheckTrackerAlertDialog
              title="Уведомление о событии"
              label="Комментарий"
              visible={true}
              onOk={(dt, comment) => {
                if(dt) {
                  handleTrackerEventAlertClick(editAlertContext.event, dt);
                }
                if(comment) {
                  addTrackerComment(editAlertContext.event, comment)
                }
                setEditAlertContext(null);
              }}
              onRemove={(comment) => {
                // отметить как решенное
                if(comment) {
                  // добавить комментарий
                  addTrackerComment(editAlertContext.event, comment)
                }
                handleTrackerEventAlertRemoveClick(editAlertContext.event);
                setEditAlertContext(null);
              }}
              onCancel={() => {
                setEditAlertContext(null);
              }}
            />
          ) : null
      }

      <Space direction="horizontal" size="middle" align="start">
        {
          userProfile?.tenant?.scopes.find(scope => scope === PERM_TRACKERS)
            ? (
              <List
                size="small"
                header={<div style={{textAlign: 'center', fontWeight: 'bold'}}>
                  <Search
                    allowClear
                    enterButton
                    placeholder="Поиск по гос.номеру"
                    onChange={handleSearchTermChanged}
                    style={{width: '90%'}}
                  />
                  <Tooltip
                    placement="top"
                    title="Вы можете найти и заблокировать транспортное средство. При блокировке будет остановлен двигатель."
                  >
                    <QuestionCircleOutlined style={{margin: "5px", marginTop: "10px"}}/>
                  </Tooltip>
                </div>}
                bordered
                dataSource={dataSource}
                renderItem={item => <TrackerItem key={item.id}
                                                 item={item}
                                                 onBlockedChange={(blocked) => handleTrackerBlockedChange(item, blocked)}/>}
                locale={{emptyText: 'Нет записей'}}
                style={{width: 300}}
                pagination={{
                  size: "small",
                  showSizeChanger: false,
                  showQuickJumper: false,
                  hideOnSinglePage: true
                }}
              />
            ) : null
        }
        {
          userProfile?.tenant?.scopes.find(scope => scope === PERM_IDLE_TRACKERS)
            ? (
              <List
                loading={idleTrackersLoading}
                size="small"
                header={
                  <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                    Простой
                    <Tooltip
                      placement="top"
                      title="Уведомления о простое транспортных средств."
                    >
                      <QuestionCircleOutlined style={{margin: "5px"}}/>
                    </Tooltip>
                  </div>
                }
                bordered
                dataSource={idleTrackers}
                locale={{emptyText: 'Нет записей'}}
                renderItem={item => <TrackerIdleItem key={item.id} item={item}/>}
                style={{width: 300}}
                pagination={{
                  size: "small",
                  showSizeChanger: false,
                  showQuickJumper: false,
                  hideOnSinglePage: true
                }}
              />
            ) : null
        }
        {
          userProfile?.tenant?.scopes.find(scope => scope === PERM_EVENTS)
            ? (
              <List
                loading={eventsLoading}
                size="small"
                header={
                  <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                    Уведомления 
                    <Button type="link" icon={<SettingOutlined/>} onClick={() => setEditEventsSettings(prevState => !prevState)}/>
                    <Tooltip
                      placement="top"
                      title="Уведомления по транспортным средствам."
                    >
                      <QuestionCircleOutlined style={{margin: "5px"}}/>
                    </Tooltip>
                  </div>
                }
                bordered
                rowKey="id"
                dataSource={notifications}
                renderItem={item => (
                  <TrackerEvent
                    key={item.id}
                    item={item}
                    onRemoveNotificationClick={handleRemoveNotificationClick}
                    onAlertClick={() => {
                      setEditAlertContext({"event": item});
                    }}
                    onRemoveAlertClick={() => {
                      // отметить как решенное
                      handleTrackerEventAlertRemoveClick(item);
                    }}
                    onChangeAlertClick={(dt) => {
                      handleTrackerEventAlertClick(item, dt);
                    }}
                  />
                )}
                locale={{
                  emptyText: 'Нет записей'
                }}
                style={{width: 320}}
                pagination={{
                  size: "small",
                  showSizeChanger: false,
                  showQuickJumper: false,
                  hideOnSinglePage: true
                }}
              />
            ) : null
        }
        {
          userProfile?.tenant?.scopes.find(scope => scope === PERM_ZONE_REQUESTS)
            ? (
              <List
                loading={zoneRequestsLoading}
                size="small"
                header={(
                  <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                    <div>
                      Заявки на выезд
                      <Tooltip
                        placement="top"
                        title="Заявки на выезд от водителей из бота телеграмм."
                      >
                        <QuestionCircleOutlined style={{margin: "5px"}}/>
                      </Tooltip>
                    </div>
                    <Space direction={"vertical"}>
                      <Search
                        allowClear
                        enterButton
                        placeholder="Поиск по гос.номеру"
                        onSearch={(value, event) => {
                          setZoneRequestsSearch(value);
                        }}
                        style={{width: '100%'}}
                      />
                      <div>
                        <Radio.Group
                          value={zoneRequestsMode}
                          onChange={(e) => {
                            setZoneRequestsMode(e.target.value);
                          }}>
                          <Radio.Button value={'new'}>Новые</Radio.Button>
                          <Radio.Button value={'all'}>Архив</Radio.Button>
                        </Radio.Group>
                        <Radio.Group
                          disabled={zoneRequestsMode === "new"}
                          value={zoneRequestsStatus}
                          onChange={(e) => {
                            setZoneRequestsStatus(e.target.value)
                          }}>
                          <Radio.Button value={'accepted'}>Разрешено</Radio.Button>
                          <Radio.Button value={'declined'}>Запрещено</Radio.Button>
                        </Radio.Group>
                      </div>
                      <RangePicker format="DD.MM.YYYY" style={{width: '100%'}} onChange={(val) => {
                        if(!val) {
                          setZoneRequestsPeriod(null);
                        } else {
                          setZoneRequestsPeriod([val[0].format("YYYY-MM-DD"), val[1].format("YYYY-MM-DD")])
                        }
                      }}/>
                    </Space>
                  </div>
                )}
                bordered
                dataSource={zoneRequests}
                renderItem={item => (
                  <LeaveZoneRequest
                    item={item}
                    onAccept={() => {
                      apiClient.setZoneRequestStatus(item.id, "accepted")
                        .then(res => {
                          message.info("Заявка разрешена");
                          setZoneRequestsUpdate(prevState => prevState + 1);
                        })
                        .catch(err => message.error(err));
                    }}
                    onDecline={() => {
                      apiClient.setZoneRequestStatus(item.id, "declined")
                        .then(res => {
                          message.info("Заявка запрещена");
                          setZoneRequestsUpdate(prevState => prevState + 1);
                        })
                        .catch(err => message.error(err));
                    }}
                  />
                )}
                locale={{
                  emptyText: 'Нет записей'
                }}
                style={{width: 450}}
                pagination={{
                  size: "small",
                  showSizeChanger: false,
                  showQuickJumper: false,
                  hideOnSinglePage: true,
                  onChange: (page, pageSize) => {
                    console.log(page, pageSize);
                  }
                }}
              />
            ) : null
        }
      </Space>

      {
        editEventsSettings && (
          <>
            <EventsSettingsDialog
              settings={eventsSettings}
              onOK={(settings) => {
                setEventsSettings(settings);
                setEditEventsSettings(false);
              }}
              onCancel={() => setEditEventsSettings(false)}
            />
          </>
        )
      }
    </>
  );
}