import React, {useState} from "react";
import {Input, Modal, Space} from "antd";

export default function StringInputDialog({title, onOK, onCancel}) {

  let [record, setRecord] = useState(null);

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-small-window"
      title={title}
      visible={true}
      onOk={() => {
        onOK(record);
      }}
      onCancel={() => {
        setRecord(null);
        onCancel();
      }}
    >
      <Space direction={"vertical"} style={{width: '100%'}}>
        <Input
          style={{width: '100%'}}
          placeholder={"Наименование"}
          value={record}
          onChange={(e) => {
            setRecord(e.target.value);
          }}/>
      </Space>
    </Modal>
  )
}