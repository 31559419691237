import React, {useEffect, useState} from "react";
import {Button, DatePicker, Input, Modal, Space, Switch, TimePicker} from "antd";
import moment from "moment";
import {QuestionOutlined} from "@ant-design/icons";
import CancelButton from "../../buttons/CancelButton";
import '../ModalStyle.css'

const { TextArea } = Input;

export default function AddNotificationDialog(props) {
  let [date, setDate] = useState(null);
  let [time, setTime] = useState(null);
  let [enableEarlyHours, setEnableEarlyHours] = useState(true);
  let [earlyHours, setEarlyHours] = useState(moment('2000-01-01 00:15:00'));
  let [comment, setComment] = useState("");

  useEffect(() => {
    if(props.item) {
      if(props.item.notification_dt) {
        const dt = moment(props.item.notification_dt);
        setDate(dt);
        setTime(dt);
      }
      if(props.item.notification_early_time) {
        setEarlyHours(moment(`2000-01-01 ${props.item.notification_early_time}`));
        setEnableEarlyHours(true);
      } else {
        setEnableEarlyHours(true);
        setEarlyHours(moment('2000-01-01 00:15:00'));
      }
      setComment(props.item.notification_comment);
    } else {
      setDate(null);
      setTime(null);
      setEnableEarlyHours(true);
      setEarlyHours(moment('2000-01-01 00:15:00'));
      setComment("");
    }
  }, [props.item]);

  const handleDateUpdated = (val) => {
    if(val) {
      val.hour(0);
      val.minute(0);
      val.second(0);
    }
    setDate(val);
  };

  const handleEarlyNotification = (checked) => {
    setEnableEarlyHours(checked);
  };

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-small-window"
      style={{top: 10}}
      title={props.title}
      visible={props.visible}
      onCancel={() => {
        props.onCancel();
      }}
      footer={[
        <CancelButton key="cancel" onClick={() => { props.onCancel(); }} value="Отмена"/>,
        <Button
          key="delete"
          disabled={!(props.item && props.item.notification_dt)}
          danger
          onClick={() => {
            Modal.confirm({
              title:  "Подтверждение",
              content: `Вы уверены, что хотите удалить напоминание?`,
              icon: <QuestionOutlined/>,
              visible: true,
              onOk() {
                props.onDelete();
              }
            });
          }}
        >
          Отменить уведомление
        </Button>,
        <Button key="ok" type="primary" onClick={() => {
          let dt = null;
          if(date) {
            dt = date;
            if(time) {
              dt.hour(time.hour());
              dt.minute(time.minute());
              dt.second(time.second());
            }
          }
          props.onOk(dt, comment, enableEarlyHours ? earlyHours.format("HH:mm:ss") : 0);
        }}>
          Сохранить
        </Button>,
      ]}>

      <Space direction="vertical">
        <Space style={{width: '100%'}}>
          <DatePicker format="DD.MM.YYYY"
                      disabledDate={(dt) => {
                        return dt && dt < moment().startOf('day')
                      }}
                      value={date}
                      onChange={handleDateUpdated}
                      style={{ width: 220 }}/>
          <TimePicker onChange={(val) => { setTime(val); }} style={{ width: 220 }} value={time}/>
        </Space>
        <TextArea placeholder={ props.label } rows={4}
                  value={comment}
                  onChange={(e) => { setComment(e.target.value); }}/>
        <Space style={{ width: '100%' }}>
          <Switch title={"Уведомить за"} onChange={handleEarlyNotification} checked={enableEarlyHours}/>
          <span>Уведомить за:</span>
          <TimePicker onChange={(val) => { setEarlyHours(val); }}
                      disabled={!enableEarlyHours}
                      style={{ width: 220 }}
                      value={earlyHours}/>
        </Space>
      </Space>
    </Modal>
  );
}