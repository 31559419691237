import React, {useEffect, useState} from "react";
import {Col, Input, Modal, Row, Select, Space, DatePicker, Checkbox, message, AutoComplete, Collapse} from "antd";
import moment from "moment";
import {UnitTypes} from "../../../common/AppEnums";
import "../../ModalStyle.css";
import {daDataService} from "../../../../services/DaDataService";

const { TextArea } = Input;
const { Option } = Select;
const rowStyle = {padding: '4px'};
const { Panel } = Collapse;

export default function VehicleOwnerEditDialog({visible, park: vehicleOwner, onOk, onCancel, title}) {
  let [record, setRecord] = useState({id: 0});
  let [suggestions, setSuggestions] = useState({});

  useEffect(() => {
    if(vehicleOwner) {
      setRecord({
        id: vehicleOwner.id || 0,
        type: vehicleOwner.type || "ip",
        ...vehicleOwner
      });
    } else {
      setRecord({id: 0, type: "ip"});
    }
  }, [vehicleOwner]);

  const isNew = () => {
    return record.id === 0;
  };

  const isIP = () => {
    return record.type === "ip";
  };

  const isSW = () => {
    return record.type === "sw";
  };

  const plForm = () => {
    return (
      <>
        <Collapse>
          <Panel header="Общее" key="1">
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>ИНН</Col>
              <Col span={18}>
                <AutoComplete
                  allowClear={true}
                  placeholder="ИНН"
                  options={suggestions.company || []}
                  value={record.inn}
                  style={{width: '100%'}}
                  onChange={(value) => {
                    console.log(value);
                    setRecord({...record, inn: value});
                    setSuggestions({...suggestions, company: []});
                  }}
                  onSearch={(text) => {
                    if(text && text.length > 2 && !text.endsWith(" ")) {
                      daDataService.suggestIndividualCompany(text)
                        .then(res => {
                          setSuggestions({...suggestions, company: res.suggestions.map(el => {return {value: el.value, data: el.data}})});
                        })
                    }
                  }}
                  onSelect={(value, option) => {
                    console.log(value, option.data);
                    setRecord({...record, inn: option.data.inn, ogrn: option.data.ogrn, fio: option.data.fio ? `${option.data.fio?.surname} ${option.data.fio?.name} ${option.data.fio?.patronymic}` : ""});
                  }}
                />
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>ФИО</Col>
              <Col span={18}>
                <Input
                  allowClear={true}
                  placeholder="ФИО"
                  value={record.fio}
                  onChange={(e) => { setRecord({...record, fio: e.target.value}) }}/>
              </Col>
            </Row>
            {/* для Самозанятого: Справка о сомозанятости */}
            {
              isSW() ? (
                <Row gutter={[0, 16]} style={rowStyle}>
                  <Col span={6}>Справка о самозанятости</Col>
                  <Col span={18}>
                    <Space direction={"horizontal"}>
                      <Input
                        allowClear={true}
                        placeholder="Номер"
                        style={{width: '270px'}}
                        value={record.sw_statement}
                        onChange={(e) => { setRecord({...record, sw_statement: e.target.value}) }}/>
                      <DatePicker
                        format="DD.MM.YYYY"
                        placeholder="Дата выдачи"
                        value={record.sw_statement_dt ? moment(record.sw_statement_dt, "YYYY-MM-DD") : null}
                        onChange={(val) => { setRecord({...record, sw_statement_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                        style={{width: 255}}/>
                    </Space>
                  </Col>
                </Row>
              ) : null
            }
            {/* Для ИП: Доверенность и ОГРНИП */}
            {
              isIP() ? (
                <>
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>ОГРНИП</Col>
                    <Col span={18}>
                      <Input
                        allowClear={true}
                        placeholder="ОГРНИП"
                        value={record.ogrn}
                        onChange={(e) => { setRecord({...record, ogrn: e.target.value}) }}/>
                    </Col>
                  </Row>

                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Доверенность от ИП</Col>
                    <Col span={18}>
                      <Input
                        allowClear={true}
                        placeholder="Доверенность от ИП"
                        value={record.proxy_ip}
                        onChange={(e) => { setRecord({...record, proxy_ip: e.target.value}) }}/>
                    </Col>
                  </Row>
                </>
              ) : null
            }
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Доверенность от физлица</Col>
              <Col span={18}>
                <Input
                  allowClear={true}
                  placeholder="Доверенность от физлица"
                  value={record.proxy_pl}
                  onChange={(e) => { setRecord({...record, proxy_pl: e.target.value}) }}/>
              </Col>
            </Row>
          </Panel>
          <Panel header="Паспорт" key="2">
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Серия и номер</Col>
              <Col span={7}>
                <Input
                  allowClear={true}
                  placeholder="Серия и номер паспорта"
                  style={{width: '270px'}}
                  value={record.passport_number}
                  onChange={(e) => { setRecord({...record, passport_number: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Дата выдачи</Col>
              <Col span={7}>
                <DatePicker
                  style={{width: '270px'}}
                  format="DD.MM.YYYY"
                  placeholder="Дата выдачи"
                  value={record.passport_issue_dt ? moment(record.passport_issue_dt, "YYYY-MM-DD") : null}
                  onChange={(val) => { setRecord({...record, passport_issue_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                />
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Кем выдан</Col>
              <Col span={18}>
                <Input
                  allowClear={true}
                  placeholder="Кем выдан"
                  value={record.passport_issuer_title}
                  onChange={(e) => { setRecord({...record, passport_issuer_title: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Код подразделения</Col>
              <Col span={7}>
                <Input
                  allowClear={true}
                  placeholder="Код"
                  style={{width: '270px'}}
                  value={record.passport_issuer_code}
                  onChange={(e) => { setRecord({...record, passport_issuer_code: e.target.value}) }}/>
              </Col>
            </Row>
          </Panel>
          <Panel header="Общие сведения" key="3">
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Дата рождения</Col>
              <Col span={7}>
                <DatePicker
                  style={{width: '270px'}}
                  format="DD.MM.YYYY"
                  placeholder="Дата рождения"
                  value={record.birth_dt ? moment(record.birth_dt, "YYYY-MM-DD") : null}
                  onChange={(val) => { setRecord({...record, birth_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                />
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Адрес регистрации</Col>
              <Col span={18}>
                <AutoComplete
                  allowClear={true}
                  placeholder="Адрес"
                  options={suggestions.reg_address || []}
                  value={record?.reg_address}
                  style={{width: '100%'}}
                  onChange={(data) => {
                    setRecord({...record, reg_address: data});
                    setSuggestions({...suggestions, reg_address: []});
                  }}
                  onSearch={(text) => {
                    if(text && text.length > 2 && !text.endsWith(" ")) {
                      daDataService.suggestAddress(text)
                        .then(res => {
                          setSuggestions({...suggestions, reg_address: res.suggestions.map(el => {return {value: el.value}})});
                        })
                    }
                  }}
                />
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Номер телефона</Col>
              <Col span={7}>
                <Input
                  allowClear={true}
                  placeholder="Номер телефона"
                  style={{width: '270px'}}
                  value={record.phone}
                  onChange={(e) => { setRecord({...record, phone: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Доп. телефон</Col>
              <Col span={7}>
                <Input
                  allowClear={true}
                  placeholder="Номер дополнительного телефона"
                  style={{width: '270px'}}
                  value={record.phone_secondary}
                  onChange={(e) => { setRecord({...record, phone_secondary: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Номер WhatsApp</Col>
              <Col span={7}>
                <Input
                  allowClear={true}
                  placeholder="Номер WhatsApp"
                  style={{width: '270px'}}
                  value={record.phone_whatsapp}
                  onChange={(e) => { setRecord({...record, phone_whatsapp: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Email</Col>
              <Col span={7}>
                <Input
                  allowClear={true}
                  placeholder="Email"
                  style={{width: '270px'}}
                  value={record.email}
                  onChange={(e) => { setRecord({...record, email: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Агентский договор</Col>
              <Col span={9}>
                  <Input
                    allowClear={true}
                    placeholder="Номер"
                    style={{width: '270px'}}
                    value={record.agent_contract_number}
                    onChange={(e) => { setRecord({...record, agent_contract_number: e.target.value}) }}/>
              </Col>
              <Col span={7}>
                  <DatePicker
                    format="DD.MM.YYYY"
                    placeholder="Дата"
                    value={record.agent_contract_dt ?  moment(record.agent_contract_dt, "YYYY-MM-DD") : null}
                    onChange={(val) => { setRecord({...record, agent_contract_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                    style={{width: '270px', marginLeft: 8}}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Наличие ЭЦП</Col>
              <Col span={18}>
                <Checkbox
                  checked={record.has_digital_signature}
                  onChange={(e) => {
                    setRecord({...record, has_digital_signature: e.target.checked });
                  }}
                />
              </Col>
            </Row>
          </Panel>
          <Panel header="Банковские реквизиты" key="4">
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Наименование</Col>
              <Col span={18}>
                <Input
                  allowClear={true}
                  placeholder="Наименование"
                  value={record.bank_title}
                  onChange={(e) => { setRecord({...record, bank_title: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Расчетный счет</Col>
              <Col span={9}>
                <Input
                  allowClear={true}
                  placeholder="Расчетный счет"
                  value={record.bank_check_account}
                  onChange={(e) => { setRecord({...record, bank_check_account: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Корр. счет</Col>
              <Col span={9}>
                <Input
                  allowClear={true}
                  placeholder="Корр. счет"
                  value={record.bank_corr_account}
                  onChange={(e) => { setRecord({...record, bank_corr_account: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>БИК</Col>
              <Col span={9}>
                <Input
                  allowClear={true}
                  placeholder="БИК"
                  value={record.bank_bik}
                  onChange={(e) => { setRecord({...record, bank_bik: e.target.value}) }}/>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </>
    )
  };

  const ulForm = () => {
    return (
      <>
        <Collapse>
          <Panel header="Общее" key="1">
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>ИНН</Col>
              <Col span={18}>
                <AutoComplete
                  allowClear={true}
                  placeholder="ИНН"
                  options={suggestions.company || []}
                  value={record.inn}
                  style={{width: '100%'}}
                  onChange={(value) => {
                    console.log(value);
                    setRecord({...record, inn: value});
                    setSuggestions({...suggestions, company: []});
                  }}
                  onSearch={(text) => {
                    if(text && text.length > 2 && !text.endsWith(" ")) {
                      daDataService.suggestLegalCompany(text)
                        .then(res => {
                          setSuggestions({...suggestions, company: res.suggestions.map(el => {return {value: el.value, data: el.data}})});
                        })
                    }
                  }}
                  onSelect={(value, option) => {
                    console.log(value, option.data);
                    setRecord({...record,
                      inn: option.data.inn,
                      ogrn: option.data.ogrn,
                      kpp: option.data.kpp,
                      full_title: option.data.name.full_with_opf,
                      short_title: option.data.name.short_with_opf,
                      legal_address: option.data.address?.value,
                      director_fio: option.data.management?.name,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Полное наименование</Col>
              <Col span={18}>
                <Input
                  allowClear={true}
                  placeholder="Полное наименование"
                  value={record.full_title}
                  onChange={(e) => { setRecord({...record, full_title: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Сокращенное наименование</Col>
              <Col span={18}>
                <Input
                  allowClear={true}
                  placeholder="Сокращенное наименование"
                  value={record.short_title}
                  onChange={(e) => { setRecord({...record, short_title: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>КПП</Col>
              <Col span={18}>
                <Input
                  allowClear={true}
                  placeholder="КПП"
                  style={{width: '350px'}}
                  value={record.kpp}
                  onChange={(e) => { setRecord({...record, kpp: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>ОГРН</Col>
              <Col span={18}>
                <Input
                  allowClear={true}
                  placeholder="ОГРН"
                  style={{width: '350px'}}
                  value={record.ogrn}
                  onChange={(e) => { setRecord({...record, ogrn: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>ФИО директора</Col>
              <Col span={9}>
                <Input
                  allowClear={true}
                  placeholder="ФИО директора"
                  style={{width: '350px'}}
                  value={record.director_fio}
                  onChange={(e) => { setRecord({...record, director_fio: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>На основании чего действует директор</Col>
              <Col span={9}>
                <Input
                  allowClear={true}
                  placeholder="На основании чего действует директор"
                  style={{width: '350px'}}
                  value={record.director_document}
                  onChange={(e) => { setRecord({...record, director_document: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Юридический адрес</Col>
              <Col span={18}>
                <TextArea
                  allowClear={true}
                  placeholder="Юридический адрес"
                  value={record.legal_address}
                  style={{width: '100%'}}
                  onChange={(e) => { setRecord({...record, legal_address: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Фактический адрес</Col>
              <Col span={18}>
                <TextArea
                  allowClear={true}
                  style={{width: '100%'}}
                  placeholder="Фактический адрес"
                  value={record.act_address}
                  onChange={(e) => { setRecord({...record, act_address: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Номер телефона</Col>
              <Col span={7}>
                <Input
                  allowClear={true}
                  placeholder="Номер телефона"
                  style={{width: '350px'}}
                  value={record.phone}
                  onChange={(e) => { setRecord({...record, phone: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Доп. телефон</Col>
              <Col span={7}>
                <Input
                  allowClear={true}
                  placeholder="Номер дополнительного телефона"
                  style={{width: '350px'}}
                  value={record.phone_secondary}
                  onChange={(e) => { setRecord({...record, phone_secondary: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Номер WhatsApp</Col>
              <Col span={7}>
                <Input
                  allowClear={true}
                  placeholder="Номер WhatsApp"
                  style={{width: '350px'}}
                  value={record.phone_whatsapp}
                  onChange={(e) => { setRecord({...record, phone_whatsapp: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Email</Col>
              <Col span={7}>
                <Input
                  allowClear={true}
                  placeholder="Email"
                  style={{width: '350px'}}
                  value={record.email}
                  onChange={(e) => { setRecord({...record, email: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Доверенность</Col>
              <Col span={18}>
                <Input
                  allowClear={true}
                  placeholder="Доверенность"
                  value={record.proxy_ul}
                  onChange={(e) => { setRecord({...record, proxy_pl: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Агентский договор</Col>
              <Col span={18}>
                <Space direction={"horizontal"}>
                  <Input
                    allowClear={true}
                    placeholder="Номер"
                    value={record.agent_contract_number}
                    onChange={(e) => { setRecord({...record, agent_contract_number: e.target.value}) }}/>
                  <DatePicker
                    format="DD.MM.YYYY"
                    placeholder="Дата"
                    value={record.agent_contract_dt ? moment(record.agent_contract_dt, "YYYY-MM-DD") : null }
                    onChange={(val) => { setRecord({...record, agent_contract_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                    style={{width: 220}}/>
                </Space>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Наличие ЭЦП</Col>
              <Col span={18}>
                <Checkbox
                  checked={record.has_digital_signature}
                  onChange={(e) => {
                    setRecord({...record, has_digital_signature: e.target.checked });
                  }}
                />
              </Col>
            </Row>
          </Panel>
          <Panel header="Банковские реквизиты" key="2">
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Наименование</Col>
              <Col span={18}>
                <Input
                  allowClear={true}
                  placeholder="Наименование"
                  value={record.bank_title}
                  onChange={(e) => { setRecord({...record, bank_title: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Расчетный счет</Col>
              <Col span={9}>
                <Input
                  allowClear={true}
                  placeholder="Расчетный счет"
                  value={record.bank_check_account}
                  onChange={(e) => { setRecord({...record, bank_check_account: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>Корр. счет</Col>
              <Col span={9}>
                <Input
                  allowClear={true}
                  placeholder="Корр. счет"
                  value={record.bank_corr_account}
                  onChange={(e) => { setRecord({...record, bank_corr_account: e.target.value}) }}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={6}>БИК</Col>
              <Col span={9}>
                <Input
                  allowClear={true}
                  placeholder="БИК"
                  value={record.bank_bik}
                  onChange={(e) => { setRecord({...record, bank_bik: e.target.value}) }}/>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </>
    )
  };

  return (
    <Modal
      destroyOnClose={true}
      style={{top: 10}}
      className="size-for-middle-window"
      title={title || "Новая запись"}
      visible={visible}
      onOk={() => {
        if(!record.inn) {
          message.warn("Укажите ИНН");
          return;
        }
        onOk(record);
        setRecord({id: 0});
      }}
      onCancel={onCancel}>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Тип собственника</Col>
        <Col span={10}>
          <Select
            style={{ width: 220 }}
            value={record.type}
            onChange={(value) => {
              setRecord({...record, type: value});
            }}
            disabled={!isNew()}
            allowClear={isNew()}
          >
            {UnitTypes
              .filter(el => el.id !== "pl")
              .map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)}
          </Select>
        </Col>
      </Row>

      {
        record
          ? (isIP() || isSW()) ? plForm() : ulForm()
          : null
      }

    </Modal>
  )
}