import React, {useEffect, useState} from "react";
import {Col, Modal, Row, DatePicker, message, Space, Button, Table, Input, Select} from "antd";
import EmptyPage from "../../EmptyPage";
import {apiClient} from "../../../../services/ApiClient";
import moment from "moment";
import {DeleteOutlined} from "@ant-design/icons";
import ReloadOutlined from "@ant-design/icons/lib/icons/ReloadOutlined";
import '../../ModalStyle.css';

const { RangePicker } = DatePicker;
const { Option } = Select;

const rowStyle = {padding: '2px'};

export default function CompetitionsEditDialog({visible, record, onOk, onClose}) {

  let [competition, setCompetition] = useState(null);
  let [allDrivers, setAllDrivers] = useState([]);
  let [drivers, setDrivers] = useState([]);
  let [driversUpdate, setDriversUpdate] = useState(0);
  let [selectedDriverId, setSelectedDriverId] = useState(null);

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    {
      title: "",
      width: 50,
      align: "center",
      dataIndex: "id",
      render: function(text, record, index) {
        return (
          <Space direction={"horizontal"}>
            <Button type="link" icon={<DeleteOutlined/>} onClick={() => {removeParticipant(record);}}/>
          </Space>
        );
      }
    },
    {
      title: 'Номер участника',
      dataIndex: 'number',
      width: 120,
      render: (text, record, index) => {
        return record.number;
      },
    },
    {
      title: 'Водитель',
      dataIndex: 'taxi_driver',
      render: (text, record, index) => {
        return record.taxi_driver ? record.taxi_driver.name : "";
      },
    },
    {
      title: 'Марка',
      dataIndex: 'taxi_driver',
      render: (text, record, index) => {
        return record.taxi_driver ? record.taxi_driver.car_model : "";
      },
    },
    {
      title: 'Номер',
      dataIndex: 'taxi_driver',
      render: (text, record, index) => {
        return record.taxi_driver ? record.taxi_driver.car_number : "";
      },
    },
    {
      title: 'Коррекция',
      dataIndex: 'correction',
      width: 150,
      render: (text, record, index) => {
        return <Input type={"number"}
                      placeholder={"Сумма коррекицц, руб"}
                      defaultValue={record.correction}
                      onChange={(e) => { updateParticipant(record.id, {correction: e.target.value}) }} />;
      },
    },
    {
      title: '',
      dataIndex: 'id',
      render: (text, record, index) => {
        return "";
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(() => {
    if(visible) {
      apiClient.getCompetitionDriversList()
        .then(res => {
          setAllDrivers(res.records);
        })
        .catch(err => {
          message.error(err);
        });
    }
  }, [visible]);

  useEffect(() => {
    if(record) {
      setCompetition(record);
      apiClient.getCompetitionParticipants(record.id)
        .then(res => {
          setDrivers(res.records);
        })
        .catch(err => {
          message.error(err);
        });
    } else {
      setCompetition(null);
    }
  }, [record, driversUpdate]);

  const removeParticipant = record => {
    apiClient.removeCompetitionParticipant(competition.id, record.id)
      .then(res => {
        setDriversUpdate(prevState => prevState + 1);
      })
      .catch(err => {
        message.error(err);
      });
  };

  const updateParticipant = (recordId, props) => {
    apiClient.updateCompetitionParticipant(competition.id, recordId, props)
      .then(res => {
        setDriversUpdate(prevState => prevState + 1);
      })
      .catch(err => {
        message.error(err);
      });
  };

  const addParticipant = selectedDriverId => {
    apiClient.addCompetitionParticipant(competition.id, selectedDriverId)
      .then(res => {
        setDriversUpdate(prevState => prevState + 1);
      })
      .catch(err => {
        message.error(err);
      });
  };

  const startCompetitionUpdate = () => {
    apiClient.recalculateCompetition(competition.id)
      .then(res => {
        message.success("Результаты соревнования обновлены");
      })
      .catch(err => {
        message.error(err);
      });
  };

  function render() {
    return (
      <>
        <Row style={{width: '100%', textAlign: "right", marginBottom: 10}}>
          <Col offset={21}>
            <Button type="primary" icon={<ReloadOutlined/>} onClick={() => { startCompetitionUpdate() }}>Пересчитать</Button>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={3}>Адрес страницы</Col>
          <Col span={18}>
            <Input readOnly value={`${process.env.REACT_APP_WEB_HOST}/competitions/${competition.uid}`} />
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={3}>Даты</Col>
          <Col span={18}>
            <RangePicker showTime
                         style={{ width: 400 }}
                         value={competition ? [moment(competition.from_dt), moment(competition.to_dt)] : []}
                         onChange={(dates, dateStrings) => { setCompetition({...competition, from_dt: dateStrings[0], to_dt: dateStrings[1]}); }}
            />
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={3}>Добавить участника</Col>
          <Col span={18}>
            <Space direction={"horizontal"}>
              <Select
                showSearch
                allowClear
                style={{ width: 400 }}
                placeholder="Водитель"
                value={selectedDriverId}
                onChange={(value) => {
                  if(!value) {
                    setSelectedDriverId(null);
                  } else {
                    setSelectedDriverId(value);
                  }
                }}
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {allDrivers.map(s => <Option key={s.id} value={s.id}>{`${s.name}, ${s.phone}`}</Option>)}
              </Select>
              <Button type={"link"}
                      disabled={!selectedDriverId}
                      onClick={() => { addParticipant(selectedDriverId) }}
              >Добавить</Button>
            </Space>
          </Col>
        </Row>

        <div style={{marginTop: 10}}>
          <Table pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
                 columns={cols}
                 scroll={{x: 1000}}
                 sticky
                 dataSource={drivers}
                 rowKey="id"/>
        </div>
      </>
    );
  }

  return (
    <Modal
      title={"Конкурс"}
      destroyOnClose={true}
      visible={visible}
      className="size-for-max-window"
      style={{ top: 20 }}
      onOk={() => { onOk(competition); }}
      onCancel={() => { onClose(); }}>
      {
        competition ? render() : <EmptyPage/>
      }
    </Modal>
  );

}