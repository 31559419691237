import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../services/ApiClient";
import {
  Button,
  Dropdown,
  Input,
  Menu,
  message,
  PageHeader,
  Select,
  Space,
  Table,
  Modal
} from "antd";
import {DownOutlined, EditFilled, PlusOutlined} from "@ant-design/icons";
import moment from "moment";
import ServiceDiagnosticStatusEdit from "./ServiceDiagnosticStatusEdit";
import {windowService} from "../../../../services/WindowService";
import LinkButton from "../../../buttons/LinkButton";

const { Search, TextArea } = Input;
const { Option } = Select;

export default function ServiceDiagnosticStatusPage() {
  let [update, setUpdate] = useState(0);
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [selected, setSelected] = useState(null);
  let [search, setSearch] = useState(null);
  let [selectedRecords, setSelectedRecords] = useState([]);
  let [owners, setOwners] = useState([]);
  let [selectedOwnerId, setSelectedOwnerId] = useState([]);
  let [commentedRecord, setCommentedRecord] = useState(null);
  let [selectedRecordsOperation, setSelectedRecordsOperation] = useState(null);
  let [regions, setRegions] = useState([]);
  let [selectedRegionId, setSelectedRegionId] = useState([]);
  let [vehicleGroups, setVehicleGroups] = useState([]);
  let [selectedGroupId, setSelectedGroupId] = useState(null);

  useEffect(() => {
    setLoading(true);
    apiClient.getServiceDiagnosticStatusList({
      regNumber: search,
      regionId: selectedRegionId,
      ownerId: selectedOwnerId,
      groupId: selectedGroupId
    })
      .then(res => {
        setRecords(res.records);
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [update, search, selectedRegionId, selectedOwnerId, selectedGroupId]);

  useEffect(() => {
    apiClient.getRegions({"order": "title"})
      .then(res => { setRegions(res.records) })
      .catch(err => { console.error(err) });
    apiClient.getVehicleOwners()
      .then(res => { setOwners(res.records) })
      .catch(err => { console.error(err) });
    apiClient.getVehicleGroups({"order": "title"})
      .then(res => { setVehicleGroups(res.records) })
      .catch(err => { console.error(err) });
  }, []);

  const executeSelectedRecordsOperation = operationKey => {
    setSelectedRecordsOperation({"key": operationKey});
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="ДК"
        extra={
          <div style={{textAlign: "right"}}>
            <Space>
              <span>{`Количество авто: ${records.length}`}</span>
              {
                selectedRecords.length > 0 && false
                  ? (
                    <>
                      <Dropdown
                        disabled={selectedRecords.length < 1}
                        overlay={(
                          <Menu onClick={(e) => { executeSelectedRecordsOperation(e.key); }}>

                          </Menu>
                        )}>
                        <Button style={{textAlign: "left"}}>
                          С выбранными <DownOutlined/>
                        </Button>
                      </Dropdown>
                    </>
                  ) : null
              }
            </Space>
          </div>
        }
      />
      <Space style={{margin: '10px 0'}}>
        <Search
          enterButton
          placeholder={"Поиск по гос.номеру"}
          onSearch={(value) => {
            setSearch(value)
          }}
          allowClear={true}
          style={{width: 400}}
        />
        <Select
          showSearch
          allowClear
          mode="multiple"
          placeholder="Регион"
          value={selectedRegionId}
          style={{width: 300}}
          onChange={(data) => { setSelectedRegionId(data); }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {
            regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
        <Select
          showSearch
          allowClear
          placeholder="Арендодатель"
          value={selectedOwnerId}
          style={{width: 300}}
          onChange={(data) => { setSelectedOwnerId(data); }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {
            owners.map(el => <Option key={el.id} value={el.id}>{`${el.fio || el.full_title || el.short_title}`}</Option>)
          }
        </Select>
        <Select
          allowClear
          placeholder="Группа"
          value={selectedGroupId}
          style={{width: 300}}
          onChange={(data) => { setSelectedGroupId(data); }}
        >
          {
            vehicleGroups.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
      </Space>
      <Table
        loading={loading}
        scroll={{x: 1400}}
        sticky={true}
        size={'small'}
        columns={[
          {
            title: "",
            align: "center",
            width: 20,
          },
          Table.SELECTION_COLUMN,
          {
            title: '',
            width: 50,
            render: (text, record, index) => {
              return (
                <Space direction={"horizontal"}>
                  <Button
                    type="link"
                    icon={<EditFilled/>}
                    disabled={!record?.id}
                    onClick={() => {
                      setSelected(record);
                    }}
                  />
                </Space>
              )
            }
          },
          {
            title: 'Номер ТС',
            align: "center",
            render: (text, record, index) => {
              if(!record.car) {
                return "";
              }
              return (
                <Button
                  type={"link"}
                  onClick={() => {
                    windowService.openRouteWindow(`cp/park/vehicles/${record.car.id}`);
                  }}
                >
                  {
                    <>
                      <div>
                        {record.car.reg_number}
                      </div>
                    </>
                  }
                </Button>
              );
            }
          },
          {
            title: 'Марка/Модель',
            align: "center",
            render: (text, record, index) => {
              return `${record.car?.brand} ${record.car?.model}`;
            }
          },
          {
            title: 'Арендодатель',
            align: 'center',
            render: (text, record, index) => {
              return record.owner
                ? (
                  <LinkButton
                    onClick={() => {
                      windowService.openRouteWindow(`cp/park/owners/${record.owner.id}`);
                    }}
                    label={record.owner?.title}
                  />
                ) : "";
            },
            sorter: (a, b) => {
              let aVal = a.owner?.title || "";
              let bVal = b.owner?.title || "";
              return aVal.localeCompare(bVal);
            },
          },
          {
            title: 'Номер',
            align: "center",
            render: (text, record, index) => {
              return record.reg_number;
            },
            sorter: (a, b) => {
              let aVal = a.reg_number || "";
              let bVal = b.reg_number || "";
              return aVal.localeCompare(bVal);
            },
          },
          {
            title: 'Дата выдачи',
            align: "center",
            dataIndex: 'issue_dt',
            render: (text, record, index) => {
              return record.issue_dt ? moment(record.issue_dt).format('DD.MM.YYYY'): "";
            },
            sorter: (a, b) => {
              let aVal = a.issue_dt ? moment(a.issue_dt).valueOf() : -1;
              let bVal = b.issue_dt ? moment(b.issue_dt).valueOf() : -1;
              return aVal - bVal;
            },
          },
          {
            title: 'Срок действия',
            align: "center",
            dataIndex: 'due_dt',
            render: (text, record, index) => {
              return record.due_dt ? moment(record.due_dt).format('DD.MM.YYYY'): "";
            },
            sorter: (a, b) => {
              let aVal = a.due_dt ? moment(a.due_dt).valueOf() : -1;
              let bVal = b.due_dt ? moment(b.due_dt).valueOf() : -1;
              return aVal - bVal;
            },
          },
          {
            title: 'Город',
            align: "center",
            render: (text, record, index) => {
              return record.region?.title;
            }
          },
          {
            title: 'Количество дней до окончания действия диагностической карты',
            align: "center",
            render: (text, record, index) => {
              return record.due_dt ?
                moment(record.due_dt).endOf('day').diff(moment(), 'days') + 1
                : "";
            },
            sorter: (a, b) => {
              let aVal = a.due_dt ? moment(a.due_dt).diff(moment(), 'days') : -1;
              let bVal = b.due_dt ? moment(b.due_dt).diff(moment(), 'days') : -1;
              return aVal - bVal;
            },
          },
          {
            title: 'Комментарий',
            dataIndex: 'comment',
            render: (text, record, index) => {
              const comment = (record.comment || "").trim();
              return comment.length > 0
                ? (
                  <div
                    onDoubleClick={() => {
                      record.__comment = record.comment;
                      setCommentedRecord(record);
                    }}
                  >{record.comment || ""}</div>
                ) : (
                  <div style={{textAlign: 'center'}}>
                    <Button type={"link"} icon={<PlusOutlined/>} onClick={() => {
                      record.__comment = record.comment;
                      setCommentedRecord(record);
                    }}/>
                  </div>
                )
            }
          },
          {
            title: "",
            align: "center",
            width: 10,
          }
        ]}
        dataSource={records}
        rowKey="id"
        pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75], hideOnSinglePage: true}}
        rowSelection={{
          columnWidth: '50px',
          type: 'checkbox',
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedRecords,
          onChange: (selectedRowKeys) => {
            setSelectedRecords(selectedRowKeys);
          }
        }}
      />
      {
        selected !== null
          ? (
            <ServiceDiagnosticStatusEdit
              visible={true}
              title={"ДК"}
              item={selected}
              onOk={(record) => {
                apiClient.updateServiceDiagnosticStatus(record.id, record)
                  .then(res => {
                    setSelected(null);
                    setUpdate(prev => prev + 1)
                  })
                  .catch(err => { message.error(err); })
              }}
              onCancel={() => { setSelected(null); }}
            />
          ) : null
      }
      {
        commentedRecord !== null && (
          <Modal
            title="Комментарий"
            visible={true}
            onOk={() => {
              apiClient.updateServiceDiagnosticStatus(commentedRecord.id, {comment: commentedRecord.comment})
                .then(res => {
                  delete commentedRecord.__comment;
                  setCommentedRecord(null);
                })
                .catch(err => {
                  message.error(err);
                });
            }}
            onCancel={() => {
              commentedRecord.comment = commentedRecord.__comment;
              delete commentedRecord.__comment;
              setCommentedRecord(null);
            }}
          >
            <TextArea
              allowClear
              style={{width: '100%'}}
              rows={3}
              onChange={(e) => {
                commentedRecord.comment = e.target.value;
              }}
              defaultValue={commentedRecord.comment}
            />
          </Modal>
        )
      }
    </>
  )

}