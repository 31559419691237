// ControlPanelArea
import React, {useEffect, useState} from "react";
import {isMobile} from 'react-device-detect';
import {Link, Redirect, Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import {Layout, Menu, message, Modal, Badge, Button, Image, Divider} from 'antd';
import './ControlPanelArea.css'
import {
  BellOutlined,
  TeamOutlined,
  LoadingOutlined,
  LogoutOutlined,
  RetweetOutlined,
  BookOutlined,
  FileTextOutlined,
  PlusCircleOutlined,
  WarningOutlined,
  DeleteOutlined,
  BarChartOutlined,
  MenuFoldOutlined,
  MinusCircleOutlined,
  CompassOutlined,
  SettingOutlined,
  PictureOutlined,
  MoneyCollectOutlined,
  CarOutlined, FileExcelOutlined,
  RightOutlined, LeftOutlined
} from "@ant-design/icons";
import PrivateRoute from "../common/PrivateRoute";
import DriversPage from "./drivermanager/DriversPage";
import MonitoringPage from "./monitoring/MonitoringPage";
import CarReturnRequestsPage from "./carreturn/CarReturnRequestsPage";
import ProfilePage from "./profile/ProfilePage";
import PrivateProtectedRoute from "../common/PrivateProtectedRoute";
import WayBillPage from "./waybill/WayBillPage";
import WayBillArchivePage from "./waybill/WayBillArchivePage";
import WaybillSettingsPage from "./waybill/WaybillSettingsPage";
import GSpreadsheetEmbedPage from "./reports/GSpreadsheetEmbedPage";
import ReportsMenuEditDialog from "./common/ReportsMenuEditDialog";
import FinesPage from "./fines/FinesPage";
import SettingsPage from "./settings/SettingsPage";
import DriversPhotoControlPage from "./photocontrol/DriversPhotoControlPage";
import DriverTransactionsPage from "./drivermanager/DriverTransactionsPage";
import DriverPaymentsPage from "./drivermanager/DriverPaymentsPage";
import CompetitionsPage from "./competitions/CompetitionsPage";
import TeamCompetitionsPage from "./competitions/TeamCompetitionsPage";
import TeamsPage from "./competitions/TeamsPage";
import DriverDetailsLoader from "./drivermanager/components/driverdetails/DriverDetailsLoader";
import VehicleOwnersPage from "./park/VehicleOwnersPage";
import VehiclesPage from "./park/VehiclesPage";
import DriverCandidatesPage from "./drivermanager/DriverCandidatesPage";
import UserProfileContext from '../../services/UserProfileContext'

import {AppProps} from "../common/AppEnums";
import {apiClient} from "../../services/ApiClient";
import ContractsPage from "./drivermanager/ContractsPage";
import imageLogoName from "../auth/sfera-logo.png";
import VehiclesTO from "./park/VehiclesTO";
import VehiclesOSAGO from "./park/VehiclesOSAGO";
import VehiclesDK from "./park/VehiclesDK";
import RentPaymentsByVehiclesPage from "./reports/rent/RentPaymentsByVehiclesPage";
import RentPaymentsByDriverPage from "./reports/rent/RentPaymentsByDriverPage";
import PartnersAndServices from "./park/PartnersAndServices";
import OwnerDetailsLoader from "./park/owner/OwnerDetailsLoader";
import CkadDebtPage from "./ckad/CkadDebtPage";
import VehicleDetailsLoader from "./park/vehicle/VehicleDetailsLoader";
import ServiceMaintenanceStatusPage from "./reports/service/ServiceMaintenanceStatusPage";
import ServiceOsagoStatusPage from "./reports/service/ServiceOsagoStatusPage";
import ServiceDiagnosticStatusPage from "./reports/diagnostic/ServiceDiagnosticStatusPage";
import ServiceKaskoStatusPage from "./reports/service/ServiceKaskoStatusPage";
import ServiceSummaryTypeStatusPage from "./reports/service/ServiceSummaryTypeStatusPage";
import ServiceSummaryModelStatusPage from "./reports/service/ServiceSummaryModelStatusPage";
import DashboardPage from "./DashboardPage";
import DptPage from "./dtp/DtpPage";
import DriverDebitsPage from "./drivermanager/DriverDebitsPage";
import NotificationsDialog from "./notifications/NotificationsDialog";
import InvestorReportPage from "./reports/investor/InvestorReportPage";

const {Header, Sider, Content, Footer} = Layout;
const { SubMenu } = Menu;

export default function ControlPanelArea(props) {
  let [collapsed, setCollapsed] = useState(false);
  let [userProfile, setUserProfile] = useState({});
  let [systemItems, setSystemItems] = useState([]);
  let [reportsItems, setReportsItems] = useState([]);
  let [headerItems, setHeaderItems] = useState([]);
  let [editedReportsMenu, setEditedReportsMenu] = useState(null);
  let [enableMenuEdit, setEnableMenuEdit] = useState(false);
  let [photoControlCount, setPhotoControlCount] = useState(0);
  let [zoneRequestsCount, setZoneRequestsCount] = useState(0);
  let [unresolvedRoadAccidentRequestsCount, setUnresolvedRoadAccidentRequestsCount] = useState(0);
  let [totalDebt, setTotalDebt] = useState(0);
  let [driversCount, setDriversCount] = useState(0);
  let [imageName, setImageName] = useState(imageLogoName);
  let [update, setUpdate] = useState(0);
  let [notification, setNtification] = useState(true);
  let [notificationStatus, setNtificationStatus] = useState(false);
  const workStatus = 'working';

  const history = useHistory();

  let {path, url} = useRouteMatch();

  // const layoutWidth = isMobile ? "1600px" : "90%";

  const onMenuClick = (item, key, keyPath, domEvent) => {
    if (item.key === '100') {
      apiClient.logout()
        .catch(err => { console.error(err); })
        .finally(() => {
          setUserProfile({});
          return history.push('/');
        });
    } else if(item.key === "add_root") {
      setEditedReportsMenu({id: 0})
    } else if(item.key.indexOf("add_") === 0) {
      let parts = item.key.split("_");
      setEditedReportsMenu({
        id: 0,
        parentId: parseInt(parts[1])
      })
    } else if(item.key.indexOf("remove_") === 0) {
      let parts = item.key.split("_");
      onReportMenuDelete(parseInt(parts[1]));
    } else if(item.key.indexOf("collapsed") === 0) {
      setCollapsed(!collapsed);
    }
  };

  const onReportMenuUpdate = (item) => {
    setEditedReportsMenu(item);
  };

  const onReportMenuDelete = (id) => {
    Modal.confirm({
      title:  "Удаление",
      content: "Вы уверены, что хотите удалить меню?",
      icon: <DeleteOutlined/>,
      visible: true,
      onOk() {
        apiClient.removeMenuItem("reports", id)
          .then(res => {
            apiClient.getMenuItems("reports")
              .then(res => {
                setReportsItems(res.records);
              })
              .catch(err => {
                message.error("Не удалось загрузить список отчетов");
              });
          })
          .catch(err => message.error(err));
      },
    });
  };

  const onReportMenuEdited = item => {
    if(item.id > 0) {
      apiClient.updateMenuItem("reports", item)
        .then(res => {
          apiClient.getMenuItems("reports")
            .then(res => {
              setReportsItems(res.records);
            })
            .catch(err => {
              message.error("Не удалось загрузить список отчетов");
            });
        })
        .catch(err => {
          message.error(err);
        });    } else {
      apiClient.createMenuItem("reports", item)
        .then(res => {
          apiClient.getMenuItems("reports")
            .then(res => {
              setReportsItems(res.records);
            })
            .catch(err => {
              message.error("Не удалось загрузить список отчетов");
            });
        })
        .catch(err => {
          message.error(err);
        });
    }
    setEditedReportsMenu(null);
  };

  const headerMenu = menu => {
    if(menu.sys_code === "settings") {
      return (
        <Menu.Item className="header-background" style={{padding: '8px'}} key={menu.id}>
          <Link to={`${url}/settings`}><SettingOutlined style={{ fontSize: 20 }}/></Link>
        </Menu.Item>
      )
    } else {
      return null;
    }
  };

  const systemMenu = menu => {
    if(menu.sys_code === "drivers") {
      return (
        <>
          {/* <Menu.Item className="menu-background" key={`${menu.id}`}>
            <Link to={`${url}/dashboards`}>Дашборды</Link>
          </Menu.Item> */}
          <SubMenu key={`sub${menu.id}`} icon={<TeamOutlined/>} title={menu.title}>
            <Menu.Item className="menu-background" key={`${menu.id}_1`}>
              <Link to={`${url}/index`}>Водители</Link>
            </Menu.Item>
            <Menu.Item className="menu-background" key={`${menu.id}_2`}>
              <Link to={`${url}/drivers/candidates`}>Кандидаты</Link>
            </Menu.Item>
            <Menu.Item className="menu-background" key={`${menu.id}_3`}>
              <Link to={`${url}/drivers/rent-contracts`}>Договоры аренды</Link>
            </Menu.Item>
          </SubMenu>
        </>
      )
    } else if(menu.sys_code === "driver-transactions") {
      return (
        <>
          <SubMenu key={`sub${menu.id}`} icon={<MoneyCollectOutlined/>} title={menu.title}>
            <Menu.Item className="menu-background" key={`${menu.id}_1`}>
              <Link to={`${url}/driver-transactions`}>Транзакции</Link>
            </Menu.Item>
            <Menu.Item className="menu-background" key={`${menu.id}_2`}>
              <Link to={`${url}/driver-payments`}>Платежи</Link>
            </Menu.Item>
            <Menu.Item className="menu-background" key={`${menu.id}_3`}>
              <Link to={`${url}/driver-debits`}>Списания</Link>
            </Menu.Item>
          </SubMenu>
        </>
      )
    } else if(menu.sys_code === "monitoring") {
      return (
        <Menu.Item className="menu-background" key={menu.id} icon={<BarChartOutlined />}>
          <Link to={`${url}/cars`}>
            <span>{menu.title}</span>
            &nbsp;
            <Badge style={{ backgroundColor: 'rgba(85, 88, 94, 0.05)' }} count={zoneRequestsCount}/>
          </Link>
        </Menu.Item>
      )
    } else if(menu.sys_code === "carreturn") {
      return (
        <Menu.Item className="menu-background" key={menu.id} icon={<RetweetOutlined/>}>
          <Link to={`${url}/vozvrat`}>{menu.title}</Link>
        </Menu.Item>
      )
    } else if(menu.sys_code === "fines") {
      return (
        <SubMenu key={`sub${menu.id}`} icon={<WarningOutlined />} title={menu.title}>
          <Menu.Item className="menu-background" key={`${menu.id}_1`}>
            <Link to={`${url}/fines`}>Штрафы ГИБДД</Link>
          </Menu.Item>
          <Menu.Item className="menu-background" key={`${menu.id}_2`}>
            <Link to={`${url}/ckad`}>Задолженности ЦКАД</Link>
          </Menu.Item>
        </SubMenu>
      )
    } else if(menu.sys_code === "competitions") {
      return (
        <SubMenu key={`sub${menu.id}`} icon={<CompassOutlined />} title={menu.title}>
          <Menu.Item className="menu-background" key={`${menu.id}_1`}>
            <Link to={`${url}/competitions/personal`}>Персональные</Link>
          </Menu.Item>
          <Menu.Item className="menu-background" key={`${menu.id}_2`}>
            <Link to={`${url}/competitions/team`}>Командные</Link>
          </Menu.Item>
          <Menu.Item className="menu-background" key={`${menu.id}_3`}>
            <Link to={`${url}/competitions/teams`}>Команды</Link>
          </Menu.Item>
        </SubMenu>
      )
    } else if(menu.sys_code === "photocontrol") {
      return (
        <Menu.Item className="menu-background" key={menu.id} icon={<PictureOutlined />}>
          <Link to={`${url}/photocontrol/drivers`}>
            <span>{menu.title}</span>
            &nbsp;
            <Badge style={{ backgroundColor: 'rgba(85, 88, 94, 0.05)' }} count={photoControlCount}/>
          </Link>
        </Menu.Item>
      )
    } else if(menu.sys_code === "waybill") {
      return (
        <SubMenu key={`sub${menu.id}`} icon={<FileTextOutlined />} title="Путевые листы">
          <Menu.Item className="menu-background" key={`sub${menu.id}_1`}>
            <Link to={`${url}/waybill`}>Создать</Link>
          </Menu.Item>
          <Menu.Item className="menu-background" key={`sub${menu.id}_2`}>
            <Link to={`${url}/waybill/archive`}>Архив</Link>
          </Menu.Item>
          <Menu.Item className="menu-background" key={`sub${menu.id}_3`}>
            <Link to={`${url}/waybill/settings`}>Настройки</Link>
          </Menu.Item>
        </SubMenu>
      )
    } else if(menu.sys_code === "park") {
      return (
        <SubMenu key={`sub${menu.id}`} icon={<CarOutlined />} title="Транспортные средства">
          <Menu.Item className="menu-background" key={`sub${menu.id}_1`}>
            <Link to={`${url}/park/owners`}>Собственники ТС</Link>
          </Menu.Item>
          <Menu.Item className="menu-background" key={`sub${menu.id}_2`}>
            <Link to={`${url}/park/vehicles`}>ТС</Link>
          </Menu.Item>
          <Menu.Item className="menu-background" key={`${menu.id}_9`}>
            <Link to={`${url}/dtp`}>
              <span>ДТП</span>
              &nbsp;
              <Badge style={{ backgroundColor: 'rgba(85, 88, 94, 0.05)' }} count={unresolvedRoadAccidentRequestsCount}/>
            </Link>
          </Menu.Item>
          <Menu.Item className="menu-background" key={`sub${menu.id}_3`}>
            <Link to={`${url}/reports/service/maintenance`}>Статус ТО</Link>
          </Menu.Item>
          <Menu.Item className="menu-background" key={`sub${menu.id}_4`}>
            <Link to={`${url}/reports/service/osago`}>ОСАГО</Link>
          </Menu.Item>
          <Menu.Item className="menu-background" key={`sub${menu.id}_5`}>
            <Link to={`${url}/reports/service/kasko`}>КАСКО</Link>
          </Menu.Item>
          <Menu.Item className="menu-background" key={`sub${menu.id}_6`}>
            <Link to={`${url}/reports/service/diagnostic`}>ДК</Link>
          </Menu.Item>
          <Menu.Item className="menu-background" key={`sub${menu.id}_7`}>
            <Link to={`${url}/reports/service/summary/type-status`}>Статусы по типу ТС</Link>
          </Menu.Item>
          <Menu.Item className="menu-background" key={`sub${menu.id}_8`}>
            <Link to={`${url}/reports/service/summary/model-status`}>Статусы по моделям ТС</Link>
          </Menu.Item>
        </SubMenu>
      )
    } else if(menu.sys_code === "reports") {
      return (
        <SubMenu key={`sub${menu.id}`} icon={<FileExcelOutlined />} title="Отчеты">
          <Menu.Item className="menu-background" key={`sub${menu.id}_1`}>
            <Link to={`${url}/reports/rent-payments/by-vehicle`}>Аренда по ТС</Link>
          </Menu.Item>
          <Menu.Item className="menu-background" key={`sub${menu.id}_2`}>
            <Link to={`${url}/reports/rent-payments/by-driver`}>Аренда по водителям</Link>
          </Menu.Item>
          <Menu.Item className="menu-background" key={`sub${menu.id}_3`}>
            <Link to={`${url}/reports/investor`}>Отчет инвестору</Link>
          </Menu.Item>
        </SubMenu>
      )
    } else {
      return null;
    }
  };

  const systemRoute = menu => {
    if(menu.sys_code === "drivers") {
      return ([
        // <PrivateRoute path={`${path}/dashboards`} key={menu.id}>
        //   <DashboardPage/>
        // </PrivateRoute>,
        <PrivateRoute path={`${path}/index`} key={menu.id}>
          <DriversPage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/drivers/candidates`} key={`${menu.id}_2`}>
          <DriverCandidatesPage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/drivers/rent-contracts`} key={`${menu.id}_3`}>
          <ContractsPage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/drivers/:uid`} key={`${menu.id}_4`}>
          <DriverDetailsLoader/>
        </PrivateRoute>,
      ])
    } else if(menu.sys_code === "driver-transactions") {
      return ([
        <PrivateRoute path={`${path}/driver-transactions`} key={`${menu.id}_1`}>
          <DriverTransactionsPage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/driver-payments`} key={`${menu.id}_2`}>
          <DriverPaymentsPage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/driver-debits`} key={`${menu.id}_3`}>
          <DriverDebitsPage/>
        </PrivateRoute>
      ])
    } else if(menu.sys_code === "monitoring") {
      return (
        <PrivateRoute path={`${path}/cars`} key={menu.id}>
          <MonitoringPage/>
        </PrivateRoute>
      )
    } else if(menu.sys_code === "carreturn") {
      return (
        <PrivateRoute path={`${path}/vozvrat`} key={menu.id}>
          <CarReturnRequestsPage/>
        </PrivateRoute>
      )
    } else if(menu.sys_code === "fines") {
      return ([
        <PrivateRoute path={`${path}/fines`} key={`${menu.id}_1`}>
          <FinesPage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/ckad`} key={`${menu.id}_2`}>
          <CkadDebtPage/>
        </PrivateRoute>
      ])
    } else if(menu.sys_code === "competitions") {
      return ([
        <PrivateRoute path={`${path}/competitions/personal`} key={`${menu.id}_1`}>
          <CompetitionsPage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/competitions/team`} key={`${menu.id}_2`}>
          <TeamCompetitionsPage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/competitions/teams`} key={`${menu.id}_3`}>
          <TeamsPage/>
        </PrivateRoute>
      ])
    } else if(menu.sys_code === "photocontrol") {
      return (
        <PrivateRoute path={`${path}/photocontrol/drivers`} key={menu.id}>
          <DriversPhotoControlPage/>
        </PrivateRoute>
      )
    } else if(menu.sys_code === "waybill") {
      return ([
        <PrivateRoute path={`${path}/waybill/archive`} key={`${menu.id}_1`}>
          <WayBillArchivePage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/waybill/settings`} key={`${menu.id}_2`}>
          <WaybillSettingsPage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/waybill`} key={`${menu.id}_3`}>
          <WayBillPage/>
        </PrivateRoute>,
      ].map(e => e))
    } else if(menu.sys_code === "park") {
      return ([
        <PrivateRoute path={`${path}/park/owners/:uid`} key={`${menu.id}_1`}>
          <OwnerDetailsLoader/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/park/owners`} key={`${menu.id}_2`}>
          <VehicleOwnersPage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/park/vehicles/:uid`} key={`${menu.id}_3`}>
          <VehicleDetailsLoader/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/park/vehicles`} key={`${menu.id}_4`}>
          <VehiclesPage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/dtp`} key={menu.id}>
          <DptPage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/park/vehiclesTO`} key={`${menu.id}_5`}>
          <VehiclesTO/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/park/vehiclesOSAGO`} key={`${menu.id}_6`}>
          <VehiclesOSAGO />
        </PrivateRoute>,
        <PrivateRoute path={`${path}/park/vehiclesDK`} key={`${menu.id}_7`}>
          <VehiclesDK/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/park/partnersAndServices`} key={`${menu.id}_8`}>
          <PartnersAndServices/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/reports/service/maintenance`} key={`${menu.id}_9`}>
          <ServiceMaintenanceStatusPage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/reports/service/osago`} key={`${menu.id}_10`}>
          <ServiceOsagoStatusPage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/reports/service/kasko`} key={`${menu.id}_11`}>
          <ServiceKaskoStatusPage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/reports/service/diagnostic`} key={`${menu.id}_12`}>
          <ServiceDiagnosticStatusPage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/reports/service/summary/type-status`} key={`${menu.id}_13`}>
          <ServiceSummaryTypeStatusPage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/reports/service/summary/model-status`} key={`${menu.id}_14`}>
          <ServiceSummaryModelStatusPage/>
        </PrivateRoute>,
      ].map(e => e))
    } else if(menu.sys_code === "reports") {
      return ([
        <PrivateRoute path={`${path}/reports/rent-payments/by-vehicle`} key={`${menu.id}_1`}>
          <RentPaymentsByVehiclesPage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/reports/rent-payments/by-driver`} key={`${menu.id}_2`}>
          <RentPaymentsByDriverPage/>
        </PrivateRoute>,
        <PrivateRoute path={`${path}/reports/investor`} key={`${menu.id}_3`}>
          <InvestorReportPage/>
        </PrivateRoute>,
      ].map(e => e))
    } else {
      return null;
    }
  };

  const defaultRoute = () => {
    if(!userProfile || !systemItems || systemItems.length < 1) {
      return null;
    }

    let firstRoute = systemItems[0];

    let redirect = <Redirect to={`${path}/index`}/>;
    if(firstRoute.sys_code === "drivers") {
      redirect = <Redirect to={`${path}/index`}/>;
    } else if(firstRoute.sys_code === "monitoring") {
      redirect = <Redirect to={`${path}/cars`}/>;
    } else if(firstRoute.sys_code === "carreturn") {
      redirect = <Redirect to={`${path}/vozvrat`}/>;
    } else if(firstRoute.sys_code === "fines") {
      redirect = <Redirect to={`${path}/fines`}/>;
    } else if(firstRoute.sys_code === "photocontrol") {
      redirect = <Redirect to={`${path}/photocontrol/drivers`}/>;
    } else if(firstRoute.sys_code === "waybill") {
      redirect = <Redirect to={`${path}/waybill`}/>;
    }
    // else if(firstRoute.sys_code === "dashboards") {
    //   redirect = <Redirect to={`${path}/dashboards`}/>;
    // }
    else if(firstRoute.sys_code === "dtp") {
      redirect = <Redirect to={`${path}/dtp`}/>;
    }
    return (
      <Route path={`${path}`}>
        {redirect}
      </Route>
    )
  };

  useEffect(() => {
    apiClient.getCurrentUserProfile()
      .then(res => {
        setUserProfile(res.data);
      })
      .catch(err => {
        message.error("Не удалось загрузить профиль пользователя");
      });

    // initialize menu
    apiClient.getMenuItems("system")
      .then(res => {
        setSystemItems(res.records);
      })
      .catch(err => {
        message.error("Не удалось загрузить основное меню");
      });
    apiClient.getMenuItems("header")
      .then(res => {
        setHeaderItems(res.records);
      })
      .catch(err => {
        message.error("Не удалось загрузить меню заголовка");
      });
    apiClient.getMenuItems("reports")
      .then(res => {
        setReportsItems(res.records);
      })
      .catch(err => {
        message.error("Не удалось загрузить список отчетов");
      });
  }, []);

  useEffect(() => {
    if(localStorage.getItem(AppProps.EnableMenuEdit) !== null) {
      setEnableMenuEdit(localStorage.getItem(AppProps.EnableMenuEdit) === "true");
    }
  }, []);

  useEffect(() => {
    const subscription = apiClient.subscribeDriverPhotoControlRecordsCount({status: "check"}, (val) => { setPhotoControlCount(val) });
    return () => apiClient.unsubscribeDriverPhotoControlRecordsCount(subscription);
  }, []);

  useEffect(() => {
    const subscription = apiClient.subscribeZoneRequestsCount((res) => { setZoneRequestsCount(res.count) });
    return () => apiClient.unsubscribe(subscription);
  }, []);

  useEffect(() => {
    const subscription = apiClient.subscribeRoadAccidentStatistics((res) => { setUnresolvedRoadAccidentRequestsCount(res.unresolved_requests) });
    return () => apiClient.unsubscribe(subscription);
  }, []);

  useEffect(() => {
    apiClient.getDriversSummary({workStatus})
      .then((res) => {
        setTotalDebt(res.debt_details.total_debt);
        setDriversCount(res.drivers_count);
      })
      .catch((err => { message.error(`Ошибка загрузки данных.`) }));
  }, [update, workStatus]);

  const onImport = (info) => {
    if (info.file.type === 'image/svg' && info.file.status === 'done') {
      message.success(`Файл загружен`);
      setUpdate(prevState => prevState + 1);
      setImageName(info.file);
    } else if (info.file.status === 'error') {
      if(info.file.response.message) {
        Modal.error({
          title: 'Ошибка импорта',
          width: 800,
          content: (
            <>
              <table style={{width: '100%'}}>
                <thead>
                <tr>
                  <th>Строка</th>
                  <th>Ошибка</th>
                </tr>
                </thead>
                <tbody>
                {
                  info.file.response.details
                    ? info.file.response.details.map(row => {
                      return (
                        <tr>
                          <td>{row.row}</td>
                          <td>{row.message}</td>
                        </tr>
                      )
                    })
                    : (
                      <tr>
                        <td colSpan={2}>Нет данных</td>
                      </tr>
                    )
                }
                </tbody>
              </table>
            </>
          ),
          onOk() {},
        });
      } else if(info.file?.response?.detail) {
        message.error(info.file?.response?.detail);
      } else {
        message.error("Ошибка");
      }
      setUpdate(prevState => prevState + 1);
    }
  };

  return (
    <UserProfileContext.Provider value={userProfile}>
      <Layout className="header-background">
        <Layout className="header-background">
          <Sider width={210} collapsed={collapsed}>
            <Menu theme="dark" defaultSelectedKeys={['1']} onClick={onMenuClick} mode="inline">
              {
                collapsed ? <Menu.Item className="menu-background" key="collapsed-top" icon={<RightOutlined/>}/>
                  : <Menu.Item className="menu-background" key="collapsed-top" style={{paddingLeft: "45%"}} icon={<LeftOutlined/>}/>
              }
              {collapsed === false && (
                <Image src={imageName} preview={false} style={{width:"165px", marginLeft: 15, marginTop:50, marginBottom: 50}}/>
              )}
              {
                systemItems.map(menu => systemMenu(menu))
              }
              {
                reportsItems.map(item => {
                  return (
                    <SubMenu key={item.id * 1000} title={item.title} icon={<MenuFoldOutlined/>}>
                      {
                        item.children.map(child => {
                          return (
                            <Menu.Item className="menu-background" key={child.id * 1000} icon={<BookOutlined/>}>
                              <Link to={`${url}/reports/${child.id}`}>{child.title}</Link>
                            </Menu.Item>
                          );
                        })
                      }
                      {
                        enableMenuEdit ? (
                          <Menu.Item className="menu-background" key={`add_${item.id}`} icon={<PlusCircleOutlined/>}>
                            Добавить отчет
                          </Menu.Item>
                        ) : null
                      }
                      {
                        enableMenuEdit ? (
                          <Menu.Item className="menu-background" key={`remove_${item.id}`} icon={<MinusCircleOutlined/>}>
                            Удалить раздел
                          </Menu.Item>
                        ) : null
                      }
                    </SubMenu>
                  );
                })

              }
              <Menu.Divider/>
              {
                enableMenuEdit ? (
                  <Menu.Item className="menu-background" key="add_root" icon={<PlusCircleOutlined/>}>
                    Добавить раздел
                  </Menu.Item>
                ) : null
              }
              <Menu.Item className="menu-background" key="100" title="Выход" icon={<LogoutOutlined/>}>
                Выход
              </Menu.Item>
              {
                collapsed ? <Menu.Item className="menu-background" key="collapsed" icon={<RightOutlined/>}/>
                : <Menu.Item className="menu-background" key="collapsed" style={{paddingLeft: "45%"}} icon={<LeftOutlined/>}/>
              }
            </Menu>
          </Sider>
          <Layout className="site-layout" style={isMobile ? {width: "1600px"} : {}}>
            <Header style={isMobile ? {width: "1600px", margin: '8px'} : {margin: '8px'}}>
              <div direction="horizontal" align="start" style={{float: "left", width: "50%"}}>
                <span className={"summary-item"}>Всего должны: {totalDebt}</span>
                <span className={"summary-item"}>Всего водителей: {driversCount}</span>
              </div>
              <div direction="horizontal" align="start" style={{float: "right", width: "50%"}}>
                <Menu className="header-background" mode="horizontal" selectedKeys={[]} style={{display: 'flex', justifyContent: 'flex-end', marginRight: 16, border: '0px'}}>
                  <Menu.Item style={{padding: '8px'}} key="2">
                    <Button type="link" onClick={() => {setNtificationStatus(notification);}}><BellOutlined style={{ fontSize: 20 }}/></Button>
                  </Menu.Item>
                  {
                    headerItems.map(e => headerMenu(e))
                  }
                  <Menu.Item key="1" style={{padding: '8px'}}>
                    <Link to={`${url}/profile`}>
                      <Button to={`${url}/profile`} type="primary" size="large" shape="circle">
                        {userProfile ? "A" || userProfile.username : <LoadingOutlined/>}
                      </Button>
                    </Link>
                  </Menu.Item>
                </Menu>
              </div>
            </Header>
            <Divider style={{marginTop: '10px'}}/>
            <Content style={isMobile ? {margin: '8px', width: "1600px", marginRight: 0} : {margin: '8px', marginRight: 0}}>
              <ReportsMenuEditDialog visible={editedReportsMenu !== null}
                                     menuItem={editedReportsMenu}
                                     onOk={(item) => {
                                       onReportMenuEdited(item)
                                     }}
                                     onCancel={() => {
                                       setEditedReportsMenu(null)
                                     }}
                                     className="reports-modal-style"/>
              <div style={{padding: 24, paddingRight: 24, minHeight: 360}}>
                <Switch>
                  <PrivateRoute path={`${path}/profile`}>
                    <ProfilePage profile={userProfile}/>
                  </PrivateRoute>
                  <PrivateRoute path={`${path}/settings`}>
                    <SettingsPage onPictureChanges={(info) => {onImport(info)}} onPropsChanged={({enableMenuEdit}) => {
                      setEnableMenuEdit(enableMenuEdit)
                    }}/>
                  </PrivateRoute>
                  {
                    reportsItems.flatMap(item => item.children).map(item => {
                      return (
                        <PrivateProtectedRoute domain={item.resource_id} path={`${path}/reports/${item.id}`} key={item.id}>
                          <GSpreadsheetEmbedPage id={item.id}
                                                 title={item.title}
                                                 code={item.resource_id}
                                                 readOnlyUrl={item.props.readonly_url}
                                                 editableUrl={item.props.editable_url}
                                                 onDelete={onReportMenuDelete}
                                                 onUpdate={onReportMenuUpdate}/>
                        </PrivateProtectedRoute>
                      );
                    })
                  }
                  {
                    systemItems.map(menu => systemRoute(menu))
                  }
                  {
                    defaultRoute()
                  }
                </Switch>
              </div>
            </Content>
            <Footer style={isMobile ? {textAlign: 'right', fontSize: '0.8em', width: "1600px"} : {textAlign: 'right', fontSize: '0.8em', width: "100%"}}>Такси.CRM ©2020</Footer>
          </Layout>
        </Layout>
      </Layout>
      <NotificationsDialog
        visible={notificationStatus}
        record={notificationStatus}
        onOk={(application) => { setNtificationStatus(false);}}
        onCancel={(dtpStatus) => {setNtificationStatus(false);}}
      />
    </UserProfileContext.Provider>
  );
}