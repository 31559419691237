import React from "react";
import {Button, Modal, Space, Table} from "antd";
import {
  CheckCircleFilled,
  CheckCircleTwoTone,
  DeleteOutlined,
  ExclamationCircleFilled,
  MinusCircleTwoTone,
  QuestionCircleOutlined
} from "@ant-design/icons";
import moment from "moment";
import {windowService} from "../../../../services/WindowService";

export default function ExportConfirm({visible, selectedRows, onRowRemove, onExport, onCancel}) {
  return (
    <>
      <Modal
        destroyOnClose={true}
        title={"Подтверждение"}
        visible={visible}
        width={1100}
        okText="Экспорт"
        onOk={onExport}
        onCancel={onCancel}
      >
        <p style={{fontWeight: "bold"}}>
          Данные штрафы уже выгружались ранее, Вы можете выгрузить их повторно, или снять с них метку о выгрузке
        </p>
        <Table
          columns={[
            {
              title: "",
              align: "center",
              width: 20,
            },
            {
              title: "",
              align: "center",
              dataIndex: "id",
              width: 50,
              render: function (text, record, index) {
                return (
                  <Space direction={"horizontal"}>
                    <Button type="link" icon={<DeleteOutlined/>} onClick={() => {
                      onRowRemove(record);
                    }}/>
                  </Space>
                );
              }
            },
            {
              title: 'Выгружен',
              align: "center",
              dataIndex: 'is_bank_exported',
              width: 50,
              render: (text, record, index) => {
                if (record.is_bank_exported) {
                  return <CheckCircleFilled style={{color: '#58D3A5', fontSize: '20px'}}/>;
                }
                return <ExclamationCircleFilled style={{color: '#D35858', fontSize: '20px'}}/>;
              },
            },
            {
              title: 'Оплачен',
              align: "center",
              dataIndex: 'pay_status',
              width: 50,
              render: (text, record, index) => {
                if(record.pay_status === 1) {
                  return <CheckCircleFilled style={{color: '#58D3A5', fontSize: '20px'}}/>;
                }
                if(record.pay_status === 4) {
                  return <ExclamationCircleFilled style={{color: '#D35858', fontSize: '20px'}} />;
                }
                return <QuestionCircleOutlined />;
              },
            },
            {
              title: 'Списан',
              align: "center",
              dataIndex: 'is_payed',
              render: (text, record, index) => {
                return record.is_payed
                  ? <CheckCircleTwoTone twoToneColor="#52c41a" />
                  : <MinusCircleTwoTone twoToneColor="#c43500"/>;
              },
            },
            {
              title: 'Постановление (УИН)',
              dataIndex: 'uin',
            },
            {
              title: 'Водитель',
              dataIndex: 'id',
              width: 250,
              render: (text, record, index) => {
                return record.driver
                  ? (
                    <Button
                      type={"link"}
                      onClick={() => {
                        windowService.openRouteWindow(`cp/drivers/${record.driver.id}`);
                      }}
                    >
                      {
                        <>
                          <div>
                            {record.driver.last_name} {record.driver.first_name?.substr(0, 1)?.toUpperCase()}.{record.driver.middle_name?.substr(0, 1)?.toUpperCase()}
                          </div>
                          <div>{record.driver.phone}</div>
                        </>
                      }
                    </Button>
                  ) : "";
              },
            },
            {
              title: 'Авто',
              dataIndex: 'car_number',
              width: 150,
            },
            {
              title: 'Сумма',
              dataIndex: 'amount',
              width: 100,
              render: (text, record, index) => {
                if (record.is_discounted && record.discount_amount) {
                  const validTo = moment(record.discount_dt);
                  if (validTo && moment().isBefore(validTo)) {
                    return (
                      <div style={{display: 'flex'}}>
                        <div style={{display: 'inline-block', fontSize: '0.9em'}}>
                          <span style={{textDecoration: 'line-through'}}>{record.amount}р</span>
                          <br/>
                          <span style={{color: '#5cb85c!important'}}>{record.discount_amount}р</span>
                        </div>
                      </div>
                    );
                  }
                }
                return `${record.amount}р`;
              },
            },
          ]}
          size="small"
          scroll={{x: 1000}}
          sticky
          dataSource={selectedRows}
          rowKey="id"
        />
      </Modal>

    </>
  )
}