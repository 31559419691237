import React, {useEffect, useState} from "react";
import {Col, Modal, Row, message, Space, Button, Table, Select, Input} from "antd";
import {apiClient} from "../../../../services/ApiClient";
import {DeleteOutlined} from "@ant-design/icons";
import '../../ModalStyle.css';

const { Option } = Select;

const rowStyle = {padding: '2px'};

export default function TeamAddDialog({visible, onOk, onClose}) {

  let [title, setTitle] = useState("");
  let [drivers, setDrivers] = useState([]);
  let [allDrivers, setAllDrivers] = useState([]);

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    {
      title: "",
      width: 50,
      align: "center",
      dataIndex: "id",
      render: function(text, record, index) {
        return (
          <Space direction={"horizontal"}>
            <Button type="link" icon={<DeleteOutlined/>} onClick={() => { setDrivers(drivers.filter(el => el.id !== record.id)); }}/>
          </Space>
        );
      }
    },
    {
      title: 'Водитель',
      dataIndex: 'name',
      width: 300,
    },
    {
      title: 'Марка',
      dataIndex: 'car_model',
      width: 200,
    },
    {
      title: 'Номер',
      dataIndex: 'car_number',
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(() => {
    if(visible) {
      apiClient.getCompetitionDriversList()
        .then(res => {
          setAllDrivers(res.records);
        })
        .catch(err => {
          message.error(err);
        });
    }
  }, [visible]);

  function render() {
    return (
      <>
        <Row style={rowStyle}>
          <Col span={5}>Наименование команды</Col>
          <Col span={16}>
            <Input placeholder={"Наименование команды"} onChange={(e) => { setTitle(e.target.value) }} allowClear={true} style={{ width: 400 }}/>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={5}>Добавить участника</Col>
          <Col span={16}>
            <Space direction={"horizontal"}>
              <Select
                showSearch
                allowClear
                style={{ width: 400 }}
                placeholder="Водитель"
                onChange={(value) => {
                  if(value) {
                    let driver = allDrivers.filter(el => el.id === value)[0];
                    if(driver) {
                      setDrivers([...drivers, driver]);
                    }
                  }
                }}
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {allDrivers.map(s => <Option key={s.id} value={s.id}>{`${s.name}, ${s.phone}`}</Option>)}
              </Select>

            </Space>
          </Col>
        </Row>
        <div style={{marginTop: 20}}>
          <Table pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
                 columns={cols}
                 scroll={{x: 1000}}
                 sticky
                 dataSource={drivers}
                 rowKey="id"/>

        </div>
      </>
    );
  }

  return (
    <Modal
      title={"Команда"}
      visible={visible}
      destroyOnClose={true}
      className="size-for-max-window"
      style={{ top: 20 }}
      onOk={() => {
        if(!title) {
          message.warn("Укажите наименование команды");
          return;
        }
        if(!drivers || drivers.length === 0) {
          message.warn("Добавьте водителей");
          return;
        }

        onOk(title, drivers);

        setTitle("");
        setDrivers([]);
      }}
      onCancel={() => {
        onClose();
      }}
    >
      {
        render()
      }
    </Modal>
  );

}