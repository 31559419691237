import React, {useEffect, useState} from "react";
import {Col, DatePicker, Input, TimePicker, Modal, Row, Space, Table} from "antd";
import moment from "moment";
import "../../ModalStyle.css";

const { TextArea } = Input;

const rowStyle = {padding: '8px'};

export default function ServiceMaintenanceStatusEdit({visible, maintenance, onOk, onCancel, title}) {

  let [record, setRecord] = useState({id: 0});

  useEffect(() => {
    if(maintenance) {
      setRecord(maintenance);
    } else {
      setRecord({id: 0});
    }
  }, [maintenance]);

  return (
    <Modal
      destroyOnClose={true}
      style={{top: 10}}
      className="size-for-middle-window"
      title={title || "Новая запись"}
      visible={visible}
      onOk={() => {
        onOk(record);
      }}
      onCancel={onCancel}>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>ТС</Col>
        <Col span={18}>
          <Input
            disabled={true}
            value={record.car?.title}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Регион</Col>
        <Col span={18}>
          <Input
            disabled={true}
            value={record.region?.title}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Пробег при получении / предыдущем осмотре ТС</Col>
        <Col span={18}>
          <Input
            type={"number"}
            style={{width: 200}}
            value={record.receipt_mileage}
            onChange={(e) => { setRecord({...record, receipt_mileage: e.target.value}) }}
          />
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Пробег при последнем ТО</Col>
        <Col span={18}>
          <Input
            type={"number"}
            style={{width: 200}}
            value={record.last_maintenance_mileage}
            onChange={(e) => { setRecord({...record, last_maintenance_mileage: e.target.value}) }}
          />
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Документация до</Col>
        <Col span={18}>
          <DatePicker
            allowClear
            format="DD.MM.YYYY"
            value={record.documents_due_dt ? moment(record.documents_due_dt, "YYYY-MM-DD") : null}
            onChange={(val) => { setRecord({...record, documents_due_dt: val ? val.format("YYYY-MM-DD") : null}) }}
            style={{width: 220}}
          />
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Дата предыдущего осмотра</Col>
        <Col span={18}>
          <DatePicker
            allowClear
            format="DD.MM.YYYY"
            value={record.prev_maintenance_dt ? moment(record.prev_maintenance_dt, "YYYY-MM-DD") : null}
            onChange={(val) => { setRecord({...record, prev_maintenance_dt: val ? val.format("YYYY-MM-DD") : null}) }}
            style={{width: 220}}
          />
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Дата тех.работ</Col>
        <Col span={18}>
          <Space>
            <DatePicker
              allowClear
              format="DD.MM.YYYY"
              value={record.planned_maintenance_dt ? moment(record.planned_maintenance_dt, "YYYY-MM-DD") : null}
              onChange={(val) => { setRecord({...record, planned_maintenance_dt: val ? val.format("YYYY-MM-DD") : null}) }}
              style={{width: 220}}
            />
            <TimePicker
              style={{width: 180}}
              value={record.planned_maintenance_time ? moment(record.planned_maintenance_time, "HH:mm") : null}
              format={"HH:mm"}
              onChange={(time, timeString) => {
                setRecord({...record, planned_maintenance_time: time ? time.format("HH:mm") : null})
              }}
            />
          </Space>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Планируемые технические работы</Col>
        <Col span={18}>
          <TextArea
            rows={3}
            placeholder=""
            value={record.workplan}
            onChange={(e) => { setRecord({...record, workplan: e.target.value}) }}
          />
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Комменатрий механика / водителя</Col>
        <Col span={18}>
          <Table
            size={'small'}
            pagination={{position: 'topRight', defaultPageSize: 10, pageSizeOptions: [15, 25, 50]}}
            columns={[
              {
                title: "",
                align: "center",
                width: 10,
              },
              {
                title: 'Дата',
                width: 140,
                render: (text, record, index) => {
                  return moment(record.create_dt).format("DD.MM.YYYY HH:mm");
                },
              },
              {
                title: 'Комментарий',
                render: (text, record, index) => {
                  return record.comment;
                }
              },
              {
                title: "",
                align: "center",
                width: 10,
              }
            ]}
            dataSource={record.comments}
            rowKey="id"
          />
        </Col>
      </Row>

    </Modal>
  );
}