import React, {useEffect, useState} from "react";
import {
  useParams
} from "react-router-dom";
import {Col, message, Row, Table} from "antd";
import {apiClient} from "../../../services/ApiClient";

export default function CompetitionResultPage() {
  let { uid } = useParams();
  let [results, setResults] = useState([]);

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    {
      title: 'Место',
      dataIndex: 'id',
      width: 120,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'Чистый доход',
      dataIndex: 'total_income',
      width: 120,
    },
    {
      title: 'ФИО Участника',
      dataIndex: 'taxi_driver',
      render: (text, record, index) => {
        return record.taxi_driver ? record.taxi_driver.name : "";
      },
    },
    {
      title: 'Номер',
      dataIndex: 'number',
      width: 120,
      render: (text, record, index) => {
        return record.number;
      },
    },
    {
      title: 'Марка',
      dataIndex: 'taxi_driver',
      render: (text, record, index) => {
        return record.taxi_driver ? record.taxi_driver.car_model : "";
      },
    },
    {
      title: 'Номер',
      dataIndex: 'taxi_driver',
      render: (text, record, index) => {
        return record.taxi_driver ? record.taxi_driver.car_number : "";
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(() => {
    apiClient.getCompetitionResults(uid)
      .then(res => {
        setResults(res.records);
      })
      .catch(err => {
        message.error("Не удалось получить результаты конкурса");
        message.error(err);
      });
  }, [uid]);

  return (
    <>
      <Row>
        <Col xs={2} sm={2} md={4} lg={6} xl={6}>
          &nbsp;
        </Col>
        <Col xs={20} sm={20} md={16} lg={12} xl={12}>
          <div style={{textAlign: 'center', fontSize: '32pt', margin: '50px 0'}}>
            <span>Результаты участников конкурса Такси Арена</span>
          </div>
          <div>
            <Table pagination={false}
                   columns={cols}
                   scroll={{x: 1000}}
                   sticky
                   dataSource={results}
                   rowKey="id"/>
          </div>
        </Col>
        <Col xs={2} sm={2} md={4} lg={6} xl={6}>
          &nbsp;
        </Col>
      </Row>
    </>
  )
}