import React, {useEffect, useState} from "react";
import {DatePicker, message, PageHeader, Select, Space, Table, Input, Dropdown, Menu, Button, Modal} from "antd";
import moment from "moment";
import {apiClient} from "../../../services/ApiClient";
import {PaymentProvider, PaymentMethod} from "../../common/AppEnums";
import UpdateButton from "../../buttons/UpdateButton";
import numeral from "numeral";
import {
  DownOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";

const { Option } = Select;
const { RangePicker } = DatePicker;
const {Search, TextArea} = Input;

const CMD_EXPORT = "export";

export default function DriverPaymentsPage() {
  let [records, setRecords] = useState([]);
  let [recordsTotal, setRecordsTotal] = useState(0);
  let [loading, setLoading] = useState(false);
  let [recordsStamp, setRecordsStamp] = useState(0);
  let [transactionsFilter, setTransactionsFilter] = useState({type: ''});
  let [drivers, setDrivers] = useState([]);
  let [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    position: 'topRight',
    defaultPageSize: 50,
    pageSizeOptions: [50, 100, 200, 300]
  });
  let [sorter, setSorter] = useState({});
  let [selectedRows, setSelectedRows] = useState([]);
  let [transactionGroups, setTransactionGroups] = useState([]);

  useEffect(() => {
    apiClient.searchDirectoryRecord('taxiDriver', '')
      .then(res => {
        setDrivers(res.records);
      })
      .catch(err => {
        message.error(err);
      });
    apiClient.getTransactionGroups({transaction_type: 'credit'})
      .then(res => {
        setTransactionGroups(res.records);
      })
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    setLoading(true);
    let filter = {
      sortField: sorter.field,
      sortOrder: sorter.order,
      type: transactionsFilter.type,
      status: transactionsFilter.status,
      vehicleNumber: transactionsFilter.vehicleNumber,
      provider: transactionsFilter.provider,
      driver: transactionsFilter.driver,
      method: transactionsFilter.method,
      group: transactionsFilter.group,
      page: pagination.current,
      pageSize: pagination.pageSize,
    };
    if(transactionsFilter.period && transactionsFilter.period.length > 0) {
      filter.from = moment(transactionsFilter.period[0]).format('YYYY-MM-DD');
      filter.to = moment(transactionsFilter.period[1]).format('YYYY-MM-DD');
    }
    apiClient.getDriverPayments(filter)
      .then(res => {
        setRecordsTotal(res.total);
        setRecords(res.records);
        setPagination(prevState => {return {...prevState, total: res.total}})
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [recordsStamp, transactionsFilter]);

  const columns = [
    {
      title: "",
      align: "center",
      width: 10,
    },
    {
      title: 'Дата',
      dataIndex: 'create_dt',
      align: 'center',
      width: 120,
      render: (text, record, index) => {
        return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
      },
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: "Водитель",
      width: 250,
      align: 'center',
      render: (text, record, index) => {
        return record.driver?.title;
      },
    },
    {
      title: "Рег.номер",
      dataIndex: "taxi_driver",
      align: "center",
      width: 120,
      render: (text, record, index) => {
        return record.regNumber;
      },
    },
    {
      title: "Сумма",
      dataIndex: "total",
      width: 120,
      render: (text, record, index) => {
        return numeral(record.total).format('0.00')
      },
      sorter: true,
    },
    {
      title: "К зачислению",
      dataIndex: "amount",
      width: 130,
      render: (text, record, index) => {
        return numeral(record.amount).format('0.00')
      },
      sorter: true,
    },
    {
      title: "Комиссия",
      dataIndex: "comission",
      width: 120,
      render: (text, record, index) => {
        return numeral(record.comission).format('0.00')
      },
      sorter: true,
    },
    {
      title: "Сервис",
      dataIndex: "provider",
      width: 120,
      render: (text, record, index) => {
        return PaymentProvider.find(el => el.id == record.provider)?.title || record.provider;
      },
    },
    {
      title: "Способ оплаты",
      dataIndex: "method",
      width: 120,
      render: (text, record, index) => {
        return PaymentMethod.find(el => el.id == record.method)?.title || record.method;
      },
    },
    {
      title: "Группа",
      dataIndex: "payment_group",
      align: "center",
      width: 180,
      render: (text, record, index) => {
        return transactionGroups.find(el => el.id == record.payment_group)?.title || record.payment_group;
      },
    },
    {
      title: "Статус",
      dataIndex: "status",
      width: 100,
      align: 'center',
      render: (text, record, index) => {
        if(record.status === "success") {
          return "Зачислен";
        }
        if(record.status === "pending") {
          return "В обработке";
        }
        if(record.status === "failed") {
          return "Ошибка";
        }
        if(record.status === "processing_failed") {
          return "Ошибка процессинга";
        }
      },
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
    },
    {
      title: "",
      align: "center",
      width: 10,
    }
  ];

  const executeSelectedRecordsOperation = key => {
    if(key === CMD_EXPORT) {
      apiClient.getDriverPaymentsExport(selectedRows.map(el => el.id))
        .then(res => {
          window.open(res.link);
          setSelectedRows([]);
        })
        .catch(err => message.error(err));
    }
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Платежи"
        extra={
          (
            <div style={{textAlign: "right"}}>
              <Space direction={"vertical"} size={"small"}>
                <span>Сумма: {numeral(selectedRows.map(el => parseFloat(el.total)).reduce((a, b) => a + b, 0)).format('0.00')} руб.</span>
                <span>К зачислению: {numeral(selectedRows.map(el => parseFloat(el.amount)).reduce((a, b) => a + b, 0)).format('0.00')} руб.</span>
                <span>Комиссия: {numeral(selectedRows.map(el => parseFloat(el.comission)).reduce((a, b) => a + b, 0)).format('0.00')} руб.</span>
                <span>{`Всего записей: ${recordsTotal}`}</span>
              </Space>
            </div>
          )
        }
      />
      <div style={{width: '100%'}}>
        <div style={{width: '80%', float: 'left', marginBottom: 40}}>
          <Space direction={"horizontal"} wrap>
            <Select
              showSearch
              allowClear
              style={{ width: 350 }}
              placeholder="Водитель"
              onChange={(value) => {
                setTransactionsFilter({...transactionsFilter, ...{driver: value}});
                setPagination({...pagination, current: 1})
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {drivers.map(s => <Option key={s.id} value={s.id}>{`${s.last_name} ${s.first_name} ${s.middle_name} (${s.phone})`}</Option>)}
            </Select>
            <Search
              enterButton
              placeholder={"Поиск по гос.номеру"}
              onSearch={(value) => {
                setTransactionsFilter({...transactionsFilter, ...{vehicleNumber: value}});
                setPagination({...pagination, current: 1});
              }}
              allowClear={true}
              style={{width: 300}}
            />
            <RangePicker format="DD.MM.YYYY" style={{width: 350}} onChange={(val) => {
              setTransactionsFilter({...transactionsFilter, ...{period: val}});
              setPagination({...pagination, current: 1});
            }}/>
            <Select
              showSearch
              allowClear
              style={{ width: 250 }}
              placeholder="Статус"
              onChange={(value) => {
                setTransactionsFilter({...transactionsFilter, ...{status: value}});
                setPagination({...pagination, current: 1})
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                [
                  {id: "success", title: "Зачислен"},
                  {id: "failed", title: "Ошибка"},
                  {id: "processing_failed", title: "Ошибка процессинга"},
                ].map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
              }
            </Select>
            <Select
              showSearch
              allowClear
              style={{ width: 250 }}
              placeholder="Сервис"
              onChange={(value) => {
                setTransactionsFilter({...transactionsFilter, ...{provider: value}});
                setPagination({...pagination, current: 1})
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                PaymentProvider.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
              }
            </Select>
            <Select
              showSearch
              allowClear
              mode={"multiple"}
              style={{ width: 250 }}
              placeholder="Способ оплаты"
              onChange={(value) => {
                setTransactionsFilter({...transactionsFilter, ...{method: value}});
                setPagination({...pagination, current: 1})
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                PaymentMethod.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
              }
            </Select>
            <Select
              showSearch
              allowClear
              style={{ width: 400 }}
              placeholder="Группа"
              onChange={(value) => {
                setTransactionsFilter({...transactionsFilter, ...{group: value}});
                setPagination({...pagination, current: 1})
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                transactionGroups.map(el => <Option key={el.id} value={el.id}>{`${el.title} (${el.transaction_type === "debit" ? "Списание" : "Зачисление"})`}</Option>)
              }
            </Select>
          </Space>
        </div>
        <div style={{width: '20%', float: 'right'}}>
          <Space style={{float: "right"}}>
            <UpdateButton onClick={ () => setRecordsStamp(prevState => prevState + 1) }/>
            {
              selectedRows.length > 0
                ? (
                  <>
                    <Dropdown
                      overlay={(
                        <Menu onClick={(e) => {
                          executeSelectedRecordsOperation(e.key);
                        }}>
                          <Menu.Item key={CMD_EXPORT} icon={<FileExcelOutlined/>}>
                            Выгрузка в Excel
                          </Menu.Item>
                        </Menu>
                      )}>
                      <Button style={{textAlign: "left"}}>
                        С выбранными <DownOutlined/>
                      </Button>
                    </Dropdown>
                  </>
                ) : null
            }
          </Space>
        </div>
      </div>

      <Table
        dataSource={records}
        columns={columns}
        size="small"
        sticky
        style={{margin: '10px 0', marginTop: 40}}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        scroll={{x: 1500}}
        onChange={(pagination, filters, sorter) => {
          setPagination(pagination);
          setSorter(sorter);
          setRecordsStamp(prevState => prevState + 1)
        }}
        rowSelection={{
          onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            setSelectedRows(selectedRows);
          },
          selectedRowKeys: selectedRows.map(el => el.id)
        }}
      />

    </>
  );
}