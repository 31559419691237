import React, { useEffect, useState} from "react";
import {Button, Col, DatePicker, Input, message, Modal, Row, Select, Spin} from "antd";
import "../ModalStyle.css";
import {apiClient} from "../../../services/ApiClient";
import VehiclesList from "./components/VehiclesList";
import moment from "moment";
import DtpDialog from "./DtpDialog"
import {QuestionOutlined} from "@ant-design/icons";
const rowStyle = {padding: '4px'};
const { Option } = Select;

export default function ApplicationDialog({visible, record, onOk, onCancel, title}) {
  let [application, setApplication] = useState({});
  let [dtpDialogApplication, setDtpDialogApplication] = useState(null);
  let [dtpStatus, setDtpStatus] = useState(false);
  let [drivers, setDrivers] = useState([]);

  useEffect(() => {
    console.debug("[ApplicationDialog][Init]", record);
    if(record) {
      setApplication(r => {
        return {
          ...r,
          ...record
        }
      });
      if(record.car) {
        setDrivers(record.car.drivers);
      } else if(record.driver) {
        setDrivers([record.driver])
      }
    } else {
      setApplication({
        accident_date: moment().format("YYYY-MM-DDTHH:mm:ss")
      });
    }
  }, [record]);

  const onUpdateDtp = (dtp) => {
    dtp["saved"] = true;
    setDtpStatus(true);
    apiClient.updateDtp(dtp["id"], dtp)
      .then(res => {
        message.success("ДТП создано!");
        onCancel(true);
      })
      .catch(err => message.error(err));
  };

  const handleVehicleChange = (data) => {
    if(data) {
      setApplication({...application, ...{car_reg_number: data}});
      if(data.drivers) {
        setDrivers(data.drivers);
      }
    }
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        className="size-for-middle-window"
        style={{top: 10}}
        title={title || "Изменить заявку"}
        visible={visible}
        okText="Сохранить"
        cancelText="Отмена"
        onOk={() => {
          if(!application.car_reg_number) {
            message.warn("Укажите номер ТС!");
            return;
          }
          if(!application.accident_date) {
            message.warn("Укажите дату ДТП!");
            return;
          }
          onOk(application, dtpStatus);
          setApplication(null);
        }}
        onCancel={() => {
          onCancel(dtpStatus);
          setApplication(null);
        }}
        >
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>Номер ТС</Col>
          <Col md={18}>
            {record ? (
              <Input
                allowClear={true}
                style={{width: 350}}
                value={application ? application.car_reg_number : ""}
                disabled
              />
            ) :
            (
              <VehiclesList
                status="1"
                onSelect={(record) => {handleVehicleChange(record)}}/>
            )
          }
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>Водитель</Col>
          <Col md={18}>
            <Select
              showSearch
              allowClear
              style={{width: 400}}
              value={application?.driver?.id}
              onChange={(value) => {
                if(value) {
                  setApplication({...application, driver: drivers.find(el => el.id === value)});
                } else {
                  setApplication({...application, driver: null});
                }
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                drivers.map(el => <Option key={el.id} value={el.id}>{el.name ? el.name : `${el.last_name} ${el.first_name} ${el.middle_name}`}</Option>)
              }
            </Select>
          </Col>
        </Row>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col span={6}>Дата, время заявки</Col>
          <Col md={18}>
            <DatePicker
              allowClear={true}
              placeholder="Дата, время заявки"
              showTime
              value={application ? moment(application.accident_date) : moment()}
              onChange={(dates, dateStrings) => {setApplication({...application, ...{accident_date: moment(dateStrings).format("YYYY-MM-DDTHH:mm:ss")}}); }}
              style={{width: 250}}
            />
          </Col>
        </Row>
        {application && application?.id > 0 && (
          <Row gutter={[0, 16]} style={rowStyle}>
            <Col span={6}>&nbsp;</Col>
            <Col md={18}>
              <Button type="primary" onClick={() => {
                Modal.confirm({
                  title:  "Подтверждение создания ДТП",
                  content: `Вы желаете создать карточку ДТП?`,
                  icon: <QuestionOutlined/>,
                  visible: true,
                  onOk() {
                    const params = {
                      culprit_type: "OUR_DRIVER_CULPRIT",
                      accident_request_id: application.id,
                      car_id: application.car?.id,
                      taxi_driver_id: application.driver?.id,
                      accident_date: application.accident_date
                    };
                    apiClient.createDtp(params)
                      .then(res => {
                        setDtpDialogApplication(res.record);
                      })
                      .catch((err) => message.error(err));
                  }
                })}
              }>Сформировать ДТП</Button>
            </Col>
          </Row>
        )}
      </Modal>
      {
        dtpDialogApplication !== null && (
          <DtpDialog
            visible={dtpDialogApplication !== null}
            type="application"
            record={dtpDialogApplication}
            onOk={(data, id) => {
              setDtpDialogApplication(null);
              onUpdateDtp(data, id);
            }}
            onCancel={() => {
              apiClient.removeDtp([dtpDialogApplication.id])
                .then(() => {
                  console.debug("temp dtp record deleted")
                })
                .catch((err) => console.error(err))
                .finally(() => { setDtpDialogApplication(null) })
            }}
          />
        )
      }
    </>
  );
};
