import { Draggable } from "react-beautiful-dnd";
import React from "react";
// import styled, { css } from "styled-components";
import { format } from 'date-fns';
import "./DragList.css";

// const CardHeader = styled.div`
//   font-weight: 500;
// `;

// const Author = styled.div`
//   display: flex;
//   align-items: center;
// `;
// const CardFooter = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `;

const ListItem = ({ key, index, item, onClick, status, prefix }) => {

  const handleClick = (item) => {
    onClick(item);
  }
  return (
    <Draggable key={`${prefix}-${item.id}`} index={index} draggableId={`${prefix}-${item.id}`}>
      {(provided, snapshot) => (
        <div>
          <div
            className="drag-item"
            onClick={() => {handleClick(item)}}
            ref={provided.innerRef}
            // snapshot={snapshot}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {(item && item.accident_date) && (
              <div>Дата ДТП: {format(new Date(item.accident_date), "dd.MM.yyyy HH:mm")}</div>
            )}
            <span>Водитель: {item.driver_fio}</span>
            <span>Номер машины: {item.car_reg_number}</span>
            {/* <div style={{display: "flex", width: "1005", justifyContent: "center", alignItems: "center"}}>
              <span></span>
              <div style={{display: "flex", justifyContent: "right"}}>
                {status}
              </div>
            </div> */}
          </div>
          {provided.placeholder}
        </div>
      )}
    </Draggable>
  );
};

export default ListItem;