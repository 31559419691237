import React, { useEffect, useState} from "react";
import {Button, Card, Col, DatePicker, Input, message, Modal, Row, Select, Spin, Typography} from "antd";
import "../ModalStyle.css";
import {apiClient} from "../../../services/ApiClient";
import moment from "moment";
import {ExclamationCircleFilled, DeleteOutlined} from "@ant-design/icons";
import "./NotificationsDialog.css";
const rowStyle = {padding: '4px'};
const { Option } = Select;
const { Paragraph } = Typography;

export default function NotificationsDialog({visible, record, onOk, onCancel}) {
  let [application, setApplication] = useState({});
  let [dtpDialogApplication, setDtpDialogApplication] = useState(null);
  let [dtpStatus, setDtpStatus] = useState(false);
  let [drivers, setDrivers] = useState([]);

  // useEffect(() => {
  //   console.debug("[ApplicationDialog][Init]", record);
  //   if(record) {
  //     setApplication(r => {
  //       return {
  //         ...r,
  //         ...record
  //       }
  //     });
  //     if(record.car) {
  //       setDrivers(record.car.drivers);
  //     } else if(record.driver) {
  //       setDrivers([record.driver])
  //     }
  //   } else {
  //     setApplication({
  //       accident_date: moment().format("YYYY-MM-DDTHH:mm:ss")
  //     });
  //   }
  // }, [record]);

  // const onUpdateDtp = (dtp) => {
  //   dtp["saved"] = true;
  //   setDtpStatus(true);
  //   apiClient.updateDtp(dtp["id"], dtp)
  //     .then(res => {
  //       message.success("ДТП создано!");
  //       onCancel(true);
  //     })
  //     .catch(err => message.error(err));
  // };

  // const handleVehicleChange = (data) => {
  //   if(data) {
  //     setApplication({...application, ...{car_reg_number: data}});
  //     if(!data.drivers || data.drivers.length < 1) {
  //       message.error("У этой машины нет водителя! Выберите другую машину!")
  //     } else {
  //       setDrivers(data.drivers);
  //     }
  //   }
  // };

  return (
    <>
      <Modal
        footer={null}
        destroyOnClose={true}
        className="position-right"
        style={{top: 10}}
        title={"Уведомления"}
        visible={visible}
        okText="Сохранить"
        cancelText="Отмена"
        onOk={() => {
          if(!application?.driver?.id) {
            message.warn("Укажите водителя!");
            return;
          }
          if(!application.car_reg_number) {
            message.warn("Укажите номер ТС!");
            return;
          }
          if(!application.accident_date) {
            message.warn("Укажите дату ДТП!");
            return;
          }
          onOk(application, dtpStatus);
          setApplication(null);
        }}
        onCancel={() => {
          onCancel(dtpStatus);
          setApplication(null);
        }}
      >
        <div style={{height: "80vh"}}>
        <Card
          hoverable
          style={{ width: "100%", margin: 0, backgroundColor: "#F5F5F5", marginBottom: 10}}
          // cover={<img alt="example" src={s.src2} />}
          // onClick={() => {setState("2"); setAdData(s);}}
        >
          <div class="flexbox-container">
            <div style={{width: '10%', float: 'left'}}>
              <span><ExclamationCircleFilled style={{color: "#58D3A5"}} /></span>
            </div>
            <div style={{width: '80%', float: 'left', marginRight: 0}}>
              <Typography.Title level={5} >s.name</Typography.Title>
              <Paragraph
                ellipsis={{
                  expandable: true,
                  onEllipsis: (ellipsis) => {
                    console.log('Ellipsis changed:', ellipsis);
                  },
                }}
              >
                ssssssssssssssssssssssssssssssssssssssssssssssddddddddddddddddddddddasfbnmt,y.ul/lkj.,hgnfdvsbfsngdmtuiyldkutjrhgsewrhetjarkysutldiykuthfgndgsrwhtaejrysktudliy,jgmhfngdfgewft24y53ua64syrkurmhngdgwt24y53u64ikutjmhfngdgrwy53u64kut,jhfngdbwgr23j4kyrmhngdbwrg25364wk5umhngdeth3j4wk5utmh
              </Paragraph>
            </div>
            <div style={{width: '10%', float: 'right'}}>
              <span style={{padding: '0 20px'}}><DeleteOutlined /></span>
            </div>
          </div>
        </Card>
        <Card
          hoverable
          style={{ width: "100%", margin: 0, backgroundColor: "#F5F5F5", marginBottom: 10}}
          // cover={<img alt="example" src={s.src2} />}
          // onClick={() => {setState("2"); setAdData(s);}}
        >
          <div class="flexbox-container">
            <div style={{width: '10%', float: 'left'}}>
              <span><ExclamationCircleFilled style={{color: "#58D3A5"}} /></span>
            </div>
            <div style={{width: '80%', float: 'left', marginRight: 0}}>
              <Typography.Title level={5} >s.name</Typography.Title>
              <Paragraph
                ellipsis={{
                  expandable: true,
                  onEllipsis: (ellipsis) => {
                    console.log('Ellipsis changed:', ellipsis);
                  },
                }}
              >
                ssssssssssssssssssssssssssssssssssssssssssssssddddddddddddddddddddddasfbnmt,y.ul/lkj.,hgnfdvsbfsngdmtuiyldkutjrhgsewrhetjarkysutldiykuthfgndgsrwhtaejrysktudliy,jgmhfngdfgewft24y53ua64syrkurmhngdgwt24y53u64ikutjmhfngdgrwy53u64kut,jhfngdbwgr23j4kyrmhngdbwrg25364wk5umhngdeth3j4wk5utmh
              </Paragraph>
            </div>
            <div style={{width: '10%', float: 'right'}}>
              <span style={{padding: '0 20px'}}><DeleteOutlined /></span>
            </div>
          </div>
        </Card>
        </div>
      </Modal>
    </>
  );
};
