import React, {useEffect, useState} from "react";
import {
  Button,
  Input,
  message,
  PageHeader,
  Space,
  Table,
  Modal,
  DatePicker,
  Radio,
  Select,
  Dropdown, Menu
} from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  EditFilled,
  PrinterOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import {apiClient} from "../../../services/ApiClient";
import moment from "moment";
import {RentContractStatus} from "../../common/AppEnums";
import RentContractEdit from "../park/contract/RentContractEdit";
import PrintFormSelectDialog from "../common/PrintFormSelectDialog";
import UpdateButton from "../../buttons/UpdateButton";
import {windowService} from "../../../services/WindowService";
import LinkButton from "../../buttons/LinkButton";

const {Search} = Input;
const { Option } = Select;

const CMD_REMOVE = "remote";

export default function ContractsPage() {
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [recordsCount, setRecordsCount] = useState(0);
  let [update, setUpdate] = useState(0);
  let [selected, setSelected] = useState(null);
  let [printRecord, setPrintRecord] = useState(null);
  let [driverFilter, setDriverFilter] = useState("");
  let [contractNumberFilter, setContractNumberFilter] = useState(null);
  let [createDateFilter, setCreateDateFilter] = useState(null);
  let [contractDateFilter, setContractDateFilter] = useState(null);
  let [contractStatus, setContractStatus] = useState(RentContractStatus[0].id);
  let [responsibles, setResponsibles] = useState([]);
  let [filterByResponsible, setFilterByResponsible] = useState(null);
  let [selectedRecords, setSelectedRecords] = useState([]);
  let [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    position: 'topRight',
    defaultPageSize: 50,
    pageSizeOptions: [25, 50, 75]
  });
  let [sorter, setSorter] = useState({});

  useEffect(() => {
    setLoading(true);
    apiClient.getRentContracts({
      driverFilter: driverFilter,
      contractNumberFilter: contractNumberFilter,
      createDateFilter: createDateFilter,
      contractDateFilter: contractDateFilter,
      contractStatus: contractStatus,
      responsible: filterByResponsible,
      sortField: sorter.field,
      sortOrder: sorter.order,
      page: pagination.current,
      pageSize: pagination.pageSize
    })
      .then(res => {
        setRecords(res.records);
        setRecordsCount(res.records_count);
        setPagination(prevState => {return {...prevState, total: res.records_count}})
      })
      .catch(err => message.error(err))
      .finally(() => setLoading(false));
  }, [
    update,
    driverFilter,
    contractNumberFilter,
    createDateFilter,
    contractDateFilter,
    contractStatus,
    filterByResponsible,
  ]);

  useEffect(() => {
    apiClient.getUsers()
      .then(res => {
        setResponsibles(res.records);
      })
      .catch(err => message.error(err));
  }, []);

  const handlePrint = (template, contract) => {
    apiClient.renderRentContractTemplate(contract.id, template.id)
      .then(res => {
        window.open(res.record.url, '_blank');
      })
      .catch(err => message.error(err));
  };

  const executeSelectedRecordsOperation = operationKey => {
    if(operationKey === CMD_REMOVE) {
      Modal.confirm({
        title:  "Удаление",
        content: "Вы уверены, что хотите удалить выбранные записи?",
        icon: <DeleteOutlined/>,
        visible: true,
        onOk() {
          setLoading(true);
          apiClient.removeRentContracts(selectedRecords)
            .then(res => {
              message.success("Записи удалены");
              setSelectedRecords([]);
              setUpdate(prevState => prevState + 1);
            })
            .catch(err => message.error("Ошибка"))
            .finally(() => setLoading(false));
        },
      });
    }
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Договоры аренды"
        extra={
          <div style={{textAlign: "right"}}>
            Всего: {recordsCount}
          </div>
        }
      />
      <div style={{marginBottom: 20}}>
        <Space style={{float: "right", marginBottom: 20}} direction="horizontal">
          <UpdateButton onClick={() => {
            setUpdate(u => u + 1)
          }}/>
          <Button onClick={() => {
            setSelected({})
          }} type="primary">Создать</Button>
          {
            selectedRecords.length > 0
              ? (
                <>
                  <Dropdown
                    disabled={selectedRecords.length < 1}
                    overlay={(
                      <Menu onClick={(e) => {
                        executeSelectedRecordsOperation(e.key);
                      }}>
                        <Menu.Item key={CMD_REMOVE} icon={<DeleteOutlined/>}>
                          Удалить
                        </Menu.Item>
                      </Menu>
                    )}>
                    <Button style={{textAlign: "left"}}>
                      С выбранными <DownOutlined/>
                    </Button>
                  </Dropdown>
                </>
              ) : null
          }
        </Space>
        <Space direction="horizontal" style={{marginBottom: 20}}>
          <Search
            allowClear
            placeholder="Поиск по водителю"
            onSearch={(value) => setDriverFilter(value)}
            style={{width: 350}}
            enterButton
          />
          <Search
            allowClear
            placeholder="Поиск № договора"
            onSearch={(value) => setContractNumberFilter(value)}
            style={{width: 350}}
            enterButton
          />
          <Radio.Group value={contractStatus} onChange={(e) => {
            setContractStatus(e.target.value);
          }}>
            {
              [...RentContractStatus, {id: "all", title: "Все"}]
                .map(el => <Radio.Button key={el.id} value={el.id}>{el.title}</Radio.Button>)
            }
          </Radio.Group>
        </Space>
        <Space direction="horizontal" style={{marginBottom: 20, display: 'flex'}}>
          <DatePicker
            allowClear
            format="DD.MM.YYYY"
            placeholder="Дата создания"
            value={createDateFilter ? moment(createDateFilter, "YYYY-MM-DD") : null}
            onChange={(val) => { setCreateDateFilter(val ? val.format("YYYY-MM-DD") : null) }}
            style={{width: 220}}
          />
          <DatePicker
            allowClear
            format="DD.MM.YYYY"
            placeholder="Дата договора"
            value={contractDateFilter ? moment(contractDateFilter, "YYYY-MM-DD") : null}
            onChange={(val) => { setContractDateFilter(val ? val.format("YYYY-MM-DD") : null) }}
            style={{width: 220}}
          />
          <Select
            showSearch
            allowClear
            style={{ width: 300 }}
            placeholder="Ответственный"
            onChange={(value) => { setFilterByResponsible(value) }}
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {responsibles.map(s => <Option key={s.id} value={s.id}>{`${s.first_name} (${s.username})`}</Option>)}
          </Select>
        </Space>

      </div>
      <Table
        loading={loading}
        scroll={{x: 1600}}
        sticky
        size={'small'}
        columns={[
          {
            title: "",
            align: "center",
            width: 30,
          },
          Table.SELECTION_COLUMN,
          {
            title: '',
            width: 100,
            render: (text, record, index) => {
              return (
                <Space direction={"horizontal"}>
                  <Button
                    type="link"
                    icon={<EditFilled/>}
                    disabled={!record?.id}
                    onClick={() => {
                      setLoading(true);
                      apiClient.getRentContract(record.id)
                        .then(res => {
                          setSelected(res.record);
                        })
                        .catch(err => message.error(err))
                        .finally(() => setLoading(false));
                    }}
                  />
                  <Button type={"link"} onClick={() => { setPrintRecord(record) }}>
                    <PrinterOutlined/>
                  </Button>
                </Space>
              )
            }
          },
          {
            title: 'Дата создания',
            defaultSortOrder: "descend",
            align: "center",
            render: (text, record, index) => {
              return record.create_dt ? `${moment(record.create_dt).format('DD.MM.YYYY')}` : "";
            },
            sorter: (a, b) => {
              return moment(a.create_dt).valueOf() - moment(b.create_dt).valueOf();
            },
          },
          {
            title: 'Дата договора',
            align: "center",
            render: (text, record, index) => {
              return record.contract_dt ? `${moment(record.contract_dt).format('DD.MM.YYYY')}` : "";
            },
            sorter: (a, b) => {
              return moment(a.contract_dt).valueOf() - moment(b.contract_dt).valueOf();
            },
          },
          {
            title: 'Номер',
            dataIndex: 'contract_number',
            align: "center",
            sorter: (a, b) => {
              return a.contract_number.localeCompare(b.contract_number);
            }
          },
          {
            title: 'Статус',
            align: "center",
            render: (text, record, index) => {
              return RentContractStatus.filter(el => el.id === record.status)[0].title;
            },
            sorter: (a, b) => {
              return a.status.localeCompare(b.status);
            }
          },
          {
            title: 'Водитель',
            width: 350,
            align: "center",
            render: (text, record, index) => {
              return record.driver
                  ? (
                  <LinkButton
                    style={{padding: 0}}
                    onClick={() => {
                      windowService.openRouteWindow(`cp/drivers/${record.driver.id}`);
                    }}
                    label={record.driver.name}
                  />
                ) : "";
            },
            sorter: (a, b) => {
              if (!a.driver && !b.driver) {
                return 0;
              }
              if(!a.driver) {
                return 1;
              }
              if(!b.driver) {
                return -1;
              }
              return a.driver.name.localeCompare(b.driver.name);
            }
          },
          {
            title: 'Начало действия',
            align: "center",
            render: (text, record, index) => {
              return record.contract_start_dt ? `${moment(record.contract_start_dt).format('DD.MM.YYYY')}` : "";
            },
            sorter: (a, b) => {
              if(!a.contract_start_dt && !b.contract_start_dt) {
                return 0;
              }
              if(!a.contract_start_dt) {
                return -1;
              }
              if(!b.contract_start_dt) {
                return 1;
              }
              return moment(a.contract_start_dt).valueOf() - moment(b.contract_start_dt).valueOf();
            },
          },
          {
            title: 'Окончание действия',
            align: "center",
            render: (text, record, index) => {
              return record.contract_end_dt ? `${moment(record.contract_end_dt).format('DD.MM.YYYY')}` : "";
            },
            sorter: (a, b) => {
              if(!a.contract_end_dt && !b.contract_end_dt) {
                return 0;
              }
              if(!a.contract_end_dt) {
                return -1;
              }
              if(!b.contract_end_dt) {
                return 1;
              }
              return moment(a.contract_end_dt).valueOf() - moment(b.contract_end_dt).valueOf();
            },
          },
          {
            title: 'Стоимость аренды в сутки',
            dataIndex: 'rent_day_price',
            align: "center",
            sorter: (a, b) => {
              return a.rent_day_price - b.rent_day_price;
            },
          },
          {
            title: 'Ответственный',
            dataIndex: 'responsible',
            render: (text, record, index) => {
              return record.responsible?.title;
            }
          },
          {
            title: "",
            align: "center",
            width: 20,
          }
        ]}
        dataSource={records}
        rowKey="id"
        pagination={pagination}
        onChange={(pagination, filters, sorter) => {
          setPagination(pagination);
          setSorter(sorter);
          setUpdate(prevState => prevState + 1);
        }}
        rowSelection={{
          columnWidth: '50px',
          type: 'checkbox',
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedRecords,
          onChange: (selectedRowKeys) => {
            setSelectedRecords(selectedRowKeys);
          }
        }}
      />

      {
        selected !== null ? (
          <RentContractEdit
            title={"Договор аренды"}
            contract={selected}
            visible={true}
            onOk={(record) => {
              setLoading(true);
              if(selected.id > 0) {
                apiClient.updateRentContract(record.id, record)
                  .then(res => {
                    setSelected(null);
                    setUpdate(prevState => prevState + 1);
                    message.info("Договор успешно обновлен");
                  })
                  .catch(err => message.error(err))
                  .finally(() => setLoading(false));
              } else {
                apiClient.createRentContract(record)
                  .then(res => {
                    setSelected(null);
                    setUpdate(prevState => prevState + 1);
                    message.info("Договор успешно создан");
                  })
                  .catch(err => message.error(err))
                  .finally(() => setLoading(false));
              }
              if(record.work_condition && record.work_condition_dt && record.driver) {
                Modal.confirm({
                  title:  "Подтверждение",
                  content: `Назначить ${record.driver.last_name} ${record.driver.first_name} ${record.driver.middle_name} условия работы ${record.work_condition.title}?`,
                  icon: <QuestionOutlined/>,
                  visible: true,
                  onOk() {
                    apiClient.updateDriverWorkCondition(record.driver.id, {id: record.work_condition.id, date: record.work_condition_dt})
                      .then(() => {
                        message.success("Условия работы назначены");
                      })
                      .catch(err => {
                        message.error('Ошибка, не удалось выполнить операцию');
                      });
                  }
                });
              }
            }}
            onClose={() => {
              setSelected(null);
            }}
          />
        ) : null
      }

      {
        printRecord !== null
          ? (
            <PrintFormSelectDialog
              visible={true}
              modelClass={"crm_pm_vehicle_contract"}
              unitType={printRecord?.owner?.type}
              title={"Выберите печатную форму"}
              onOk={(template) => {
                handlePrint(template, printRecord);
                setPrintRecord(null);
              }}
              onCancel={() => {
                setPrintRecord(null);
              }}
            />
          ) : null
      }

      </>
  );
}