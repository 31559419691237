import React, { useEffect, useState} from "react";
import { Col, DatePicker, Input, message, Modal, Row, Select} from "antd";
import {apiClient} from "../../../../services/ApiClient";
import "../../ModalStyle.css";
import moment from "moment";
import dayjs from 'dayjs';
const rowStyle = {padding: '4px'};
const { Option } = Select;
const { TextArea } = Input;

export default function CreateTransactionDialog({visible, onOk, onCancel}) {
  let [transaction, setTransaction] = useState([]);
  let [filtersList, setFiltersList] = useState(false);

  useEffect(() => {
    setTransaction({
      ...transaction,
      ...{
        transaction_type: "MINUS",
        date: moment()
      }
    });
  }, [visible]);

  useEffect(() => {
    apiClient.getListsForDtp()
      .then(res => {
        console.log({res});
        setFiltersList({...filtersList, ...{transaction_types: res.transaction_types}});
      })
      .catch(err => {
        console.error(err);
        message.error("Ошибка: не удалось загрузить типы транзакций");
      });
  }, []);

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-middle-window"
      style={{top: 10}}
      title={"Создать транзакцию"}
      visible={visible}
      okText="Сохранить"
      cancelText="Отмена"
      onOk={() => {
        if(!transaction.amount) {
          message.warn("Укажите сумму!");
          return;
        }
        onOk(transaction);
        setTransaction(null);
      }}
      onCancel={() => {
        onCancel();
        setTransaction(null);
      }}
    >
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Тип транзакции</Col>
        <Col md={18}>
          <Select
            style={{width: 400}}
            allowClear
            placeholder="Тип транзакции"
            value={transaction?.transaction_type}
            onChange={(val, option) => {
              setTransaction({...transaction, transaction_type: val})
            }}>
          >
            {
              filtersList.transaction_types ? filtersList.transaction_types.map(el => <Option key={el[0]} value={el[0]}>{`${el[1]}`}</Option>) : []
            }
          </Select>
        </Col>
      </Row>
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Сумма</Col>
        <Col md={18}>
          <Input
            style={{width: "250px"}}
            allowClear={true}
            placeholder="Сумма"
            onChange={(e) => { setTransaction({...transaction, ...{amount: e.target.value}}); }}
          />
        </Col>
      </Row>
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Дата, время транзакции</Col>
        <Col md={18}>
          <DatePicker
            allowClear={true}
            placeholder="Дата, время транзакции"
            showTime
            disabled
            defaultValue={transaction ? moment(transaction.date) : moment()}
            style={{width: 250}}
          />
        </Col>
      </Row>
      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Комментарий</Col>
        <Col md={18}>
          <TextArea
            allowClear={true}
            value={transaction ? transaction.comment : ""}
            onChange={(e) => {setTransaction({...transaction, ...{comment: e.target.value}});}}
          />
        </Col>
      </Row>
    </Modal>
  );
};