import React, {useEffect, useState} from "react";
import {DatePicker, message, Select, Space, Table} from "antd";
import {LoadingOutlined} from '@ant-design/icons';

import {DriverTransactionCategories, TemplatePaymentSource} from "../../../../common/AppEnums";
import {apiClient} from "../../../../../services/ApiClient";

import moment from "moment";
import UpdateButton from "../../../../buttons/UpdateButton";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function TransactionsTab({needUpdate, driver}) {

  let [page, setPage] = useState(1);
  let [pageSize, setPageSize] = useState(50);
  let [total, setTotal] = useState(0);
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [transactionsFilter, setTransactionsFilter] = useState({type: ''});
  let [update, setUpdate] = useState(0);

  const columns = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    {
      title: 'Дата',
      dataIndex: 'event_dt',
      render: (text, record, index) => {
        return `${moment(record.event_dt).format('DD.MM.YYYY HH:mm')}`;
      },
      defaultSortOrder: 'descend',
    },
    {
      title: "Операция",
      dataIndex: "operation_type",
      render: (text, record, index) => {
        if(record.operation_type === "debit") {
          return "Списание средств";
        }
        if(record.operation_type === "credit") {
          return "Зачисление средств";
        }
        return record.operation_type;
      },
    },
    {
      title: "Сумма",
      dataIndex: "amount",
    },
    {
      title: "Состояние",
      dataIndex: "processing_status",
      render: (text, record, index) => {
        if(record.processing_status === "new" || record.processing_status === "processing") {
          return <LoadingOutlined />;
        }
        if(record.processing_status === "processed") {
          return "Обработка сервисом";
        }
        if(record.processing_status === "complete") {
          return "Обработана";
        }
        if(record.processing_status === "failed") {
          return "Ошибка";
        }
      },
    },
    {
      title: "Баланс",
      dataIndex: "balance",
      render: (text, record, index) => {
        return TemplatePaymentSource.find(el => el.value === record.balance)?.text
      }
    },
    {
      title: "Категория",
      dataIndex: "category_name",
    },
    {
      title: "Ответственный",
      render: (text, record, index) => {
        return record.created_by ? (`${record.name} (${record.username})`) : null
      }
    },
    {
      title: "Комментарий",
      dataIndex: "comment"
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(() => {
    if(driver) {
      let pagination = {
        page: page,
        page_size: pageSize
      };
      let filter = {
        type: transactionsFilter.type,
        category: transactionsFilter.category,
        balance: transactionsFilter.balance,
      };
      if(transactionsFilter.period && transactionsFilter.period.length > 0) {
        filter.from = moment(transactionsFilter.period[0]).format('YYYY-MM-DD');
        filter.to = moment(transactionsFilter.period[1]).format('YYYY-MM-DD');
      }
      setLoading(true);
      apiClient.getDriverTransactionsById(driver.id, filter, pagination)
        .then(res => {
          setRecords(res.records);
          setTotal(res.total);
          // если среди транзакций есть со статусом "в обработке" - перезагрузить через 5 секунд
          if(res.records.some(e => e.processing_status === 'processing' || e.processing_status === 'new')) {
            setTimeout(() => {
              setUpdate(prevState => prevState + 1);
            }, 5000);
          }
        })
        .catch(err => {
          message.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setRecords([]);
    }
  }, [driver, needUpdate, update, page, pageSize, transactionsFilter]);

  return (
    <>
      <div style={{width: '90%', float: 'left' }}>
        <Space direction={"horizontal"} style={{marginBottom: 10}} wrap>
          <Select
            bordered
            allowClear
            placeholder="Тип операции"
            onChange={(e) => {
              setPage(1);
              setTransactionsFilter({...transactionsFilter, ...{type: e}})
            }} style={{width: '200px'}}>
            <Option key='debit' value='debit'>Списание</Option>
            <Option key='credit' value='credit'>Пополнение</Option>
          </Select>
          <Select
            bordered
            allowClear
            placeholder="Категория"
            onChange={(e) => {
              setPage(1);
              setTransactionsFilter({...transactionsFilter, ...{category: e}})
            }} style={{width: '300px'}}>
            {
              DriverTransactionCategories.map(e => <Option key={e.value} value={e.value}>{e.text}</Option>)
            }
          </Select>
          <Select
            bordered allowClear
            placeholder="Баланс"
            onChange={(e) => {
              setPage(1);
              setTransactionsFilter({...transactionsFilter, ...{balance: e}})
            }} style={{width: '200px'}}>
            {
              TemplatePaymentSource.map(e => <Option key={e.value} value={e.value}>{e.text}</Option>)
            }
          </Select>
          <RangePicker
            format="DD.MM.YYYY" style={{width: 350}}
            onChange={(val) => {
              setPage(1);
              setTransactionsFilter({...transactionsFilter, ...{period: val}});
            }}/>
        </Space>
      </div>
      <div style={{width: '10%', float: 'right' }}>
        <Space style={{float: 'right'}}>
          <UpdateButton onClick={() => setUpdate(prevState => prevState + 1)}></UpdateButton>
        </Space>
      </div>
      <div style={{margin: '10px 0'}}>
        <Table dataSource={records}
               columns={columns}
               size="small"
               rowKey="uid"
               scroll={{x: 1000}}
               sticky
               loading={loading}
               pagination={{
                 position: 'topRight',
                 pageSizeOptions: [25, 50, 75],
                 defaultPageSize: pageSize,
                 current: page,
                 total: total,
                 onChange: (page, pageSize) => {
                   setPage(page);
                   setPageSize(pageSize);
                 }
               }}
        />
      </div>
    </>
  )
}