import React, {useEffect, useState} from "react";
import {Button, message, Space, Table, Modal, PageHeader} from "antd";
import {EditFilled, QuestionOutlined} from "@ant-design/icons";

import moment from "moment";

import {apiClient} from "../../../services/ApiClient";
import CompetitionsEditDialog from "./components/CompetitionsEditDialog";
import CompetitionsAddDialog from "./components/CompetitionsAddDialog";

export default function CompetitionsPage() {
  let [loading, setLoading] = useState(false);
  let [dsLoading, setDsLoading] = useState(false);
  let [editedRecord, setEditedRecord] = useState(null);
  let [selectedRecords, setSelectedRecords] = useState([]);
  let [ds, setDs] = useState([]);
  let [update, setUpdate] = useState(0);
  let [competitionsAddDialogVisible, setCompetitionsAddDialogVisible] = useState(false);

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: "",
      width: 50,
      align: "center",
      dataIndex: "id",
      render: function(text, record, index) {
        return (
          <Space direction={"horizontal"}>
            <Button type="link" icon={<EditFilled/>} onClick={() => {setEditedRecord(record);}}/>
          </Space>
        );
      }
    },
    {
      title: 'Создано',
      dataIndex: 'create_dt',
      align: "center",
      render: (text, record, index) => {
        return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
      },
    },
    {
      title: 'Начало',
      dataIndex: 'from_dt',
      align: "center",
      render: (text, record, index) => {
        return `${moment(record.from_dt).format('DD.MM.YYYY HH:mm')}`;
      },
    },
    {
      title: 'Конец',
      dataIndex: 'to_dt',
      align: "center",
      render: (text, record, index) => {
        return `${moment(record.to_dt).format('DD.MM.YYYY HH:mm')}`;
      },
    },
    {
      title: 'Страница с результатами',
      dataIndex: 'id',
      render: (text, record, index) => {
        const url = `${process.env.REACT_APP_WEB_HOST}/competitions/${record.uid}`;
        return <a target="_blank" rel="noopener noreferrer" href={url}>{url}</a>;
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  const removeSelected = () => {
    Modal.confirm({
      title:  "Подтверждение",
      content: `Вы уверены, что хотите удалить ${selectedRecords.length} записей?`,
      icon: <QuestionOutlined/>,
      visible: true,
      onOk() {
        let proms = selectedRecords.map(el => apiClient.removeCompetition(el));

        setLoading(true);
        Promise.all(proms)
          .catch(err => {
            message.error("Ошибка, операция не удалась");
          })
          .finally(() => {
            setSelectedRecords([]);
            setLoading(false);
            setUpdate(prevState => prevState + 1);
          });
      }
    });
  };

  useEffect(() => {
    setDsLoading(true);
    apiClient.getCompetitions()
      .then(res => {
        setDs(res.records);
      })
      .catch(err => {
        message.error("Не удалось загрузить список конкурсов");
      })
      .finally(() => {
        setDsLoading(false);
      });
  }, [update]);

  const addCompetition = (from, to, drivers) => {
    setLoading(true);
    apiClient.addCompetition(from, to, drivers)
      .then(res => {
        message.info("Конкурс добавлен");
        setUpdate(prevState => prevState + 1);
      })
      .catch(err => {
        message.error("Не удалось загрузить список конкурсов");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateCompetition = editedRecord => {
    setLoading(true);
    apiClient.updateCompetition(editedRecord.id, editedRecord)
      .then(res => {
        message.info("Конкурс обновлен");
        setUpdate(prevState => prevState + 1);
      })
      .catch(err => {
        message.error("Не удалось загрузить список конкурсов");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Конкурсы"
      />
      <div>
        <span style={{marginLeft: 8}}>
          <span style={{float: "right"}}>
            {selectedRecords.length > 0 ? `Выбрано ${selectedRecords.length} записей` : ''}
          </span>
        </span>
      </div>
      <div>
        <Space style={{float: "right", marginBottom: 16}} direction="horizontal">
          <Button type="primary" onClick={() => { setCompetitionsAddDialogVisible(true) }} loading={loading}>
            Создать
          </Button>
          {
            selectedRecords.length > 0
              ? (<Button type={"danger"} onClick={() => {
                  removeSelected()
                }} loading={loading}>
                  Удалить
                </Button>
              ) : null
          }
        </Space>
      </div>
      <Table rowSelection={{ selectedRecords, onChange: (selectedRowKeys) => { setSelectedRecords(selectedRowKeys) } }}
             pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
             columns={cols}
             scroll={{x: 1000}}
             sticky
             size="small"
             dataSource={ds}
             loading={dsLoading}
             rowKey="id"/>

      <CompetitionsEditDialog visible={editedRecord !== null}
                              record={editedRecord}
                              onOk={(result) => {
                                updateCompetition(result);
                                setEditedRecord(null);
                              }}
                              onClose={() => {
                                setEditedRecord(null);
                              }}/>
      <CompetitionsAddDialog visible={competitionsAddDialogVisible}
                             onOk={(period, drivers) => {
                               addCompetition(period[0], period[1], drivers);
                               setCompetitionsAddDialogVisible(false);
                             }}
                             onClose={() => {
                               setCompetitionsAddDialogVisible(false);
                             }}/>
    </>
  )
}