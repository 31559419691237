import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../services/ApiClient";
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  message,
  PageHeader,
  Select,
  Space,
  Table,
  Popover,
  TimePicker, Popconfirm, Modal
} from "antd";
import {
  AccountBookOutlined, ClearOutlined, CloseCircleOutlined, CommentOutlined, DownOutlined,
  EditFilled, EditOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import moment from "moment";
import ServiceMaintenanceStatusEdit from "./ServiceMaintenanceStatusEdit";
import AssignMaintenanceDateDialog from "./AssignMaintenanceDateDialog";

import "./ServiceMaintenanceStatusPage.css"
import ServiceMaintenanceStatusSettingsDialog from "./ServiceMaintenanceStatusSettingsDialog";
import {ParkStatuses} from "../../../common/AppEnums";
import {windowService} from "../../../../services/WindowService";
import LinkButton from "../../../buttons/LinkButton";
import AssignMaintenanceStatusDialog from "./AssignMaintenanceStatusDialog";

const { Search, TextArea } = Input;
const { Option } = Select;

const CMD_ASSIGN_MAINTENANCE_DATE = "assignMaintenanceDate";
const CMD_CLEAR_WORKPLAN = "cleanWorkplan";
const CMD_SET_WORKPLAN = "setWorkplan";

export default function ServiceMaintenanceStatusPage() {

  let [update, setUpdate] = useState(0);
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [selected, setSelected] = useState(null);
  let [search, setSearch] = useState(null);
  let [regions, setRegions] = useState([]);
  let [vehicleGroups, setVehicleGroups] = useState([]);
  let [selectedRegionId, setSelectedRegionId] = useState([]);
  let [selectedGroupId, setSelectedGroupId] = useState([]);
  let [selectedStatus, setSelectedStatus] = useState(null);
  let [selectedMaintenanceDate, setSelectedMaintenanceDate] = useState(null);
  let [selectedRecords, setSelectedRecords] = useState([]);
  let [selectedRecordsOperation, setSelectedRecordsOperation] = useState(null);
  let [recordMaintenanceTimeToUpdate, setRecordMaintenanceTimeToUpdate] = useState(null);
  let [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    setLoading(true);
    apiClient.getServiceMaintenanceStatusList({
      regNumber: search,
      regionId: selectedRegionId,
      groupId: selectedGroupId,
      status: selectedStatus,
      maintenanceDate: selectedMaintenanceDate,
    })
      .then(res => {
        setRecords(res.records);
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [update, search, selectedStatus, selectedRegionId, selectedMaintenanceDate, selectedGroupId]);

  useEffect(() => {
    apiClient.getRegions({"order": "title"})
      .then(res => { setRegions(res.records) })
      .catch(err => { console.error(err) });
    apiClient.getVehicleGroups({"order": "title"})
      .then(res => { setVehicleGroups(res.records) })
      .catch(err => { console.error(err) });
  }, []);

  const executeSelectedRecordsOperation = operationKey => {
    if(operationKey === CMD_CLEAR_WORKPLAN) {
      Modal.confirm({
        title:  "Удаление",
        content: "Вы уверены, что хотите очистить описание тех.работ у выбранных записей?",
        icon: <ClearOutlined/>,
        visible: true,
        onOk() {
          apiClient.assignServiceMaintenanceStatus(selectedRecords, {workplan: null})
            .then(res => {
              message.info("Изменения сохранены");
            })
            .catch(err => {
              message.error(err);
            })
            .finally(() => {
              setUpdate(prevState => prevState + 1);
              setSelectedRecordsOperation(null);
              setSelectedRecords([]);
            });
        },
      });
    } else {
      setSelectedRecordsOperation({"key": operationKey});
    }
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Статус ТО"
        extra={
          <div style={{textAlign: "right"}}>
            <Space>
              <span>{`Количество авто: ${records.length}`}</span>
              <Button type={"link"} icon={<SettingOutlined/>}
                      onClick={() => {
                        setShowSettings(true);
                      }}>
                Настройки
              </Button>
              {
                selectedRecords.length > 0
                  ? (
                    <>
                      <Dropdown
                        disabled={selectedRecords.length < 1}
                        overlay={(
                          <Menu onClick={(e) => {
                            executeSelectedRecordsOperation(e.key);
                          }}>
                            <Menu.Item key={CMD_ASSIGN_MAINTENANCE_DATE} icon={<AccountBookOutlined/>}>
                              Назначить дату тех.работ
                            </Menu.Item>
                            <Menu.Item key={CMD_CLEAR_WORKPLAN} icon={<ClearOutlined/>}>
                              Очистить тех.работы
                            </Menu.Item>
                            <Menu.Item key={CMD_SET_WORKPLAN} icon={<EditOutlined/>}>
                              Добавить описание тех.работ
                            </Menu.Item>
                          </Menu>
                        )}>
                        <Button style={{textAlign: "left"}}>
                          С выбранными <DownOutlined/>
                        </Button>
                      </Dropdown>
                    </>
                  ) : null
              }
            </Space>
          </div>
        }
      />
      <Space direction="horizontal" align={{width: '100%'}} style={{marginBottom: 10}} wrap>
        <Search
          enterButton
          placeholder={"Поиск по гос.номеру"}
          onSearch={(value) => {
            setSearch(value)
          }}
          allowClear={true}
          style={{width: 400}}
        />
        <Select
          allowClear
          mode="multiple"
          placeholder="Регион"
          value={selectedRegionId}
          style={{width: 300}}
          onChange={(data) => { setSelectedRegionId(data); }}
        >
          {
            regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
        <Select
          showSearch
          allowSearch
          placeholder="Статус"
          value={selectedRegionId}
          style={{width: 300}}
          onChange={(data) => { setSelectedStatus(data); }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {
            ParkStatuses.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
        <DatePicker
          allowClear
          format="DD.MM.YYYY"
          placeholder="Дата тех.работ"
          value={selectedMaintenanceDate ? moment(selectedMaintenanceDate, "YYYY-MM-DD") : null}
          onChange={(val) => { setSelectedMaintenanceDate(val ? val.format("YYYY-MM-DD") : null) }}
          style={{width: 220}}

        />
        <Select
          allowClear
          placeholder="Группа"
          value={selectedGroupId}
          style={{width: 300}}
          onChange={(data) => { setSelectedGroupId(data); }}
        >
          {
            vehicleGroups.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
      </Space>
      <Table
        loading={loading}
        size={'small'}
        sticky
        scroll={{x: 2000}}
        dataSource={records}
        columns={[
          {
            title: "",
            align: "center",
            width: 10,
          },
          Table.SELECTION_COLUMN,
          {
            title: '',
            width: 50,
            render: (text, record, index) => {
              return (
                <Space direction={"horizontal"}>
                  <Button
                    type="link"
                    icon={<EditFilled/>}
                    disabled={!record?.id}
                    onClick={() => {
                      setSelected(record);
                    }}
                  />
                </Space>
              )
            }
          },
          {
            title: 'Номер ТС',
            align: "center",
            render: (text, record, index) => {
              return record.car && (
                <Button
                  type={"link"}
                  onClick={() => {
                    windowService.openRouteWindow(`cp/park/vehicles/${record.car?.id}`);
                  }}
                >
                  {
                    <>
                      <div>
                        {record.car?.reg_number}
                      </div>
                    </>
                  }
                </Button>
              );

            }
          },
          {
            title: 'Марка/Модель',
            align: "center",
            render: (text, record, index) => {
              return `${record.car?.brand} ${record.car?.model}`;
            }
          },
          {
            title: 'Статус ТС',
            align: "center",
            render: (text, record, index) => {
              return ParkStatuses.find(el => el.id === record.car?.park_status)?.title || record.car?.park_status;
            },
            sorter: (a, b) => {
              let aVal = a.car?.park_status || "";
              let bVal = b.car?.park_status || "";
              return aVal.localeCompare(bVal);
            },
          },
          {
            title: 'Пробег при получении ТС / предыдущем осмотре ТС',
            dataIndex: 'receipt_mileage',
            align: "center",
            sorter: (a, b) => a.receipt_mileage - b.receipt_mileage,
          },
          {
            title: 'Документация до',
            dataIndex: 'documents_due_dt',
            align: "center",
            render: (text, record, index) => {
              return record.documents_due_dt ? moment(record.documents_due_dt).format('DD.MM.YYYY'): "";
            },
            sorter: (a, b) => {
              let aVal = a.documents_due_dt ? moment(a.documents_due_dt).valueOf() : -1;
              let bVal = b.documents_due_dt ? moment(b.documents_due_dt).valueOf() : -1;
              return aVal - bVal;
            },
          },
          {
            title: 'Дата предыдущего осмотра',
            dataIndex: 'prev_maintenance_dt',
            align: "center",
            render: (text, record, index) => {
              return record.prev_maintenance_dt ? moment(record.prev_maintenance_dt).format('DD.MM.YYYY'): "";
            },
            sorter: (a, b) => {
              let aVal = a.prev_maintenance_dt ? moment(a.prev_maintenance_dt).valueOf() : -1;
              let bVal = b.prev_maintenance_dt ? moment(b.prev_maintenance_dt).valueOf() : -1;
              return aVal - bVal;
            },
          },
          {
            title: 'Число дней после осмотра',
            align: "center",
            render: (text, record, index) => {
              return record.prev_maintenance_dt ? moment().diff(moment(record.prev_maintenance_dt), 'days') : "";
            },
            sorter: (a, b) => {
              let aVal = a.prev_maintenance_dt ? moment().diff(moment(a.prev_maintenance_dt), 'days') : -1;
              let bVal = b.prev_maintenance_dt ? moment().diff(moment(b.prev_maintenance_dt), 'days') : -1;
              return aVal - bVal;
            },
          },
          {
            title: 'Интервал ТО',
            dataIndex: 'maintenance_interval',
            align: "center",
          },
          {
            title: 'Остаток пробега до ТО',
            align: "center",
            render: (text, record, index) => {
              if(!record.maintenance_interval || record.maintenance_interval < 1) {
                return "";
              }
              return record.maintenance_interval - (record.receipt_mileage - record.last_maintenance_mileage);
            },
            sorter: (a, b) => {
              let aVal = 0;
              let bVal = 0;
              if(a.maintenance_interval && a.maintenance_interval > 0) {
                aVal = a.maintenance_interval - (a.receipt_mileage - a.last_maintenance_mileage);
              }
              if(b.maintenance_interval && b.maintenance_interval > 0) {
                bVal = b.maintenance_interval - (b.receipt_mileage - b.last_maintenance_mileage);
              }
              return aVal - bVal;
            },
          },
          {
            title: 'Город',
            align: "center",
            render: (text, record, index) => {
              return record.region?.title;
            }
          },
          {
            title: 'Комментарий механика/водителя',
            dataIndex: 'comment',
            render: (text, record, index) => {
              return record.comment
                ? <div style={{textAlign: 'center'}}><Popover content={record.comment}><CommentOutlined/></Popover></div>
                : null;
            }
          },
          {
            title: 'Пробег при последнем ТО',
            dataIndex: 'last_maintenance_mileage',
            align: "center",
            sorter: (a, b) => a.last_maintenance_mileage - b.last_maintenance_mileage,
          },
          {
            title: 'Дата тех.работ',
            align: 'center',
            render: (text, record, index) => {
              return (record.planned_maintenance_dt
                  ? (
                    record.planned_maintenance_time
                      ? (
                        <div>
                          <Space size={"small"}>
                            <span>{moment(record.planned_maintenance_dt + " " + record.planned_maintenance_time).format('DD.MM.YYYY')}</span>
                            <Popconfirm
                              placement="topLeft"
                              title={"Удаление"}
                              onConfirm={() => {
                                apiClient.assignServiceMaintenanceTime([record.id], {
                                  dt: null,
                                  tm: null
                                })
                                  .then(res => {
                                    record.__planned_maintenance_time_edit = false;
                                    record.planned_maintenance_time = null;
                                    record.planned_maintenance_dt = null;
                                    setRecords(prevState => [...prevState]);
                                  })
                                  .catch(err => {
                                    message.warn(err);
                                  })
                              }}
                              okText="Да"
                              cancelText="Отмена"
                            >
                              <Button type={"link"} icon={<CloseCircleOutlined/>}/>
                            </Popconfirm>
                          </Space>
                          <div>
                            <Popover
                              content={(
                                <Space>
                                  <TimePicker
                                    style={{width: 160}}
                                    format={"HH:mm"}
                                    defaultValue={moment(record.planned_maintenance_dt + " " + record.planned_maintenance_time)}
                                    onChange={(val) => {
                                      setRecordMaintenanceTimeToUpdate(val ? val.format("HH:mm:ss") : null);
                                    }}
                                  />
                                  <Button onClick={() => {
                                    apiClient.assignServiceMaintenanceTime([record.id], {
                                      dt: record.planned_maintenance_dt,
                                      tm: recordMaintenanceTimeToUpdate,
                                    })
                                      .then(res => {
                                        record.__planned_maintenance_time_edit = false;
                                        record.planned_maintenance_time = recordMaintenanceTimeToUpdate;
                                        setRecords(prevState => [...prevState]);
                                      })
                                      .catch(err => {
                                        message.warn(err);
                                      });
                                  }}>OK</Button>
                                </Space>
                              )}
                              title="Время тех.работ"
                              trigger="click"
                              visible={record.__planned_maintenance_time_edit}
                              onVisibleChange={(newOpen) => {
                                record.__planned_maintenance_time_edit = newOpen;
                                setRecords(prevState => [...prevState]);
                              }}
                            >
                              <Button type={"link"}>{moment(record.planned_maintenance_dt + " " + record.planned_maintenance_time).format('HH:mm')}</Button>
                            </Popover>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div>
                            <Space size={"small"}>
                              <span>{moment(record.planned_maintenance_dt).format('DD.MM.YYYY')}</span>
                              <Popconfirm
                                placement="topLeft"
                                title={"Удаление"}
                                onConfirm={() => {
                                  apiClient.assignServiceMaintenanceTime([record.id], {
                                    dt: null,
                                    tm: null
                                  })
                                    .then(res => {
                                      record.__planned_maintenance_time_edit = false;
                                      record.planned_maintenance_time = null;
                                      record.planned_maintenance_dt = null;
                                      setRecords(prevState => [...prevState]);
                                    })
                                    .catch(err => {
                                      message.warn(err);
                                    })
                                }}
                                okText="Да"
                                cancelText="Отмена"
                              >
                                <Button type={"link"} icon={<CloseCircleOutlined/>}/>
                              </Popconfirm>
                            </Space>
                          </div>
                          <div>
                            <Popover
                              content={(
                                <Space>
                                  <TimePicker
                                    style={{width: 160}}
                                    format={"HH:mm"}
                                    onChange={(val) => {
                                      setRecordMaintenanceTimeToUpdate(val ? val.format("HH:mm:ss") : null);
                                    }}
                                  />
                                  <Button onClick={() => {
                                    apiClient.assignServiceMaintenanceTime([record.id], {
                                      dt: record.planned_maintenance_dt,
                                      tm: recordMaintenanceTimeToUpdate
                                    })
                                      .then(res => {
                                        record.__planned_maintenance_time_edit = false;
                                        record.planned_maintenance_time = recordMaintenanceTimeToUpdate;
                                        setRecords(prevState => [...prevState]);
                                      })
                                      .catch(err => {
                                        message.warn(err);
                                      });
                                  }}>OK</Button>
                                </Space>
                              )}
                              title="Время тех.работ"
                              trigger="click"
                              visible={record.__planned_maintenance_time_edit}
                              onVisibleChange={(newOpen) => {
                                record.__planned_maintenance_time_edit = newOpen;
                                setRecords(prevState => [...prevState]);
                              }}
                            >
                              <LinkButton label={"Добавить время"}/>
                            </Popover>
                          </div>
                        </div>
                      )
                  ) : ""
              );
            },
            sorter: (a, b) => {
              let aVal = a.planned_maintenance_dt ? moment(a.planned_maintenance_dt + " " + (a.planned_maintenance_time || "00:00:00")).valueOf() : -1;
              let bVal = b.planned_maintenance_dt ? moment(b.planned_maintenance_dt + " " + (b.planned_maintenance_time || "00:00:00")).valueOf() : -1;
              return aVal - bVal;
            },
          },
          {
            title: 'Планируемые технические работы',
            dataIndex: 'workplan',
            render: (text, record, index) => {
              const workplan = (record.workplan || "").trim();
              return record.__workplan_edit
                ? (
                  <Space direction={"vertical"} align={"end"}>
                    <TextArea
                      style={{width: '100%'}}
                      allowClear={true}
                      rows={5}
                      onChange={(e) => {
                        record.workplan = e.target.value;
                      }}
                      defaultValue={record.workplan}
                    />
                    <Space>
                      <Button size={"small"} onClick={() => {
                        delete record.__workplan_edit;
                        setRecords(prevState => [...prevState]);
                        apiClient.updateServiceMaintenanceStatus(record.id, {workplan: record.workplan})
                          .then(res => { })
                          .catch(err => {
                            message.error(err);
                          });
                      }}>OK</Button>
                      <Button size={"small"} onClick={() => {
                        delete record.__workplan_edit;
                        setRecords(prevState => [...prevState]);
                      }}>Отмена</Button>
                    </Space>
                  </Space>
                ) : (
                  workplan.length > 0
                    ? (
                      <div
                        onDoubleClick={() => {
                          record.__workplan_edit = true;
                          setRecords(prevState => [...prevState]);
                        }}
                      >{record.workplan || ""}</div>
                    ) : (
                      <div style={{textAlign: 'center'}}>
                        <Button type={"link"} icon={<PlusOutlined/>} onClick={() => {
                          record.__workplan_edit = true;
                          setRecords(prevState => [...prevState]);
                        }}/>
                      </div>
                    )
                )
            },
          },
          {
            title: "",
            align: "center",
            width: 20,
          }
        ]}
        rowKey="id"
        rowClassName={(record, index) => { return `bordered-row` }}
        pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75], hideOnSinglePage: true}}
        rowSelection={{
          columnWidth: '50px',
          type: 'checkbox',
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedRecords,
          onChange: (selectedRowKeys) => {
            setSelectedRecords(selectedRowKeys);
          }
        }}
      />
      {
        selected !== null
          ? (
            <ServiceMaintenanceStatusEdit
              visible={true}
              title={"Статус ТО"}
              maintenance={selected}
              onOk={(record) => {
                apiClient.updateServiceMaintenanceStatus(record.id, record)
                  .then(res => {
                    setSelected(null);
                    setUpdate(prev => prev + 1)
                  })
                  .catch(err => {
                    message.error(err);
                  })
              }}
              onCancel={() => { setSelected(null); }}
            />
          ) : null
      }
      {
        /* диалог выбора назначаемых условий работы */
        selectedRecordsOperation?.key === CMD_ASSIGN_MAINTENANCE_DATE
          ? (
            <AssignMaintenanceDateDialog
              selectedRecords={selectedRecords}
              visible={true}
              onOk={() => {
                setUpdate(prevState => prevState + 1);
                setSelectedRecordsOperation(null);
                setSelectedRecords([]);
              }}
              onCancel={() => {
                setSelectedRecordsOperation(null);
              }}
            />
          ) : null
      }
      {
        /* диалог выбора назначаемых условий работы */
        selectedRecordsOperation?.key === CMD_SET_WORKPLAN
          ? (
            <AssignMaintenanceStatusDialog
              selectedRecords={selectedRecords}
              visible={true}
              onOk={() => {
                setUpdate(prevState => prevState + 1);
                setSelectedRecordsOperation(null);
                setSelectedRecords([]);
              }}
              onCancel={() => {
                setSelectedRecordsOperation(null);
              }}
            />
          ) : null
      }
      {
        showSettings && (
            <ServiceMaintenanceStatusSettingsDialog
              onCancel={() => {
                setShowSettings(false);
              }}
              onOK={() => {
                setShowSettings(false);
              }}
            />
          )
      }
    </>
  )
}