import React, {useEffect, useState} from "react";
import {Button, Col, Input, message, Modal, Row, Select, List, Tabs, Tooltip} from "antd";
import {apiClient} from "../../../../services/ApiClient";
import {DeleteOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import '../../ModalStyle.css';

const { Option } = Select;
const { TabPane } = Tabs;

const rowStyle = {padding: '8px'};

export default function TenantDialog({visible, record, onOk, onCancel, title}) {

  let [tenant, setTenant] = useState({name: "", id: 0, scopes: []});
  let [scopes, setScopes] = useState([]);

  useEffect(() => {
    if (record) {
      setTenant(r => {
        return {
          ...r,
          ...record
        }
      });
    } else {
      setTenant({name: "", id: 0, scopes: []});
    }
    apiClient.getTenantScopes()
      .then(res => {
        setScopes(res.records);
      })
      .catch(err => message.error(err));
  }, [record]);

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-middle-window"
      style={{top: 10}}
      title={title || "Новая организация"}
      visible={visible}
      onOk={() => {
        if(!tenant.name) {
          message.warn("Укажите наименование");
          return;
        }
        onOk(tenant);
        setTenant({name: "", id: 0});
      }}
      onCancel={onCancel}
    >

      <Tabs type={"card"}>
        <TabPane tab="Общие" key="1">
          <Row gutter={[0, 16]} style={rowStyle}>
            <Col span={6}>Имя</Col>
            <Col span={18}>
              <Input
                allowClear={true}
                placeholder="Наименование организации"
                value={tenant.name}
                onChange={(e) => { setTenant({...tenant, name: e.target.value}) }}/>
            </Col>
          </Row>

          <Row gutter={[0, 16]} style={rowStyle}>
            <Col span={6}>Контактное лицо</Col>
            <Col span={18}>
              <Input
                allowClear={true}
                placeholder="Контактное лицо"
                value={tenant.contact}
                onChange={(e) => { setTenant({...tenant, contact: e.target.value}) }}/>
            </Col>
          </Row>

          <Row gutter={[0, 16]} style={rowStyle}>
            <Col span={6}>Телефон</Col>
            <Col span={18}>
              <Input
                allowClear={true}
                placeholder="Телефон"
                value={tenant.phone}
                onChange={(e) => { setTenant({...tenant, phone: e.target.value}) }}/>
            </Col>
          </Row>

          <Row gutter={[0, 16]} style={rowStyle}>
            <Col span={6}>Комментарий</Col>
            <Col span={18}>
              <Input
                allowClear={true}
                placeholder="Комментарий"
                value={tenant.comment}
                onChange={(e) => { setTenant({...tenant, comment: e.target.value}) }}/>
            </Col>
          </Row>

          <Row gutter={[0, 16]} style={rowStyle}>
            <Col span={6}>Модули</Col>
            <Col span={18}>
              <Select
                value={null}
                showSearch
                allowClear
                style={{width: '100%'}}
                placeholder="Выберите модуль для добавления"
                onChange={(value) => {
                  if(value && !tenant.scopes.find(el => el === value)) {
                    setTenant({...tenant, scopes: [...tenant.scopes, value]})
                  }
                }}
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {scopes.map(s => <Option key={s.id} value={s.code}>{s.title}</Option>)}
              </Select>
            </Col>
          </Row>

          <List
            bordered
            size="small"
            pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
            renderItem={item => (
              <>
                <div style={{padding: '5px 10px', lineHeight: '2'}}>
                  {scopes.find(el => el.code === item)?.title}
                  <Button
                    style={{float: 'right'}}
                    icon={<DeleteOutlined/>}
                    type={"link"}
                    onClick={() => {
                      setTenant({...tenant, scopes: tenant.scopes.filter(el => el !== item)})
                    }}
                  />
                </div>
              </>
            )}
            dataSource={tenant.scopes}
            rowKey="id"
          />
        </TabPane>
        <TabPane tab="ГдеМои" key="2">
          <Row gutter={[0, 16]} style={rowStyle}>
            <Col span={6}>
              Логин
              <Tooltip
                placement="top"
                title='Укажите логин сервиса "Где мои" для настройки интеграции.'
              >
                <QuestionCircleOutlined style={{margin: "5px"}}/>
              </Tooltip>
            </Col>
            <Col span={18}>
              <Input
                allowClear={true}
                value={tenant.navixy_login}
                onChange={(e) => { setTenant({...tenant, navixy_login: e.target.value}) }}/>
            </Col>
          </Row>
          <Row gutter={[0, 16]} style={rowStyle}>
            <Col span={6}>
              Пароль
              <Tooltip
                placement="top"
                title='Укажите пароль сервиса "Где мои" для настройки интеграции.'
              >
                <QuestionCircleOutlined style={{margin: "5px"}}/>
              </Tooltip>
            </Col>
            <Col span={18}>
              <Input
                type={"password"}
                allowClear={true}
                value={tenant.navixy_password}
                onChange={(e) => { setTenant({...tenant, navixy_password: e.target.value}) }}/>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Бот-водитель" key="3">
          <Row gutter={[0, 16]} style={rowStyle}>
            <Col md={6}>Аккаунт поддержки</Col>
            <Col md={18}>
              <Input
                allowClear={true}
                placeholder={"https://t.me/[chat]"}
                value={tenant.driverbot_support_link}
                onChange={(e) => { setTenant({...tenant, driverbot_support_link: e.target.value}) }}/>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </Modal>
  );}