import React, {useEffect, useState} from "react";
import {Space, Table, PageHeader, message} from "antd";
import UpdateButton from "../../buttons/UpdateButton";
import {apiClient} from "../../../services/ApiClient";
import "./VehiclesPage.css";
import MenuButton from "../../buttons/MenuButton";

export default function VehiclesClass({onClick}) {
  let [loading, setLoading] = useState(false);
  let [update, setUpdate] = useState(0);
  let [ownedCount, setOwnedCount] = useState(0);
  let [records, setRecords] = useState([]);

  useEffect(() => {
    setLoading(true);
    apiClient.getVehiclesClass()
      .then(res => {
        if(res.owned_count) {
          setRecords(res.records);
          setOwnedCount(res.owned_count);
        }
      })
      .catch(err => message.error(err))
      .finally(() => { setLoading(false); });
  }, [update]);

  

  return (
    <>
      <div>
        <Space style={{marginBottom: 16, float: "left"}} direction="horizontal">
          <MenuButton value={"Отсортировать по Классам"} statusBtn={true} onClick={onClick}/>
        </Space>
        <Space style={{marginBottom: 16, float: "right"}} direction="horizontal">
          <UpdateButton loading={loading} onClick={() => { setUpdate(u => u + 1) }}/>
        </Space>
      </div>
      <Table
        loading={loading}
        size={'small'}
        scroll={{x: 1000}}
        sticky
        columns={[
          {
            title: "",
            align: "center",
            width: 30,
          },
          {
            title: 'Город',
            width: 50,
          },
          {
            title: 'Класс',
            render: (text, record, index) => {
              return `${record.brand} ${record.model}`;
            },
            width: 80,
          },
          {
            title: 'Всего ТС',
          },
          {
            title: 'На линии',
          },
          {
            title: 'В парке',
          },
          {
            title: 'ДТП',
          },
          {
            title: 'На ремонте',
          },
          {
            title: 'Без лицензии',
          },
          {
            title: 'Подготовка',
            width: 100,
          },
          {
            title: 'Иное',
            width: 50,
          },
          {
            title: "",
            align: "center",
            width: 20,
          }
        ]}
        dataSource={records}
        rowKey="id"
        rowClassName={(index) => {
            let className =  index % 2 !== 0 ? "vehicle-activity-blocked" : "vehicle-activity-active";
            return className;
        }}
      />
    </>
  )
}