import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../services/ApiClient";
import {Button, message, PageHeader, Select, Space, Table} from "antd";

import './ServiceSummaryTypeStatusPage.css'
import ServiceSummaryTypeStatusDetailsDialog from "./ServiceSummaryTypeStatusDetailsDialog";

const { Option } = Select;

export default function ServiceSummaryModelStatusPage() {
  let [update, setUpdate] = useState(0);
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [regions, setRegions] = useState([]);
  let [selectedRegionId, setSelectedRegionId] = useState(null);
  let [cellDetails, setCellDetails] = useState(null);
  let [vehicleGroups, setVehicleGroups] = useState([]);
  let [selectedGroupId, setSelectedGroupId] = useState(null);

  useEffect(() => {
    setLoading(true);
    apiClient.getServiceSummaryModelStatus({regionId: selectedRegionId, groupId: selectedGroupId})
      .then(res => {
        setRecords(res.records);
      })
      .catch(err => { message.error(err); })
      .finally(() => { setLoading(false); })
  }, [update, selectedRegionId, selectedGroupId]);

  useEffect(() => {
    apiClient.getRegions({"order": "title"})
      .then(res => {
        setRegions(res.records);
      })
      .catch(err => { console.error(err) });
    apiClient.getVehicleGroups({"order": "title"})
      .then(res => { setVehicleGroups(res.records) })
      .catch(err => { console.error(err) });
  }, []);

  const showDetails = (brand, model, colName) => {
    setLoading(true);
    apiClient.getServiceSummaryModelStatusDetails(brand, model, colName, { regionId: selectedRegionId })
      .then(res => {
        setCellDetails(res.records);
      })
      .catch(err => { message.error(err); })
      .finally(() => { setLoading(false); })
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Статусы по моделям ТС"
        extra={
          <div style={{textAlign: "right"}}>
            <Space>
              <span>{`Моделей ТС: ${records.length}`}</span>
            </Space>
          </div>
        }
      />
      <Space style={{margin: '10px 0'}}>
        <Select
          allowClear
          placeholder="Регион"
          value={selectedRegionId}
          style={{width: 300}}
          onChange={(data) => { setSelectedRegionId(data); }}
        >
          {
            regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
        <Select
          allowClear
          placeholder="Группа"
          value={selectedGroupId}
          style={{width: 300}}
          onChange={(data) => { setSelectedGroupId(data); }}
        >
          {
            vehicleGroups.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
      </Space>
      <Table
        loading={loading}
        size={'small'}
        scroll={{x: 1000}}
        sticky
        columns={[
          {
            title: "",
            align: "center",
            width: 10,
          },
          {
            title: 'Модель ТС',
            dataIndex: 'brand_model',
            align: "center",
          },
          {
            title: 'Всего',
            dataIndex: 'total',
            align: "center",
            render: (text, record, index) => {
              return (
                record.total > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'total') }}>{record.total}</Button>
                  : <div style={{textAlign: 'center'}}>{record.total}</div>
              )
            },
            sorter: (a, b) => {
              return a.total - b.total;
            },
          },
          {
            title: 'На линии',
            dataIndex: 'working',
            align: "center",
            render: (text, record, index) => {
              return (
                record.working > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'working') }}>{record.working}</Button>
                  : <div style={{textAlign: 'center'}}>{record.working}</div>
              )
            },
            sorter: (a, b) => {
              return a.working - b.working;
            },
          },
          {
            title: 'В парке',
            dataIndex: 'parked',
            align: "center",
            render: (text, record, index) => {
              return (
                record.parked > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'parked') }}>{record.parked}</Button>
                  : <div style={{textAlign: 'center'}}>{record.parked}</div>
              )
            },
            sorter: (a, b) => {
              return a.parked - b.parked;
            },
          },
          {
            title: 'ДТП',
            dataIndex: 'accident',
            align: "center",
            render: (text, record, index) => {
              return (
                record.accident > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'accident') }}>{record.accident}</Button>
                  : <div style={{textAlign: 'center'}}>{record.accident}</div>
              )
            },
            sorter: (a, b) => {
              return a.accident - b.accident;
            },
          },
          {
            title: 'На ремонте',
            dataIndex: 'repair',
            align: "center",
            render: (text, record, index) => {
              return (
                record.repair > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'repair') }}>{record.repair}</Button>
                  : <div style={{textAlign: 'center'}}>{record.repair}</div>
              )
            },
            sorter: (a, b) => {
              return a.repair - b.repair;
            },
          },
          {
            title: 'Без лицензии',
            dataIndex: 'licenseless',
            align: "center",
            render: (text, record, index) => {
              return (
                record.licenseless > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'licenseless') }}>{record.licenseless}</Button>
                  : <div style={{textAlign: 'center'}}>{record.licenseless}</div>
              )
            },
            sorter: (a, b) => {
              return a.licenseless - b.licenseless;
            },
          },
          {
            title: 'Подготовка',
            dataIndex: 'preparing',
            align: "center",
            render: (text, record, index) => {
              return (
                record.preparing > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'preparing') }}>{record.preparing}</Button>
                  : <div style={{textAlign: 'center'}}>{record.preparing}</div>
              )
            },
            sorter: (a, b) => {
              return a.preparing - b.preparing;
            },
          },
          {
            title: 'Иное',
            dataIndex: 'other',
            align: "center",
            render: (text, record, index) => {
              return (
                record.other > 0
                  ? <Button type={"link"} style={{width: '100%', textAlign: 'center'}} onClick={() => { showDetails(record.brand, record.model, 'other') }}>{record.other}</Button>
                  : <div style={{textAlign: 'center'}}>{record.other}</div>
              )
            },
            sorter: (a, b) => {
              return a.other - b.other;
            },
          },
          {
            title: "",
            align: "center",
            width: 10,
          }
        ]}
        dataSource={records}
        rowKey="brand_model"
        rowClassName={(record, index) => { return `bordered-row` }}
        pagination={{position: 'topRight', defaultPageSize: 100, pageSizeOptions: [100, 150, 200], hideOnSinglePage: true}}
        summary={(pageData) => {
          let totalTotal = 0;
          let totalWorking = 0;
          let totalParked = 0;
          let totalAccident = 0;
          let totalRepair = 0;
          let totalLicenseless = 0;
          let totalPreparing = 0;
          let totalOther = 0;
          pageData.forEach(({ brand_model, total, working, parked, accident, repair, licenseless, preparing, other }) => {
            totalTotal += total;
            totalWorking += working;
            totalParked += parked;
            totalAccident += accident;
            totalRepair += repair;
            totalLicenseless += licenseless;
            totalPreparing += preparing;
            totalOther += other;
          });
          return loading ? null : (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}><div style={{textAlign: 'center'}}>{totalTotal}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={3}><div style={{textAlign: 'center'}}>{totalWorking}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={4}><div style={{textAlign: 'center'}}>{totalParked}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={5}><div style={{textAlign: 'center'}}>{totalAccident}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={6}><div style={{textAlign: 'center'}}>{totalRepair}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={7}><div style={{textAlign: 'center'}}>{totalLicenseless}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={8}><div style={{textAlign: 'center'}}>{totalPreparing}</div></Table.Summary.Cell>
                <Table.Summary.Cell index={9}><div style={{textAlign: 'center'}}>{totalOther}</div></Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      {
        cellDetails && (
          <ServiceSummaryTypeStatusDetailsDialog
            data={cellDetails}
            onClose={() => { setCellDetails(null); }}
          />
        )
      }
    </>
  )
}