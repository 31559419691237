import React, {useEffect, useState} from "react";
import {
  Col,
  Collapse,
  DatePicker,
  Input,
  Select,
  Modal,
  Row,
  Checkbox,
  message,
  Space,
  Button,
  Table,
  Upload,
  Image,
  AutoComplete,
} from "antd";
import "../ModalStyle.css";
import {
  DeleteOutlined,
  EyeOutlined,
  DownloadOutlined,
  UploadOutlined,
  QuestionOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import moment from "moment";
import numeral from "numeral";
import { apiClient } from "../../../services/ApiClient";
import VehiclesList from "./components/VehiclesList";
import TransactionTab from "./components/TransactionTab";
import {daDataService} from "../../../services/DaDataService";
const rowStyle = {padding: '4px'};
const { Panel } = Collapse;
const { TextArea } = Input;
const { Option } = Select;
const inputErrorStyle = {borderColor: 'darkred', borderWidth: '1px'};

const DEFAULT_SECTIONS_STATE = {
  "OUR_DRIVER_CULPRIT": {
    inspection_place: "",
    inspection_address: "",
  },
  "ANOTHER_DRIVER_WITH_POLICY_CULPRIT": {
    inspection_place: "",
    inspection_address: "",
  },
  "ANOTHER_DRIVER_WITHOUT_POLICY_CULPRIT": {
    inspection_place: "",
    inspection_address: "",
  },
};

export default function DtpDialog({visible, record, onOk, onCancel, type, title}) {
  let [dtp, setDtp] = useState(null);
  let [status, setStatus] = useState("");
  let [error, setError] = useState([]);
  let [filtersList, setFiltersList] = useState([]);
  let [files, setFiles] = useState([]);
  let [imageFiles, setImageFiles] = useState([]);
  let [transaction, setTransaction] = useState([]);
  let [filesUpdate, setFilesUpdate] = useState(0);
  let [imagePreviewVisible, setImagePreviewVisible] = useState(false);
  let [suggestions, setSuggestions] = useState({});
  let [filesLoading, setFilesLoading] = useState(false);
  let [dictionariesLoading, setDictionariesLoading] = useState(false);
  let [transactionLoading, setTransactionLoading] = useState(false);
  let [transactionsUpdate, setTransactionsUpdate] = useState(0);
  let [drivers, setDrivers] = useState([]);
  let [vehicles, setVehicles] = useState([]);
  let [activePanelKey, setActivePanelKey] = useState(["DEFAULT"]);
  let [sectionData, setSectionData] = useState({...DEFAULT_SECTIONS_STATE});

  useEffect(() => {
    if(visible) {
      console.debug("[DtpDialog][Init]", type, record);
      if(record && type === "application") {
        if(record.car) {
          setVehicles([record.car]);
        }
        if(record.car?.drivers) {
          setDrivers(record.car.drivers);
        } else if(record.driver) {
          setDrivers([record.driver])
        }
        setStatus(record.culprit_type);
        setDtp({
          id: record.id,
          status: "NEW",
          car: record.car,
          car_id: record.car?.id,
          accident_date: record.accident_date,
          creation_dt: moment(),
          driver: record.driver,
          taxi_driver_id: record.driver?.id,
          osago_active: false,
          osago: false,
          accident_request_id: record.accident_request?.id,
          culprit_type: record.culprit_type,
        });
      } else if(record && type === "dtp") {
        setStatus(record.culprit_type);
        if(record.driver) {
          setDrivers([record.driver]);
        }
        if(record.car) {
          setVehicles([record.car]);
        }
        setSectionData(prevState => {
          let state = { ...prevState };
          state[record.culprit_type] = {
            inspection_place: record.inspection_place,
            inspection_address: record.inspection_address,
          };
          return state;
        });
        setDtp(r => {
          return {
            ...r,
            ...record
          }
        });
      } else if (record && type === "new") {
        setStatus(record.culprit_type);
        setDtp({...dtp,
          id: record.id,
          status: "NEW",
          culprit_type: record.culprit_type,
          osago: false,
          osago_active: false,
          creation_dt: moment().format("YYYY-MM-DDTHH:mm:ss"),
          accident_date: moment().format("YYYY-MM-DDTHH:mm:ss")
        })
      }
    }
  }, [visible]);

  useEffect(() => {
    setDictionariesLoading(true);
    apiClient.getListsForDtp()
      .then(res => {
        console.log("[DtpDialog] dictionaries loaded", res);
        setFiltersList({...filtersList, ...{
          culprit_type: res.accident_types,
          debt_status: res.debt_status,
          inspection_place: res.inspection_place,
          registration_type: res.registration_type,
          accident_statuses: res.accident_statuses
        }});
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setDictionariesLoading(false);
      });
    apiClient.getVehicles({owned: true}, "minimal")
      .then(res => {
        setVehicles(res.records);
      })
  }, []);

  useEffect(() => {
    if(dtp?.id) {
      setFilesLoading(true);
      apiClient.getFiles(dtp.id)
        .then(res => {
          console.debug("[DtpDialog] files loaded", res);
          setFiles(res.records);
          const images = res.records.filter(el => el.photo_file.content_type && el.photo_file.content_type.startsWith("image/"));
          setImageFiles(images);
        })
        .catch(err => { console.error(err) })
        .finally(() => {
          setFilesLoading(false);
        })
    } else {
      setFiles([]);
      setImageFiles([]);
    }
  }, [dtp, filesUpdate]);

  useEffect(() => {
    if(dtp?.id) {
      setTransactionLoading(true);
      apiClient.getTransactions(dtp.id)
        .then(res => {
          console.debug("[DtpDialog] transactions loaded", res);
          setTransaction(res.records);
        })
        .catch(err => { console.error(err) })
        .finally(() => {
          setTransactionLoading(false);
        })
    } else {
      setTransaction([]);
    }

  }, [dtp, transactionsUpdate]);

  useEffect(() => {
    console.debug("[DtpDialog] dtp changed: ", dtp);
  }, [dtp]);

  const createTransaction = (data) => {
    let params = {
      insurance: data?.insurance || "",
      amount: data.amount,
      transaction_type: data.transaction_type,
      date: moment(data.date).format("YYYY-MM-DDTHH:mm:ss"),
      comment: data.comment
    };
    apiClient.createTransaction(dtp.id, params)
      .then(res => {
        message.success("Транзакция добавлена");
        setTransactionsUpdate(prevState => prevState + 1);
        apiClient.getDtp(dtp.id)
          .then((res) => {
            setDtp({...dtp, ...{
                driver_debt: res.record.driver_debt,
                repair_amount: res.record.repair_amount,
                current_paid: res.record.current_paid,
                debt_status: res.record.debt_status,
              }});
          })
          .catch(err => console.log(err));

      })
      .catch(err => message.error(err));
  };

  const handleVehicleChange = (data) => {
    if(data) {
      console.debug(data);
      setDrivers(data.drivers);
      setDtp({...dtp, ...{
          car: data,
          car_id: data.id,
          work_region_id: data.work_region  ? data.work_region : "Неизвестен"
      }});
    }
  };

  const hasAccidentRequest = () => {
    return dtp?.accident_request && dtp?.saved
  };

  const downloadFile = file => {
    window.open(file.photo_file.fileurl, '_blank');
  };

  const deleteFile = fileId => {
    Modal.confirm({
      title:  "Подтверждение",
      content: `Вы уверены, что хотите удалить файл?`,
      icon: <QuestionOutlined/>,
      visible: true,
      onOk() {
        setFilesLoading(true);
        apiClient.removeFile(fileId)
          .then(res => {
            setFilesUpdate(prevState => prevState + 1);
          })
          .catch(err => message.error(err))
          .finally(() => {
            setFilesLoading(false);
          });
      }
    });
  };

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-max-window"
      style={{top: 10}}
      title={title || "Изменить ДТП"}
      visible={visible}
      okText="Сохранить"
      cancelText="Отмена"
      onOk={() => {
        console.debug(dtp);
        if(!dtp.car_id) {
          message.warn("Укажите ТС!");
          return;
        }
        if(!dtp.accident_date) {
          setError({...error, ...{name: "accident_date"}});
          message.warn("Укажите дату ДТП!");
          return;
        }
        if(!dtp.culprit_type) {
          message.warn("Укажите виновника ДТП!");
          return;
        }
        if(!dtp.third_party_driver_reg_number) {
          setError({...error, ...{name: "third_party_driver_reg_number"}});
          message.warn("Укажите гос. номер авто второго участника ДТП!");
          return;
        }
        if(!dtp.registration_type) {
          message.warn("Укажите тип оформления!");
          return;
        }
        //
        dtp.inspection_place = sectionData[dtp.culprit_type]?.inspection_place;
        dtp.inspection_address = sectionData[dtp.culprit_type]?.inspection_address;
        //
        onOk(dtp);

        setDtp(null);
        setSectionData({...DEFAULT_SECTIONS_STATE});
        setError([]);
        setStatus("");
      }}
      onCancel={() => {
        onCancel();

        setDtp(null);
        setSectionData({...DEFAULT_SECTIONS_STATE});
        setError([]);
        setStatus("");
      }}
      >
      {
        dictionariesLoading ? <div style={{margin: '20px auto', width: '100%', textAlign: 'center', fontSize: '2em'}}>
          <LoadingOutlined/>
        </div> : <>
          <Collapse activeKey={activePanelKey} onChange={(key => {
            setActivePanelKey(key)
          })}>
            <Panel header="Общие" key="DEFAULT">
              <Row gutter={[0, 16]} style={rowStyle}>
                <Col span={6}>Статус ДТП</Col>
                <Col md={18}>
                  <Select
                    style={{width: 400}}
                    allowClear
                    placeholder="Статус ДТП"
                    value={dtp?.status}
                    onChange={(value) => {
                      setDtp({...dtp, ...{status: value}});
                    }}
                  >
                    {
                      filtersList.accident_statuses ? filtersList.accident_statuses.map(el => <Option key={el[0]}
                                                                                                      value={el[0]}>{`${el[1]}`}</Option>) : []
                    }
                  </Select>
                </Col>
              </Row>
              <Row gutter={[0, 16]} style={rowStyle}>
                <Col span={6}>ТС</Col>
                <Col md={18}>
                  {dtp?.car ? (
                      <Select
                        showSearch
                        allowClear
                        disabled={hasAccidentRequest()}
                        value={dtp?.car?.id}
                        placeholder="ТС"
                        style={{width: 400}}
                        onChange={(value) => {
                          if (value) {
                            const vehicle = vehicles.find(el => el.id === value);
                            setDtp({
                              ...dtp, ...{
                                car: vehicle,
                                car_id: vehicle.id,
                                work_region_id: vehicle.work_region ? vehicle.work_region : "Неизвестен"
                              }
                            });
                          } else {
                            handleVehicleChange(null);
                            setDtp({
                              ...dtp, ...{
                                car: null,
                                car_id: null,
                                work_region_id: null
                              }
                            });
                          }
                        }}
                        filterOption={(input, option) => {
                          return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                      >
                        {
                          vehicles.map(el => <Option key={el.id} value={el.id}>{`${el.model} ${el.reg_number}`}</Option>)
                        }
                      </Select>
                    ) :
                    (
                      <VehiclesList
                        status="1"
                        onSelect={(record) => {
                          handleVehicleChange(record);
                        }}/>
                    )
                  }
                </Col>
              </Row>
              <Row gutter={[0, 16]} style={rowStyle}>
                <Col span={6}>Водитель</Col>
                <Col md={18}>
                  <Select
                    showSearch
                    allowClear
                    disabled={hasAccidentRequest()}
                    style={{width: 400}}
                    value={dtp?.driver?.id}
                    onChange={(value) => {
                      if (value) {
                        setDtp({...dtp, ...{taxi_driver_id: value}});
                      } else {
                        setDtp({...dtp, ...{taxi_driver_id: null}});
                      }
                    }}
                    filterOption={(input, option) => {
                      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                  >
                    {
                      drivers.map(el => <Option key={el.id} value={el.id}>{el.display_name || el.name}</Option>)
                    }
                  </Select>
                </Col>
              </Row>
              <Row gutter={[0, 16]} style={rowStyle}>
                <Col span={6}>Дата ДТП</Col>
                <Col md={18}>
                  <DatePicker
                    style={error.name === "accident_date" ? {...inputErrorStyle, ...{width: 400}} : {width: 400}}
                    allowClear={true}
                    placeholder="Дата ДТП"
                    showTime
                    disabled={hasAccidentRequest()}
                    defaultValue={dtp ? moment(dtp.accident_date) : moment(new Date())}
                    onChange={(dates, dateStrings) => {
                      setDtp({...dtp, ...{accident_date: moment(dateStrings).format("YYYY-MM-DDTHH:mm:ss")}});
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={[0, 16]} style={rowStyle}>
                <Col span={6}>Виновник ДТП</Col>
                <Col md={18}>
                  <Select
                    style={{width: 400}}
                    allowClear
                    placeholder="Виновник ДТП"
                    value={dtp ? dtp.culprit_type : ""}
                    onChange={(value) => {
                      setDtp({...dtp, ...{culprit_type: value}});
                      setStatus(value);
                      setActivePanelKey(["DEFAULT", value]);
                    }}
                  >
                    {
                      filtersList.culprit_type ? filtersList.culprit_type.map(el => <Option key={el[0]}
                                                                                            value={el[0]}>{`${el[1]}`}</Option>) : []
                    }
                  </Select>
                </Col>
              </Row>
              <Row gutter={[0, 16]} style={rowStyle}>
                <Col span={6}>Гос. номер авто второго участника ДТП</Col>
                <Col md={18}>
                  <Input
                    style={error.name === "third_party_driver_reg_number" ? {...inputErrorStyle, ...{width: 400}} : {width: 400}}
                    allowClear={true}
                    placeholder="Гос. номер авто второго участника ДТП"
                    value={dtp ? dtp.third_party_driver_reg_number : ""}
                    onChange={(e) => {
                      setDtp({...dtp, ...{third_party_driver_reg_number: e.target.value}});
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={[0, 16]} style={rowStyle}>
                <Col span={6}>Тип оформления</Col>
                <Col md={18}>
                  <Select
                    allowClear
                    placeholder="Тип оформления"
                    value={dtp ? dtp.registration_type : ""}
                    style={{width: 400}}
                    onChange={(value) => {
                      setDtp({...dtp, ...{registration_type: value}});
                    }}
                  >
                    {
                      filtersList.registration_type ? filtersList.registration_type.map(el => <Option key={el[0]}
                                                                                                      value={el[0]}>{`${el[1]}`}</Option>) : []
                    }
                  </Select>
                </Col>
              </Row>
              <Row gutter={[0, 16]} style={rowStyle}>
                <Col span={6}>Долг за ДТП</Col>
                <Col md={18}>
                  <Input
                    style={error.name === "driver_debt" ? {...inputErrorStyle, ...{width: 400}} : {width: 400}}
                    placeholder="Долг за ДТП"
                    disabled={true}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={dtp?.driver_debt}
                  />
                </Col>
              </Row>
              <Row gutter={[0, 16]} style={rowStyle}>
                <Col span={6}>Сумма выплаты виновником</Col>
                <Col md={18}>
                  <Input
                    style={error.name === "current_paid" ? {...inputErrorStyle, ...{width: 400}} : {width: 400}}
                    disabled={true}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Сумма выплаты виновником"
                    value={dtp?.current_paid}
                  />
                </Col>
              </Row>
              <Row gutter={[0, 16]} style={rowStyle}>
                <Col span={6}>Остаток долга за ДТП</Col>
                <Col md={18}>
                  <Input
                    style={{width: 400}}
                    disabled={true}
                    placeholder="Остаток долга за ДТП"
                    value={dtp?.current_paid && dtp?.driver_debt ? numeral(parseFloat(dtp?.driver_debt) - parseFloat(dtp?.current_paid)).format("0.00") : ""}
                    onChange={(e) => {
                      setDtp({...dtp, ...{repair_amount: e.target.value}});
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={[0, 16]} style={rowStyle}>
                <Col span={6}>Сумма расходов за ремонт</Col>
                <Col md={18}>
                  <Input
                    style={error.name === "repair_amount" ? {...inputErrorStyle, ...{width: "350px"}} : {width: 400}}
                    allowClear={true}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Сумма расходов за ремонт"
                    value={dtp ? dtp.repair_amount : ""}
                    onChange={(e) => {
                      setDtp({...dtp, ...{repair_amount: e.target.value}});
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={[0, 16]} style={rowStyle}>
                <Col span={6}>Статус долга</Col>
                <Col md={18}>
                  <Select
                    allowClear
                    placeholder="Статус долга"
                    value={dtp ? dtp.debt_status : ""}
                    style={{width: 400}}
                    onChange={(value) => {
                      setDtp({...dtp, ...{debt_status: value}});
                    }}
                  >
                    {
                      filtersList.debt_status ? filtersList.debt_status.map(el => <Option key={el[0]}
                                                                                          value={el[0]}>{`${el[1]}`}</Option>) : []
                    }
                  </Select>
                </Col>
              </Row>
              <Row gutter={[0, 16]} style={rowStyle}>
                <Col span={6}>Комментарий</Col>
                <Col md={18}>
                  <TextArea
                    allowClear={true}
                    value={dtp ? dtp.comment : ""}
                    onChange={(e) => {
                      setDtp({...dtp, ...{comment: e.target.value}});
                    }}
                  />
                </Col>
              </Row>
              <Collapse style={{margin: '5px 0'}}>
                <Panel header="Фотографии и документы" key="1">
                  <>
                    <div>
                      <Space style={{float: "right", marginBottom: 16}} direction="horizontal">
                        <Upload
                          name={"file"}
                          action={apiClient.actionUrl(`roadAccident/accidentFiles/${dtp?.id}`)}
                          headers={apiClient.requestConfig().headers}
                          multiple={true}
                          showUploadList={false}
                          onChange={(info) => {
                            if (info.file.status !== 'uploading') {
                              console.log(info.file, info.fileList);
                            }
                            if (info.file.status === 'done') {
                              message.success(`Файл загружен`);
                              setFilesUpdate(prevState => prevState + 1);
                            } else if (info.file.status === 'error') {
                              message.error(`Ошибка`);
                              setFilesUpdate(prevState => prevState + 1);
                            }
                          }}
                        >
                          <Button type="primary" icon={<UploadOutlined/>}>Добавить</Button>
                        </Upload>
                      </Space>
                    </div>
                    <Table
                      size={'small'}
                      scroll={{x: 1000}}
                      sticky
                      loading={filesLoading}
                      columns={[
                        {
                          title: "",
                          align: "center",
                          width: 30,
                        },
                        {
                          title: "",
                          width: 150,
                          render: (text, record, index) => {
                            return (
                              <Space direction={"horizontal"}>
                                {
                                  record.photo_file.content_type && record.photo_file.content_type.startsWith("image/")
                                    ? (
                                      <Button type="link" onClick={() => {
                                        setImagePreviewVisible(true)
                                      }}><EyeOutlined/></Button>
                                    ) : null
                                }
                                <Button type="link" onClick={() => {
                                  downloadFile(record)
                                }}><DownloadOutlined/></Button>
                                <Button type="link" onClick={() => {
                                  deleteFile(record.id)
                                }}><DeleteOutlined/></Button>
                              </Space>
                            )
                          }
                        },
                        {
                          title: 'Имя файла',
                          render: (text, record, index) => {
                            return record.photo_file.filename;
                          }
                        },
                        {
                          title: "",
                          align: "center",
                          width: 20,
                        }
                      ]}
                      dataSource={files}
                      rowKey="id"
                    />
                    {
                      imagePreviewVisible
                        ? (
                          <Modal
                            title="Изображения"
                            width={'80%'}
                            visible={true}
                            destroyOnClose={true}
                            onOk={() => setImagePreviewVisible(false)}
                            onCancel={() => setImagePreviewVisible(false)}
                          >
                            <div style={{textAlign: 'center'}}>
                              <Image.PreviewGroup>
                                {
                                  imageFiles.map(photo => (
                                    <div style={{padding: '10px 10px', display: 'inline-block'}} key={photo.id}>
                                      <Image title={photo.photo_file.filename} width={200}
                                             src={photo.photo_file.fileurl}/>
                                    </div>
                                  ))
                                }
                              </Image.PreviewGroup>
                            </div>
                          </Modal>
                        ) : null
                    }
                  </>
                </Panel>
              </Collapse>
            </Panel>
            <Panel header="Виновник наш водитель" key="OUR_DRIVER_CULPRIT"
                   collapsible={status !== "OUR_DRIVER_CULPRIT" ? "disabled" : "header"}>
              {
                status === "OUR_DRIVER_CULPRIT" && (
                  <>
                    <Row gutter={[0, 16]} style={rowStyle}>
                      <Col span={6}>Место осмотра ТС</Col>
                      <Col md={18}>
                        <Select
                          allowClear
                          placeholder="Место осмотра ТС"
                          value={sectionData["OUR_DRIVER_CULPRIT"].inspection_place}
                          style={{width: 350}}
                          onChange={(value) => {
                            setSectionData(prevState => {
                              let state = { ...prevState };
                              state["OUR_DRIVER_CULPRIT"].inspection_place = value;
                              return state;
                            });
                          }}
                        >
                          {
                            filtersList.inspection_place ? filtersList.inspection_place.map(el => <Option key={el[0]}
                                                                                                          value={el[0]}>{`${el[1]}`}</Option>) : []
                          }
                        </Select>
                      </Col>
                    </Row>
                    <Row gutter={[0, 16]} style={rowStyle}>
                      <Col span={6}>Адрес места осмотра ТС</Col>
                      <Col span={18}>
                        <AutoComplete
                          allowClear={true}
                          placeholder="Адрес места осмотра ТС"
                          options={suggestions.inspection_address || []}
                          value={sectionData["OUR_DRIVER_CULPRIT"].inspection_address}
                          style={{width: '350px'}}
                          onChange={(data) => {
                            setSectionData(prevState => {
                              let state = { ...prevState };
                              state["OUR_DRIVER_CULPRIT"].inspection_address = data;
                              return state;
                            });
                            setSuggestions({...suggestions, inspection_address: []});
                          }}
                          onSearch={(text) => {
                            if (text && text.length > 2 && !text.endsWith(" ")) {
                              daDataService.suggestAddress(text)
                                .then(res => {
                                  setSuggestions({
                                    ...suggestions, inspection_address: res.suggestions.map(el => {
                                      return {value: el.value}
                                    })
                                  });
                                })
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )
              }
            </Panel>
            <Panel header="Виновник второй водитель (полис действителен)" key="ANOTHER_DRIVER_WITH_POLICY_CULPRIT"
                   collapsible={status !== "ANOTHER_DRIVER_WITH_POLICY_CULPRIT" ? "disabled" : "header"}>
              {
                status === "ANOTHER_DRIVER_WITH_POLICY_CULPRIT" && (
                  <>
                    <Row gutter={[0, 16]} style={rowStyle}>
                      <Col span={6}>Место осмотра ТС</Col>
                      <Col md={18}>
                        <Select
                          allowClear
                          placeholder="Место осмотра ТС"
                          value={sectionData["ANOTHER_DRIVER_WITH_POLICY_CULPRIT"].inspection_place}
                          style={{width: 350}}
                          onChange={(value) => {
                            setSectionData(prevState => {
                              let state = { ...prevState };
                              state["ANOTHER_DRIVER_WITH_POLICY_CULPRIT"].inspection_place = value;
                              return state;
                            });
                          }}
                        >
                          {
                            filtersList.inspection_place ? filtersList.inspection_place.map(el => <Option key={el[0]}
                                                                                                          value={el[0]}>{`${el[1]}`}</Option>) : []
                          }
                        </Select>
                      </Col>
                    </Row>
                    <Row gutter={[0, 16]} style={rowStyle}>
                      <Col span={6}>Адрес места осмотра ТС</Col>
                      <Col span={18}>
                        <AutoComplete
                          allowClear={true}
                          placeholder="Адрес места осмотра ТС"
                          options={suggestions.inspection_address || []}
                          value={sectionData["ANOTHER_DRIVER_WITH_POLICY_CULPRIT"].inspection_address}
                          style={{width: '350px'}}
                          onChange={(data) => {
                            setSectionData(prevState => {
                              let state = { ...prevState };
                              state["ANOTHER_DRIVER_WITH_POLICY_CULPRIT"].inspection_address = data;
                              return state;
                            });
                            setSuggestions({...suggestions, inspection_address: []});
                          }}
                          onSearch={(text) => {
                            if (text && text.length > 2 && !text.endsWith(" ")) {
                              daDataService.suggestAddress(text)
                                .then(res => {
                                  setSuggestions({
                                    ...suggestions, inspection_address: res.suggestions.map(el => {
                                      return {value: el.value}
                                    })
                                  });
                                })
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <Row gutter={[0, 16]} style={rowStyle}>
                      <Col span={6}>Наличие ОСАГО</Col>
                      <Col md={18}>
                        <Checkbox
                          onChange={(e) => setDtp({...dtp, ...{osago: e.target.checked}})}
                          defaultChecked={dtp ? dtp.osago : false}
                          checked={dtp ? dtp.osago : false}
                        />
                      </Col>
                    </Row>
                    {(dtp && dtp.osago === true) && (
                      <>
                        <Row gutter={[0, 16]} style={rowStyle}>
                          <Col span={6}>Действующий ли полис ОСАГО?</Col>
                          <Col md={18}>
                            <Checkbox
                              onChange={(e) => setDtp({...dtp, ...{osago_active: e.target.checked}})}
                              defaultChecked={dtp ? dtp.osago_active : false}
                              checked={dtp ? dtp.osago_active : false}
                            />
                          </Col>
                        </Row>
                        <Row gutter={[0, 16]} style={rowStyle}>
                          <Col span={6}>Номер полиса ОСАГО</Col>
                          <Col md={18}>
                            <Input
                              style={error.name === "osago_number" ? {...inputErrorStyle, ...{width: "350px"}} : {width: "350px"}}
                              allowClear={true}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder="Номер полиса ОСАГО"
                              value={dtp ? dtp.osago_number : ""}
                              onChange={(e) => {
                                setDtp({...dtp, ...{osago_number: e.target.value}});
                              }}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                )
              }
            </Panel>
            <Panel header="Виновник второй водитель (полис недействителен)" key="ANOTHER_DRIVER_WITHOUT_POLICY_CULPRIT"
                   collapsible={status !== "ANOTHER_DRIVER_WITHOUT_POLICY_CULPRIT" ? "disabled" : "header"}>
              {
                status === "ANOTHER_DRIVER_WITHOUT_POLICY_CULPRIT" && (
                  <>
                    <Row gutter={[0, 16]} style={rowStyle}>
                      <Col span={6}>Место осмотра ТС</Col>
                      <Col md={18}>
                        <Select
                          allowClear
                          placeholder="Место осмотра ТС"
                          value={sectionData["ANOTHER_DRIVER_WITHOUT_POLICY_CULPRIT"].inspection_place}
                          style={{width: 350}}
                          onChange={(value) => {
                            setSectionData(prevState => {
                              let state = { ...prevState };
                              state["ANOTHER_DRIVER_WITHOUT_POLICY_CULPRIT"].inspection_place = value;
                              return state;
                            });
                          }}
                        >
                          {
                            filtersList.inspection_place ? filtersList.inspection_place.map(el => <Option key={el[0]}
                                                                                                          value={el[0]}>{`${el[1]}`}</Option>) : []
                          }
                        </Select>
                      </Col>
                    </Row>
                    <Row gutter={[0, 16]} style={rowStyle}>
                      <Col span={6}>Адрес места осмотра ТС</Col>
                      <Col span={18}>
                        <AutoComplete
                          allowClear={true}
                          placeholder="Адрес места осмотра ТС"
                          options={suggestions.inspection_address || []}
                          value={sectionData["ANOTHER_DRIVER_WITHOUT_POLICY_CULPRIT"].inspection_address}
                          style={{width: '350px'}}
                          onChange={(data) => {
                            setSectionData(prevState => {
                              let state = { ...prevState };
                              state["ANOTHER_DRIVER_WITHOUT_POLICY_CULPRIT"].inspection_address = data;
                              return state;
                            });
                            setSuggestions({...suggestions, inspection_address: []});
                          }}
                          onSearch={(text) => {
                            if (text && text.length > 2 && !text.endsWith(" ")) {
                              daDataService.suggestAddress(text)
                                .then(res => {
                                  setSuggestions({
                                    ...suggestions, inspection_address: res.suggestions.map(el => {
                                      return {value: el.value}
                                    })
                                  });
                                })
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <Row gutter={[0, 16]} style={rowStyle}>
                      <Col span={6}>Наличие ОСАГО</Col>
                      <Col md={18}>
                        <Checkbox
                          onChange={(e) => setDtp({...dtp, ...{osago: e.target.checked}})}
                          defaultChecked={dtp ? dtp.osago : false}
                          checked={dtp ? dtp.osago : false}
                        />
                      </Col>
                    </Row>
                    {(dtp && dtp.osago === true) && (
                      <>
                        <Row gutter={[0, 16]} style={rowStyle}>
                          <Col span={6}>Действующий ли полис ОСАГО?</Col>
                          <Col md={18}>
                            <Checkbox
                              onChange={(e) => setDtp({...dtp, ...{osago_active: e.target.checked}})}
                              defaultChecked={dtp ? dtp.osago_active : false}
                              checked={dtp ? dtp.osago_active : false}
                            />
                          </Col>
                        </Row>
                        <Row gutter={[0, 16]} style={rowStyle}>
                          <Col span={6}>Номер полиса ОСАГО</Col>
                          <Col md={18}>
                            <Input
                              style={error.name === "osago_number" ? {...inputErrorStyle, ...{width: "350px"}} : {width: "350px"}}
                              allowClear={true}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder="Номер полиса ОСАГО"
                              value={dtp ? dtp.osago_number : ""}
                              onChange={(e) => {
                                setDtp({...dtp, ...{osago_number: e.target.value}});
                              }}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                )
              }
            </Panel>
            <Panel header="Виновник не определен" key="CULPRIT_UNDEFINED"
                   collapsible={status !== "CULPRIT_UNDEFINED" ? "disabled" : "header"}>
              {
                status === "CULPRIT_UNDEFINED" && (
                  <>
                    <Row gutter={[0, 16]} style={rowStyle}>
                      <Col span={6}>Наличие ОСАГО</Col>
                      <Col md={18}>
                        <Checkbox
                          onChange={(e) => {
                            setDtp({...dtp, osago: e.target.checked});
                          }}
                          defaultChecked={dtp ? dtp.osago : false}
                          checked={dtp ? dtp.osago : false}
                        />
                      </Col>
                    </Row>

                    {(dtp && dtp.osago === true) && (
                      <>
                        <Row gutter={[0, 16]} style={rowStyle}>
                          <Col span={6}>Действующий ли полис ОСАГО?</Col>
                          <Col md={18}>
                            <Checkbox
                              onChange={(e) => setDtp({...dtp, ...{osago_active: e.target.checked}})}
                              defaultChecked={dtp ? dtp.osago_active : false}
                              checked={dtp ? dtp.osago_active : false}
                            />
                          </Col>
                        </Row>
                        <Row gutter={[0, 16]} style={rowStyle}>
                          <Col span={6}>Номер полиса ОСАГО</Col>
                          <Col md={18}>
                            <Input
                              style={error.name === "osago_number" ? {...inputErrorStyle, ...{width: "350px"}} : {width: "350px"}}
                              allowClear={true}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder="Номер полиса ОСАГО"
                              value={dtp ? dtp.osago_number : ""}
                              onChange={(e) => {
                                setDtp({...dtp, ...{osago_number: e.target.value}});
                              }}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row gutter={[0, 16]} style={rowStyle}>
                      <Col span={6}>Адрес отделения ГИБДД</Col>
                      <Col span={18}>
                        <AutoComplete
                          allowClear={true}
                          placeholder="Адрес отделения ГИБДД"
                          options={suggestions.traffic_police_address || []}
                          value={dtp?.traffic_police_address}
                          style={error.name === "traffic_police_address" ? {...inputErrorStyle, ...{width: "350px"}} : {width: "350px"}}
                          onChange={(data) => {
                            setDtp({...dtp, ...{traffic_police_address: data}});
                            setSuggestions({...suggestions, traffic_police_address: []});
                          }}
                          onSearch={(text) => {
                            if (text && text.length > 2 && !text.endsWith(" ")) {
                              daDataService.suggestAddress(text)
                                .then(res => {
                                  setSuggestions({
                                    ...suggestions, traffic_police_address: res.suggestions.map(el => {
                                      return {value: el.value}
                                    })
                                  });
                                })
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )
              }
            </Panel>
            <Panel header="Транзакции" key={"TX"}>
              <Row gutter={[0, 16]} style={rowStyle}>
                <TransactionTab
                  active={visible}
                  dataLoading={transactionLoading}
                  onCreateTransaction={(data) => {
                    createTransaction(data);
                  }}
                  onDeleteTransaction={(txId) => {
                    Modal.confirm({
                      title: "Удаление",
                      content: "Вы уверены, что хотите удалить запись?",
                      icon: <DeleteOutlined/>,
                      visible: true,
                      onOk() {
                        setTransactionLoading(true);
                        apiClient.removeTransaction(txId)
                          .then(res => {
                            message.success("Транзакция удалена");
                            setTransactionsUpdate(prevState => prevState + 1);
                            apiClient.getDtp(dtp.id)
                              .then((res) => {
                                setDtp({
                                  ...dtp, ...{
                                    driver_debt: res.record.driver_debt,
                                    repair_amount: res.record.repair_amount,
                                    current_paid: res.record.current_paid,
                                    debt_status: res.record.debt_status,
                                  }
                                });
                              })
                              .catch(err => console.log(err));

                          })
                          .catch(err => {
                            message.error(err);
                            setTransactionLoading(false);
                          });
                      },
                    });
                  }}
                  data={transaction}
                />
              </Row>
            </Panel>
          </Collapse>
        </>
      }
    </Modal>
  );
};