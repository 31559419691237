import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../../services/ApiClient";
import {message, Modal, PageHeader, Space, Table} from "antd";
import {windowService} from "../../../../../services/WindowService";

export default function UnassignedDriversDetails({visible, onClose}) {

  let [loading, setLoading] = useState(false);
  let [data, setData] = useState([]);

  useEffect(() => {
    if(visible) {
      setLoading(true);
      apiClient.getDriversWithoutWorkConditions()
        .then(res => { setData(res.records); })
        .catch((err) => { message.error(err) })
        .finally(() => { setLoading(false); })
    } else {
      setData([]);
    }
  }, [visible]);

  const openDriverPage = driverId => {
    windowService.openRouteWindow(`cp/drivers/${driverId}`);
  };

  return (
    <Modal
      destroyOnClose={true}
      width={800}
      title={"Детализация"}
      visible={visible}
      onOk={() => { onClose(); }}
      onCancel={() => { onClose(); }}>
      <>
        <PageHeader
          className="site-page-header"
          title="Детализация"
          extra={
            <div style={{textAlign: "right"}}>
              <Space direction={"horizontal"} style={{marginBottom: 10}}>
                <span>{`Записей: ${data ? data.length : 0}`}</span>
              </Space>
            </div>
          }
        />
        <Table dataSource={data}
              sticky
               columns={[
                {
                  title: "",
                  align: "center",
                  width: 30,
                },
                 {
                   title: 'ФИО',
                   dataIndex: 'name',
                 },
                 {
                   title: 'Телефон',
                   dataIndex: 'phone',
                 },
                 {
                   title: 'Авто',
                   dataIndex: 'car',
                 },
                 {
                   title: "",
                   align: "center",
                   width: 20,
                 }
               ]}
               loading={loading}
               size="small"
               rowKey="id"
               pagination={{position: 'topRight', defaultPageSize: 25, pageSizeOptions: [10, 25, 50]}}
               rowClassName={"cursor-pointer"}
               onRow={(record, rowIndex) => {
                 return {
                   onClick: event => { openDriverPage(record.id) },
                 };
               }}
        />
      </>
    </Modal>
  )
}