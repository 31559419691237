import React, {useEffect, useState} from "react";
import {Col, DatePicker, Input, Modal, Row} from "antd";
import moment from "moment";
import "../../ModalStyle.css";

const {TextArea} = Input;
const rowStyle = {padding: '4px'};

export default function ServiceDiagnosticStatusEdit({visible, item, onOk, onCancel, title}) {

  let [record, setRecord] = useState({id: 0});

  useEffect(() => {
    if(item) {
      setRecord(item);
    } else {
      setRecord({id: 0});
    }
  }, [item]);

  return (
    <Modal
      destroyOnClose={true}
      style={{top: 10}}
      className="size-for-middle-window"
      title={title || "Новая запись"}
      visible={visible}
      onOk={() => {
        onOk(record);
      }}
      onCancel={onCancel}>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>ТС</Col>
        <Col span={18}>
          <Input
            disabled={true}
            value={record.car?.title}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Регион</Col>
        <Col span={18}>
          <Input
            disabled={true}
            value={record.region?.title}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Владелец</Col>
        <Col span={18}>
          <Input
            disabled={true}
            value={record.owner?.title}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Номер</Col>
        <Col span={18}>
          <Input
            disabled={true}
            value={record.reg_number}/>
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Дата выдачи</Col>
        <Col span={18}>
          <DatePicker
            allowClear
            disabled={true}
            format="DD.MM.YYYY"
            value={record.issue_dt ? moment(record.issue_dt, "YYYY-MM-DD") : null}
            style={{width: 220}}
          />
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Срок действия</Col>
        <Col span={18}>
          <DatePicker
            allowClear
            disabled={true}
            format="DD.MM.YYYY"
            value={record.due_dt ? moment(record.due_dt, "YYYY-MM-DD") : null}
            style={{width: 220}}
          />
        </Col>
      </Row>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Комментарий</Col>
        <Col span={18}>
          <TextArea
            rows={3}
            placeholder=""
            value={record.comment}
            onChange={(e) => { setRecord({...record, comment: e.target.value}) }}
          />
        </Col>
      </Row>

    </Modal>
  );
}