import React, {useEffect, useState} from "react";
import {Col, Input, message, Modal, Row} from "antd";
import '../../../ModalStyle.css';

const rowStyle = {padding: '8px'};

export default function TaxiLicenceAuthorityEditDialog({visible, region, onOk, onCancel, title}) {
  let [record, setRecord] = useState({id: 0});

  useEffect(() => {
    if(region) {
      setRecord({
        id: region.id,
        title: region.title || "",
      });
    } else {
      setRecord({id: 0});
    }
  }, [region]);

  return (
    <Modal
      destroyOnClose={true}
      style={{top: 10}}
      className="size-for-small-window"
      title={title || "Новая запись"}
      visible={visible}
      onOk={() => {
        if(!record.title) {
          message.warn("Укажите наименование");
          return;
        }
        onOk(record);
        setRecord({id: 0});
      }}
      onCancel={onCancel}>

      <Row gutter={[0, 16]} style={rowStyle}>
        <Col span={6}>Наименование</Col>
        <Col span={18}>
          <Input
            allowClear={true}
            placeholder="Наименование"
            value={record.title}
            onChange={(e) => { setRecord({...record, title: e.target.value}) }}/>
        </Col>
      </Row>

    </Modal>
  );
}