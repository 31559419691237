import React from "react";
import {useParams} from "react-router-dom";
import VehicleEditDialog from "./VehicleEditDialog";

export default function VehicleDetailsLoader() {

  let { uid } = useParams();

  return (
    <VehicleEditDialog
      visible={true}
      vehicleId={uid}
      onClose={() => {
        window.close();
      }}
    />

  )
}