import React, {useEffect, useState} from "react";
import {AutoComplete, Button, Col, Form, Input, message, Modal, Row, Space, Switch} from "antd";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditFilled,
  MinusCircleFilled,
  PlusCircleFilled
} from "@ant-design/icons";
import {apiClient} from "../../../../services/ApiClient";
const { confirm } = Modal;

export default function VehiclesList({status, onSelect}) {
  let [record, setRecord] = useState(null);
  let [options, setOptions] = useState([]);
  let [formVisible, setFormVisible] = useState(false);
  let [loading, setLoading] = useState(false);
  let [enableSave, setEnableSave] = useState(false);
  let [activeSearch, setActiveSearch] = useState(null);
  let [form] = Form.useForm();

  const mapRecord = (record) => {
    record.displayName = `${record.model} (${record.reg_number})`;
    return {
      key: record.id,
      value: record.displayName,
      record: record
    };
  };

  const search = (val) => {
    if(activeSearch) {
      clearTimeout(activeSearch);
    }
    let params = {
      reg_number: val,
      owned: true,
      documents_disabled: false
    };
    const h = setTimeout(() => {
      setLoading(true);
      apiClient.getVehicles(params, "default")
        .then(res => {
          setOptions(res.records.map(e => mapRecord(e)));
        })
        .catch(err => message.error("Не удалось выполнить поиск"))
        .finally(() => { setLoading(false); });
    }, 700);
    setActiveSearch(h);
  };

  const remove = () => {
    if(!record) {
      return;
    }
    apiClient.removeDirectoryRecord('car', record.id)
      .then(res => {
        if(res.success) {
          setRecord(null);
          setOptions([]);
          onSelect(null);
          if(formVisible) {
            form.resetFields();
          }
        } else {
          message.error("Ошибка");
        }
      })
      .catch(err => {
        message.error(err);
      });
  };

  const renderSelectedActions = () => {
    return (
      <>
        {formVisible
          ? <Button type="link" icon={<CloseCircleOutlined/>} onClick={() => setFormVisible(false)}/>
          : <Button type="link" icon={<EditFilled/>} onClick={() => setFormVisible(true)}/>}
        <Button type="link"
                style={{color: 'darkred'}}
                icon={<DeleteOutlined/>}
                onClick={() => {
                  confirm({
                    title:  "Удаление",
                    content: "Вы уверены, что хотите удалить запись?",
                    icon: <DeleteOutlined/>,
                    visible: true,
                    onOk() {
                      remove();
                    },
                  })
                }}/>
      </>
    );
  };

  const renderNotSelectedActions = () => (
    formVisible
      ? <Button type="link" icon={<MinusCircleFilled/>} onClick={() => setFormVisible(false)}/>
      : <Button type="link" icon={<PlusCircleFilled/>} onClick={() => setFormVisible(true)}/>
  );

  useEffect(() => {
    if(localStorage.getItem(`CarSection.enableSave`) === null) {
      setEnableSave(false);
    } else {
      const rec = localStorage.getItem('CarSection.record');
      if(rec) {
        updateRecord(JSON.parse(rec));
      }
    }
  }, []);

  const updateRecord = rec => {
    if(rec) {
      localStorage.setItem('CarSection.record', JSON.stringify(rec));
    } else {
      localStorage.removeItem('CarSection.record');
    }
    setRecord(rec);
    onSelect(rec);
  };

  return (
    <>
      <Space direction="horizontal">
        <AutoComplete
          style={{width: '400px'}}
          options={options}
          onSelect={(val, opt) => { updateRecord(opt.record); }}
          onSearch={search}
          onChange={(val) => {
            if(val === undefined) {
              setOptions([]);
              updateRecord(null);
              if(formVisible) {
                form.resetFields();
              }
            } else {
              setRecord({displayName: val});
            }
          }}
          value={record ? record.displayName : ""}
          allowClear={true}
          placeholder="Искать автомобиль..."
        />
        {status === "2" && (
          <>
            {
              record != null && record.id > 0
                ? renderSelectedActions()
                : renderNotSelectedActions()
            }
          </>
        )}
      </Space>
    </>
  );
}