import React, {useEffect, useState} from "react";
import {message, Modal, Table} from "antd";
import {apiClient} from "../../../../services/ApiClient";

import moment from "moment";
import {TemplatePaymentSource} from "../../../common/AppEnums";

export default function TodayTransactionsDialog({visible, driver, onClose}) {
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);

  useEffect(() => {
    if(visible) {
      setLoading(true);
      apiClient.getDriverBalanceHistory(driver.id, moment().format('YYYY-MM-DD'))
        .then(res => {
          setRecords(res.records);
        })
        .catch(err => message.error(err))
        .finally(() => { setLoading(false) });
    } else {

    }
  }, [visible]);
  return (
    <Modal
      title={"Транзакции"}
      visible={visible}
      width="800px"
      style={{ top: 20 }}
      onOk={onClose}
      onCancel={onClose}
    >
      <Table dataSource={records}
            sticky
             rowClassName={(record, index) => { return `status-${record.execution_status}` }}
             columns={[
              {
                title: "",
                align: "center",
                width: 30,
              },
               {
                 title: "Дата",
                 dataIndex: "create_dt",
                 render: (text, record, index) => {
                   return moment(record.create_dt).format('DD.MM.YYYY HH:mm:ss');
                 },
               },
               {
                 title: "Сумма",
                 dataIndex: "change_amount",
               },
               {
                 title: "Баланс",
                 dataIndex: "balance",
                 render: (text, record, index) => {
                   return TemplatePaymentSource.find(el => el.value === record.balance)?.text;
                 }
               },
               {
                 title: "Комментарий",
                 dataIndex: "comment",
               },
               {
                 title: "",
                 align: "center",
                 width: 20,
               }
             ]}
             size="small"
             rowKey="id"
             loading={loading}
             pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
      />
    </Modal>
  );
}