import React, {useContext, useEffect, useState} from "react";

import {CarReturnReasons} from "../../common/AppEnums"
import {
  Button,
  Card,
  Comment,
  DatePicker,
  Input,
  message,
  Modal,
  PageHeader, Rate,
  Select,
  Space,
  Table
} from "antd";
import {apiClient} from "../../../services/ApiClient";
import moment from "moment";
import {DeleteOutlined, EditFilled, LinkOutlined} from "@ant-design/icons";
import CommentEditor from "../drivermanager/CommentEditor";
import UserProfileContext from "../../../services/UserProfileContext";
import {windowService} from "../../../services/WindowService";
import '../ModalStyle.css';
import SearchSelect from "../../common/SearchSelect";
import DownloadButton from "../../buttons/DownloadButton";

const { Option } = Select;
const {Search, TextArea} = Input;
const { RangePicker } = DatePicker;

export default function CarReturnRequestsPage() {

  let userProfile = useContext(UserProfileContext);

  let [loading, setLoading] = useState(false);
  let [dataSource, setDataSource] = useState([]);
  let [isDialogVisible, setIsDialogVisible] = useState(false);
  let [dialogModel, setDialogModel] = useState(null);
  let [requestComments, setRequestComments] = useState([]);
  let [vehicles, setVehicles] = useState([]);
  let [filters, setFilters] = useState({});

  const columns = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    {
      title: '',
      width: 50,
      render: (text, record, index) => {
        return (
          <Space direction={"horizontal"}>
            <Button
              type="link"
              icon={<EditFilled/>}
              onClick={() => {
                showRequestEditDialog(record);
              }}
            />
          </Space>
        )
      }
    },
    {
      title: 'ID',
      width: 50,
      dataIndex: 'id',
      sorter: (a, b) => {
        return a.id - b.id;
      },
    },
    {
      title: 'ФИО',
      width: 300,
      dataIndex: 'fio',
      render: (text, record, index) => {
        return record.driver_id
          ? (
            <Button
              type={"link"}
              style={{padding: 0}}
              onClick={() => {
                windowService.openRouteWindow(`cp/drivers/${record.driver_id}`);
              }}
            >
              {
                <>
                  <div>
                    {record.fio}
                  </div>
                </>
              }
            </Button>
          ) : record.fio;
      },
      sorter: (a, b) => {
        return a.fio.localeCompare(b.fio);
      },
    },
    {
      title: 'Номер авто',
      dataIndex: 'car_number',
      align: "center",
      sorter: (a, b) => {
        return a.car_number.localeCompare(b.car_number);
      },
    },
    {
      title: 'Марка, модель',
      dataIndex: 'car_info',
      align: "center",
      sorter: (a, b) => {
        return a.car_info.localeCompare(b.car_info);
      },
    },
    {
      title: 'Дата создания',
      dataIndex: 'create_dt',
      align: "center",
      render: (text, record, index) => {
        return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
      },
      sorter: (a, b) => {
        return moment(a.create_dt).valueOf() - moment(b.create_dt).valueOf();
      },
    },
    {
      title: 'Планируемая дата',
      dataIndex: 'planned_return_dt',
      align: "center",
      sortOrder: 'descend',
      render: (text, record, index) => {
        return `${moment(record.planned_return_dt).format('DD.MM.YYYY')}`;
      },
      sorter: (a, b) => {
        return moment(a.planned_return_dt).valueOf() - moment(b.planned_return_dt).valueOf();
      },
    },
    {
      title: 'Причина возврата',
      dataIndex: 'reason_id',
      width: 180,
      render: (text, record, index) => {
        const reason = CarReturnReasons.find(e => e.value === record.reason_id);
        return `${reason.text}`;
      },
      sorter: (a, b) => {
        return a.reason_id - b.reason_id;
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  const showRequestEditDialog = (record) => {
    loadRequestComments(record);
    setDialogModel(record);
    setIsDialogVisible(true);
  };

  const saveRequestEditDialog = () => {
    // upload dialogModel changes
    apiClient.updateCarReturnRequests(dialogModel)
      .then(() => {
        // change in dataSource
        message.success("Данные сохранены");
        setDataSource([...dataSource.map(e => e.id !== dialogModel.id ? e : dialogModel)]);
      })
      .catch(err => {
        message.error('Ошибка, не удалось сохранить данные');
      });
  };

  const loadRequestComments = (record) => {
    apiClient.getCarReturnRequestComments(record.id)
      .then(res => {
        setRequestComments(res.data);
      })
      .catch(err => {
        message.error("Не удалось загрузить комментарии")
      })
  };

  const handleCommentDelete = (record, comment) => {
    apiClient.removeCarReturnRequestComment(record.id, comment.id)
      .then(() => {
        loadRequestComments(record);
      })
      .catch(err => {
        message.error("Ошибка");
      })
  };

  const formatVehicleTitle = el => {
    return el ? `${el.brand} ${el.model} ${el.reg_number}` : "";
  };

  const canViewReturnFeedback = () => {
    return userProfile.is_superuser || userProfile.permissions.some(el => el === "view_return_request_feedback");
  };

  useEffect(() => {
    apiClient.getVehicles({owned: true, documents_disabled: false}, "minimal")
      .then(res => {
        setVehicles(res.records);
      })
      .catch(err => message.error(err));
  }, []);

  useEffect(() => {
    setLoading(true);
    apiClient.getCarReturnRequests(filters)
      .then(res => {
        setDataSource(res.data);
      })
      .catch(err => {
        message.error('Ошибка, не удалось загрузить данные');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filters]);

  return (
    <React.Fragment>
      <PageHeader
        className="site-page-header"
        title="Заявки на возврат автомобилей"
        extra={
          <div style={{textAlign: "right"}}>
            Всего: {dataSource.length}
            <Button type="link" icon={<LinkOutlined/>}
                    onClick={() => {
                      Modal.info({
                        title:  "Ссылка на страницу",
                        width: 800,
                        content: `${process.env.REACT_APP_WEB_HOST}/vozvrat/${userProfile.tenant.code}`,
                        visible: true,
                      });
                    }}>
              Ссылка на страницу
            </Button>
            {
              canViewReturnFeedback() && (
                <>
                  <DownloadButton onClick={() => {
                    setLoading(true);
                    apiClient.getCarReturnRequests({...filters, export: true})
                      .then(res => {
                        window.open(res.link);
                      })
                      .catch(err => {
                        message.error('Ошибка, не удалось загрузить данные');
                      })
                      .finally(() => {
                        setLoading(false);
                      });
                  }}/>
                </>
              )
            }
          </div>
        }
      />

      <Space direction="horizontal" align={{width: '100%'}} wrap>
        {/* ... */}
        <Search
          allowClear
          placeholder="Поиск по ФИО"
          onSearch={(value, event) => {
            setFilters({...filters, fio: value});
          }}
          enterButton
          style={{width: 400}}
        />
        <SearchSelect
          placeholder="Авто"
          style={{width: 300}}
          options={vehicles.map(el => ({id: el.id, title: formatVehicleTitle(el)}))}
          value={filters.vehicle ? formatVehicleTitle(vehicles.find(el => el.id === filters.vehicle)) : null}
          onSelect={(el) => {
            setFilters({...filters, vehicle: el?.id});
          }}
        />
        <RangePicker
          allowClear={true}
          style={{width: 350}}
          onChange={(dates, dateStrings) => {
            setFilters({...filters, date_from: dateStrings[0], date_to: dateStrings[1]});
          }}
        />
      </Space>

      <div style={{margin: '10px 0'}}>
        <Table
          loading={loading}
          dataSource={dataSource}
          columns={columns}
          size='small'
          rowKey="id"
          scroll={{x: 1200}}
          sticky
          pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [50, 100, 200]}}
        />
      </div>

      {
        isDialogVisible && (
          <Modal
            title={`Заявка ${dialogModel ? dialogModel.id : ""}`}
            visible={isDialogVisible}
            onOk={() => { saveRequestEditDialog(); setIsDialogVisible(false); }}
            okText="Сохранить"
            onCancel={() => { setIsDialogVisible(false) }}
            cancelText="Отмена"
            className="size-for-middle-window"
            style={{ top: 20 }}
          >
            <Card style={{margin: '0 0 20px 0'}}>
              <Space direction="vertical" style={{width: '100%'}}>
                <div>
                  <label>ФИО</label>
                  <Input
                    placeholder="Введите ваше ФИО"
                    value={dialogModel ? dialogModel.fio : ""}
                    onChange={(e) => { setDialogModel({...dialogModel, ...{fio: e.target.value}}) }}
                  />
                </div>
                <div>
                  <label>Номер автомобиля</label>
                  <Input
                    placeholder="Введите номер автомобиля"
                    value={dialogModel ? dialogModel.car_number : ""}
                    onChange={(e) => { setDialogModel({...dialogModel, ...{car_number: e.target.value}}) }}
                  />
                </div>
                <div>
                  <label>Планируемая дата возврата автомобиля</label>
                  <div>
                    <DatePicker
                      format="DD.MM.YYYY" placeholder="Дата возврата автомобиля"
                      value={dialogModel ? moment(dialogModel.planned_return_dt) : null}
                      onChange={(e) => { setDialogModel({...dialogModel, ...{planned_return_dt: e}}) }}
                      allowClear={false}
                    />
                  </div>
                </div>
                <div>
                  <label>Причина возврата</label>
                  <div>
                    <Select
                      style={{width: "400px"}}
                      bordered
                      placeholder="Выберите вариант из списка"
                      value={dialogModel ? dialogModel.reason_id : null}
                      onChange={(e) => { setDialogModel({...dialogModel, ...{reason_id: e}}) }}
                    >
                      {CarReturnReasons.map(s => <Option key={s.value} value={s.value}>{s.text}</Option>)}
                    </Select>
                  </div>
                </div>
                {
                  canViewReturnFeedback() && (
                    <>
                      <div>
                        <label>Плюсы сотрудничества с нами</label>
                        <div>
                          <TextArea
                            rows={3}
                            value={dialogModel?.positive_feedback}
                          />
                        </div>
                      </div>
                      <div>
                        <label>Минусы сотрудничества с нами</label>
                        <div>
                          <TextArea
                            rows={3}
                            value={dialogModel?.negative_feedback}
                          />
                        </div>
                      </div>
                      <div>
                        <label>Оцените по шкале от 0 до 10 вероятность того, что Вы порекомендуете компанию друзьям и знакомым</label>
                        <div>
                          <Rate count={10} value={dialogModel?.recommendation_rating} character={({ index }) => <span style={{margin: '0 5px'}}>{index + 1}</span>}/>
                        </div>
                      </div>
                    </>
                  )
                }
              </Space>
            </Card>

            <h3>Комментарии</h3>

            {requestComments.length > 0
              ? requestComments.map(e => <Comment
                className="request-comment"
                key={e.id}
                actions={[
                  <span onClick={() => handleCommentDelete(dialogModel, e) }>
                <span className="comment-action"><DeleteOutlined /> Удалить</span>
              </span>
                ]}
                author={<span>{e.created_by}</span>}
                content={<span>{e.comment}</span>}
                datetime={<span>{moment(e.create_dt).format('DD.MM.YYYY HH:mm')}</span>}
              />)
              : (<div style={{textAlign: 'center', margin: '10px'}}>Нет записей</div>)}

            <CommentEditor
              onSubmit={(value) => {
                return new Promise((resolve, reject) => {
                  if(!value) {
                    message.error("Необходимо ввести комментарий");
                    reject();
                    return;
                  }
                  apiClient.addCarReturnRequestComment(dialogModel.id, value)
                    .then(res => {
                      resolve();
                      loadRequestComments(dialogModel);
                    })
                    .catch(err => {
                      reject(err);
                      message.error("Ошибка")
                    });
                });
              }}
            />
          </Modal>
        )
      }

    </React.Fragment>
  );

}