import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import {Avatar, Col, message, Row, Table} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {apiClient} from "../../../services/ApiClient";
import EmptyPage from "../EmptyPage";

import moment from "moment";
import './TeamCompetitionResultPage.css'

export default function TeamCompetitionResultPage() {
  let { uid } = useParams();
  let [result, setResult] = useState(null);

  const cols = [
    {
      title: '',
      dataIndex: 'id',
      width: 80,
      render: (text, record, index) => {
        if(!record.avatar_url) {
          return <Avatar size={"large"} icon={<UserOutlined />} />;
        }
        return <Avatar size={"large"} src={record.avatar_url} />;
      },
    },
    {
      title: '№',
      dataIndex: 'id',
      width: 80,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'Чистый доход',
      dataIndex: 'income',
      width: 120,
    },
    {
      title: 'ФИО Участника',
      dataIndex: 'name',
    },
    {
      title: 'Марка',
      dataIndex: 'car',
    },
    {
      title: 'Номер',
      dataIndex: 'car_reg_number',
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(() => {
    apiClient.getTeamCompetitionResults(uid)
      .then(res => {
        setResult(res.record);
      })
      .catch(err => {
        message.error("Не удалось получить результаты конкурса");
        message.error(err);
      });
  }, [uid]);

  const renderDriverTransactions = driver => {
    if(driver.transactions.length < 1) {
      return (
        <div style={{margin: "20px 0", textAlign: "center"}}>
          <span>Нет транзакций</span>
        </div>
      );
    }
    return (
      <div style={{margin: "5px 0"}}>
        <Table pagination={false}
                scroll={{x: 1000}}
                sticky
               columns={[
                  {
                    title: "",
                    align: "center",
                    width: 30,
                  },
                 {
                   title: 'Заказ',
                   dataIndex: 'order_id',
                   align: "center",
                   width: 120,
                 },
                 {
                   title: 'Дата',
                   align: "center",
                   dataIndex: 'event_at',
                   width: 120,
                   render: (text, record, index) => {
                     return moment(record.event_at).format("DD.MM.YYYY HH:mm");
                   },
                 },
                 {
                   title: 'Сумма',
                   align: "center",
                   dataIndex: 'amount',
                 },
                 {
                   title: 'Категория',
                   dataIndex: 'category_name',
                 },
                 {
                   title: 'Описание',
                   dataIndex: 'description',
                 },
                 {
                   title: "",
                   align: "center",
                   width: 20,
                 }
               ]}
               dataSource={driver.transactions.sort((a, b) => { return moment(a.event_at).valueOf() - moment(b.event_at).valueOf(); })}
               rowKey="id"
               size={"small"}
        />
      </div>
    );
  };

  const renderTeam = team => {
    return (
      <>
        <Row>
          <Col xs={0} sm={0} md={4} lg={6} xl={6}>
            &nbsp;
          </Col>
          <Col xs={24} sm={24} md={16} lg={12} xl={12}>
            <div style={{textAlign: 'center', fontSize: '24pt', margin: '20px 0', lineHeight: 1}}>
              {team.title}: {team.income} руб
              <div style={{textAlign: 'center', fontSize: '16pt'}}>
                Участников: {team.drivers.length}
              </div>
            </div>
          <div style={{fontSize: "0.8em"}}>
            <Table pagination={false}
                   columns={cols}
                   dataSource={team.drivers.sort((a, b) => b.income - a.income)}
                   rowKey="id"
                   size={"small"}
                   scroll={{x: 1000}}
                   sticky
                   style={{margin: 0}}
                   expandable={{
                     indentSize: 0,
                     expandedRowRender: record => renderDriverTransactions(record),
                   }}
            />
          </div>
        </Col>
          <Col xs={0} sm={0} md={4} lg={6} xl={6}>
            &nbsp;
          </Col>
        </Row>
      </>
    )
  };

  return result ? (
    <>
      <Row>
        <Col xs={1} sm={1} md={4} lg={6} xl={6}>
          &nbsp;
        </Col>
        <Col xs={22} sm={22} md={16} lg={12} xl={12}>
          <div style={{textAlign: 'center', fontSize: '26pt', margin: '50px 0 5px 0'}}>
            Результаты участников конкурса Такси Арена
          </div>
          <div style={{textAlign: 'center', fontSize: '14pt'}}>
            <div>{moment(result.from).format("DD.MM.YYYY HH:mm")} - {moment(result.to).format("DD.MM.YYYY HH:mm")}</div>
          </div>
          <div style={{textAlign: 'center', fontSize: '18pt'}}>
            {
              result.teams
                .sort((a, b) => b.income - a.income)
                .map(team => {
                  return (
                    <div style={{display: "inline-block", margin: "10px 20px"}}>
                      <div style={{fontSize: "18pt"}}>
                        {team.title}: {team.income} руб
                      </div>
                      <div style={{textAlign: 'center', fontSize: '14pt'}}>
                        Участников: {team.drivers.length}
                      </div>
                    </div>
                  );
                })
            }
          </div>
        </Col>
        <Col xs={1} sm={1} md={4} lg={6} xl={6}>
          &nbsp;
        </Col>
      </Row>
      {
        result.teams
          .sort((a, b) => b.income - a.income)
          .map(team => renderTeam(team))
      }
      <Row>
        <Col>
          &nbsp;
        </Col>
      </Row>
    </>
  ) : <EmptyPage/>
}