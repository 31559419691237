import React, {useEffect, useState} from "react";
import {
  Button,
  Input,
  message,
  Modal,
  PageHeader,
  Row,
  Radio,
  Select,
  Space,
  Switch,
  Table,
  Upload,
  Menu,
  Dropdown, Popover, Tooltip
} from "antd";
import {
  CheckCircleFilled, CheckCircleTwoTone,
  DeleteOutlined, DownOutlined,
  EditFilled,
  ExclamationCircleFilled, InfoCircleFilled,
  UploadOutlined,
  WarningTwoTone
} from "@ant-design/icons";
import {apiClient} from "../../../services/ApiClient";
import VehicleEditDialog from "./vehicle/VehicleEditDialog";
import UpdateButton from "../../buttons/UpdateButton";
import CancelButton from "../../buttons/CancelButton";

import "./VehiclesPage.css"
import VehiclesModel from "./VehiclesModel";
import VehiclesClass from "./VehiclesClass";
import {windowService} from "../../../services/WindowService";
import OnlinegibddSyncErrorReportDialog from "./reports/OnlinegibddSyncErrorReportDialog";
import {ParkStatuses} from "../../common/AppEnums";
import NumberInputDialog from "../common/NumberInputDialog";
import LinkButton from "../../buttons/LinkButton";

const {Search} = Input;
const {Option} = Select;

const CMD_REMOVE = "remove";
const CMD_SET_MAINTENANCE_PERIOD = "setMaintenancePeriod";

export default function VehiclesPage() {
  let [loading, setLoading] = useState(false);
  let [editedRecordId, setEditedRecordId] = useState(null);
  let [selected, setSelected] = useState([]);
  let [records, setRecords] = useState([]);
  let [update, setUpdate] = useState(0);
  let [search, setSearch] = useState("");
  let [ownStatus, setOwnStatus] = useState("owned");
  let [yandexStatus, setYandexStatus] = useState("all");
  let [notLinkedCount, setNotLinkedCount] = useState(0);
  let [ownedCount, setOwnedCount] = useState(0);
  let [totalCount, setTotalCount] = useState(0);
  let [filter, setFilter] = useState({});
  let [ownerOptions, setOwnerOptions] = useState([]);
  let [driverOptions, setDriverOptions] = useState([]);
  let [modelOptions, setModelOptions] = useState([]);
  let [paymentGroups, setPaymentGroups] = useState([]);
  let [regions, setRegions] = useState([]);
  let [selectedTabKey, setSelectedTabKey] = useState("0");
  let [showOnlinegibddSyncErrorReport, setShowOnlinegibddSyncErrorReport] = useState(false);
  let [vehicleStatusFilter, setVehicleStatusFilter] = useState([]);
  let [numberInputRequest, setNumberInputRequest] = useState(null);

  const cols = [
    {
      title: "",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: "",
      width: 50,
      render: (text, record, index) => {
        return (
          <Space direction={"horizontal"}>
            {
              record.alert_msg && (<WarningTwoTone twoToneColor={'#eb2f96'} title={record.alert_msg}/>)
            }
            <LinkButton label={<EditFilled/>} onClick={() => { setEditedRecordId(record.id) }}/>
          </Space>
        )
      }
    },
    {
      title: <Tooltip
        placement="top"
        title="Отображает статус наличия ТС в сервисе Яндекс.Таксометр."
      >
        <p className="tooltip-text-style">Я.Таксометер</p>
      </Tooltip>,
      dataIndex: 'yandex_service_id',
      render: (text, record, index) => {
        if(record.yandex_service_id) {
          return <CheckCircleFilled style={{color: '#58D3A5', fontSize: '20px'}}/>;
        }
        return <ExclamationCircleFilled style={{color: '#D35858', fontSize: '20px'}} />;
      },
    },
    {
      title: 'Гос. номер',
      dataIndex: 'reg_number',
    },
    {
      title: 'СТС',
      dataIndex: 'registration_cert',
    },
    {
      title: 'Марка/Модель',
      render: (text, record, index) => {
        return `${record.brand} ${record.model}`;
      }
    },
    {
      title: 'Год выпуска',
      width: 120,
      render: (text, record, index) => {
        return record.year;
      }
    },
    {
      title: 'VIN',
      width: 180,
      render: (text, record, index) => {
        return record.vin;
      }
    },
    {
      title: <Tooltip
        title="Проверка соответствия СТС и номера авто."
      >
        <p className="tooltip-text-style">Валидация</p>
        
      </Tooltip>,
      width: 120,
      align: "center",
      render: (text, record, index) => {
        if(record.validation_status_code === 0) {
          return (
            <Popover content={"Проверки еще не было"}>
              <CheckCircleTwoTone twoToneColor="#CCCFD3"/>
            </Popover>
          );
        }
        if(record.validation_status_code === 8) {
          return (
            <Popover content={"Комбинация (стс + грз) найдена в базе ГИБДД"}>
              <CheckCircleTwoTone twoToneColor="#52c41a"/>
            </Popover>
          )
        }
        if(record.validation_status_code === 10) {
          return (
            <Popover content={"Комбинация (стс + грз) найдена в базе ГИБДД, СТС валиден в ГИС ГМП"}>
              <CheckCircleTwoTone twoToneColor="#52c41a"/>
            </Popover>
          )
        }
        return (
          <Popover
            content={() => {
              if(record.validation_status_code & 1) {
                return "СТС не валиден в ГИС ГМП"
              }
              if(record.validation_status_code & 4) {
                return "Комбинация (СТС + ГРЗ) не найдена в базе ГИБДД"
              }
              if(record.validation_status_code & 16) {
                return "Запрос по комбинации (стс + грз) постоянно приводит к ошибке в ГИБДД"
              }
              if(record.validation_status_code & 32) {
                return "Запрос по СТС постоянно приводит к ошибке в ГИС"
              }
              if(record.validation_status_code & 64) {
                return "СТС автомобиля был изменен в процессе загрузки штрафов"
              }
              if(record.validation_status_code & 128) {
                return "Гос номер автомобиля был изменен в процессе загрузки штрафов"
              }
            }}>
            <InfoCircleFilled style={{color: '#d30500', fontSize: '20px'}}/>
          </Popover>
        );
      }
    },
    {
      title: 'Организация',
      render: (text, record, index) => {
        return record.agency_contract_company;
      }
    },
    {
      title: 'Арендодатель',
      render: (text, record, index) => {
        return record.owner
            ? (
            <div style={{textAlign: "center"}}>
              <a onClick={() => { windowService.openRouteWindow(`cp/park/owners/${record.owner.id}`); }}>
                { record.owner.title }
              </a>
            </div>
          ) : "";
      }
    },
    {
      title: 'Водители',
      render: (text, record, index) => {
        return record.drivers.map(el => (
          <div style={{textAlign: "center"}}>
            <a onClick={() => { windowService.openRouteWindow(`cp/drivers/${el.id}`); }}>
              {el.name}
            </a>
          </div>
        ))
      }
    },
    {
      title: 'Состояние',
      render: (text, record, index) => {
        return [
          {id: "working", label: "Работает"},
          {id: "not_working", label: "Не работает"},
        ].find(el => el.id === record.status)?.label || record.status;
      }
    },
    {
      title: 'Статус ТС',
      render: (text, record, index) => {
        return record.park_status ? ParkStatuses.find(el => el.id === record.park_status)?.title : "";
      }
    },
    {
      title: 'Во владении',
      render: (text, record, index) => {
        return (
          <Switch
            title="Во владении"
            checked={record.is_owned}
            onChange={(checked) => { updateVehicleOwnership(record, checked) }}
          />
        );
      }
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(() => {
    setLoading(true);
    let params = {
      search: search,
      yandexStatus: yandexStatus,
      vehicleStatus: vehicleStatusFilter,
      owned: ownStatus !== "all",
      ...filter
    };
    apiClient.getVehicles(params)
      .then(res => {
        setRecords(res.records);
        setNotLinkedCount(res.not_linked_count);
        if(res.owned_count) {
          setOwnedCount(res.owned_count);
        }
        if(res.total_count) {
          setTotalCount(res.total_count);
        }
      })
      .catch(err => message.error(err))
      .finally(() => { setLoading(false); });
  }, [update, search, ownStatus, filter, yandexStatus, vehicleStatusFilter]);

  useEffect(() => {
    apiClient.getVehiclesFilters()
      .then(res => {
        setOwnerOptions(res.owners);
        setDriverOptions(res.drivers);
        setModelOptions(res.brands);
        setPaymentGroups(res.groups);
        setRegions(res.regions);
      })
      .catch(err => message.error(err))
  }, [update]);

  const executeSelectedRecordsOperation = operationKey => {
    if(operationKey === CMD_REMOVE) {
      Modal.confirm({
        title:  "Удаление",
        content: "Вы уверены, что хотите удалить выбранные записи?",
        icon: <DeleteOutlined/>,
        visible: true,
        onOk() {
          setLoading(true);
          Promise.all(selected.map(vehicleId => apiClient.removeVehicle(vehicleId)))
            .then(res => {
              message.success("Записи удалены");
              setSelected([]);
              setUpdate(prevState => prevState + 1);
            })
            .catch(err => message.error("Ошибка"))
            .finally(() => setLoading(false));
        },
      });
    } else if(operationKey === CMD_SET_MAINTENANCE_PERIOD) {
      setNumberInputRequest({
        title: "Укажите значение",
        onOK: (value) => {
          apiClient.updateVehicles(selected, {maintenance_interval: value})
            .then(res => {
              setUpdate(prevState => prevState + 1);
              message.info("Записи обновлены");
            })
            .catch(err => message.error(err));
        }
      })
    }
  };

  const onExportClick = () => {
    let params = {
      search: search
    };
    if(ownStatus !== "all") {
      params.owned = true;
    }
    apiClient.getVehicleExportCode(params)
      .then(res => {
        window.open(apiClient.actionUrl(`park/vehicles/export/download?uid=${res.uid}`), "_blank");
      })
      .catch(err => {
        message.error(err);
      })
  };

  const updateVehicleOwnership = (record, isOwned) => {
    apiClient.updateVehicle(record.id, {is_owned: isOwned})
      .catch(err => {
        message.error(err);
      });
    setRecords(prevState => {
      return prevState.map(el => {
        if(el.id === record.id) {
          el.is_owned = isOwned;
        }
        return el;
      });
    })
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Автомобили"
        extra={
          <div style={{textAlign: "right"}}>
            <Space direction={"horizontal"}>
              <span>Во владении: {ownedCount}</span>
              <span>Подключенные: {totalCount}</span>
              {
                notLinkedCount > 0
                ? (
                    <span
                      style={{color: "#dd0d00", cursor: "pointer"}}
                      onClick={() => {
                        setShowOnlinegibddSyncErrorReport(true);
                      }}>Ошибка синхронизации: {notLinkedCount}</span>
                ) : null
              }
            </Space>
          </div>
        }
      />
      <div style={{marginBottom: 16}}>
        <span style={{marginLeft: 8}}>
          <span style={{float: "right"}}>
            {selected.length > 0 ? `Выбрано ${selected.length} записей` : ''}
          </span>
        </span>
      </div>
      {selectedTabKey === "0" && (
        <>
        <Row style={{marginBottom: 10}}>
          <div style={{width: '100%'}}>
          <div style={{width: '60%', float: 'left'}} >
            <Space direction={"horizontal"} wrap style={{width: '100%', marginBottom: 10}}>
              <Search
                enterButton
                placeholder={"Поиск по гос.номеру, СТС, VIN"}
                onSearch={(value) => { setSearch(value) }}
                allowClear={true}
                style={{width: 400}}/>
            </Space>
            <Space direction={"horizontal"} wrap>
              <Radio.Group value={ownStatus} onChange={(e) => { setOwnStatus(e.target.value) }}>
                <Radio.Button value="owned">Во владении</Radio.Button>
                <Radio.Button value="all">Подключенные</Radio.Button>
              </Radio.Group>
              <Radio.Group value={yandexStatus} onChange={(e) => { setYandexStatus(e.target.value) }}>
                <Radio.Button value="all">Все</Radio.Button>
                <Radio.Button value="connected">Есть в Яндексе</Radio.Button>
                <Radio.Button value="disconnected">Нет в Яндексе</Radio.Button>
              </Radio.Group>
            </Space>
          </div>
          <div style={{width: '40%', float: 'right'}} >
            <Space style={{float: "right"}} direction="horizontal">
              <Switch
                checkedChildren={<span>Без интервала ТО</span>}
                unCheckedChildren={<span>Все</span>}
                onChange={(e) => {
                  setFilter({...filter, maintenanceIntervalMissing: e ? true : null});
                  setSelected([]);
                }}
              />
              <UpdateButton disabled={loading} onClick={() => { setUpdate(u => u + 1) }}/>
              <Button disabled={loading} onClick={() => { setEditedRecordId(0) }} type={"primary"}>Добавить</Button>
              <CancelButton onClick={() => { onExportClick() }} value="Экспорт"/>
              <Upload
                name={"file"}
                action={apiClient.actionUrl(`park/vehicles/import`)}
                headers={apiClient.requestConfig().headers}
                multiple={false}
                showUploadList={false}
                onChange={(info) => {
                  if (info.file.status !== 'uploading') {
                    setLoading(true);
                  }
                  if (info.file.status === 'done') {
                    message.success(`Файл загружен`);
                    setUpdate(prevState => prevState + 1);
                    setLoading(false);
                  } else if (info.file.status === 'error') {
                    if(info.file.response.message) {
                      Modal.error({
                        title: 'Ошибка импорта',
                        width: 800,
                        content: (
                          <>
                            <table style={{width: '100%'}}>
                              <thead>
                                <tr>
                                  <th>Строка</th>
                                  <th>Ошибка</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  info.file.response.details
                                    ? info.file.response.details.map(row => {
                                      return (
                                        <tr>
                                          <td>{row.row}</td>
                                          <td>{row.message}</td>
                                        </tr>
                                      )
                                    })
                                    : (
                                      <tr>
                                        <td colSpan={2}>Нет данных</td>
                                      </tr>
                                    )
                                }
                              </tbody>
                            </table>
                          </>
                        ),
                        onOk() {},
                      });
                    } else if(info.file.response.detail) {
                      message.error(info.file.response.detail);
                    } else {
                      message.error("Ошибка");
                    }

                    setUpdate(prevState => prevState + 1);
                    setLoading(false);
                  }
                }}
              >
                <Button type="primary" icon={<UploadOutlined />}>Загрузить</Button>
              </Upload>
              <Dropdown
                disabled={loading || selected.length < 1}
                overlay={(
                  <Menu onClick={(e) => { executeSelectedRecordsOperation(e.key); }}>
                    <Menu.Item key={CMD_SET_MAINTENANCE_PERIOD}>
                      Установить интервал ТО
                    </Menu.Item>
                    <Menu.Item key={CMD_REMOVE}>
                      Удалить
                    </Menu.Item>
                  </Menu>
                )}>
                <Button style={{textAlign: "left"}}>
                  С выбранными <DownOutlined/>
                </Button>
              </Dropdown>
            </Space>
          </div>
          </div>
        </Row>
        <Row style={{marginBottom: 20}}>
          <Space direction={"horizontal"} style={{width: '100%'}} wrap>
            <Select
              showSearch
              allowClear
              defaultValue={[]}
              style={{width: 400}}
              placeholder="Арендодатель"
              onChange={(value) => {
                setFilter(prevState => {
                  return {...prevState, "owner": value}
                })
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {ownerOptions.map((el) => (<Option key={el.id} value={el.id}>{el.title}</Option>))}
            </Select>
            <Select
              showSearch
              allowClear
              defaultValue={[]}
              style={{width: 400}}
              placeholder="Водитель"
              onChange={(value) => {
                setFilter(prevState => {
                  return {...prevState, "driver": value}
                })
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {driverOptions.map((el) => (<Option key={el.id} value={el.id}>{el.title}</Option>))}
            </Select>
            <Select
              showSearch
              allowClear
              defaultValue={[]}
              style={{width: 400}}
              placeholder="Марка/Модель"
              onChange={(value) => {
                setFilter({...filter, "brandModel": value})
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {modelOptions.map((el) => (<Option key={el} value={el}>{el}</Option>))}
            </Select>
            <Select
              allowClear
              mode="multiple"
              placeholder="Статус"
              value={vehicleStatusFilter}
              style={{width: 300}}
              onChange={(data) => { setVehicleStatusFilter(data); }}
            >
              {
                ParkStatuses.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
              }
            </Select>
            <Select
              showSearch
              allowClear
              defaultValue={[]}
              style={{width: 400}}
              placeholder="Группа"
              onChange={(value) => {
                setFilter(prevState => {
                  return {...prevState, "group": value}
                })
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {paymentGroups.map((el) => (<Option key={el.id} value={el.id}>{el.title}</Option>))}
            </Select>
            <Select
              showSearch
              allowClear
              defaultValue={[]}
              style={{width: 400}}
              placeholder="Регион"
              onChange={(value) => {
                setFilter(prevState => {
                  return {...prevState, "region": value}
                })
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {regions.map((el) => (<Option key={el.id} value={el.id}>{el.title}</Option>))}
            </Select>
          </Space>
        </Row>
        <Table
          showSorterTooltip={false}
          columns={cols}
          dataSource={records}
          loading={loading}
          rowKey="id"
          scroll={{x: 1600}}
          size="small"
          sticky
          rowSelection={{
            selectedRowKeys: selected,
            onChange: (selectedRowKeys) => {
              setSelected(selectedRowKeys);
            },
          }}
          rowClassName={(record, index) => {
            let className = "";
            if(record.alert_msg) {
              className += " with-alert";
            }
            return className;
          }}
          pagination={{
            position: ['bottomRight', 'topRight'],
            defaultPageSize: 50,
            pageSizeOptions: [50, 50, 100, 200],
            hideOnSinglePage: false,
          }}
        />
        </>
      )}
      {selectedTabKey === "1" && (
        <VehiclesModel onClick={() => setSelectedTabKey("0")}/>
      )}
      {selectedTabKey === "2" && (
        <VehiclesClass onClick={() => setSelectedTabKey("0")}/>
      )}

      {
        editedRecordId !== null
          ? (
            <VehicleEditDialog
              visible={true}
              vehicleId={editedRecordId}
              onClose={(updated) => {
                setEditedRecordId(null);
                if(updated) {
                  setUpdate(prevState => prevState + 1);
                }
              }}
            />
          ) : null
      }

      {
        showOnlinegibddSyncErrorReport && (
          <OnlinegibddSyncErrorReportDialog
            visible={showOnlinegibddSyncErrorReport}
            onOK={() => {
              setShowOnlinegibddSyncErrorReport(false);
            }}
            onCancel={() => {
              setShowOnlinegibddSyncErrorReport(false);
            }}
          />
        )
      }

      {
        numberInputRequest && (
          <NumberInputDialog
            title={numberInputRequest.title}
            onOK={(value) => {
              numberInputRequest.onOK(value);
              setNumberInputRequest(null);
            }}
            onCancel={() => { setNumberInputRequest(null) }}
          />
        )
      }
    </>
  );
}