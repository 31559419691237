import React, {useEffect, useState} from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Radio,
  Select,
  Space,
  Switch,
  Table,
  Menu,
  Dropdown,
  Popover,
  DatePicker,
  Divider,
  Checkbox,
  Tooltip
} from "antd";
// paper plane outline
import {
  EditFilled,
  QuestionCircleOutlined,
  BellFilled,
  BellOutlined,
  SendOutlined,
  EditOutlined,
  LinkOutlined,
  DownOutlined,
  AccountBookOutlined,
  InfoCircleFilled, WarningTwoTone, MessageOutlined, EyeInvisibleOutlined, ClearOutlined
} from "@ant-design/icons";
import CommentEditor from "./CommentEditor";
import CommentDialog from "../common/CommentDialog";
import AddNotificationDialog from "../common/AddNotificationDialog";
import DriverDetails from "./components/driverdetails/DriverDetails";
import SelectWorkConditionsDialog from "./components/SelectWorkConditionsDialog";
import TodayTransactionsDialog from "./components/TodayTransactionsDialog";

import {apiClient} from "../../../services/ApiClient";
import {ActivityStatuses, WorkStatuses} from "../../common/AppEnums"

import moment from "moment";
import numeral from "numeral"

import "./DriversPage.css"
import SearchSelect from "../../common/SearchSelect";
import DriverCreateDialog from "./DriverCreateDialog";
import {windowService} from "../../../services/WindowService";
import LinkButton from "../../buttons/LinkButton";

const {Search, TextArea} = Input;
const {Option} = Select;
const { RangePicker } = DatePicker;

const notifySound = new Audio('/assets/drivers-page-notification.wav');

const STATUS_FIRED = "fired";
const STATUS_WORKING = "working";
const FILTER_WORK_CONDITION_ALERT = "workConditionAlert";

const CMD_COMMENTS_REPORT = "sendCommentsReport";
const CMD_SELECT_WORK_CONDITION = "showSelectWorkConditions";
const CMD_SKIP_WORK_CONDITION_CHECK = "skipWorkConditionCheck";

export default function DriversPage() {
  let userId = 0;
  let updateLimitHandle = null;

  let [drivers, setDrivers] = useState([]);
  let [driversCount, setDriversCount] = useState(0);
  let [workConditionAlertCount, setWorkConditionAlertCount] = useState(0);
  let [dataSource, setDataSource] = useState([]);
  let [selectedWorkStatus, setSelectedWorkStatus] = useState("");
  let [selectedActivityStatus, setSelectedActivityStatus] = useState("");
  let [selectedWorkCondition, setSelectedWorkCondition] = useState("");
  let [selectedSearch, setSelectedSearch] = useState("");
  let [displayedComments, setDisplayedComments] = useState([]);
  let [editCommentDialogVisible, setEditCommentDialogVisible] = useState(false);
  let [editedComment, setEditedComment] = useState(null);
  let [blockActionDialogVisible, setBlockActionDialogVisible] = useState(false);
  let [blockAction, setBlockAction] = useState(null);
  let [addNotificationAction, setAddNotificationAction] = useState(null);
  let [showDriverDetails, setShowDriverDetails] = useState(null);
  let [workStatus, setWorkStatus] = useState(STATUS_WORKING);
  let [driversUpdateState, setDriversUpdateState] = useState(0);
  let [selectedRecords, setSelectedRecords] = useState([]);
  let [allWorkCOnditions, setAllWorkCOnditions] = useState([]);
  let [selectedTaxiPark, setSelectedTaxiPark] = useState("");
  let [taxiParks, setTaxiParks] = useState([]);
  let [deletePeriod, setDeletePeriod] = useState(null);
  let [driversLoading, setDriversLoading] = useState(false);
  let [todayTransactionsDriver, setTodayTransactionsDriver] = useState(null);
  let [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    position: 'topRight',
    defaultPageSize: 50,
    pageSizeOptions: [50, 100, 200]
  });
  let [sorter, setSorter] = useState({});
  let [selectedRecordsOperation, setSelectedRecordsOperation] = useState(null);
  // для поиска по ТС
  let [vehicles, setVehicles] = useState([]);
  let [vehicle, setVehicle] = useState('');
  let [filterName, setFilterName] = useState('');
  let [birthdays, setBirthdays] = useState([]);
  let [createRecord, setCreateRecord] = useState(false);
  let [finesProcessing, setFinesProcessing] = useState("all");
  let [visibleColumnsList, setVisibleColumnsList] = useState(false);
  let [columnsStatus, setColumnsStatus] = useState({
    fio: true,
    work_status: true,
    phone: true,
    car_full_details: true,
    taxipark: true,
    work_condition: true,
    balance: true,
    fines_balance: true,
    deposit_balance: true,
    day_payments_total: true,
    limit: true,
    car_tracker: true,
  });
  let [columnsChange, setColumnsChange] = useState(columnsStatus);
  // let [columnsList, setColumnsList] = useState(["fio", "work_status", "phone", "car_full_details", "taxipark", "work_condition", "balance", "fines_balance", "deposit_balance", "day_payments_total", "limit", "car_tracker"]);

  const handleLimitChanged = (record, limit) => {
    if (updateLimitHandle) {
      clearTimeout(updateLimitHandle);
    }
    updateLimitHandle = setTimeout(() => {
      message.info("Обновление лимита...");
      apiClient.updateDriverLimit(record.id, limit)
        .then(res => {
          setDrivers([res.driver, ...drivers.filter(e => e.id !== record.id)]);

          message.success("Лимит обновлен");
        })
        .catch(err => {
          message.error("Операция не выполнена")
        });
    }, 1000);
  };

  // подтсверждение и выполнение блокировки
  const handleBlockRequest = (record) => {
    setBlockAction({"driver": record});
    setBlockActionDialogVisible(true);
  };

  const handleEditNotification = (record) => {
    setAddNotificationAction({"driver": record});
  };

  const handleRemoveNotification = (record) => {
    apiClient.removeDriverNotification(record.id)
      .then(() => {
        record.notification_dt = null;
        record.notification_expired_dt = null;
        record.notification_early_expired_dt = null;
        record.notification_early_time = null;
        record.notification_comment = null;
        setDrivers([record, ...drivers.filter(e => e.id !== record.id)]);
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const columns = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    {
      title: "",
      dataIndex: "notification_dt",
      align: "center",
      width: 100,
      render: (text, record, index) => {
        let widget = null;
        if (record.notification_dt) {
          if (!record.notification_expired_dt && !record.notification_early_expired_dt) {
            // show remove icon
            widget = (
              <Button title={`Изменить уведомление`}
                      type="link"
                      icon={<BellFilled/>}
                      onClick={() => { handleEditNotification(record) }}/>
            );
          } else {
            // show notification
            widget = (
              <Button title={`Изменить уведомление`}
                      type="link"
                      icon={<BellOutlined style={{color: '#3F3D56'}}/>}
                      onClick={() => { handleEditNotification(record) }}/>
            );
          }
        } else {
          // add notification
          widget = (<Button title="Добавить уведомление"
                         onClick={() => {
                           handleEditNotification(record);
                         }}
                         style={{opacity: '0.5'}}
                         type="link"
                         icon={<BellOutlined style={{color: '#B9B9B9'}}/>}/>);
        }
        return (
          <Space direction="horizontal">
            <Button icon={<EditFilled/>} onClick={() => {
              setDriversLoading(true);
              apiClient.getDriverDetails(record.id)
                .then(res => setShowDriverDetails(res.driver))
                .catch(err => message.error(err))
                .finally(() => { setDriversLoading(false); });
            }} type="link"/>
            {widget}
          </Space>
        );
      }
    },
    {...(columnsStatus.work_status === true &&
      {
        title: "Статус",
        width: 100,
        dataIndex: "work_status",
        align: "center",
        render: (text, record, index) => {
          const ws = WorkStatuses.find(s => s.value === record.work_status);
          return <span title={ws.title} className={`badge badge--pointer badge--${ws.value}`}>{ws.shortText}</span>;
        }
      }
    )},
    {
      title: "ФИО",
      width: 300,
      dataIndex: "fio",
      render: (text, record, index) => {
        if(record.work_condition_alert) {
          return (
            <>
              <WarningTwoTone twoToneColor={'#eb2f96'} title="Проверьте условия работы"/>
              {record.fio}
            </>
          )
        }
        return (
          <Space direction="horizontal">
            {record.fio}
          </Space>
        );
      }
    },
    {...(columnsStatus.phone === true &&
      {
        title: "Телефон",
        align: "center",
        width: 150,
        dataIndex: "phone",
      }
    )},
    {...(columnsStatus.car_full_details === true &&
      {
        title: "Авто",
        width: 300,
        align: "center",
        dataIndex: "car_full_details",
        render: (text, record, index) => {
          return record.current_rent && (
            <LinkButton
              label={<><LinkOutlined style={{width: "15px"}}/>  {record.car_full_details}</>}
              onClick={() => {
                windowService.openRouteWindow(`cp/park/vehicles/${record.current_rent?.id}`);
              }}
            />
          );

        }
      }
    )},
    {...(columnsStatus.taxipark === true &&
      {
        title: "Парк",
        width: 60,
        align: "center",
        render: (text, record, index) => {
          return record.taxipark
            ? (
              <Popover content={record.taxipark.title}>
                <InfoCircleFilled style={{ color: '#58D3A5', fontSize: '20px' }}/>
              </Popover>
            )
            : ""
        }
      }
    )},
    {...(columnsStatus.work_condition === true &&
      {
        title: "Условия работы",
        width: 150,
        dataIndex: "work_condition",
        align: "center",
        render: (text, record, index) => {
          return record.work_condition
            ? (
              <Popover content={record.work_condition.title}>
                <InfoCircleFilled style={{ color: '#58D3A5', fontSize: '20px' }} />
              </Popover>
            )
            : ""
        }
      }
    )},
    {...(columnsStatus.balance === true &&
      {
        title: <Tooltip
          placement="top"
          title="Этот баланс всегда совпадает с балансом водителя в личном кабинете Яндекс.Таксометр."
        >
          <p className="tooltip-text-style">Баланс</p>
        </Tooltip>,
        width: 100,
        dataIndex: "balance",
        align: "center",
        defaultSortOrder: "ascend",
        sorter: (a, b) => {
          if(a.notification_expired_dt || a.notification_early_expired_dt) {
            return -1;
          }
          if(b.notification_expired_dt || b.notification_early_expired_dt) {
            return 1;
          }
          return a.balance - b.balance;
        },
      }
    )},
    {...(columnsStatus.fines_balance === true &&
      {
        title: <Tooltip
          placement="top"
          title="Внутренний баланс, используется для списаний средств с водителя (Штрафы, ЦКАД и другие). 
          Показывает задолженность водителя перед парком, которая еще не погашена."
        >
          <p className="tooltip-text-style">Сводный баланс</p>
        </Tooltip>,
        dataIndex: "fines_balance",
        width: 150,
        align: "center",
        sorter: (a, b) => {
          if(a.notification_expired_dt || a.notification_early_expired_dt) {
            return -1;
          }
          if(b.notification_expired_dt || b.notification_early_expired_dt) {
            return 1;
          }
          return a.fines_balance - b.fines_balance;
        },
        render: (text, record, index) => {
          let style = {};
          let balance = record.fines_balance;
          if(balance > 0) {
            style.color = 'darkred';
          }
          return <span style={style}>{Number(balance * -1).toFixed(2)}</span>;
        }
      }
    )},
    {...(columnsStatus.deposit_balance === true &&
      {
        title: <Tooltip
          placement="top"
          title="Внутренний баланс, используется для контроля оплаты залога  за транспортное средство водителем. 
          Показывает накопленный залог водителя."
        >
          <p className="tooltip-text-style">Депозит</p>
        </Tooltip>,
        width: 200,
        dataIndex: "deposit_balance",
        align: "center",
        render: (text, record, index) => {
          return (
          <span>{Number(record.deposit_paid).toFixed(0)}/{Number(record.deposit_balance).toFixed(0)}</span>
          );
        }
      }
    )},
    {...(columnsStatus.day_payments_total === true &&
      {
        title: "Списаний сегодня",
        width: 160,
        dataIndex: "day_payments_total",
        align: "center",
        render: (text, record, index) => {
          if(!record.day_payments_dt || moment(record.day_payments_dt).day() !== moment().day()) {
            return "";
          }
          if(record.day_payments_total > 0 || record.day_payments_total < 0) {
            return (
              <Button type={"link"} onClick={() => { setTodayTransactionsDriver(record) }}>{`${numeral(record.day_payments_total).format("0.00")}`}</Button>
            );
          }
          return "";
        }
      }
    )},
    {...(columnsStatus.limit === true &&
      {
        title: <Tooltip
          placement="top"
          title="Лимит устанавливается для каждого водителя индивидуально, для контроля баланса. 
          Если  баланс водителя выходит за установленный лимит, то запись водителя подсвечивается красным цветом. 
          В случае если транспортное средство заблокировано в связи с отрицательным балансом, 
          лимит устанавливается для автоматической разблокировки ТС 
          (при оплате до суммы выше лимита, блокировка с ТС снимается автоматически)."
        >
          <p className="tooltip-text-style">Лимит</p>
        </Tooltip>,
        width: 120,
        dataIndex: "limit",
        align: "center",
        render: (text, record, index) => {
          return (
            <InputNumber defaultValue={record.limit} onChange={(val) => {
              handleLimitChanged(record, val);
            }}/>
          );
        }
      }
    )},
    {...(columnsStatus.car_tracker === true &&
      {
        title: <Tooltip
          placement="topRight"
          title="Вы можете заблокировать водителя, двигатель его транспортного средства будет остановлен
          (Для работы этой функции в настрйоках организаций пропишите доступ к сервису 'Где мои')."
        >
          <p className="tooltip-text-style">Блок</p>
        </Tooltip>,
        width: 70,
        dataIndex: "id",
        align: "center",
        sorter: (a, b) => {
          if (a.car_tracker && b.car_tracker) {
            if (a.car_tracker.is_blocked && !b.car_tracker.is_blocked) {
              return 1;
            }
            if (b.car_tracker.is_blocked && !a.car_tracker.is_blocked) {
              return -1;
            }
            if (b.car_tracker.is_blocked && a.car_tracker.is_blocked) {
              return 0;
            }
          }
          if (a.car_tracker && !b.car_tracker) {
            return 1;
          }
          if (!a.car_tracker && b.car_tracker) {
            return -1;
          }
          return 0;
        },
        render: (text, record, index) => {
          if (!record.car_tracker) {
            return (
              <span>{""}</span>
            );
          }
          return (
            <Popconfirm
              title={`${record.car_tracker.is_blocked ? "Разблокировать" : "Заблокировать"} автомобиль "${record.car_tracker.label}"?`}
              onConfirm={() => handleBlockRequest(record)}
              okText="Да"
              cancelText="Нет">
              <Switch title="Изменить статус блокировки" checked={record.car_tracker.is_blocked}/>
            </Popconfirm>
          );
        }
      }
    )},
    {
      title: "",
      align: "center",
      width: 20,
    }
  ]

  // const handleColumnsStatus = () => {
  //   let value = columnsChange;
  //   console.log({columnsChange});
  //   options.map((s) => value.find((r) => r === s.value) || s.value === "fio" ? s.status = true : s.status = false)
  //   let colStatus = columnsStatus;
  //   options.map((s) => colStatus[s.value] = s.status);
  //   console.log({colStatus});
  //   setColumnsStatus(colStatus);
  //   setVisibleColumnsList(false);
  // };

  const handleRowExpanded = (record) => {
    reloadDriverComments(record.id);
  };

  const handleCommentEdit = (e) => {
    setEditedComment(e);
    setEditCommentDialogVisible(true);
  };

  const reloadDriverComments = (id) => {
    apiClient.getDriverComments(id, 'drivers_page,drivers_page_notification,trackers_page')
      .then(data => {
        let otherComments = displayedComments
          .filter(e => e.driver !== id);
        otherComments.push(...data);
        setDisplayedComments(otherComments);
      });
  };

  const renderRowDetails = (record) => {
    return (
      <div style={{margin: "0 10px"}}>
        <Space direction="vertical" size="small" style={{width: "100%"}}>
          <h1>Комментарии</h1>
          <CommentEditor
            onSubmit={(value) => {
              return new Promise((resolve, reject) => {
                if (!value) {
                  message.error("Необходимо ввести комментарий");
                  reject();
                  return;
                }
                apiClient.addDriverComment(record.id, value)
                  .then(res => {
                    resolve();
                    reloadDriverComments(record.id);
                  })
                  .catch(err => reject(err));
              });
            }}
          />
          {displayedComments.length > 0
            ? (
              <Table dataSource={displayedComments.filter(e => e.driver === record.id)}
                className="select-text-for-wrap"
                columns={[
                {
                  title: "",
                  align: "center",
                  width: 30,
                },
                  {
                    title: '',
                    dataIndex: 'id',
                    render: (text, record, index) => {
                      return (
                        <Space direction="horizontal">
                          <Button icon={<EditOutlined/>} onClick={() => handleCommentEdit(record)} type="link"/>
                        </Space>
                      );
                    },
                  },
                  {
                    title: 'Дата',
                    dataIndex: 'display_dt',
                  },
                  {
                    title: 'Автор',
                    dataIndex: 'created_by',
                  },
                  {
                    title: 'Комментарий',
                    dataIndex: "comment"
                  },
                  {
                    title: "",
                    align: "center",
                    width: 20,
                  }
                ]}
                size="small"
                rowKey="id"
                style={{width: "100%"}}
                pagination={{position: 'topRight', defaultPageSize: 25, pageSizeOptions: [25, 50, 75]}}
              />
            )
            : (<div style={{textAlign: "center", margin: "10px"}}>Нет записей</div>)}
        </Space>
      </div>
    )
  };

  useEffect(() => {
    apiClient.getWorkConditions()
      .then(res => {
        setAllWorkCOnditions(res.records);
      })
      .catch(err => {
        message.error(err);
      });
    apiClient.getParks()
      .then(res => {
        setTaxiParks(res.records);
      })
      .catch(err => {
        message.error(err);
      });
    apiClient.getVehicles({owned: true, documents_disabled: false}, "minimal")
      .then(res => {
        setVehicles(res.records);
      })
      .catch(err => message.error(err));
  }, []);

  useEffect(() => {
    const filters = {
      filterName,
      accountStatus: workStatus,
      workStatus: selectedWorkStatus,
      activityStatus: selectedActivityStatus,
      taxiPark: selectedTaxiPark,
      workCondition: selectedWorkCondition,
      deletePeriod: workStatus === STATUS_FIRED && deletePeriod
        ? deletePeriod.map(el => el.format('YYYY-MM-DD')).join()
        : null,
      search: selectedSearch,
      vehicle: vehicle,
      finesProcessing: finesProcessing,
      sortField: sorter.field,
      sortOrder: sorter.order,
      page: pagination.current,
      pageSize: pagination.pageSize
    };
    setDriversLoading(true);
    apiClient.getDriversList(filters)
      .then((res) => {
        let data = res.drivers.map(d => {
          d.key = d.id;
          return d;
        });
        setDrivers(data);
        setDriversCount(res.drivers_count);
        setWorkConditionAlertCount(res.work_condition_alert_count);
        setPagination(prevState => {return {...prevState, total: res.drivers_count}})
      })
      .catch((err => { message.error(`Ошибка загрузки данных.`) }))
      .finally(() => { setDriversLoading(false) });
  }, [
    userId,
    workStatus,
    selectedWorkStatus,
    selectedActivityStatus,
    selectedTaxiPark,
    selectedWorkCondition,
    driversUpdateState,
    selectedSearch,
    deletePeriod,
    vehicle,
    filterName,
    finesProcessing,
    sorter
  ]);

  useEffect(() => {
    setDataSource(drivers);
  }, [drivers]);

  // запуск нотификаций
  useEffect(() => {
    const refresh = () => {
      setDataSource(prevState => {
        const now = moment();
        let notify = false;
        let update = false;
        prevState.forEach(driver => {
          if (driver.notification_dt) {
            if (driver.notification_early_time) {
              let parts = driver.notification_early_time.split(":");
              let earlyNotificationDate = moment(driver.notification_dt);
              earlyNotificationDate.subtract(parts[0], "hours");
              earlyNotificationDate.subtract(parts[1], "minutes");
              earlyNotificationDate.subtract(parts[2], "seconds");
              if (earlyNotificationDate.isSame(now, "minute")) {
                if (!driver.notification_early_expired_dt) {
                  driver.notification_early_expired_dt = now;
                  update = true;
                  notify = true;
                }
              } else {
                if (driver.notification_early_expired_dt) {
                  driver.notification_early_expired_dt = null;
                  update = true;
                }
              }
            }

            let notificationDate = moment(driver.notification_dt);
            if(notificationDate.isSame(now, "minute") && !driver.notification_expired_dt) {
              driver.notification_expired_dt = now;
              update = true;
              notify = true;
            } else if (!notificationDate.isAfter(now) && !driver.notification_expired_dt) {
              driver.notification_expired_dt = now;
              update = true;
            }
          }
        });
        if(notify) {
          notifySound.play();
        }
        if(update) {
          prevState = [...prevState];
        }
        return prevState;
      });
    };
    const subscription = setInterval(refresh, 5000);

    return () => clearInterval(subscription);
  }, []);

  useEffect(() => {
    // загрузка ДР
    apiClient.getUserBirthdays()
      .then(res => {
        setBirthdays(res.records);
      })
      .catch(err => console.error(err));
  }, []);

  const executeSelectedRecordsOperation = operationKey => {
    setSelectedRecordsOperation({"key": operationKey});
  };

  const assignWorkConditions = (workConditionId, items) => {
    Promise.all(items.map(el => apiClient.updateDriverWorkCondition(el, {id: workConditionId})))
      .then(res => {
        message.success("Выполнено успешно");
        // reload
        setDriversUpdateState(prevState => prevState + 1)
      })
      .catch(err => {
        message.error(err);
      })
      .finally(() => {
        setSelectedRecords([]);
      })
  };

  const formatVehicleTitle = el => {
    return el ? `${el.brand} ${el.model} ${el.reg_number}` : null;
  };

  return (
    <React.Fragment>
      {
        birthdays.map(el => (
          <>
            <div key={el.id} style={{textAlign: 'center', fontSize: '1.1em', fontWeight: 'bold'}}>
              {el.message}
            </div>
          </>
        ))
      }
      <PageHeader
        className="site-page-header"
        title="Водители"
        extra={
          <div style={{textAlign: "right"}}>
            <Space direction={"horizontal"}>
              {
                workConditionAlertCount > 0
                  ? (
                    <>
                      <Switch
                        checkedChildren={<span>Приостановлено списаний: {workConditionAlertCount}</span>}
                        unCheckedChildren={<span>Приостановлено списаний: {workConditionAlertCount}</span>}
                        onChange={(e) => {
                          e ? setFilterName(FILTER_WORK_CONDITION_ALERT) : setFilterName(null);
                          setSelectedRecords([]);
                        }}
                      />
                      <Tooltip
                        placement="top"
                        title="При выборе этого фильтра Вы увидите водителей, у которых приостановлены списания."
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </>
                  ) : null
              }
              <Divider type={"vertical"}/>
              <div style={{textAlign: "right"}}>
                Всего: {driversCount}
              </div>
              <Button
                onClick={() => {
                  setCreateRecord(true);
                }}
              >Создать</Button>
              {/* <Dropdown
                visible={visibleColumnsList}
                overlay={(
                  <div style={{background: "white", borderRadius: 10}}>
                    <Menu
                      className="list-scroll"
                      onClick={(e) => {setColumnsChange({...columnsChange, ...{[e.key]: columnsChange[e.key] === false
                        ? true : false}})}}
                    >
                      <Menu.Item style={{height: 28, marginBottom: 0}} key="fio" disabled={true}>
                        <Checkbox
                          disabled={true}
                          defaultChecked={true}
                          checked={true}
                        />   ФИО
                      </Menu.Item>
                      <Menu.Item style={{height: 30, marginBottom: 0}} key="work_status">
                        <Checkbox
                          defaultChecked={columnsChange["work_status"]}
                          checked={columnsChange["work_status"]}
                          onClick={(e) => {setColumnsChange({...columnsChange, ...{"work_status": e.target.checked}})}}
                        />   Статус
                      </Menu.Item>
                      <Menu.Item style={{height: 30, marginBottom: 0}} key="phone">
                        <Checkbox
                          defaultChecked={columnsChange["phone"]}
                          checked={columnsChange["phone"]}
                          onClick={(e) => {setColumnsChange({...columnsChange, ...{"phone": e.target.checked}})}}
                        />   Телефон
                      </Menu.Item>
                      <Menu.Item style={{height: 30, marginBottom: 0}} key="car_full_details">
                        <Checkbox
                          defaultChecked={columnsChange["car_full_details"]}
                          checked={columnsChange["car_full_details"]}
                          onClick={(e) => {setColumnsChange({...columnsChange, ...{"car_full_details": e.target.checked}})}}
                        />   Авто
                      </Menu.Item>
                      <Menu.Item style={{height: 30, marginBottom: 0}} key="taxipark">
                        <Checkbox
                          defaultChecked={columnsChange["taxipark"]}
                          checked={columnsChange["taxipark"]}
                          onClick={(e) => {setColumnsChange({...columnsChange, ...{"taxipark": e.target.checked}})}}
                        />   Таксипарк
                      </Menu.Item>
                      <Menu.Item style={{height: 30, marginBottom: 0}} key="work_condition">
                        <Checkbox
                          defaultChecked={columnsChange["work_condition"]}
                          checked={columnsChange["work_condition"]}
                          onClick={(e) => {setColumnsChange({...columnsChange, ...{"work_condition": e.target.checked}})}}
                        />   Условия работы
                      </Menu.Item>
                      <Menu.Item style={{height: 30, marginBottom: 0}} key="balance">
                        <Checkbox
                          defaultChecked={columnsChange["balance"]}
                          checked={columnsChange["balance"]}
                          onClick={(e) => {setColumnsChange({...columnsChange, ...{"balance": e.target.checked}})}}
                        />   Баланс
                      </Menu.Item>
                      <Menu.Item style={{height: 30, marginBottom: 0}} key="fines_balance">
                        <Checkbox
                          defaultChecked={columnsChange["fines_balance"]}
                          checked={columnsChange["fines_balance"]}
                          onClick={(e) => {setColumnsChange({...columnsChange, ...{"fines_balance": e.target.checked}})}}
                        />   Сводный баланс
                      </Menu.Item>
                      <Menu.Item style={{height: 30, marginBottom: 0}} key="deposit_balance">
                        <Checkbox
                          defaultChecked={columnsChange["deposit_balance"]}
                          checked={columnsChange["deposit_balance"]}
                          onClick={(e) => {setColumnsChange({...columnsChange, ...{"deposit_balance": e.target.checked}})}}
                        />   Депозит
                      </Menu.Item>
                      <Menu.Item style={{height: 30, marginBottom: 0}} key="day_payments_total">
                        <Checkbox
                          defaultChecked={columnsChange["day_payments_total"]}
                          checked={columnsChange["day_payments_total"]}
                          onClick={(e) => {setColumnsChange({...columnsChange, ...{"day_payments_total": e.target.checked}})}}
                        />   Списаний сегодня
                      </Menu.Item>
                      <Menu.Item style={{height: 30, marginBottom: 0}} key="limit">
                        <Checkbox
                          defaultChecked={columnsChange["limit"]}
                          checked={columnsChange["limit"]}
                          onClick={(e) => {setColumnsChange({...columnsChange, ...{"limit": e.target.checked}})}}
                        />   Лимит
                      </Menu.Item>
                      <Menu.Item style={{height: 30, marginBottom: 0}} key="car_tracker">
                        <Checkbox
                          defaultChecked={columnsChange["car_tracker"]}
                          checked={columnsChange["car_tracker"]}
                          onClick={(e) => {setColumnsChange({...columnsChange, ...{"car_tracker": e.target.checked}})}}
                        />   Блок
                      </Menu.Item>
                    </Menu>
                    <Divider style={{ margin: 0 }} />
                    <Space style={{ padding: 8 }}>
                      <Button style={{width: "210px"}} size="small" type="primary" onClick={() => {setVisibleColumnsList(false); setColumnsStatus(columnsChange)}}>Применить</Button>
                    </Space>
                  </div>
                )
                }>
                  <Button onClick={() => {visibleColumnsList === true ? setVisibleColumnsList(false) : setVisibleColumnsList(true)}} style={{width: "190px", textAlign: "left"}}>
                    Выберите столбцы <DownOutlined style={{marginLeft: "30px"}}/>
                  </Button>
                </Dropdown> */}
              {
                selectedRecords.length > 0
                ? (
                  <>

                    <Divider type={"vertical"}/>
                    <span>{selectedRecords.length > 0 ? `Выбрано: ${selectedRecords.length}` : ""}</span>
                    <Dropdown style={{width: "210px"}} disabled={selectedRecords.length < 1}
                              overlay={(
                                <Menu onClick={(e) => {
                                  executeSelectedRecordsOperation(e.key);
                                }}>
                                  <Menu.Item key={CMD_SELECT_WORK_CONDITION} icon={<AccountBookOutlined/>}>
                                    Назначить условия работы
                                  </Menu.Item>
                                  <Menu.Item key={CMD_SKIP_WORK_CONDITION_CHECK} icon={<EyeInvisibleOutlined/>}>
                                    Убрать из уведомления
                                  </Menu.Item>
                                  <Menu.Item key={CMD_COMMENTS_REPORT} icon={<MessageOutlined/>}>
                                    Отправить отчет
                                  </Menu.Item>
                                </Menu>
                              )}>
                      <Button  style={{width: "190px", textAlign: "left"}}>
                        {`С выбранными...   `} <DownOutlined/>
                      </Button>
                    </Dropdown>

                  </>
                ) : null
              }
            </Space>
          </div>
        }
      />
      <Space direction="horizontal" align={{width: '100%'}} wrap>
        {/* ... */}
        <Search
          allowClear
          placeholder="Поиск по ФИО"
          onSearch={(value, event) => {
            setSelectedSearch(value);
            setPagination(prevState => { return {...prevState, current: 1} });
          }}
          enterButton
          style={{width: 400}}
        />
        <SearchSelect
          placeholder="Авто"
          style={{width: 300}}
          options={vehicles.map(el => ({id: el.id, title: formatVehicleTitle(el)}))}
          value={formatVehicleTitle(vehicles.find(el => el.id === vehicle))}
          onSelect={(el) => {
            setVehicle(el?.id);
            setPagination(prevState => {return { ...prevState, current: 1} });
          }}
        />
        {/* ... */}
        <Radio.Group
          value={workStatus}
          onChange={(e) => {
            setWorkStatus(e.target.value);
            setPagination(prevState => { return {...prevState, current: 1} });
          }}>
          <Radio.Button value={STATUS_WORKING}>Работает</Radio.Button>
          <Radio.Button value={STATUS_FIRED}>Уволен</Radio.Button>
        </Radio.Group>
        {
          workStatus === STATUS_FIRED && (
            <RangePicker
              format="DD.MM.YYYY"
              value={deletePeriod}
              onChange={(dates) => {
                setDeletePeriod(dates)
              }}
            />
          )
        }
        {/* ... */}
        <Select
          style={{width: "240px"}}
          placeholder="Статус"
          value={WorkStatuses.find(el => el.value === selectedWorkStatus)?.text}
          defaultValue={[]}
          onChange={(value) => {
            setSelectedWorkStatus(value);
            setPagination(prevState => { return {...prevState, current: 1} });
          }}
          allowClear
        >
          {WorkStatuses.map((s) => (<Option key={s.value} value={s.value}>{s.text}</Option>))}
        </Select>
        {/* ... */}
        <Select
          style={{width: "150px"}}
          placeholder="Активность"
          value={ActivityStatuses.find(el => el.value === selectedActivityStatus)?.text}
          defaultValue={[]}
          onChange={(value) => {
            setSelectedActivityStatus(value);
            setPagination(prevState => { return {...prevState, current: 1} });
          }}
          allowClear
        >
          {ActivityStatuses.map((s) => (<Option key={s.value} value={s.value}>{s.text}</Option>))}
        </Select>
        {/* ... */}
        <Select
          allowClear
          showSearch
          style={{width: "200px"}}
          placeholder="Парк"
          value={taxiParks.find(el => el.id === selectedTaxiPark)?.title}
          defaultValue={[]}
          onChange={(value) => {
            setSelectedTaxiPark(value);
            setPagination(prevState => { return {...prevState, current: 1} });
          }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {taxiParks.map((s) => (<Option key={s.id} value={s.id}>{s.title}</Option>))}
        </Select>
        {/* ... */}
        <Select
          showSearch
          allowClear
          className="select-text"
          style={{width: "400px"}}
          placeholder="Условия работы"
          value={allWorkCOnditions.find(el => el.id === selectedWorkCondition)?.title}
          defaultValue={[]}
          onChange={(value) => {
            setSelectedWorkCondition(value);
            setPagination(prevState => { return {...prevState, current: 1} });
          }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {allWorkCOnditions.map((s) => (<Option className="select-text" key={s.id} value={s.id}>{s.title}</Option>))}
        </Select>
        <Radio.Group
          value={finesProcessing}
          onChange={(e) => {
            setFinesProcessing(e.target.value);
            setPagination(prevState => { return {...prevState, current: 1} });
          }}>
          <Radio.Button value={"enabled"}>Штрафы включены</Radio.Button>
          <Radio.Button value={"all"}>Все</Radio.Button>
          <Radio.Button value={"disabled"}>Штрафы отключены</Radio.Button>
        </Radio.Group>
        <Button
          icon={<ClearOutlined/>}
          disabled={driversLoading}
          onClick={ () => {
            setVehicle(null);
            setWorkStatus(STATUS_WORKING);
            setDeletePeriod(null);
            setSelectedWorkStatus("");
            setSelectedActivityStatus("");
            setSelectedTaxiPark("");
            setSelectedWorkCondition("");
            setFinesProcessing("all");
            setPagination(prevState => {
              return {
                ...prevState,
                current: 1,
              }
            });
          }}>Очистить фильтры</Button>
        {/* ... */}
      </Space>
      <div style={{margin: "10px 0", marginTop: 40, marginRight: 0}}>
        <Table
          showSorterTooltip={false}
          dataSource={dataSource}
          className="select-text"
          columns={columns}
          size="small"
          rowKey="id"
          loading={driversLoading}
          pagination={pagination}
          scroll={{x: 2100}}
          sticky
          rowSelection={{
            type: 'checkbox',
            preserveSelectedRowKeys: true,
            selectedRowKeys: selectedRecords,
            onChange: (selectedRowKeys) => {
              setSelectedRecords(selectedRowKeys);
            }
          }}
          expandable={{
            expandedRowRender: (record, index, indent, expanded) => expanded ? renderRowDetails(record) : null,
            onExpand: (expanded, record) => {
              if (expanded) {
                handleRowExpanded(record);
              }
            },
            expandIcon: ({expanded, onExpand, record}) =>
              expanded ? (
                <SendOutlined onClick={e => onExpand(record, e)}
                              style={{color: "#58D3A5", opacity: "0.3"}}/>
              ) : (
                <SendOutlined onClick={e => onExpand(record, e)} style={{color: "#58D3A5"}}/>
              )
          }}
          onChange={(pagination, filters, sorter) => {
            setPagination(pagination);
            setSorter(sorter);
          }}
          rowClassName={(record, index) => {
            let className = `row-${index % 2 === 0 ? "even" : "odd"}`;
            if (record.activity_status === 1) {
              className += " driver-activity-active";
            } else if (record.activity_status === 2) {
              className += " driver-activity-blocked";
            }
            if (record.work_condition_alert) {
              className += " driver-workcondition-alert";
            }
            return className;
          }}
        />
      </div>
      {
        editCommentDialogVisible
          ? (
            <Modal
              title="Комментарий"
              visible={editCommentDialogVisible}
              onOk={() => {
                apiClient.updateDriverComment(editedComment.driver, editedComment.id, editedComment.comment)
                  .then(() => {
                    reloadDriverComments(editedComment.driver);
                    setEditCommentDialogVisible(false);
                    message.info("Комментарий обновлен")
                  })
                  .catch(err => message.error("Не удалось обновить данные"));
              }}
              onCancel={() => {
                setEditCommentDialogVisible(false);
              }}
            >
              <Form.Item>
                <TextArea rows={4}
                          value={editedComment ? editedComment.comment : ""}
                          onChange={(e) => {
                            setEditedComment({
                              comment: e.target.value,
                              id: editedComment.id,
                              driver: editedComment.driver
                            })
                          }}/>
              </Form.Item>
            </Modal>
          ) : null
      }

      {
        // AddNotificationDialog
        addNotificationAction !== null
          ? (
            <AddNotificationDialog
              title="Уведомление"
              visible={true}
              item={addNotificationAction ? addNotificationAction.driver : null}
              onOk={(dt, comment, earlyHours) => {
                const driver = addNotificationAction.driver;
                if(dt) {
                  apiClient.setDriverNotification(driver.id, dt, comment, earlyHours)
                    .then(data => {
                      driver.notification_dt = dt;
                      driver.notification_comment = comment;
                      driver.notification_early_time = earlyHours;
                      driver.notification_expired_dt = null;
                      driver.notification_early_expired_dt = null;
                      setDrivers([driver, ...drivers.filter(e => e.id !== driver.id)]);
                      message.success("Уведомление обновлено");
                    })
                    .catch(err => message.error(err));
                }
                if(comment) {
                  apiClient.addDriverComment(driver.id, comment, 'drivers_page_notification')
                    .then(data => {
                      message.success("Комментарий добавлен");
                    })
                    .catch(err => {
                      message.error("Не удалось добавить комментарий. " + err);
                    });
                }
                setAddNotificationAction(null);
              }}
              onCancel={() => {
                setAddNotificationAction(null);
              }}
              onDelete={() => {
                handleRemoveNotification(addNotificationAction.driver);
                setAddNotificationAction(null);
              }}
            />
          ) : null
      }

      {
        showDriverDetails !== null
          ? (
            <DriverDetails
              title="Карточка водителя"
              visible={true}
              item={showDriverDetails}
              onClose={(updated) => {
                setShowDriverDetails(null);
                if(updated) {
                  setDriversUpdateState(prevState => prevState + 1);
                }
              }}/>
          ) : null
      }

      {
        /* диалог комментариев */
        blockActionDialogVisible
          ? (
            <CommentDialog
              title={blockAction ? `${blockAction.driver.car_tracker.is_blocked ? "Разблокировать" : "Заблокировать"} авто ${blockAction.driver.car_tracker.label}` : ""}
              label="Причина"
              visible={true}
              onOk={(comment) => {
                setBlockActionDialogVisible(false);
                apiClient.updateTrackerBlocked(blockAction.driver.car_tracker.id, !blockAction.driver.car_tracker.is_blocked, comment)
                  .then(data => {
                    blockAction.driver.car_tracker.is_blocked = !blockAction.driver.car_tracker.is_blocked;
                    setDrivers([blockAction.driver, ...drivers.filter(e => e.id !== blockAction.driver.id)]);
                    message.success("Уведомление установлено");
                  })
                  .catch(err => {
                    message.error("Ошибка");
                  });
              }}
              onCancel={() => {
                setBlockActionDialogVisible(false);
              }}
            />
          ) : null
      }

      {
        /* диалог выбора назначаемых условий работы */
        selectedRecordsOperation?.key === CMD_SELECT_WORK_CONDITION
          ? (
            <SelectWorkConditionsDialog
              visible={true}
              conditionsList={allWorkCOnditions}
              onOk={(workConditionId) => {
                assignWorkConditions(workConditionId, selectedRecords);
                setSelectedRecordsOperation(null);
              }}
              onCancel={() => {
                setSelectedRecordsOperation(null);
              }}
            />
          ) : null
      }

      {
        /* отображение списка транзакций сегодня */
        todayTransactionsDriver !== null
          ? (
            <TodayTransactionsDialog
              visible={true}
              driver={todayTransactionsDriver}
              onClose={() => {
                setTodayTransactionsDriver(null)
              }}
            />
          ) : null
      }

      {
        selectedRecordsOperation?.key === CMD_COMMENTS_REPORT
          ? (
            <Modal
              title={"Уведомление"}
              visible={true}
              onOk={() => {
                apiClient.sendDriversCommentsReport(selectedRecords, 'drivers_page,drivers_page_notification,trackers_page')
                  .then(res => {
                    message.info("Уведомление отправлено в чат");
                    setSelectedRecordsOperation(null);
                    setSelectedRecords([]);
                  })
                  .catch(err => message.error(err));
              }}
              onCancel={() => setSelectedRecordsOperation(null)}
            >
              <p>Последние комментарии у выбранных водителей будут отправлены в телеграмм чат "Диспетчерский чат"</p>
            </Modal>
          ) : null
      }

      {
        selectedRecordsOperation?.key === CMD_SKIP_WORK_CONDITION_CHECK
          ? (
            <Modal
              title={"Убрать из уведомлений"}
              visible={true}
              onOk={() => {
                if(!selectedRecordsOperation.skipDays || selectedRecordsOperation.skipDays < 1) {
                  message.warn("Выберите число дней");
                  return;
                }
                apiClient.setDriversWorkConditionAlertSkip(selectedRecords, {skipDays: selectedRecordsOperation.skipDays})
                  .then(res => {
                    setSelectedRecordsOperation(null);
                    setSelectedRecords([]);
                    setDriversUpdateState(prevState => prevState + 1);

                    message.info("Уведомления обновлены");
                  })
                  .catch(err => message.error(err));
              }}
              onCancel={() => setSelectedRecordsOperation(null)}
            >
              <p>Убрать из уведомлений на (дней):</p>
              <Select
                bordered
                placeholder="Выберите число дней"
                onChange={(value) => {
                  setSelectedRecordsOperation({...selectedRecordsOperation, skipDays: parseInt(value)})
                }}
              >
                <Option key={"1"} value={"1"}>1 день</Option>
                <Option key={"2"} value={"2"}>2 дня</Option>
              </Select>
            </Modal>
          ) : null
      }

      {
        createRecord
          ? (
            <DriverCreateDialog
              onOK={(recordId) => {
                windowService.openRouteWindow(`cp/drivers/${recordId}`);
                setCreateRecord(false);
                setDriversUpdateState(prevState => prevState + 1);
              }}
              onCancel={() => {
                setCreateRecord(false);
              }}
            />
          ) : null
      }

    </React.Fragment>
  );
}