import React, {useEffect, useState} from "react";
import {Col, message, Modal, Row, Table} from "antd";
import moment from "moment";
import {apiClient} from "../../../../services/ApiClient";

const rowStyle = {padding: '8px'};

export default function OnlinegibddSyncErrorReportDialog({visible, onOK, onCancel}) {

  let [records, setRecords] = useState([]);
  let [serviceRecordsCount, setServiceRecordsCount] = useState(0);
  let [crmRecordsCount, setCrmRecordsCount] = useState(0);

  useEffect(() => {
    apiClient.getVehicleReport('onlinegibddErrors')
      .then(res => {
        setRecords(res.records);
        setCrmRecordsCount(res.crm_count);
        setServiceRecordsCount(res.service_count);
      })
      .catch(err => message.error(err));
  }, [visible]);

  return (
    <Modal
      title={"Синхронизация"}
      visible={visible}
      width={1024}
      onOk={() => {
        onOK();
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Row gutter={[8, 0]} align="middle" style={rowStyle}>
        <Col md={6}>ТС в сервисе</Col>
        <Col md={12}>
          {serviceRecordsCount}
        </Col>
      </Row>
      <Row gutter={[8, 0]} align="middle" style={rowStyle}>
        <Col md={6}>ТС в CRM</Col>
        <Col md={12}>
          {crmRecordsCount}
        </Col>
      </Row>

      <Table
        dataSource={records}
        scroll={{x: 1000}}
        sticky
        columns={[
          {
            title: 'Дата события',
            dataIndex: 'update_dt',
            width: 180,
            render: (text, record, index) => {
              return `${moment(record.update_dt).format('DD.MM.YYYY HH:mm')}`;
            },
          },
          {
            title: "ТС",
            width: 200,
            render: (text, record, index) => {
              return record.taxi_car?.name;
            },
          },
          {
            title: "Сообщение",
            dataIndex: "error_message",
          },
        ]}
        size="small"
        rowKey="id"
      />
    </Modal>
  )
}