import React, {useEffect, useState} from "react";
import {DatePicker, TimePicker, Form, Input, Modal, Select, message, Switch, Space, Button} from "antd";
import {EditOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {apiClient} from "../../../../../services/ApiClient";
import {
  SchedulerPeriods,
  TemplateScheduleType
} from "../../../../common/AppEnums";

import moment from "moment";
import TransactionTemplateDialog from "../../../settings/components/txtemplates/TransactionTemplateDialog";

const { Option } = Select;

export default function AssignTransactionTemplateDialog({className, visible, record, driver, onOK, onCancel}) {
  let [scheduleType, setScheduleType] = useState(null);
  let [templates, setTemplates] = useState([]);
  let [suggestedTriggerTime, setSuggestedTriggerTime] = useState("");
  let [editedPersonalTemplate, setEditedPersonalTemplate] = useState(null);
  let [selectedTemplate, setSelectedTemplate] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    if(record) {
      if(record.id > 0) {
        form.setFieldsValue({
          template: record.payment_template.id,
          schedule_type: record.schedule_type,
          regular_period: record.regular_period,
          pattern_run_days: record.pattern_run_days,
          pattern_skip_days: record.pattern_skip_days,
          skip_first: record.delay_days > 0,
          start_dt: moment(record.start_dt),
          execution_time: moment(`01-01-2000 ${record.execution_time}`),
        });
        setScheduleType(record.schedule_type);
        setSelectedTemplate(record.payment_template);
      }
      apiClient.getTransactionTemplates({driver_id: driver.id})
        .then(res => {
          setTemplates(res.records);
        })
        .catch(err => message.error(err));
    } else {
      form.resetFields();
      setTemplates([]);
      setSelectedTemplate(null);
    }
  }, [record, form]);

  const updateTriggerTimeSuggestion = values => {
    setSuggestedTriggerTime("");
    apiClient.getTransactionTemplateAssignmentTriggerTimeSuggestion(values)
      .then(res => {
        if(res.data) {
          setSuggestedTriggerTime(moment(res.data).format("DD.MM.YYYY HH:mm:ss"));
        }
      })
      .catch(err => {
        console.warn(err);
      });
  };

  function mapValues(values) {
    return {
      "template": values.template,
      "schedule_type": values.schedule_type,
      "regular_period": values.regular_period,
      "pattern_run_days": values.pattern_run_days,
      "pattern_skip_days": values.pattern_skip_days,
      "skip_first": values.skip_first,
      "start_dt": moment(values.start_dt).format('YYYY-MM-DD'),
      "execution_time": moment(values.execution_time).format('HH:mm:ss'),
    };
  }

  const updatePersonalTemplate = (id, params) => {
    apiClient.updateDriverTransactionTemplate(driver.id, id, params)
      .then(res => {
        apiClient.getTransactionTemplates({driver_id: driver.id})
          .then(res => { setTemplates(res.records); })
          .catch(err => message.error(err));
      })
      .catch(err => message.error(err));
  };

  const addPersonalTemplate = params => {
    apiClient.addDriverTransactionTemplate(driver.id, params)
      .then(res => {
        const record = res.record;
        apiClient.getTransactionTemplates({driver_id: driver.id})
          .then(res => {
            setTemplates(res.records);
            setSelectedTemplate(record);
            form.setFieldsValue({template: record.id});
          })
          .catch(err => message.error(err));
      })
      .catch(err => message.error(err));
  };

  function draw() {
    return (
      <Modal
        className={className}
        title="Назначение шаблона"
        visible={visible}
        okText={'Выполнить'}
        destroyOnClose={true}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              onOK({id: record.id, ...mapValues(values)});
            })
            .catch(info => {
              message.info("Проверьте корректность заполнения формы")
            });
        }}
        onCancel={() => onCancel()}
      >
        <Form
          form={form}
          layout="vertical"
          onValuesChange={(changedValues, allValues) => {
            updateTriggerTimeSuggestion(mapValues(allValues));
          }}
        >
          <Space direction={"horizontal"} style={{width: "100%"}}>
            <Form.Item
              label="Шаблон списаний"
              name="template"
              rules={[
                {
                  required: true,
                  message: 'Укажите шаблон',
                },
              ]}
            >
              <Select bordered
                      onChange={(value) => {
                        setSelectedTemplate(templates.filter(el => el.id === value)[0]);
                      }}
                      allowClear={false}
                      style={{width: 400}}>
                {
                  templates.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
                }
              </Select>
            </Form.Item>
            <Button type={"link"}
                    style={{marginTop: 20}}
                    icon={<PlusCircleOutlined/>}
                    onClick={() => { setEditedPersonalTemplate({id: 0}); }}/>
            {
              selectedTemplate && selectedTemplate.driver
                ? <Button type={"link"}
                          style={{marginTop: 20}}
                          icon={<EditOutlined/>}
                          onClick={() => { setEditedPersonalTemplate(selectedTemplate); }}/>
                : null
            }
          </Space>

          <Form.Item
            label="Порядок списания"
            name="schedule_type"
            rules={[
              {
                required: true,
                message: 'Укажите порядок списания',
              },
            ]}
          >
            <Select bordered
                    style={{width: 400}}
                    allowClear={false}
                    onChange={(value) => { setScheduleType(value); }}>
              {
                TemplateScheduleType.map(el => <Option key={el.value} value={el.value}>{el.text}</Option>)
              }
            </Select>
          </Form.Item>

          <Form.Item
            hidden={scheduleType !== "regular"}
            label="Периодичность списаний"
            name="regular_period"
            rules={[
              {
                required: scheduleType === "regular",
                message: 'Укажите периодичность  списания',
              },
            ]}
          >
            <Select bordered allowClear={false}>
              {
                SchedulerPeriods
                  .filter(e => e.visible)
                  .map(e => <Option key={e.value} value={e.value}>{e.text}</Option>)
              }
            </Select>
          </Form.Item>

          <Form.Item
            hidden={scheduleType !== "pattern"}
            label="Дней списаний"
            name="pattern_run_days"
            rules={[
              {
                required: scheduleType === "pattern",
                message: 'Укажите периодичность списания',
              },
            ]}
          >
            <Input allowClear={true} placeholder="Дней списаний" type={"number"}/>
          </Form.Item>

          <Form.Item
            hidden={scheduleType !== "pattern"}
            label="Дней пропуска"
            name="pattern_skip_days"
            rules={[
              {
                required: scheduleType === "pattern",
                message: 'Укажите периодичность списания',
              },
            ]}
          >
            <Input allowClear={true} placeholder="Дней списаний" type={"number"}/>
          </Form.Item>

          <Form.Item
            label="Дата начала"
            name="start_dt"
            initialValue={moment()}
            rules={[
              {
                required: true,
                message: 'Укажите дату начала применения шаблона',
              },
            ]}
          >
            <DatePicker style={{width:"220px"}} format="DD.MM.YYYY" />
          </Form.Item>

          <Form.Item
            label="Время списания"
            name="execution_time"
            initialValue={moment("2021-01-01 12:00:00")}
            rules={[
              {
                required: true,
                message: 'Укажите время списания',
              },
            ]}
          >
            <TimePicker style={{width:"220px"}}/>
          </Form.Item>

          <Form.Item
            label={"Пропустить первый день"}
            name="skip_first"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          {
            suggestedTriggerTime
              ? (<div>Планируемое списание по шаблону: {suggestedTriggerTime}</div>)
              : (<div>Планируемое списание по шаблону: <i>заполните форму</i></div>)
          }

        </Form>
        <TransactionTemplateDialog
          title={"Изменить запись"}
          visible={editedPersonalTemplate !== null}
          record={editedPersonalTemplate}
          onOk={({id, ...params}) => {
            if (id > 0) {
              updatePersonalTemplate(id, params);
            } else {
              addPersonalTemplate(params);
            }
            setEditedPersonalTemplate(null);
          }}
          onCancel={() => {
            setEditedPersonalTemplate(null)
          }}/>
      </Modal>
    );
  }

  return draw();
}