import axios from "axios";

class DaDataService {
  requestConfig() {
    const config = {
      responseType: 'json',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Token " + process.env.REACT_APP_DADATA_API_KEY
      }
    };
    return config;
  }

  _post(url, data) {
    return new Promise(((resolve, reject) => {
      try {
        axios.post(url, data, this.requestConfig())
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            console.error(err);
            reject(err.message);
          });
      } catch (e) {
        // Sentry.captureException(e);
        reject(e.message);
      }
    }));
  }

  suggestFio(query) {
    return this._post("https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio", {query: query});
  }

  suggestEmail(query) {
    return this._post("https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/email", {query: query});
  }

  suggestAddress(query) {
    return this._post("https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address", {query: query});
  }

  suggestLegalCompany(query) {
    return this._post("https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party", {query: query, type: "LEGAL"});
  }

  suggestIndividualCompany(query, type) {
    return this._post("https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party", {query: query, type: "INDIVIDUAL"});
  }

}

const daDataService = new DaDataService();

export {
  daDataService
}