import React, {useEffect, useState} from "react";
import {Button, message, Popover, Space, Table} from "antd";
import {BellTwoTone, DownloadOutlined, EyeOutlined, ReloadOutlined} from "@ant-design/icons";
import moment from "moment";
import {PhotoControlStatuses} from "../../../common/AppEnums";
import {apiClient} from "../../../../services/ApiClient";
import ControlDetails from "./ControlDetails";
import UpdateButton from "../../../buttons/UpdateButton";
import DownloadButton from "../../../buttons/DownloadButton";

export default function ControlTab({search, filterByDriver, status, active}) {
  let [loading, setLoading] = useState(true);
  let [records, setRecords] = useState([]);
  let [selectedRecord, setSelectedRecord] = useState(null);
  let [page, setPage] = useState(1);
  let [pageSize, setPageSize] = useState(50);
  let [total, setTotal] = useState(0);
  let [update, setUpdate] = useState(0);

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    {
      title: "",
      align: "center",
      dataIndex: "id",
      width: 50,
      render: function(text, record, index) {
        return (
          <Space direction={"horizontal"}>
            <Button type="link" icon={<EyeOutlined/>} onClick={() => {setSelectedRecord(record); return true; }}/>
          </Space>
        );
      }
    },
    {
      title: 'Дата прохождения',
      width: 180,
      align: "center",
      dataIndex: 'create_dt',
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
      },
      sorter: (a, b) => {
        return moment(a.create_dt).unix() - moment(b.create_dt).unix();
      }
    },
    {
      title: 'Статус',
      width: 180,
      align: "center",
      dataIndex: 'status',
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return (
          <Popover
            title={<div style={{textAlign: "center"}}>Обновлено</div>}
            content={`${moment(record.update_dt).format('DD.MM.YYYY HH:mm')}`}>
            {
              record.status === "updated"
                ? <span style={{margin: '0 10px'}}><BellTwoTone className="blink" twoToneColor="#eb2f96"/></span>
                : null
            }
            <span style={{textDecoration: 'underline', textDecorationStyle: 'dashed', cursor: 'default'}}>{`${PhotoControlStatuses[record.status]}`}</span>
          </Popover>
        );
      },
    },
    {
      title: 'Номер ТС',
      dataIndex: 'vehicle_number',
      align: "center",
    },
    {
      title: 'Водитель',
      dataIndex: 'id',
      render: (text, record, index) => {
        if(!record.driver_info || !record.driver_info.last_name) {
          return "";
        }
        return `${record.driver_info.last_name} ${record.driver_info.first_name} ${record.driver_info.middle_name} (${record.driver_info.phone})`;
      },
    },
    {
      title: 'Пробег',
      dataIndex: 'mileage',
      align: "center",
    },
    {
      title: 'Принял',
      align: "center",
      render: (text, record, index) => {
        if(!record.completed_by) {
          return "";
        }
        return `${record.completed_by.name} (${record.completed_by.username})`;
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  useEffect(() => {
    if(active) {
      const filters = {search, filterByDriver, status, page, pageSize};
      const subscription = apiClient.subscribeControlRecords(filters, ({records, total}) => {
        setRecords(records);
        setTotal(total);
        setLoading(false);
      });
      return () => apiClient.unsubscribe(subscription);
    }
  }, [search, filterByDriver, status, active, update, page, pageSize]);

  useEffect(() => {
    setLoading(active);
  }, [status, filterByDriver, search, active, update, page, pageSize]);

  return (
    <>
      <div style={{width: '10%', float: 'right'}}>
        <Space style={{float: "right"}}>
          <UpdateButton icon={<ReloadOutlined/>} onClick={ () => setUpdate(update + 1) }/>
          <DownloadButton onClick={ () => {
            const filters = {
              search,
              filterByDriver,
              status,
              page: 1,
              pageSize: 999999,
              startDate: moment().subtract(1, 'months').format('DD.MM.YYYY'),
              endDate: moment().format('DD.MM.YYYY')
            };
            setLoading(true);
            apiClient.exportControlRecords(filters, 'xlsx')
              .then(res => {
                window.open(res.link);
              })
              .catch(err => message.error(err))
              .finally(() => { setLoading(false); })
          } }/>
        </Space>
      </div>

      <Table
        loading={loading}
        columns={cols}
        size="small"
        rowKey="id"
        scroll={{x: 1000}}
        sticky
        dataSource={records}
        pagination={{
          position: "topRight",
          pageSizeOptions: [50, 100, 200],
          defaultPageSize: pageSize,
          current: page,
          total: total,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }
        }}
      />

      <ControlDetails
        title={"Фотоконтроль"}
        visible={selectedRecord !== null}
        record={selectedRecord}
        onClose={() => {
          setSelectedRecord(null)
        }}
        onAccepted={() => {
          setRecords(items => items.filter(e => {
            return e.id !== selectedRecord.id;
          }));
          setSelectedRecord(null);
        }}
        onRejected={() => {
          setRecords(items => items.filter(e => {
            return e.id !== selectedRecord.id;
          }));
          setSelectedRecord(null);
        }}
        onItemPropertyChange={(propName, propValue) => {
          selectedRecord[propName] = propValue;
          setRecords(items => items.map(e => {
            if (e.id === selectedRecord.id) {
              e[propName] = propValue;
            }
            return e;
          }));
          setSelectedRecord(selectedRecord);
        }}/>
    </>
  );
}