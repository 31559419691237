import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../../services/ApiClient";
import {Button, message, Modal, Space, Switch, Table} from "antd";
import {EditFilled, QuestionOutlined, CaretUpOutlined, CaretDownOutlined} from "@ant-design/icons";
import WorkflowStepEdit from "./WorkflowStepEdit";

export default function WorkflowStepsEditor({active, title, process}) {
  const [loading, setLoading] = useState(false);
  const [descriptor, setDescriptor] = useState([]);
  const [editStep, setEditStep] = useState(null);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setLoading(true);
    apiClient.getPhotoControlWorkflowDescriptor(process)
      .then(res => {
        setDescriptor(res.record)
      })
      .catch(err => message.error(err))
      .finally(() => {
        setLoading(false);
      })
  }, [active, process]);

  const removeSelected = () => {
    Modal.confirm({
      title:  "Подтверждение",
      content: `Вы уверены, что хотите удалить ${selected.length} записей?`,
      icon: <QuestionOutlined/>,
      visible: true,
      onOk() {
        apiClient.deletePhotoControlWorkflowDescriptorSteps(process, selected)
          .then(res => {
            setDescriptor(res.record);
            setSelected([]);
          })
          .catch(err => message.error(err));
      }
    });
  };

  const addStep = step => {
    apiClient.addPhotoControlWorkflowDescriptorStep(process, step)
      .then(res => {
        setDescriptor(res.record);
        message.info("Процесс обновлен");
      })
      .catch(err => { message.error(err) });
  };

  const updateStep = (code, step) => {
    apiClient.updatePhotoControlWorkflowDescriptorStep(process, step)
      .then(res => {
        setDescriptor(res.record);
        message.info("Процесс обновлен");
      })
      .catch(err => { message.error(err) });
  };

  return (
    <>
      <Space direction={"horizontal"} style={{marginBottom: 10}}>
        <Button type="primary" onClick={() => { setEditStep({code: ""}) }} loading={loading}>
          Создать шаг
        </Button>
        {
          selected.length > 0
            ? (<Button type={"danger"} onClick={() => {
                removeSelected()
              }} loading={loading}>
                Удалить
              </Button>
            ) : null
        }
      </Space>
      <Table
        dataSource={descriptor}
        scroll={{x: 1000, y: 600}}
        sticky
        columns={[
          {
            title: "",
            align: "center",
            width: 30,
          },
          Table.SELECTION_COLUMN,
          {
            title: "",
            width: 50,
            align: "center",
            dataIndex: "id",
            render: function(text, record, index) {
              return (
                <Space direction={"horizontal"}>
                  <Button type="link" icon={<EditFilled/>} onClick={() => {setEditStep(record);}}/>
                </Space>
              );
            }
          },
          {
            title: 'Шаг',
            dataIndex: 'text',
          },
          {
            title: 'Включено',
            dataIndex: 'enabled',
            width: 120,
            render: (text, record, index) => {
              return (
                <Switch checked={record.enabled}
                        checkedChildren={"Отключить"}
                        unCheckedChildren={"Включить"}
                        onChange={(checked, event) => {
                          record.enabled = checked;
                          apiClient.updatePhotoControlWorkflowDescriptorStep(process, record)
                            .then(res => setDescriptor(res.record))
                            .catch(err => message.error(err));
                        }}/>
              )
            },
          },
          {
            title: 'Порядок',
            width: 100,
            render: (text, record, index) => {
              return (
                <Space direction={"vertical"} size={"small"}>
                  <Button size={"small"} type="link" icon={<CaretUpOutlined />} onClick={() => {
                    apiClient.upPhotoControlWorkflowDescriptorStep(process, record)
                      .then(res => setDescriptor(res.record))
                      .catch(err => message.error(err))
                  }}/>
                  <Button size={"small"} type="link" icon={<CaretDownOutlined />} onClick={() => {
                    apiClient.downPhotoControlWorkflowDescriptorStep(process, record)
                      .then(res => setDescriptor(res.record))
                      .catch(err => message.error(err))
                  }}/>
                </Space>
              )
            },
          },
        ]}
        size='small'
        rowKey="code"
        rowSelection={{
          selectedRecords: selected,
          onChange: (selectedRowKeys) => {
            setSelected(selectedRowKeys)
          }
        }}
        pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [50, 100, 200]}}
      />
      <WorkflowStepEdit
        step={editStep}
        visible={editStep !== null}
        onOk={(step) => {
          console.log(step);
          if(!step.code) {
            addStep(step);
          } else {
            updateStep(editStep.code, step)
          }
          setEditStep(null);
        }}
        onCancel={() => { setEditStep(null) }}
        />
    </>
  )
}