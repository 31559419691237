import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import PrivateRoute from "./components/common/PrivateRoute";
import './App.less';
import LoginPage from "./components/auth/LoginPage";
import ControlPanelArea from "./components/controlpanel/ControlPanelArea";
import CarReturnPage from "./components/controlpanel/carreturn/CarReturnPage";
import PaymentPage from "./components/payments/PaymentPage";
import CarReturnCompletePage from "./components/controlpanel/carreturn/CarReturnCompletePage";
import CompetitionResultPage from "./components/controlpanel/competitions/CompetitionResultPage";
import TeamCompetitionResultPage from "./components/controlpanel/competitions/TeamCompetitionResultPage";
import DriverCandidatePage from "./components/controlpanel/drivermanager/DriverCandidatePage";

function App() {
  return (
      <Router>
        <Switch>
          <Route path="/login">
            <LoginPage/>
          </Route>
          <Route path="/vozvrat/:tenantCode">
            <CarReturnPage/>
          </Route>
          <Route path="/vozvrat">
            <CarReturnPage/>
          </Route>
          <Route path="/vozvrat-complete/:tenantCode">
            <CarReturnCompletePage/>
          </Route>
          <Route path="/candidates/:tenantCode">
            <DriverCandidatePage/>
          </Route>
          <Route path="/payrent">
            <PaymentPage
              tenantCode="49278d83-2044-4f17-bca9-483aed1727ac"
              paymentProviders={[
                {
                  "id": "sbp",
                  "title": "СБП",
                  "commission": 0.008,
                  "minCommission": 0,
                },
                {
                  "id": "payonline",
                  "title": "PayOnline",
                  "commission": 0.021,
                  "minCommission": 23
                }
              ]}
              infoContent={(
                <>
                  <div>
                    Уважаемый арендатор оплаты для оплаты транспортного средства по договору правильно укажите: Номер телефона, Номер транспортного средства.
                  </div>
                  <div>
                    Подтверждение оплаты вы можете посмотреть в телеграм боте @Poletdriverbot
                  </div>
                  <div>
                    Возврат и перерасчёт арендной платы осуществляется Вам на карту по согласованию с менеджерами компании.
                  </div>
                </>
              )}
            />
          </Route>
          <Route path="/pulospay">
            <PaymentPage
              tenantCode="3b717139-5820-4c24-9b4f-de0f223c27f0"
              paymentProviders={[
                {
                  "id": "sbp",
                  "title": "СБП",
                  "commission": 0.015,
                  "minCommission": 0,
                }
              ]}
              infoContent={(
                <>
                  <div>
                    Уважаемый арендатор оплаты для оплаты транспортного средства по договору правильно укажите: Номер телефона, Номер транспортного средства.
                  </div>
                  <div>
                    Возврат и перерасчёт арендной платы осуществляется Вам на карту по согласованию с менеджерами компании.
                  </div>
                </>
              )}
            />
          </Route>
          <Route path="/competitions/:uid">
            <CompetitionResultPage/>
          </Route>
          <Route path="/team-competitions/:uid">
            <TeamCompetitionResultPage/>
          </Route>
          <PrivateRoute path="/cp">
            <ControlPanelArea/>
          </PrivateRoute>
          <Route path="/">
            <Redirect to={'/cp'}/>
          </Route>
        </Switch>
      </Router>
  );
}

export default App;
