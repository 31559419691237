import React, {useEffect, useState} from "react";
import {Button, Space, Table, message} from "antd";
import moment from "moment";
import {apiClient} from "../../../../services/ApiClient";
import {ReloadOutlined} from "@ant-design/icons";
import UpdateButton from "../../../buttons/UpdateButton";

export default function VehiclePropertiesChangeHistory({vehicle, active}) {
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [update, setUpdate] = useState(0);

  useEffect(() => {
    if(vehicle && active) {
      setLoading(true);
      apiClient.getVehicleChangeHistory(vehicle.id)
        .then(res => {
          setRecords(res.records);
        })
        .catch(err => message.error(err))
        .finally(() => setLoading(false));
    } else {
      setRecords([]);
    }
  }, [vehicle, active, update]);

  return (
    <>
      <div>
        <Space style={{marginBottom: 16, float: "right"}} direction="horizontal">
          <UpdateButton loading={loading} onClick={() => { setUpdate(u => u + 1) }}/>
        </Space>
      </div>
      <Table
        loading={loading}
        size={'small'}
        scroll={{x: 1000}}
        sticky
        columns={[
          {
            title: "",
            align: "center",
            width: 30,
          },
          {
            title: 'Дата изменения',
            render: (text, record, index) => {
              return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
            }
          },
          {
            title: 'Реквизит',
            render: (text, record, index) => {
              return record.attr_name;
            }
          },
          {
            title: 'Старое значение',
            render: (text, record, index) => {
              return record.attr_old_value;
            }
          },
          {
            title: 'Новое значение',
            render: (text, record, index) => {
              return record.attr_new_value;
            }
          },
          {
            title: 'Пользователь',
            render: (text, record, index) => {
              return record.created_by ? record.created_by.username : "Система";
            }
          },
          {
            title: "",
            align: "center",
            width: 20,
          }
        ]}
        dataSource={records}
        rowKey="id"
      />
    </>
  )
}