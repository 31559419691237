import React, {useEffect, useState} from "react";
import {Button, message, Space, Table, Modal, PageHeader} from "antd";
import {EditFilled, QuestionOutlined} from "@ant-design/icons";

import moment from "moment";

import {apiClient} from "../../../services/ApiClient";
import TeamAddDialog from "./components/TeamAddDialog";
import TeamEditDialog from "./components/TeamEditDialog";

export default function TeamsPage() {
  let [loading, setLoading] = useState(false);
  let [dsLoading, setDsLoading] = useState(false);
  let [editedRecord, setEditedRecord] = useState(null);
  let [selectedRecords, setSelectedRecords] = useState([]);
  let [ds, setDs] = useState([]);
  let [update, setUpdate] = useState(0);
  let [addDialogVisible, setAddDialogVisible] = useState(false);

  const cols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: "",
      width: 50,
      align: "center",
      dataIndex: "id",
      render: function(text, record, index) {
        return (
          <Space direction={"horizontal"}>
            <Button type="link" icon={<EditFilled/>} onClick={() => {setEditedRecord(record);}}/>
          </Space>
        );
      }
    },
    {
      title: 'Создано',
      dataIndex: 'create_dt',
      align: "center",
      render: (text, record, index) => {
        return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
      },
    },
    {
      title: 'Наименование',
      dataIndex: 'title',
      align: "center",
    },
    {
      title: 'Водителей',
      dataIndex: 'drivers',
      render: (text, record, index) => {
        return record.drivers.length;
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  const removeSelected = () => {
    Modal.confirm({
      title:  "Подтверждение",
      content: `Вы уверены, что хотите удалить ${selectedRecords.length} записей?`,
      icon: <QuestionOutlined/>,
      visible: true,
      onOk() {
        let proms = selectedRecords.map(el => apiClient.removeCompetitionTeam(el));

        setLoading(true);
        Promise.all(proms)
          .catch(err => {
            message.error("Ошибка, операция не удалась");
          })
          .finally(() => {
            setSelectedRecords([]);
            setLoading(false);
            setUpdate(prevState => prevState + 1);
          });
      }
    });
  };

  useEffect(() => {
    setDsLoading(true);
    apiClient.getCompetitionTeams()
      .then(res => {
        setDs(res.records);
      })
      .catch(err => {
        message.error("Не удалось загрузить список");
      })
      .finally(() => {
        setDsLoading(false);
      });
  }, [update]);

  const addTeam = (title, drivers) => {
    setLoading(true);
    apiClient.addCompetitionTeam(title, drivers)
      .then(res => {
        message.info("Команда добавлена");
        setUpdate(prevState => prevState + 1);
      })
      .catch(err => {
        message.error("Ошибка при выполнении операции");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateTeam = editedRecord => {
    setLoading(true);
    apiClient.updateCompetitionTeam(editedRecord.id, {title: editedRecord.title, drivers: editedRecord.drivers})
      .then(res => {
        message.info("Конкурс обновлен");
        setUpdate(prevState => prevState + 1);
      })
      .catch(err => {
        message.error("Не удалось загрузить список конкурсов");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Команды"
      />
      <div>
        <Space style={{float: "right", marginBottom: 16}} direction="horizontal">
          <Button type="primary" onClick={() => { setAddDialogVisible(true) }} loading={loading}>
            Создать
          </Button>
          {
            selectedRecords.length > 0
              ? (<Button type={"danger"} onClick={() => {
                  removeSelected()
                }} loading={loading}>
                  Удалить
                </Button>
              ) : null
          }
        </Space>
        <span style={{marginLeft: 8}}>
          <span style={{float: "right"}}>
            {selectedRecords.length > 0 ? `Выбрано ${selectedRecords.length} записей` : ''}
          </span>
        </span>
      </div>
      <Table rowSelection={{ selectedRecords, onChange: (selectedRowKeys) => { setSelectedRecords(selectedRowKeys) } }}
             pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [25, 50, 75]}}
             columns={cols}
             dataSource={ds}
             scroll={{x: 1000}}
             size="small"
             sticky
             loading={dsLoading}
             rowKey="id"/>

      <TeamEditDialog visible={editedRecord !== null}
                              record={editedRecord}
                              onOk={(result) => {
                                updateTeam(result);
                                setEditedRecord(null);
                              }}
                              onClose={() => {
                                setEditedRecord(null);
                              }}/>
      <TeamAddDialog visible={addDialogVisible}
                             onOk={(title, drivers) => {
                               addTeam(title, drivers);
                               setAddDialogVisible(false);
                             }}
                             onClose={() => {
                               setAddDialogVisible(false);
                             }}/>
    </>
  )
}