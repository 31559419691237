import React, {useEffect, useState} from "react";
import {Button, Space, Table, message} from "antd";
import moment from "moment";
import {apiClient} from "../../../../services/ApiClient";
import {ReloadOutlined} from "@ant-design/icons";
import UpdateButton from "../../../buttons/UpdateButton";
import {windowService} from "../../../../services/WindowService";

export default function VehicleRentHistory({vehicle, active}) {
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [update, setUpdate] = useState(0);

  useEffect(() => {
    if(active && vehicle && vehicle.id > 0) {
      setLoading(true);
      apiClient.getVehicleRentHistory(vehicle.id)
        .then(res => {
          setRecords(res.records);
        })
        .catch(err => message.error(err))
        .finally(() => setLoading(false));
    } else {
      setRecords([]);
    }
  }, [vehicle, active, update]);

  return (
    <>
      <div>
        <Space style={{marginBottom: 16, float: "right"}} direction="horizontal">
          <UpdateButton loading={loading} onClick={() => { setUpdate(u => u + 1) }}/>
        </Space>
      </div>
      <Table
        loading={loading}
        size={'small'}
        sticky
        columns={[
          {
            title: "",
            align: "center",
            width: 30,
          },
          {
            title: 'Начало периода',
            align: "center",
            width: 200,
            render: (text, record, index) => {
              return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
            }
          },
          {
            title: 'Конец периода',
            align: "center",
            width: 200,
            render: (text, record, index) => {
              return record.delete_dt
                ? `${moment(record.delete_dt).format('DD.MM.YYYY HH:mm')}`
                : "";
            }
          },
          {
            title: 'Водитель',
            render: (text, record, index) => {
              return record.driver
                ? (
                  <Button
                    type={"link"}
                    onClick={() => { windowService.openRouteWindow(`cp/drivers/${record.driver.id}`); }}
                  >
                    {record.driver.name}
                  </Button>
                ) : "";
            }
          },
          {
            title: "",
            align: "center",
            width: 20,
          }
        ]}
        dataSource={records}
        rowKey="id"
      />
    </>
  )
}