import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../services/ApiClient";
import {Button, Input, message, Modal, Space, Table} from "antd";
import {EditFilled, PrinterOutlined, QuestionOutlined} from "@ant-design/icons";
import moment from "moment";
import {RentContractStatus} from "../../../common/AppEnums";
import RentContractEdit from "../contract/RentContractEdit";
import PrintFormSelectDialog from "../../common/PrintFormSelectDialog";
import UpdateButton from "../../../buttons/UpdateButton";
import {windowService} from "../../../../services/WindowService";

const {Search} = Input;

export default function VehicleContracts({vehicle, active}) {
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [update, setUpdate] = useState(0);
  let [selected, setSelected] = useState(null);
  let [printRecord, setPrintRecord] = useState(null);
  let [search, setSearch] = useState("");

  useEffect(() => {
    if(vehicle && active) {
      setLoading(true);
      apiClient.getVehicleRentContracts(vehicle.id, {search})
        .then(res => {
          setRecords(res.records);
        })
        .catch(err => message.error(err))
        .finally(() => setLoading(false));
    } else {
      setRecords([]);
    }
  }, [active, vehicle, search, update]);

  const handlePrint = (template, contract) => {
    apiClient.renderRentContractTemplate(contract.id, template.id)
      .then(res => {
        window.open(res.record.url, '_blank');
      })
      .catch(err => message.error(err));
  };

  return (
    <>
      <div>
        <Space style={{marginBottom: 16, float: "left"}} direction="horizontal">
          <Search
            allowClear
            placeholder="Поиск по номеру"
            onSearch={(value) => setSearch(value)}
            style={{width: 300}}
            enterButton
          />
        </Space>
        <Space style={{marginBottom: 16, float: "right"}} direction="horizontal">
          <UpdateButton onClick={() => { setUpdate(u => u + 1) }}/>
          <Button onClick={() => { setSelected({id: 0}) }} type={"primary"}>
            Создать
          </Button>
        </Space>
      </div>
      <Table
        loading={loading}
        size={'small'}
        scroll={{x: 1400}}
        sticky
        columns={[
          {
            title: "",
            align: "center",
            width: 10,
          },
          {
            title: '',
            render: (text, record, index) => {
              return (
                <Space direction={"horizontal"}>
                  <Button
                    type="link"
                    icon={<EditFilled/>}
                    disabled={!record?.id}
                    onClick={() => {
                      setLoading(true);
                      apiClient.getRentContract(record.id)
                        .then(res => {
                          setSelected(res.record);
                        })
                        .catch(err => message.error(err))
                        .finally(() => setLoading(false));
                    }}
                  />
                  <Button type={"link"} onClick={() => { setPrintRecord(record) }}>
                    <PrinterOutlined/>
                  </Button>
                </Space>
              )
            }
          },
          {
            title: 'Дата',
            render: (text, record, index) => {
              return record.contract_dt ? `${moment(record.contract_dt).format('DD.MM.YYYY')}` : "";
            },
            defaultSortOrder: 'descend',
          },
          {
            title: 'Номер',
            dataIndex: 'contract_number',
          },
          {
            title: 'Статус',
            render: (text, record, index) => {
              return RentContractStatus.filter(el => el.id === record.status)[0].title;
            }
          },
          {
            title: 'Водитель',
            width: 400,
            render: (text, record, index) => {
              return record.driver
                ? (
                  <Button
                    type={"link"}
                    onClick={() => { windowService.openRouteWindow(`cp/drivers/${record.driver.id}`); }}
                  >
                    {record.driver.name}
                  </Button>
                ) : "";
            }
          },
          {
            title: 'Начало действия',
            render: (text, record, index) => {
              return record.contract_start_dt ? `${moment(record.contract_start_dt).format('DD.MM.YYYY')}` : "";
            }
          },
          {
            title: 'Окончание действия',
            render: (text, record, index) => {
              return record.contract_end_dt ? `${moment(record.contract_end_dt).format('DD.MM.YYYY')}` : "";
            }
          },
          {
            title: 'Стоимость аренды в сутки',
            dataIndex: 'rent_day_price',
          },
          {
            title: 'Ответственный',
            dataIndex: 'responsible',
            render: (text, record, index) => {
              return record.responsible?.login;
            }
          },
          {
            title: "",
            align: "center",
            width: 10,
          }
        ]}
        dataSource={records}
        rowKey="id"
      />

      <RentContractEdit
        title={"Договор аренды"}
        visible={selected !== null}
        vehicle={vehicle}
        contract={selected}
        onOk={(record) => {
          setLoading(true);
          if(selected.id && selected.id > 0) {
            apiClient.updateRentContract(selected.id, record)
              .then(res => {
                setSelected(null);
                setUpdate(prevState => prevState + 1);
                message.info("Договор успешно обновлен");
              })
              .catch(err => message.error(err))
              .finally(() => setLoading(false));
          } else {
            apiClient.createRentContract(vehicle.id, record)
              .then(res => {
                setSelected(null);
                setUpdate(prevState => prevState + 1);
                message.info("Договор успешно создан");
              })
              .catch(err => message.error(err))
              .finally(() => setLoading(false));
          }
          if(record.work_condition && record.work_condition_dt && record.driver) {
            Modal.confirm({
              title:  "Подтверждение",
              content: `Назначить ${record.driver.last_name} ${record.driver.first_name} ${record.driver.middle_name} условия работы ${record.work_condition.title}?`,
              icon: <QuestionOutlined/>,
              visible: true,
              onOk() {
                apiClient.updateDriverWorkCondition(record.driver.id, {id: record.work_condition.id, date: record.work_condition_dt})
                  .then(() => {
                    message.success("Условия работы назначены");
                  })
                  .catch(err => {
                    message.error('Ошибка, не удалось выполнить операцию');
                  });
              }
            });
          }

        }}
        onClose={() => {
          setSelected(null);
        }}
      />
      <PrintFormSelectDialog
        visible={printRecord !== null}
        modelClass={"crm_pm_vehicle_contract"}
        unitType={printRecord?.owner?.type}
        title={"Выберите печатную форму"}
        onOk={(template) => {
          handlePrint(template, printRecord);
          setPrintRecord(null);
        }}
        onCancel={() => {
          setPrintRecord(null);
        }}
      />
    </>
  )
}