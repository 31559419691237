import React, {useEffect, useState} from "react";
import EmptyPage from "../EmptyPage";
import {Checkbox, Col, Input, message, Modal, Row, Select} from "antd";
import {apiClient} from "../../../services/ApiClient";
import moment from "moment";
import {QuestionOutlined} from "@ant-design/icons";
import '../ModalStyle.css';

const { Option } = Select;
const rowStyle = {padding: '8px'};

export default function CkadDebtDetails({visible, recordId, onClose}) {

  let [record, setRecord] = useState(null);
  let [update, setUpdate] = useState(0);
  let [drivers, setDrivers] = useState([]);
  let [driversSearch, setDriversSearch] = useState("");

  useEffect(() => {
    if(visible) {
      apiClient.getCkadDebt(recordId)
        .then(res => {
          setRecord(res.record)
        })
        .catch(err => message.error(err));
    } else {
      setRecord(null);
    }
  }, [visible, recordId, update]);

  useEffect(() => {
    if(record) {
      if(record.driver) {
        setDrivers([record.driver]);
      }
      apiClient.getDriversList({ accountStatus: 'all', search: driversSearch }, 'simple')
        .then(res => {
          setDrivers(res.drivers.map(el => {return {id: el.id, title: `${el.last_name} ${el.first_name} ${el.middle_name} (${el.is_working ? 'работает' : 'уволен'})`}}));
        })
        .catch(err => { console.error(err) });
    } else {
      setDrivers([]);
    }
  }, [record, driversSearch]);

  const content = () => {
    return (
      <>
        <Row gutter={[8, 0]} align="middle" style={rowStyle}>
          <Col md={6}>Дата задолженности</Col>
          <Col md={12}>
            <Input
              placeholder="руб"
              value={record.debt_dt
                ? moment(record.debt_dt).format('DD.MM.YYYY HH:mm') :
                "нет данных"
              }
              readOnly={true}/>
          </Col>
        </Row>

        <Row gutter={[8, 0]} align="middle" style={rowStyle}>
          <Col md={6}>Гос. номер</Col>
          <Col md={12}>
            <Input
              placeholder="Гос. номер"
              value={record.vehicle_reg_number}
              readOnly={true}/>
          </Col>
        </Row>

        <Row gutter={[8, 0]} align="middle" style={rowStyle}>
          <Col md={6}>Сумма задолженности</Col>
          <Col md={12}>
            <Input
              placeholder="Сумма задолженности"
              value={record.amount}
              readOnly={true}/>
          </Col>
        </Row>

        <Row gutter={[8, 0]} align="middle" style={rowStyle}>
          <Col md={6}>Транспортое средство</Col>
          <Col md={12}>
            <Input
              placeholder="Транспортое средство"
              value={record.vehicle?.title}
              readOnly={true}/>
          </Col>
        </Row>

        <Row gutter={[8, 0]} align="middle" style={rowStyle}>
          <Col md={6}>Водитель</Col>
          <Col md={12}>
            <Select
              showSearch
              style={{width: '100%'}}
              value={record.driver?.id}
              onChange={(value) => {
                if (value) {
                  Modal.confirm({
                    title: "Подтверждение",
                    content: `Назначить выбранному водителю?`,
                    icon: <QuestionOutlined/>,
                    visible: true,
                    onOk() {
                      apiClient.updateCkadDebt(record.id, {driver: value})
                        .then(setUpdate(prev => prev + 1))
                    }
                  });
                }
              }}
              onSearch={(value) => {
                setDriversSearch(value);
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                drivers.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
              }
            </Select>
          </Col>
        </Row>

        <Row gutter={[8, 0]} align="middle" style={rowStyle}>
          <Col md={6}>Списан</Col>
          <Col md={12}>
            <Checkbox style={{color: "#FFFFFF"}} checked={record.is_payed}/>
          </Col>
        </Row>

      </>
    );
  };

  return (
    <Modal
      title={"Задолженность ЦКАД"}
      visible={visible}
      className="size-for-middle-window"
      style={{ top: 20 }}
      onOk={() => {
        onClose(update > 0);
      }}
      onCancel={() => {
        onClose(false);
      }}
    >
      {
        record ? content() : <EmptyPage/>
      }
    </Modal>
  )
}