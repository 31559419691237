import React, { useEffect, useState} from "react";
import { Button, Space, Table} from "antd";
import "../../ModalStyle.css";
import { format } from 'date-fns';
import {DeleteOutlined} from '@ant-design/icons';
import moment from "moment";
import {apiClient} from "../../../../services/ApiClient";
import CreateTransactionDialog from "./CreateTransactionDialog";
import {AccidentTransactionTypes} from "../../../common/AppEnums";

export default function TransactionTab({onCreateTransaction, onDeleteTransaction, data, active, dataLoading}) {
  let [loading, setLoading] = useState(false);
  let [transaction, setTransaction] = useState([]);
  let [visible, setVisible] = useState(false);
  let [driversList, setDriversList] = useState([]);

  useEffect(() => {
    apiClient.getCrmUser()
      .then(res => {
        setDriversList(res.records);
      })
      .catch(err => { console.error(err) });
    setTransaction(data);
  }, [data, active]);

  useEffect(() => {
    setLoading(dataLoading);
  }, [dataLoading]);

  const culpritCols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    {
      title: 'Дата транзакции',
      align: "center",
      dataIndex: 'date',
      render: (text, record, index) => {
        const date = new Date(record.date);
        return format(date, "dd.MM.yyyy HH:mm");
      },
      sorter: (a, b) => {
        return moment(a.date).valueOf() - moment(b.date).valueOf();
      },
    },
    {
      title: 'Тип',
      align: "center",
      dataIndex: 'transaction_type',
      render: (text, record, index) => {
        return AccidentTransactionTypes.find(el => el.id === record.transaction_type)?.title
      },
      sorter: (a, b) => {
        return a.responsible_person_id.localeCompare(b.responsible_person_id);
      },
    },
    {
      title: 'Сумма транзакции',
      align: "center",
      dataIndex: 'amount',
      sorter: (a, b) => {
        return a.amount - b.amount;
      },
    },
    {
      title: 'Ответственное лицо',
      align: "center",
      dataIndex: 'responsible_person_id',
      render: (text, record, index) => {
        let res;
        if (driversList.length !== 0 && record.responsible_person_id !== "") {
          let type = driversList.find((s) => s.id === record.responsible_person_id);
          res = type !== undefined ? type.first_name : "";
        }
        return res;
      },
      sorter: (a, b) => {
        return a.responsible_person_id.localeCompare(b.responsible_person_id);
      },
    },
    {
      title: 'Комментарий',
      align: "center",
      dataIndex: 'comment',
    },
    {
      title: "",
      align: "center",
      width: 30,
      render: (text, record, index) => {
        return (
          <Space direction="horizontal">
            <Button
              icon={<DeleteOutlined style={{color: "#FACC2B"}} />}
              onClick={(event) => { onDeleteTransaction(record.id) }}
              type="link"
            />
          </Space>
        );
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  return (
    <>
      <div style={{width: '100%'}}>
        <div style={{width: '20%', float: 'right'}} >
          <Space style={{float: "right"}} direction="horizontal">
            <Button type="primary" loading={loading} onClick={() => {setVisible(true);}}>
              Добавить транзакцию
            </Button>
          </Space>
        </div>
      </div>
      <Table
        style={{width: '100%'}}
        loading={loading}
        columns={culpritCols}
        dataSource={transaction}
        rowKey="id"
        size='small'
        pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [50, 100, 200]}}
      />
      <CreateTransactionDialog
        visible={visible}
        onOk={(data) => {
          setVisible(false);
          onCreateTransaction(data);
        }}
        onCancel={() => { setVisible(false); }}
      />
    </>
  );
};