import React, {useContext, useEffect, useState} from "react";
import {message, Space, Table, Input, Select, DatePicker, Button, Modal, Radio, Divider} from "antd";
import {DeleteOutlined, EditFilled} from '@ant-design/icons';
import {apiClient} from "../../../services/ApiClient";
import { format } from 'date-fns';
import moment from "moment";
import DtpDialog from "./DtpDialog";
import CancelButton from "../../buttons/CancelButton";
import LinkButton from "../../buttons/LinkButton";
import {windowService} from "../../../services/WindowService";
import numeral from "numeral";
import UserProfileContext from "../../../services/UserProfileContext";

const {Search} = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function DtpTab() {

  const userProfile = useContext(UserProfileContext);

  let [loading, setLoading] = useState(false);
  let [createDtp, setCreateDtp] = useState(null);
  let [changeDtp, setChangeDtp] = useState(null);
  let [dtp, setDtp] = useState([]);
  let [filters, setFilters] = useState({state: 0});
  let [selectedDtp, setSelectedDtp] = useState([]);
  let [regions, setRegions] = useState([]);
  let [filtersList, setFiltersList] = useState([]);
  let [driversList, setDriversList] = useState([]);
  let [summary, setSummary] = useState({});

  const usersCols = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    Table.SELECTION_COLUMN,
    {
      title: "",
      align: "center",
      width: 50,
      render: (text, record, index) => {
        return (
          <Space direction="horizontal">
            <Button
              icon={<EditFilled/>}
              onClick={(event) => { showDtp(record) }}
              type="link"
            />
          </Space>
        );
      },
    },
    {
      title: 'Дата, время ДТП',
      align: "center",
      dataIndex: 'accident_date',
      render: (text, record, index) => {
        let res = record.accident_date ? record.accident_date : "";
        const date = res !== "" ? new Date(res) : "";
        return date !== "" ? format(date, "dd.MM.yyyy HH:mm") : "";
      },
      sorter: (a, b) => {
        return moment(a.accident_date).valueOf() - moment(b.accident_date).valueOf();
      },
    },
    {
      title: 'Дата создания ДТП',
      align: "center",
      dataIndex: 'creation_dt',
      defaultSortOrder: "descend",
      render: (text, record, index) => {
        return record.creation_dt ?  format(new Date(record.creation_dt), "dd.MM.yyyy HH:mm") : record.creation_dt;
      },
      sorter: (a, b) => {
        return moment(a.creation_dt).valueOf() - moment(b.creation_dt).valueOf();
      },
    },
    {
      title: 'Авто',
      align: "center",
      dataIndex: 'car_reg_number',
      sorter: (a, b) => {
        return a.car_reg_number.localeCompare(b.car_reg_number);
      },
      render: (text, record, index) => {
        return (
          <LinkButton
            label={record.car_reg_number}
            onClick={() => {
              windowService.openRouteWindow(`cp/park/vehicles/${record.car_id}`);
            }}
          />
        );
      }
    },
    {
      title: 'ФИО водителя',
      align: "center",
      dataIndex: 'driver_fio',
      render: (text, record, index) => {
        return (
          <LinkButton
            label={record.driver?.display_name}
            onClick={() => {
              windowService.openRouteWindow(`cp/drivers/${record.driver?.id}`);
            }}
          />
        );
      },
      sorter: (a, b) => {
        return a.driver_fio.localeCompare(b.driver_fio);
      },
    },
    {
      title: 'Виновник ДТП',
      align: "center",
      dataIndex: 'culprit_type',
      render: (text, record, index) => {
        let res;
        if (record.culprit_type !== null) {
          let type = filtersList.culprit_type ? filtersList.culprit_type.find((s) => s[0] === record.culprit_type) : "";
          res = type !== "" ? type[1] : "";
        }
        return record.culprit_type === null ? "" : res;
      },
      sorter: (a, b) => {
        return a.culprit_type.localeCompare(b.culprit_type);
      },
    },
    {
      title: 'Тип оформления',
      align: "center",
      dataIndex: 'registration_type',
      render: (text, record, index) => {
        let res;
        if (record.registration_type !== null) {
          let type = filtersList.registration_type ? filtersList.registration_type.find((s) => s[0] === record.registration_type) : "";
          res = type !== "" ? type[1] : "";
        }
        return record.registration_type === null ? "" : res;
      },
      sorter: (a, b) => {
        return a.registration_type.localeCompare(b.registration_type);
      },
    },
    {
      title: 'Гос. номер авто второго участника ДТП',
      align: "center",
      dataIndex: 'third_party_driver_reg_number',
      sorter: (a, b) => {
        return a.third_party_driver_reg_number.localeCompare(b.third_party_driver_reg_number);
      },
    },
    {
      title: 'Место осмотра',
      align: "center",
      dataIndex: 'inspection_place',
      render: (text, record, index) => {
        let res;
        if (record.inspection_place) {
          let type = filtersList.inspection_place ? filtersList.inspection_place.find((s) => s[0] === record.inspection_place) : "";
          res = type !== "" ? type[1] : "";
        }
        return record.inspection_place ? res : "";
      }
    },
    {
      title: 'Стоимость ремонта',
      align: "center",
      dataIndex: 'repair_amount',
    },
    {
      title: 'Долг',
      align: "center",
      dataIndex: 'driver_debt',
    },
    {
      title: 'Сумма выплаченная водителем',
      align: "center",
      dataIndex: 'current_paid',
    },
    {
      title: 'Остаток долга за ДТП',
      align: "center",
      render: (text, record, index) => {
        return numeral(parseFloat(record?.driver_debt) - parseFloat(record?.current_paid)).format("0.00")
      },
      sorter: (a, b) => {
        return (parseFloat(a.driver_debt) - parseFloat(a.current_paid)) - (parseFloat(b.driver_debt) - parseFloat(b.current_paid));
      },
    },
    {
      title: 'Статус долга',
      align: "center",
      dataIndex: 'debt_status',
      render: (text, record, index) => {
        let res;
        if (record.debt_status) {
          let type = filtersList.debt_status ? filtersList.debt_status.find((s) => s[0] === record.debt_status) : "";
          res = type !== "" ? type[1] : "";
        }
        return record.debt_status ? res : "";
      },
    },
    {
      title: 'Ответственное лицо',
      align: "center",
      dataIndex: 'responsible_person_id',
      render: (text, record, index) => {
        let res = record.responsible_person_id;
        if (driversList.length !== 0 && res !== "") {
          let type = driversList.find((s) => s.id === res);
          res = type !== undefined ? type.first_name : "";
        }
        return res;
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  const canViewSummary = () => {
    return userProfile.is_superuser || userProfile.permissions.some(el => el === "dtp_view_summary");
  };

  const showDtp = (record) => {
    console.debug("edit record: ", record);
    setChangeDtp(record);
  };

  const onSelectChange = selectedRowKeys => {
    setSelectedDtp(selectedRowKeys);
  };

  function createParams(defaultValues) {
    return {
      ...defaultValues,
      reg_number: filters.vehicle,
      fio: filters.driver,
      osago_number: filters.osago,
      date_from: filters.date_from !== "" && filters.date_from !== undefined ? filters.date_from : null,
      date_to: filters.date_to !== "" && filters.date_to !== undefined ? filters.date_to : null,
      accident_types: filters.culprit_type,
      registration_type: filters.registration_type,
      // inspection_place: filters.inspection_place,
      debt_status: filters.debt_status,
      work_region_id: filters.work_region_id,
      status: filters.status,
      show_only_archived: filters.archived === "show_only_archived" ? 1 : undefined,
      show_only_with_debt: filters.paymentDebt === "show_only_with_debt" ? 1 : undefined
    };
  }

  const onDeleteSelected = () => {
    Modal.confirm({
      title:  "Удаление",
      content: "Вы уверены, что хотите удалить записи?",
      icon: <DeleteOutlined/>,
      visible: true,
      onOk() {
        apiClient.removeDtp(selectedDtp)
          .then(res => {
            message.success("ДТП удалено");
            setSelectedDtp([]);
            setFilters({...filters, ...{state: filters.state + 1}});
          })
          .catch(err => message.error(err));
      },
    });
  };

  const onUpdateDtp = (dtp) => {
    if(dtp.osago === false) {
      dtp["osago_active"] = false;
    }
    dtp["saved"] = true;
    apiClient.updateDtp(dtp.id, dtp)
      .then(res => {
        message.success("ДТП обновлено");
        setFilters({...filters, ...{state: filters.state + 1}});
      })
      .catch(err => message.error(err));
  };

  const handleRegistrationTypeChange = (value) => {
    const registrationType = Object.keys(value).length !== 0 ? value.toString() : undefined;
    setFilters({...filters, ...{registration_type: registrationType}});
  };

  const handleAccidentTypeChange = (value) => {
    const accidentType = Object.keys(value).length !== 0 ? value.toString() : undefined;
    setFilters({...filters, ...{culprit_type: accidentType}});
  };

  const handleDebtStatusChange = (value) => {
    const debtStatus = Object.keys(value).length !== 0 ? value.toString() : undefined;
    setFilters({...filters, ...{debt_status: debtStatus}});
  };

  const handleRegionChange = (value) => {
    const region = Object.keys(value).length !== 0 ? value.toString() : undefined;
    setFilters({...filters, ...{work_region_id: region}});
  };

  const handleDtpStatusChange = (value) => {
    const dtpStatus = Object.keys(value).length !== 0 ? value.toString() : undefined;
    setFilters({...filters, ...{status: dtpStatus}});
  };

  const handleExportStatusChange = () => {
    let params = createParams({xl_export: 1});
    apiClient.getAllDtp(params)
      .then(res => {
        window.open(res.link);
      })
      .catch(err => {
        message.warn("Ошибка экспорта данных");
        console.error(err)
      });
  };

  useEffect(() => {
    setLoading(true);
    apiClient.getListsForDtp()
      .then(res => {
        setFiltersList({...filtersList, ...{
          culprit_type: res.accident_types,
          debt_status: res.debt_status,
          inspection_place: res.inspection_place,
          registration_type: res.registration_type,
          accident_statuses: res.accident_statuses
        }});
        setLoading(false);
      })
      .catch(err => {
        message.error(err);
      });
  }, []);

  useEffect(() => {
    apiClient.getRegions({"order": "title"})
      .then(res => { setRegions(res.records) })
      .catch(err => { console.error(err) });
  }, []);

  useEffect(() => {
    const params = createParams({});
    setLoading(true);
    apiClient.getAllDtp(params)
      .then(res => {
        let data = res.records;
        data.map((s) => s.driver_fio = s.driver?.display_name);
        data.map((s) => s.taxi_driver_id = s.driver?.id);
        setDtp(data);
      })
      .catch(err => { console.error(err) })
      .finally(() => {
        setLoading(false);
      })
  }, [filters]);

  useEffect(() => {
    setLoading(true);
    apiClient.getDtpSummary({from_dt: filters.date_from, to_dt: filters.date_to})
      .then(res => {
        setSummary(res);
      })
      .catch(err => message.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, [filters]);

  useEffect(() => {
    apiClient.getCrmUser()
      .then(res => {
        setDriversList(res.records);
      })
      .catch(err => { console.error(err) });
  }, []);

  return (
    <>
      {
        canViewSummary() && (
          <>
            <div>
              <span style={{marginLeft: 8}}>
                <span style={{float: "right"}}>
                  <Space direction={"horizontal"} size={"small"} split={<Divider type={"vertical"} orientationMargin={0}/>}>
                    <span>Наш водитель: {summary?.out_driver || 0}</span>
                    <span>Другой водитель (со страховкой): {summary?.other_driver_ensured || 0}</span>
                    <span>Другой водитель (без страховки): {summary?.other_drivet_unensured || 0}</span>
                    <span>Стоимость ремонта: {summary?.repair_amount || 0}</span>
                    <span>Сумма выплат: {summary?.payments_done || 0}</span>
                    <span>Остаток выплат: {summary?.payments_wait || 0}</span>
                  </Space>
                </span>
              </span>
            </div>
            <div style={{float: "clear"}}>&nbsp;</div>
          </>
        )
      }
      <div style={{width: '100%'}}>
        <div style={{width: '80%', float: 'right'}} >
          <Space style={{float: "right", marginBottom: 16}} wrap direction="horizontal">
            {selectedDtp.length !== 0 && (
              <Button type="danger" loading={loading} onClick={onDeleteSelected}>
                Удалить ДТП
              </Button>
            )}
              <Button type="primary" onClick={() => {
                const params = {
                  culprit_type: "OUR_DRIVER_CULPRIT"
                };
                apiClient.createDtp(params)
                  .then(res => {
                    setCreateDtp(res.record);
                  })
                  .catch((err) => message.error(err));
              }}>
                Добавить ДТП
              </Button>
              <CancelButton value="Экспорт" onClick={handleExportStatusChange}/>
          </Space>
        </div>
      </div>
      <div>
        <span style={{marginLeft: 8}}>
          <span style={{float: "left"}}>
            <Space direction={"horizontal"} size={"small"} split={<Divider type={"vertical"}/>}>
              <span>{`Найдено ${dtp.length} записей`}</span>
              <span>Выбрано {selectedDtp.length} записей</span>
            </Space>
          </span>
        </span>
      </div>
      <div style={{width: '100%', float: 'left'}} >
        <Space direction={"horizontal"} wrap style={{marginBottom: 16, marginTop: 10}}>
          <Search
            enterButton
            placeholder={"Поиск по водителю"}
            onChange={(e) => { setFilters({...filters, ...{driver: e.target.value}}); }}
            allowClear={true}
            style={{width: 350}}
          />
          <Search
            enterButton
            placeholder={"Поиск по номеру авто"}
            onChange={(e) => { setFilters({...filters, ...{vehicle: e.target.value}}); }}
            allowClear={true}
            style={{width: 350}}
          />
          <Search
            enterButton
            placeholder={"Поиск по ОСАГО"}
            onChange={(e) => { setFilters({...filters, ...{osago: e.target.value}}); }}
            allowClear={true}
            style={{width: 350}}
          />
          <RangePicker
            allowClear={true}
            style={{width: 350}} 
            onChange={(dates, dateStrings) => { setFilters({...filters, date_from: dateStrings[0], date_to: dateStrings[1]}); }}
          />
          <Select
            allowClear
            mode="multiple"
            placeholder="Тип оформления"
            style={{width: 300}}
            onChange={handleRegistrationTypeChange}
          >
            {
              filtersList.registration_type ? filtersList.registration_type.map(el => <Option key={el[0]} value={el[0]}>{`${el[1]}`}</Option>) : []
            }
          </Select>
          <Select
            allowClear
            mode="multiple"
            placeholder="Виновник ДТП"
            style={{width: 400}}
            onChange={handleAccidentTypeChange}
          >
            {
              filtersList.culprit_type ? filtersList.culprit_type.map(el => <Option key={el[0]} value={el[0]}>{`${el[1]}`}</Option>) : []
            }
          </Select>
          <Select
            allowClear
            mode="multiple"
            placeholder="Статус долга"
            style={{width: 300}}
            onChange={handleDebtStatusChange}
          >
            {
              filtersList.debt_status ? filtersList.debt_status.map(el => <Option key={el[0]} value={el[0]}>{`${el[1]}`}</Option>) : []
            }
          </Select>
          <Select
            allowClear
            mode="multiple"
            placeholder="Регион"
            style={{width: 300}}
            onChange={handleRegionChange}
          >
            {
              regions ? regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>) : []
            }
          </Select>
          <Select
            allowClear
            mode="multiple"
            placeholder="Статус ДТП"
            style={{width: 300}}
            onChange={handleDtpStatusChange}
          >
            {
              filtersList.accident_statuses ? filtersList.accident_statuses.map(el => <Option key={el[0]} value={el[0]}>{`${el[1]}`}</Option>) : []
            }
          </Select>
          <Radio.Group
            value={filters && filters.archived ? filters.archived : "dtp"}
            onChange={(e) => {
              setFilters({...filters, ...{archived: e.target.value}});
            }}
          >
            <Radio.Button value="dtp">Все ДТП</Radio.Button>
            <Radio.Button value="show_only_archived">Только архивные</Radio.Button>
          </Radio.Group>
          <Radio.Group
            value={filters && filters.paymentDebt ? filters.paymentDebt : "all"}
            onChange={(e) => {
              setFilters({...filters, ...{paymentDebt: e.target.value}});
            }}
          >
            <Radio.Button value="show_only_with_debt">Должники</Radio.Button>
            <Radio.Button value="all">Все ДТП</Radio.Button>
          </Radio.Group>
        </Space>
      </div>

      <Table
        loading={loading}
        rowSelection={{selectedDtp, onChange: onSelectChange}}
        columns={usersCols}
        dataSource={dtp}
        rowKey="id"
        size='small'
        scroll={{x: 2000}}
        sticky
        pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [50, 100, 200]}}
      />

      {
        createDtp !== null && (
          <DtpDialog
            visible={createDtp != null}
            record={createDtp}
            type="new"
            onOk={(dtp, id) => {
              setCreateDtp(null);
              onUpdateDtp(dtp);
            }}
            onCancel={() => {
              setCreateDtp(null);
              setFilters({...filters, ...{state: filters.state + 1}});
            }}
          />
        )
      }

      {
        changeDtp !== null && (
          <DtpDialog
            visible={changeDtp !== null}
            type="dtp"
            record={changeDtp}
            onOk={(dtp, id) => {
              setChangeDtp(null);
              onUpdateDtp(dtp);
            }}
            onCancel={() => {
              setChangeDtp(null);
              setFilters({...filters, ...{state: filters.state + 1}});
            }}
          />
        )
      }
    </>
  )
}